import { PostPreviewProps } from '@components/PostsFeed/components/PostPreview/PostPreview';

export const news: PaginatedData<PostPreviewProps> = {
	data: [
		{
			title:
				'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip в качестве делового партнёра',
			url: '/events/event',
			date: '2020-08-02 13:00:00',
			picture: {
				src: '/temp/event-1.png',
				width: 1280,
				height: 920,
				alt: '',
			},
		},
		{
			title:
				'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
			url: '/events/event',
			date: '2020-09-25 13:00:00',
			picture: {
				src: '/temp/event-2.png',
				width: 1280,
				height: 920,
				alt: '',
			},
		},
		{
			title:
				'Этим летом Генеральный директор компании FENSMA совершил поход на самую высокую точку Европы — Эльбрус',
			url: '/events/event',
			date: '2020-09-28 13:00:00',
			picture: {
				src: '/temp/event-3.png',
				width: 1280,
				height: 920,
				alt: '',
			},
		},
		{
			title:
				'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip в качестве делового партнёра',
			url: '/events/event',
			date: '2020-10-12 13:00:00',
			picture: {
				src: '/temp/event-4.png',
				width: 1280,
				height: 920,
				alt: '',
			},
		},
	],
};

export const news2020: PaginatedData<PostPreviewProps> = {
	data: [
		{
			title:
				'Этим летом Генеральный директор компании FENSMA совершил поход на самую высокую точку Европы — Эльбрус',
			url: '/events/event',
			date: '2020-09-28 13:00:00',
			picture: {
				src: '/temp/event-3.png',
				width: 1280,
				height: 920,
				alt: '',
			},
		},
		{
			title:
				'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip в качестве делового партнёра',
			url: '/events/event',
			date: '2020-10-12 13:00:00',
			picture: {
				src: '/temp/event-4.png',
				width: 1280,
				height: 920,
				alt: '',
			},
		},
		{
			title:
				'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip в качестве делового партнёра',
			url: '/events/event',
			date: '2020-08-02 13:00:00',
			picture: {
				src: '/temp/event-1.png',
				width: 1280,
				height: 920,
				alt: '',
			},
		},
		{
			title:
				'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
			url: '/events/event',
			date: '2020-09-25 13:00:00',
			picture: {
				src: '/temp/event-2.png',
				width: 1280,
				height: 920,
				alt: '',
			},
		},
	],
};

export const news2019: PaginatedData<PostPreviewProps> = {
	data: [
		{
			title:
				'Этим летом Генеральный директор компании FENSMA совершил поход на самую высокую точку Европы — Эльбрус',
			url: '/events/event',
			date: '2020-09-28 13:00:00',
			picture: {
				src: '/temp/event-3.png',
				width: 1280,
				height: 920,
				alt: '',
			},
		},
		{
			title:
				'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
			url: '/events/event',
			date: '2020-09-25 13:00:00',
			picture: {
				src: '/temp/event-2.png',
				width: 1280,
				height: 920,
				alt: '',
			},
		},
		{
			title:
				'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip в качестве делового партнёра',
			url: '/events/event',
			date: '2020-10-12 13:00:00',
			picture: {
				src: '/temp/event-4.png',
				width: 1280,
				height: 920,
				alt: '',
			},
		},
	],
};
