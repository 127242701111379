import css from './Article.module.scss';
import text from '@core/Text/Text.module.scss';
import classnames from 'classnames';
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { Box, Container, Icon, LinkTag, PrimitiveKeys, Tag, Text } from '@core';
import { euFormatDate, normalize } from '@utils';
import { useScrollWithEase } from '@hooks';
import { ViewportContext } from '@context';
import { ShareProps, Share } from '@core/Share/Share';
import fastdom from 'fastdom';

export interface ArticleProps {
	head?: ArticleHead;
	items?: Array<{ type: PrimitiveKeys; content?: string }>;
	pictures?: Array<Picture>;
	sharing?: ShareProps;
}

export const Article: FC<ArticleProps> = React.memo(({ head, items, pictures, sharing }) => {
	const { bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	return (
		<article className={css.article}>
			<Container className={css.layout}>
				<>{!isMob && pictures && <ArticleAside pictures={pictures} />}</>
				<Box className={css.content}>
					{head && (
						<header className={css.heading}>
							{head?.title && (
								<>
									{head?.url && (
										<Tag className={css.title} type="h3">
											<LinkTag href={head.url}>
												{head.title}
												<Icon id="link" className={css.arrow} />
											</LinkTag>
										</Tag>
									)}
									{!head?.url && (
										<Tag className={css.title} type="h3" content={head.title} isHTML={true} />
									)}
								</>
							)}
							{head?.date && (
								<time className={classnames(css.date, text.md)}>
									{euFormatDate(head.date, { withMonthName: true })}
								</time>
							)}
						</header>
					)}
					{isMob && pictures && pictures[0] && (
						<picture className={css.mobPicture}>
							<Image
								src={pictures[0].src}
								alt={pictures[0].alt}
								width={pictures[0].width}
								height={pictures[0].height}
								layout="intrinsic"
								sizes="(max-width: 639px) 100vw, 30vw"
							/>
						</picture>
					)}
					<Text className={classnames(css.articleText, 'wysiwyg')} size="md" color="gray">
						{items?.map((item, i) => {
							return <Tag key={item?.content?.slice(i, i + 8) + '-' + i} {...item} />;
						})}
					</Text>
					{sharing && <Share {...sharing} />}
				</Box>
				{/*
				{isMob && pictures && pictures[1] && (
					<picture className={css.mobPicture}>
						<Image
							src={pictures[1].src}
							alt={pictures[1].alt}
							width={pictures[1].width}
							height={pictures[1].height}
							layout="intrinsic"
						/>
					</picture>
				)}
				*/}
			</Container>
		</article>
	);
});

export const ArticleAside: FC<Pick<ArticleProps, 'pictures'>> = ({ pictures }) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const refBox = useRef<HTMLDivElement | null>(null);

	// const { vh } = useContext(ViewportContext);
	const [seek, setSeek] = useState(0);

	const onScroll = useCallback(
		(current: number, height: number, posY?: number) => {
			let boxHeight = 0;
			fastdom.measure(() => refBox?.current?.offsetHeight || 0);
			if (posY) {
				height = height - boxHeight;
				const scroll = Math.min(Math.abs(Math.min(current, 0)), height);
				setSeek(scroll / height);
			}
		},
		[refBox]
	);

	useScrollWithEase(ref, onScroll, {
		ease: 0.25,
		edge: 'top',
	});

	useEffect(() => {
		fastdom.mutate(() => {
			const aside = ref?.current;
			const pictures = Array.from(aside?.querySelectorAll(`picture`) || []);

			if (pictures?.length) {
				const stages = getStages(seek, pictures.length * 2).slice(1);

				pictures?.slice(1).forEach((pic, i) => {
					pic.style.clipPath = `polygon(0 0, ${stages[i * 2] * 100}% 0, ${
						stages[i * 2] * 100
					}% 100%, 0% 100%)`;
				});
			}
		});
	}, [seek]);

	return (
		<Box className={css.aside} ref={ref}>
			<Box className={css.asideBox} ref={refBox}>
				{pictures?.map((pic) => (
					<picture key={pic.src}>
						<Image
							src={pic.src}
							alt={pic.alt}
							layout="fill"
							sizes="(max-width: 639px) 100vw, 30vw"
							// layout="intrinsic"
							// width={pic.width}
							// height={pic.height}
						/>
					</picture>
				))}
			</Box>
		</Box>
	);
};

const getStages = (seek: number, length: number) => {
	let prev = 0;

	const frag = 1 / length;
	const stages = [];

	for (let i = 0; i < length; i++) {
		stages.push(normalize(seek, prev + frag, prev, true));
		prev = prev + frag;
	}

	return stages;
};
