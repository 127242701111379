import css from './CardsSlider.module.scss';
import classnames from 'classnames';
import React, {
	FC,
	PropsWithChildren,
	HTMLAttributes,
	useRef,
	useEffect,
	LegacyRef,
	useState,
	useContext,
} from 'react';
import { Box, DragCursor, ProgressBar } from '@core';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useMousePosition } from '@hooks';
import { ViewportContext } from '@context';

interface CardsTickerFeedProps {
	items: Array<any>;
	ChildCard: FC<any>;
	classNameCard?: string;
	classNameProgress?: string;
	progressBar?: boolean;
}

export const CardsSlider: FC<
	PropsWithChildren<CardsTickerFeedProps & HTMLAttributes<HTMLElement>>
> = React.memo(({ items, className, classNameCard, classNameProgress, progressBar, ChildCard }) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const { bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	const splideRef = useRef<Splide>();
	const { px, py } = useMousePosition<HTMLDivElement | null>(ref);

	useEffect(() => {
		const ref = splideRef.current;
		if (ref) {
			ref?.splide?.go(0);
		}
	}, [items]);

	const [progress, setProgress] = useState(0);

	const updateCounts = (splide: any) => {
		if (splide) {
			const end = splide.Components.Controller.getEnd();
			const cur = splide.Components.Controller.getIndex();

			setProgress(cur / end);
		}
	};

	return (
		<>
			<Box ref={ref} className={classnames(className, css.feed)}>
				<Splide
					ref={splideRef as LegacyRef<Splide>}
					onMove={updateCounts}
					onMounted={updateCounts}
					options={{
						type: 'slide',
						autoWidth: true,
						arrows: false,
						pagination: false,
						speed: isMob ? 800 : 600,
						rewindSpeed: isMob ? 1200 : 600,
					}}>
					{items?.map((card, i) => (
						<SplideSlide
							className={classnames(card?.slideClass)}
							key={`cards-slider-${i}-${className}`}>
							<ChildCard
								className={classnames(classNameCard, css.card)}
								index={i}
								total={items.length}
								{...card}
							/>
						</SplideSlide>
					))}
				</Splide>
				<DragCursor className={css.cursor} x={px} y={py} />
			</Box>
			{progressBar && (
				<ProgressBar seek={progress} withTransition={true} className={classNameProgress} />
			)}
		</>
	);
});
