import css from './TechDetails.module.scss';
import classnames from 'classnames';
import type { ContentType } from '@api/hooks/types';
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Container, Section, Box, Flex, Tag } from '@core';
import { useScrollWithEase } from '@hooks';
import { ViewportContext } from '@context';
import { TextBlock } from '@components/TextBlock/TextBlock';
import { FeaturesList } from '@components/FeaturesList/FeaturesList';
import { AnimationType } from '@components/animations';
import { animationComponents } from '@components/animations/Animation/Animation';
import { Performance } from '@utils';
import useIntersectionObserver from '@hooks/useIntersectionObserver';

type ContentSectionsType = 'textBlock' | 'featuresList';

interface Props {
	sections?: Array<ContentType>;
	animation?: AnimationType;
}

const content: Record<ContentSectionsType, FC> = {
	textBlock: TextBlock,
	featuresList: FeaturesList,
};

export const TechDetails: FC<Props> = React.memo(({ sections, animation = 'bimCubeView' }) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const { vh, bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';
	const [seek, setSeek] = useState(0);

	const onScroll = useCallback(
		(current: number, height: number) => {
			const start = height - vh;
			const end = current - vh;
			const absolute = 1 - end / start;
			const relative = Math.max(Math.min(absolute, 1), 0);

			setSeek(relative);
		},
		[vh]
	);

	useScrollWithEase(ref, onScroll, {
		ease: 0.25,
		edge: 'bottom',
	});

	return (
		<Section className={css.module} ref={ref}>
			<Container>
				<Flex>
					{!isMob && (
						<Box className={css.track}>
							<Box className={css.sticky}>
								<TechDetailsAnimation animation={animation} seek={seek} />
							</Box>
						</Box>
					)}
					<Box className={css.content}>
						{isMob && (
							<Box className={classnames(css.animation, css.section)}>
								<TechDetailsAnimation animation={animation} />
							</Box>
						)}
						<TechDetailsContent sections={sections} />
					</Box>
				</Flex>
			</Container>
		</Section>
	);
});

export const TechDetailsContent: FC<Pick<Props, 'sections'>> = React.memo(({ sections }) => {
	return (
		<>
			{sections?.map((section, i) => {
				if (!(section.type in content)) {
					throw new Error(`"type: ${section.type}" is not exist`);
				}
				const Section = content[section.type as ContentSectionsType];
				return <Section key={`cotnent-item-${i}`} className={css.section} {...section.content} />;
			})}
		</>
	);
});

export const TechDetailsAnimation: FC<{
	animation: AnimationType;
	seek?: number;
}> = React.memo(({ animation, seek }) => {
	const Component = animationComponents[animation];
	const ref = useRef<HTMLDivElement | null>(null);

	const entry = useIntersectionObserver(ref, {
		threshold: 0.5,
		freezeOnceVisible: true,
	});

	if (typeof seek === 'undefined') {
		return (
			<div ref={ref}>
				<TechDetailsAnimationLoop Component={Component} started={!!entry?.isIntersecting} />
			</div>
		);
	}

	return <Component seek={seek} />;
});

export const TechDetailsAnimationLoop: FC<{
	Component: FC<any>;
	started: boolean;
}> = ({ Component, started }) => {
	const [seek, setSeek] = useState(0);

	useEffect(() => {
		if (!started) return;

		const onTick = () => {
			const time = performance.now() * 0.0005;
			const start = -1;
			const end = 1;
			const current = Math.cos(time);
			setSeek((current - start) / (end - start));
		};

		Performance.addListener(onTick);

		return () => {
			Performance.removeListener(onTick);
		};
	}, [started]);

	return <Component seek={seek} />;
};

TechDetailsAnimation.displayName = 'TechDetailsAnimation';
TechDetailsContent.displayName = 'TechDetailsContent';
