import { FC } from 'react';
import dynamic from 'next/dynamic';
import type { AnimationType } from '@components/animations';
import type {
	BimCubeViewProps,
	BimFeaturesViewProps,
	CareerFeaturesViewProps,
	TwinFramesViewProps,
	EnvelopeViewProps,
} from '@components/animations';

export const animationComponents: Record<AnimationType, FC<any>> = {
	bimCubeView: dynamic<BimCubeViewProps>(
		() => import('@components/animations/common').then((mod) => mod.BimCubeView),
		{
			ssr: false,
			loading: () => <></>,
		}
	) as FC<BimCubeViewProps>,
	bimFeaturesView: dynamic<BimFeaturesViewProps>(
		() => import('@components/animations/common').then((mod) => mod.BimFeaturesView),
		{
			ssr: false,
			loading: () => <></>,
		}
	) as FC<BimFeaturesViewProps>,
	careerFeaturesView: dynamic<CareerFeaturesViewProps>(
		() =>
			import('@components/animations/CareerFeaturesView/CareerFeaturesView').then(
				(mod) => mod.CareerFeaturesView
			),
		{
			ssr: false,
			loading: () => <></>,
		}
	) as FC<CareerFeaturesViewProps>,
	twinFramesView: dynamic<TwinFramesViewProps>(
		() =>
			import('@components/animations/TwinFramesView/TwinFramesView').then(
				(mod) => mod.TwinFramesView
			),
		{
			ssr: false,
			loading: () => <></>,
		}
	) as FC<TwinFramesViewProps>,
	envelopeView: dynamic<EnvelopeViewProps>(
		() =>
			import('@components/animations/EnvelopeView/EnvelopeView').then((mod) => mod.EnvelopeView),
		{
			ssr: false,
			loading: () => <></>,
		}
	) as FC<EnvelopeViewProps>,
};

// interface AnimationProps {
// 	type: AnimationType;
// 	props: any;
// }
//
// export const AnimationComponent: FC<AnimationProps> = memo(({ type, props }) => {
// 	const DynamicComponent = animationComponents[type] || null;
// 	return <DynamicComponent {...props} />;
// });
