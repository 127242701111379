import { PageData } from '@api/hooks/types';
import { vacanciesEntityA, vacanciesEntityB } from '@api/mock/vacanciesList';

export const vacancies: PageData = {
	meta: {
		title: 'Вакансии',
		description: 'Вакансии в компании Fensma. Присоединяйся к нашей команде.',
	},
	data: [
		{
			type: 'intro',
			content: {
				title: 'Присоединяйся<br /><color-accent>к нашей команде</color-accent>',
				breadcrumbs: [{ text: 'Карьера', url: '/career' }, { text: 'Вакансии' }],
			},
		},

		{
			type: 'vacancies',
			links: {
				self: '/m-career/vacancies',
				next: '/m-career/vacancies?page[offset]=10',
			},
			content: {
				backLink: '/m-career/vacancies',
				title: 'Вакансии',
				filters: [
					{
						text: 'Все',
						key: 'section',
						value: null,
					},
					{
						text: 'Для офиса',
						key: 'section',
						value: 'office',
					},
					{
						text: 'Для стройплощадки',
						key: 'section',
						value: 'building-yard',
					},
				],
				extendedFilters: [
					{
						name: 'location',
						caption: 'Местонахождение',
						options: [
							{ label: 'Египет, Каир', value: 'egypt' },
							{ label: 'Москва', value: 'moscow' },
							{ label: 'Нижний Новгород', value: 'nnovgorod' },
							{ label: 'Санкт-Петербург', value: 'spb' },
						],
					},
					{
						name: 'experience',
						caption: 'Опыт работы',
						options: [
							{ label: 'Без опыта', value: 'without' },
							{ label: 'От 1 года до 3 лет', value: 'from-1-to-3' },
							{ label: 'От 3 до 6 лет', value: 'from-3-to-6' },
							{ label: 'Более 6 лет', value: '6-plus' },
						],
					},
					{
						name: 'employment',
						caption: 'Тип занятости',
						options: [
							{ label: 'Полная занятость', value: 'fulltime' },
							{ label: 'Вахтовый метод', value: 'rotational' },
							{ label: 'Частичная занятость', value: 'parttime' },
							{ label: 'Стажировка', value: 'traineeship' },
						],
					},
				],
				extendedFiltersCaption: 'Фильтры',
				items: [
					vacanciesEntityA,
					vacanciesEntityB,
					vacanciesEntityA,
					vacanciesEntityB,
					vacanciesEntityA,
					vacanciesEntityB,
					vacanciesEntityA,
					vacanciesEntityB,
				],
				emptyText:
					'По вашему запросу вакансии не найдены. Пожалуйста, измените настройки фильтров, чтобы увидеть другие вакансии.',

				// Для детального описания по ссылке типа /career/vacancies/id
				// details: vacancyDetails,
			},
			wrapper: {
				type: 'stripsVertical',
				props: {
					color: 'dark',
					startScreen: true,
					paddingBottom: true,
				},
			},
		},

		{
			type: 'feedback',
			content: {
				title: 'Не нашли<br /><color-accent>вакансию?</color-accent>',
				form: {
					action: '/forms/feedback',
					method: 'post',
					grcKey: '6LfURdcZAAAAAAz9aJ1T0WeUAeVGQI3RsarvmhRi',
					items: [
						{
							type: 'text',
							name: 'name',
							label: 'ФИО',
							required: true,
							validation: {
								required: 'Заполните это поле',
							},
						},
						{
							type: 'email',
							name: 'email',
							label: 'Электронная почта',
							required: true,
							pattern: '[0-9a-z_.-]+@[0-9a-z_]+\\.[a-z]{2,5}',
							validation: {
								required: 'Заполните это поле',
								pattern: 'Некорректный адрес email',
							},
						},
						{
							type: 'tel',
							name: 'phone',
							label: 'Номер телефона',
							required: true,
							mask: '+0 000 0000000',
							pattern: '^(\\+\\d)\\s\\d{3}\\s\\d{7}$',
							validation: {
								required: 'Заполните это поле',
								pattern: 'Некорректный номер телефона',
							},
						},
						{
							type: 'file',
							name: 'file',
							placeholder: 'Прикрепить резюме',
							accept: 'application/pdf',
							acceptLabel: 'pdf',
							sizeLimit: 52428800,
							sizeLimitLabel: 'До 50 Мб',
							validation: {
								accept: 'Недопустимый формат файла',
								size: 'Превышен допустимый размер файла',
							},
						},
					],
					button: 'Отправить',
					notice:
						'Нажимая «Отправить», я соглашаюсь с условиями<br /><a href="/" target="_blank">Политики конфиденциальности</a> и <a href="/" target="_blank">Пользовательского соглашения</a>',
				},
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'white',
					paddingTop: false,
					paddingBottom: false,
				},
			},
		},

		{
			type: 'goToNext',
			content: {
				pre: 'Перейти к следующему разделу',
				text: 'Работа у нас',
				url: '/career',
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'transparent',
					paddingTop: false,
					paddingBottom: false,
				},
			},
		},
	],
};
