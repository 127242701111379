import type { PageMeta } from '@api/hooks/types';
import React, { FC } from 'react';
import { PageLoaderProvider, ViewportProvider } from '@context';
import { SvgSprite } from '../components/SvgSprite/SvgSprite';
import { PageUp } from '@core/PageUp/PageUp';
import { PageTransition } from '../components/PageTransition/PageTransition';
import { Background } from '@components/Layout/components/Background/Background';
import { Header } from '../components/Header/Header';
import { Footer } from '../components/Footer/Footer';

export interface LayoutProps {
	meta?: PageMeta;
	simpleLayout?: boolean;
}

export const LayoutDefault: FC<LayoutProps> = ({ children, meta, simpleLayout }) => {
	return (
		<>
			<SvgSprite />
			<Background />
			<ViewportProvider>
				<PageLoaderProvider>
					<PageTransition>
						<Header langSwitch={meta?.langSwitch} />
						<main className="page__main">{children}</main>
						{!simpleLayout && (
							<>
								<Footer />
								<PageUp />
							</>
						)}
					</PageTransition>
				</PageLoaderProvider>
			</ViewportProvider>
		</>
	);
};
