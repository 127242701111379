import css from './Property.module.scss';
import React, { FC, PropsWithChildren } from 'react';
import { Box, LinkTag } from '@core';

interface Props {
	label?: string;
	text?: string;
	url?: string;
}

export const PropertyWrap: FC<PropsWithChildren<Pick<Props, 'url'>>> = ({ url, children }) => {
	return (
		<>
			{url && (
				<LinkTag className={css.property} href={url} withIcon={true}>
					{children}
				</LinkTag>
			)}
			{!url && <Box className={css.property}>{children}</Box>}
		</>
	);
};

export const Property: FC<PropsWithChildren<Props>> = ({ label, text, url }) => {
	return (
		<PropertyWrap url={url}>
			{label && <Box className={css.label}>{label}</Box>}
			<Box className={css.text}>{text}</Box>
		</PropertyWrap>
	);
};
