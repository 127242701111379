import css from './Logo.module.scss';
import classnames from 'classnames';
import fastdom from 'fastdom';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Box, Container } from '@core';
import { PageLoaderContext, ViewportContext } from '@context';
import { HomeIntroContext } from '@components/HomeIntro/HomeIntro';
import { normalize } from '@utils';
import { getFadeUpCss, getStages } from '@components/HomeIntro/utils';
import { useRouter } from 'next/router';
import { useIsomorphicLayoutEffect } from '@hooks';

interface Props {
	endPoint: number;
}

export const Logo: FC<Props> = React.memo(({ endPoint }) => {
	const [wrap, setWrap] = useState<HTMLDivElement | null>(null);

	const [tip, setTip] = useState<HTMLElement | null>(null);
	const [tipTrack, setTipTrack] = useState<HTMLElement | null>(null);
	const [word, setWord] = useState<HTMLElement | null>(null);
	const [letters, setLetters] = useState<NodeListOf<HTMLElement> | null>(null);

	const { bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	const { seek, config } = useContext(HomeIntroContext);

	/*
	 * Состояния загрузки
	 */
	const { loadingState, setLoadingState } = useContext(PageLoaderContext);

	useEffect(() => {
		if (loadingState === 'playing' && setLoadingState) {
			setTimeout(() => {
				setLoadingState('complete');
			}, 0);
		}
	}, [loadingState, setLoadingState]);

	/*
	 * DOM-элементы
	 */
	useEffect(() => {
		if (wrap) {
			setTip(wrap.querySelector<HTMLElement>(`.${css.tip}`));
			setTipTrack(wrap.querySelector<HTMLElement>(`.${css.tipTrack}`));
			setWord(wrap.querySelector<HTMLElement>(`.${css.word}`));
			setLetters(wrap.querySelectorAll<HTMLElement>(`.${css.letter}`));
		}
	}, [wrap]);

	/*
	 * Основная анимация
	 */
	useEffect(() => {
		fastdom.mutate(() => {
			if (tip && tipTrack) {
				const value = normalize(seek, 0.05, 0, true);

				tip.style.transform = `translate3d(0, ${value * 100}%, 0)`;
				tipTrack.style.opacity = (1 - value).toString();
			}

			if (word) {
				if (isMob) {
					const value = normalize(seek, 0, 0.5, true);
					word.style.transform = `scale(${1 - 0.36 * value}) translateZ(0)`;
				} else {
					const value = normalize(seek, 0.1, 0, true);
					word.style.transform = `scale(${1 - 0.32 * value}) translateZ(0)`;
				}
			}
		});
	}, [tip, tipTrack, word, seek, isMob]);

	/*
	 * Анимация прячет логотип по скроллу
	 */
	const screens = config?.count || 1;
	const seekFadeOut = normalize(seek, endPoint + 1 / screens, endPoint, true);
	const fadeOutStages = getStages(seekFadeOut, 6);

	useEffect(() => {
		fastdom.mutate(() => {
			if (letters?.length === 6) {
				letters.forEach((el, i) => {
					el.style.cssText = getFadeUpCss(fadeOutStages[5 - i], fadeOutStages[4 - i]);
				});
			}
		});
	}, [letters, fadeOutStages]);

	return (
		<Box
			ref={setWrap}
			className={classnames(css.module, {
				[css.playing]: loadingState !== 'waiting',
				[css.complete]: loadingState === 'complete',
			})}>
			<LogoElements />
		</Box>
	);
});

export const LogoElements: FC = React.memo(() => {
	const { locale } = useRouter();
	const tip = locale === 'en' ? 'Scroll down' : 'Листай дальше';

	return (
		<>
			<Box className={css.bg} />
			<Container className={classnames(css.container)}>
				<Box className={classnames(css.word)}>
					<LogoLetters />
				</Box>
			</Container>
			<Box className={css.tip}>
				<Box className={css.tipTrack}>
					<Box className={css.tipIcon}>
						<span>{tip}</span>
					</Box>
					<Box className={css.tipIcon}>
						<span>{tip}</span>
					</Box>
				</Box>
			</Box>
		</>
	);
});

export const LogoLetters: FC = React.memo(() => {
	const { setReadyState } = useContext(PageLoaderContext);

	const handleAnimationEnd = useCallback(() => {
		setReadyState && setReadyState(true);
	}, [setReadyState]);

	useIsomorphicLayoutEffect(() => {
		window.setTimeout(handleAnimationEnd, 3000);
	}, [handleAnimationEnd]);

	return (
		<>
			<Box className={css.letter}>
				<svg viewBox="0 0 112 149">
					<path d="M0 143V0h112v24.163H29.144v32.02h71.0v24.0H29.144V143z" />
				</svg>
			</Box>
			<Box className={css.letter}>
				<svg viewBox="0 0 112 149">
					<path d="M0 143V0h109.097v24.163h-79.25v31.772h71.203v24.163H29.846v38.74H112V143z" />
				</svg>
			</Box>
			<Box className={css.letter}>
				<svg viewBox="0 0 112 149">
					<path d="M0 0v143h26.393V49.017L83.464 143H112V0H85.607v95.81L27.611 0z" />
				</svg>
			</Box>
			<Box className={css.letter}>
				<svg viewBox="0 0 118 149">
					<path d="M0 96.982h28.597c1.706 9.44 5.167 16.395 10.385 20.817 5.268 4.472 12.292 6.658 21.172 6.658 9.432 0 16.506-1.938 21.272-5.913C86.192 114.62 88.6 110 88.6 104.682c0-3.378-1.003-6.26-3.01-8.694-2.007-2.385-5.519-4.422-10.535-6.16-3.362-1.243-11.139-3.28-23.33-6.31-15.653-3.876-26.64-8.645-32.961-14.31C9.884 61.31 5.468 51.622 5.468 40.195c0-7.304 2.108-14.21 6.271-20.619 4.165-6.36 10.185-11.228 18.062-14.557C37.677 1.64 47.11 0 58.297 0c18.162 0 31.808 3.975 40.99 11.974 9.18 7.999 13.997 19.625 14.449 32.99h-29.4c-1.255-7.453-3.914-12.77-8.027-15.999-4.064-3.23-10.235-4.869-18.413-4.869-8.429 0-15.051 1.74-19.817 5.217-3.06 2.236-4.615 5.217-4.615 8.943 0 3.428 1.455 6.36 4.364 8.744 3.662 3.13 12.593 6.31 26.841 9.689 14.249 3.279 24.734 6.756 31.557 10.334 6.823 3.577 12.141 8.446 16.004 14.607 3.863 6.21 5.77 13.811 5.77 22.903 0 8.248-2.308 15.949-6.923 23.153-4.616 7.204-11.138 12.57-19.617 16.047C83.031 147.211 72.495 149 59.853 149c-18.312 0-32.41-4.223-42.243-12.62C7.777 128.034 1.906 112.98 0 96.982z" />
				</svg>
			</Box>
			<Box className={css.letter}>
				<svg viewBox="0 0 136 149">
					<path d="M0 143V0h42.78l25.317 97.54L93.122 0H136v143h-26.39l-.098-112.562L81.659 143H54.195L26.487 30.438 26.39 143z" />
				</svg>
			</Box>
			<Box className={css.letter}>
				<svg viewBox="0 0 142 149">
					<path d="M142 143h-30.925l-12.182-32.563H42.082L30.289 143H0L54.95 0h30.288L142 143zM89.597 86.274l-19.675-52.92-19.13 52.92h38.805z" />
				</svg>
			</Box>
			<div className={css.curtain}>
				<div className={css.strip} />
				<div className={css.strip} />
				<div className={css.strip} />
				<div className={css.strip} />
				<div className={css.strip} />
				<div className={css.strip} onAnimationEndCapture={handleAnimationEnd} />
			</div>
		</>
	);
});
