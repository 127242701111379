import css from './PreambleMediaAbout.module.scss';
import React, { FC } from 'react';
import { InfoBlockProps, InfoBlock } from '@components/InfoBlock/InfoBlock';

type PreambleMediaAboutProps = InfoBlockProps;

export const PreambleMediaAbout: FC<PreambleMediaAboutProps> = React.memo(
	({ title, subtitle, text, sentence }) => {
		return (
			<>
				<InfoBlock
					className={css.section}
					title={title}
					subtitle={subtitle}
					sentence={sentence}
					text={text}
				/>
			</>
		);
	}
);
