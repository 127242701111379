import css from './EnvelopeView.module.scss';
import React, { FC, memo, useState, useEffect, useContext } from 'react';
import classnames from 'classnames';
import { Move } from '@utils';
import { ViewportContext } from '@context';

export interface EnvelopeViewProps {
	seek: number;
	autoplay?: boolean;
}

export const EnvelopeView: FC<EnvelopeViewProps> = memo(({ seek, autoplay }) => {
	const [canvas, setCanvas] = useState<HTMLDivElement | null>();
	const [play, setPlay] = useState(false);

	const { bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	useEffect(() => {
		if (!canvas) return;
		canvas.style.setProperty('--seek', `${-seek}`);
	}, [canvas, seek]);

	useEffect(() => {
		if (!canvas || isMob) return;

		let timer = 0;

		if (autoplay) {
			window.setTimeout(() => {
				setPlay(true);
			}, 400);
			return;
		}

		const onTick = ({ x, y }: { x: number; y: number }) => {
			canvas.style.setProperty('--mx', `${x}`);
			canvas.style.setProperty('--my', `${y}`);
		};

		Move.addListener(onTick);

		return () => {
			Move.removeListener(onTick);
			window.clearTimeout(timer);
		};
	}, [canvas, isMob, autoplay]);

	return (
		<div className={classnames(css.module, { [css.play]: play })} ref={setCanvas}>
			<div className={css.frame}>
				<div className={css.triangle}>
					<svg preserveAspectRatio="none" viewBox="0 0 300 106">
						<path d="M298.426 0.499977L150 105.388L1.5739 0.499996L298.426 0.499977Z" />
					</svg>
				</div>
				<div className={css.frameInner}>
					<div className={classnames(css.intersect, css.triangle)}>
						<svg preserveAspectRatio="none" viewBox="0 0 300 106">
							<path d="M298.426 0.499977L150 105.388L1.5739 0.499996L298.426 0.499977Z" />
						</svg>
					</div>
				</div>
			</div>
		</div>
	);
});
