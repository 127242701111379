import css from './FilterLinks.module.scss';
import classnames from 'classnames';
import React, { PropsWithChildren, FC, useCallback, useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Box } from '@core';
import { prepareSearchString } from '@utils';

interface Props {
	items: Array<FilterLinkType>;
	mode?: 'default' | 'replace';
	onClick?: () => void;
}

export const FilterLinks: FC<PropsWithChildren<Props>> = React.memo(
	({ items, mode = 'replace', onClick }) => {
		const { query, asPath, push } = useRouter();
		const pathname = asPath.split('?')[0];

		const handleFilterChange = useCallback(
			(key: string, value: string | null) => {
				const q = Object.entries({ ...query, [key]: value })
					.filter((item) => item[0] !== 'slug')
					.filter((item) => item[1])
					.map((item) => item[0] + '=' + item[1])
					.join('&');

				const pathname = asPath.split('?')[0];
				const url = pathname + (q ? '?' + q : '');

				push(url, undefined, { shallow: true }).then();
			},
			[query, asPath, push]
		);

		const [search, setSearch] = useState('');

		useEffect(() => {
			setSearch(prepareSearchString(asPath));
		}, [query, asPath]);

		return (
			<Box className={css.module}>
				{items.map(({ key, value, text }, i) => {
					const params = value ? `?${key}=${value}` : ``;
					const href = `${pathname}${params}`;

					const isActive =
						value === null ? !search.includes(key) : search.includes(`${key}=${value}`);
					const className = classnames(css.link, { [css.linkActive]: isActive });

					return (
						<Link key={text + '-' + i} href={href} shallow={true} prefetch={false}>
							<a
								onClick={(e) => {
									if (mode === 'replace') {
										e.preventDefault();
										handleFilterChange(key, value);
									}
									onClick && onClick();
								}}
								className={className}>
								{text}
							</a>
						</Link>
					);
				})}
			</Box>
		);
	}
);
