import React, { PropsWithChildren } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { GMapProps, GMapControls, GMapMarker } from '@core';
import styles from '../utils/styles';

export const GMapWidget = React.memo(
	React.forwardRef<
		HTMLDivElement,
		PropsWithChildren<
			GMapProps & {
				defaultZoom: number;
				defaultCenter: [number, number];
			}
		>
	>(({ defaultZoom, defaultCenter, setCenterOnActive, objects }, ref) => {
		return (
			<GoogleMap
				mapContainerStyle={{ width: '100%', height: '100%' }}
				options={{
					styles: styles,
					zoomControl: false,
					mapTypeControl: false,
					streetViewControl: false,
					fullscreenControl: false,
					center: {
						lat: defaultCenter[0],
						lng: defaultCenter[1],
					},
				}}>
				<GMapControls
					ref={ref}
					objects={objects}
					defaultZoom={defaultZoom}
					defaultCenter={defaultCenter}
					setCenterOnActive={setCenterOnActive}
				/>
				{Array.isArray(objects) &&
					objects?.map((marker, i) => (
						<GMapMarker key={`gmap-objects-${i}-${marker.data?.title}`} object={marker} />
					))}
			</GoogleMap>
		);
	})
);
