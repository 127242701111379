import css from './ActivityScopesMobile.module.scss';
import type { ActivityScopesProps } from '@components/ActivityScopes/ActivityScopes';
import React, { FC, useCallback, useContext, useRef, useState } from 'react';
import { Box } from '@core';
import { ActivityScopesCard } from '@components/ActivityScopes/components/ActivityScopesCard/ActivityScopesCard';
import { ActivityScopesHeading } from '@components/ActivityScopes/components/ActivityScopesRegular/ActivityScopesRegular';
import { ViewportContext } from '@context';
import { useScrollWithEase } from '@hooks';

export const ActivityScopesMobile: FC<ActivityScopesProps> = ({ title, titleUrl, items }) => {
	return (
		<Box className={css.module}>
			<ActivityScopesHeading className={css.head} title={title} titleUrl={titleUrl} />
			<ActivityScopesFeed items={items} />
		</Box>
	);
};

export const ActivityScopesFeed: FC<Pick<ActivityScopesProps, 'items'>> = React.memo(
	({ items = [] }) => {
		return (
			<Box className={css.feed}>
				{items?.map((card, i) => (
					<ActivityScopesCardWrap key={card.title}>
						<ActivityScopesCard className={css.card} {...card} />
					</ActivityScopesCardWrap>
				))}
			</Box>
		);
	}
);

export const ActivityScopesCardWrap: FC = React.memo(({ children }) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const { vh } = useContext(ViewportContext);
	const [seek, setSeek] = useState(0);

	const onScroll = useCallback(
		(current: number) => {
			const start = vh / 2;
			const end = current - vh / 1.25;
			const absolute = 1 - end / start;
			const relative = Math.max(Math.min(absolute, 1), 0);

			setSeek(relative);
		},
		[vh]
	);

	useScrollWithEase(ref, onScroll, {
		ease: 0.125,
		edge: 'bottom',
	});

	return (
		<Box
			ref={ref}
			className={css.card}
			style={{
				transform: `translate3d(${(1 - seek) * 100}%, 0, 0)`,
			}}>
			{children}
		</Box>
	);
});
