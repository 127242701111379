import css from './Header.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { Icon, LinkTag, Tag } from '@core';
import text from '@core/Text/Text.module.scss';
import { euFormatDate } from '@utils';

interface Props {
	title: string;
	date?: string | null;
	url?: string | null;
}

export const Header: FC<PropsWithChildren<Props & HTMLAttributes<HTMLDivElement>>> = ({
	title,
	date,
	url,
	className,
}) => {
	return (
		<header className={classnames(className, css.heading)}>
			{title && (
				<>
					{url && (
						<Tag className={css.title} type="h3">
							<LinkTag href={url}>
								{title}
								<Icon id="link" className={css.arrow} />
							</LinkTag>
						</Tag>
					)}
					{!url && <Tag className={css.title} type="h3" content={title} isHTML={true} />}
				</>
			)}
			{date && (
				<time className={classnames(css.date, text.md)} dateTime={date.replace(/\s/gi, 'T')}>
					{euFormatDate(date, { withMonthName: true })}
				</time>
			)}
		</header>
	);
};
