import css from './MapBox.module.scss';
import classnames from 'classnames';
import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Link from 'next/link';
import { MapObject, Box, Container, GMapContext, Flex, Icon, Text, RawHtml, Tag } from '@core';
import { useDelayUnmount } from '@hooks';

interface MapBoxInfoProps {}
interface MapBoxInfoContentProps {
	object: MapObject;
}

export const MapBoxInfo: FC<MapBoxInfoProps> = () => {
	const { activeObject } = useContext(GMapContext);
	const timer = useRef(0);

	const [object, setObject] = useState<MapObject | undefined>();
	const shouldRender = useDelayUnmount(!!activeObject, 600);

	useEffect(() => {
		if (timer.current) {
			window.clearTimeout(timer.current);
		}

		if (activeObject) {
			setObject(activeObject);
		} else {
			timer.current = window.setTimeout(() => {
				setObject(undefined);
			}, 800);
		}
	}, [activeObject]);

	return (
		<Box className={classnames(css.mapBoxInfo, { [css.isShown]: !!activeObject })}>
			{shouldRender && object && <MapBoxInfoContent object={object} />}
		</Box>
	);
};

export const MapBoxInfoContent: FC<MapBoxInfoContentProps> = ({ object: { data } }) => {
	const linkType = useMemo(() => {
		if (data?.url) {
			const { origin } = window.location;
			return data.url.includes(origin) ? 'inner' : 'outer';
		}
	}, [data?.url]);

	return (
		<Container>
			<Flex>
				<Box className={css.headArea}>
					{data?.title && (
						<Tag className={css.title} type="h4" content={data.title} isHTML={true} />
					)}
				</Box>
				<Box className={css.infoArea}>
					{data?.text && (
						<Text className={css.text} size="sm">
							<RawHtml content={data.text} />
						</Text>
					)}
					{data?.address && (
						<Text className={css.address} size="sm">
							<RawHtml content={data.address} />
						</Text>
					)}
				</Box>
				{data?.url && (
					<Box className={css.linkArea}>
						{linkType === 'inner' && (
							<Link href={data.url}>
								<a className={css.link}>
									<Icon id="link" />
								</a>
							</Link>
						)}
						{linkType === 'outer' && (
							<a
								className={css.linkOuter}
								href={data.url}
								target="_blank"
								rel="noreferrer noopener">
								<Icon id="outer-link" />
							</a>
						)}
					</Box>
				)}
			</Flex>
		</Container>
	);
};
