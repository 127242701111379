import css from './HomeCareerMedia.module.scss';
import type { HomeCareerProps } from '@components/HomeCareer/HomeCareer';
import React, { FC, useEffect, useRef } from 'react';
import Image from 'next/image';
import useIntersectionObserver from '@hooks/useIntersectionObserver';
import { Video } from '@core';

export type HomeCareerMediaProps = Pick<HomeCareerProps, 'cover' | 'video'>;

export const HomeCareerMedia: FC<HomeCareerMediaProps> = React.memo(({ cover, video }) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const entry = useIntersectionObserver(ref, {
		threshold: 0,
		freezeOnceVisible: false,
	});

	return (
		<div ref={ref} className={css.module}>
			{cover && (
				<picture>
					<Image src={cover.src} alt={cover.alt} layout="fill" quality={100} />
				</picture>
			)}
			{video?.mp4 && (
				<Video className={css.video} mp4={video.mp4} playing={!!entry?.isIntersecting} />
			)}
		</div>
	);
});
