import css from './BigSlider.module.scss';
import classnames from 'classnames';
import React, {
	createContext,
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Box, Container, Heading, Odometer, ProgressBar, Section } from '@core';
import { MediaBg } from '@components/HomeIntro/components/MediaBg/MediaBg';
import useIntersectionObserver from '@hooks/useIntersectionObserver';
import { useScrollWithEase } from '@hooks';
import { ViewportContext } from '@context';
import { findLastIndex, normalize, padForNum, splitByLines } from '@utils';
import { getStages } from '@components/HomeIntro/utils';
import fastdom from 'fastdom';

interface SlideProps {
	odometer?: string;
	text?: string;
}

export interface BigSliderContentProps {
	items: Array<SlideProps>;
}

export interface BigSliderProps {
	items: Array<SlideProps>;
	title?: string;
	titleUrl?: string;
	picture?: Picture;
	video?: Video;
}

export interface BigSliderMainProps extends Pick<BigSliderProps, 'items' | 'title' | 'titleUrl'> {}

export const BigSliderContext = createContext({ seek: 0, current: -1, stages: [0] });

export const BigSlider: FC<BigSliderProps> = React.memo(
	({ items = [], title, titleUrl, picture, video }) => {
		const ref = useRef<HTMLDivElement | null>(null);

		const screens = items.length * 2;
		const frag = 1 / screens;

		const { vh } = useContext(ViewportContext);

		const entry = useIntersectionObserver(ref, {
			threshold: 0,
			freezeOnceVisible: true,
		});

		const [seek, setSeek] = useState(0);

		const onScroll = useCallback(
			(current: number, height: number) => {
				const overlap = 0;
				const start = height - vh;
				const end = current - vh;
				const absolute = 1 - end / start;
				const relative = Math.max(Math.min(absolute, 1 / (1 - overlap)), 0);

				const seek = normalize(relative, 1 - frag, 0, true);

				setSeek(seek);
			},
			[vh, frag]
		);

		useScrollWithEase(ref, onScroll, {
			ease: 1, // 0.25,
			edge: 'bottom',
		});

		const stages = getStages(seek, items.length * 2);

		const current = Math.min(
			findLastIndex(stages, (value) => value >= 1),
			items.length
		);

		return (
			<Section
				ref={ref}
				style={{
					height: `${screens * 100}vh`,
				}}
				className={classnames(css.module, 'first-screen')}>
				<Box className={css.sticky}>
					<MediaBg picture={picture} video={video} playing={entry?.isIntersecting} />
					<BigSliderContext.Provider value={{ seek, stages, current }}>
						<BigSliderMain items={items} title={title} titleUrl={titleUrl} />
					</BigSliderContext.Provider>
					<ProgressBar className={css.progress} seek={seek} />
				</Box>
			</Section>
		);
	}
);

export const BigSliderMain: FC<BigSliderMainProps> = React.memo(({ items, title, titleUrl }) => {
	const { current } = useContext(BigSliderContext);
	const { bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	return (
		<Box className={classnames(css.content, { [css.isActive]: current >= 0 })}>
			<Container>
				<Heading className={css.heading} title={title} titleUrl={titleUrl} />
				{!isMob && <BigSliderContent items={items} />}
				{isMob && <BigSliderMobile items={items} />}
			</Container>
		</Box>
	);
});

export const BigSliderMobile: FC<BigSliderContentProps> = React.memo(({ items }) => {
	const { current } = useContext(BigSliderContext);

	return (
		<Box className={css.mobSlides}>
			{items?.map((slide, i) => {
				const odometer = slide?.odometer;
				const gt = !!odometer?.match(/>/gi);
				const digits = odometer?.replace(/>/gi, '') || '00';

				return (
					<Box
						key={i + '-' + slide.text}
						className={classnames(css.mobSlide, { [css.mobSlideActive]: current >= i + 1 })}>
						<Box className={css.mobSlideOdometer}>
							{gt && <>&gt;</>}
							<Odometer value={current >= i + 1 ? Number(digits) : undefined} length={gt ? 2 : 3} />
						</Box>
						<Box className={css.mobSlideLabel}>
							<Box className={css.mobSlideLabelInner}>{slide.text}</Box>
						</Box>
					</Box>
				);
			})}
		</Box>
	);
});

export const BigSliderContent: FC<BigSliderContentProps> = React.memo(({ items }) => {
	const { stages } = useContext(BigSliderContext);

	const [wrap, setWrap] = useState<HTMLDivElement | null>(null);

	const [texts, setTexts] = useState<Array<string[]>>([]);
	const [textHelper, setTextHelper] = useState<HTMLDivElement | null>(null);

	useEffect(() => {
		if (!textHelper) return;

		const resizeObserver = new ResizeObserver(() => {
			fastdom.measure(() => {
				const texts = items.map((slide) => splitByLines(slide?.text || '', textHelper));
				setTexts(texts);
			});
		});

		resizeObserver.observe(textHelper);

		return () => {
			resizeObserver.unobserve(textHelper);
			resizeObserver.disconnect();
		};
	}, [textHelper, items]);

	const comb = stages.reduce((acc, stage, i) => {
		const ev = (i + 1) % 2 === 0;
		acc += ev ? stage : 0;
		return acc;
	}, 0);

	useEffect(() => {
		fastdom.mutate(() => {
			if (!wrap) return;

			wrap.style.setProperty('--odometerA', `${comb * -100}%`);
			wrap.style.setProperty('--odometerB', `${comb * 1.3 * -100}%`);
			wrap.style.setProperty('--odometerC', `${comb * 1.8 * -100}%`);
			wrap.style.setProperty('--odometerD', `${comb * 2.3 * -100}%`);
		});
	}, [wrap, comb]);

	return (
		<Box ref={setWrap} className={css.layout}>
			<Box className={css.left}>
				<BigSliderStats items={items} />
			</Box>
			<Box className={css.right}>
				<Box className={css.text}>
					<Box className={css.textHelper} ref={setTextHelper} />
					<BigSliderTexts texts={texts} />
				</Box>
				<BigSliderCounter items={items} />
			</Box>
		</Box>
	);
});

export const BigSliderStats: FC<BigSliderContentProps> = React.memo(({ items }) => {
	const columns = useMemo(
		() =>
			items.reduce(
				(acc, item) => {
					if (item?.odometer) {
						padForNum(item.odometer, '---')
							.split('')
							.forEach((d, i) => {
								acc[i].push(d === '-' ? null : d);
							});
					}
					return acc;
				},
				[[], [], [], []] as Array<Array<string | null>>
			),
		[items]
	);

	return (
		<Box className={css.odometerWrap}>
			<Box className={css.odometer}>
				{columns.map((column, i) => {
					return (
						<Box key={`slide-odometer-column-${i}`} className={css.odometerC}>
							<Box>&nbsp;</Box>
							{column.map((d, j) => {
								const digit = Number(d);
								const notEmpty = d !== null && !Number.isNaN(digit);
								return (
									<Box
										key={`slide-odometer-d-${j}`}
										className={classnames(css.odometerD, {
											[css.odometerAccent]: notEmpty,
										})}>
										{d !== null ? d : 0}
									</Box>
								);
							})}
						</Box>
					);
				})}
			</Box>
		</Box>
	);
});

export const BigSliderTexts: FC<{ texts: Array<string[]> }> = React.memo(({ texts }) => {
	const textGroupsByLines = useMemo(() => {
		const result = [];

		const maxLines = texts.reduce((acc, current, i) => {
			if (acc < current.length) {
				acc = current.length;
			}
			return acc;
		}, 0);

		for (let i = 0; i < maxLines; i++) {
			result.push(texts.map((text) => text[i] || ''));
		}

		return result;
	}, [texts]);

	return (
		<>
			{textGroupsByLines.map((line, i) => {
				return (
					<Box key={`group-${i}`} className={css.textLine}>
						<Box className={css.textTrack}>
							<div>&nbsp;</div>
							{line.map((item, j) => (
								<div key={`group-${i}-text-${j}-${item}`}>{item.length ? item : <>&nbsp;</>}</div>
							))}
						</Box>
					</Box>
				);
			})}
		</>
	);
});

export const BigSliderCounter: FC<BigSliderContentProps> = React.memo(({ items }) => {
	return (
		<Box className={css.counter}>
			<div>0</div>
			<Box className={css.counterTrack}>
				<Box>&nbsp;</Box>
				{items.map((_, i) => (
					<Box key={`slide-counter-${i}`}>{i + 1}</Box>
				))}
			</Box>
			<span>&mdash;</span>
			<span>{items.length}</span>
		</Box>
	);
});
