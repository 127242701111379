import React, { FC, useState, createContext } from 'react';
import { MapObject } from '@core';

interface ContextProps {
	activeObject?: MapObject;
	setActiveObject?: React.Dispatch<React.SetStateAction<MapObject | undefined>>;
}

export const GMapContext = createContext<ContextProps>({
	activeObject: undefined,
	setActiveObject: undefined,
});

export const GMapContextProvider: FC<{ initialActiveObject?: MapObject }> = ({
	initialActiveObject,
	children,
}) => {
	const [activeObject, setActiveObject] = useState<MapObject | undefined>(initialActiveObject);

	return (
		<GMapContext.Provider value={{ activeObject, setActiveObject }}>
			{children}
		</GMapContext.Provider>
	);
};
