import css from './VacanciesFilters.module.scss';
import classnames from 'classnames';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Box, Button, Flex, Icon } from '@core';
import { useRouter } from 'next/router';
import AnimateHeight from 'react-animate-height';
import { prepareSearchString } from '@utils';

export interface VacanciesFiltersProps {
	filters?: Array<FilterGroupType>;
	caption?: string;
	resetBtnText?: string;
	onFilterChange?: () => void;
	isActive?: boolean;
	toggleActive?: () => void;
}

export const VacanciesFilters: FC<VacanciesFiltersProps> = memo(
	({ filters = [], onFilterChange, caption, resetBtnText, isActive, toggleActive }) => {
		const { locale, query, asPath, push } = useRouter();
		const toggleText = caption || (locale === 'en' ? 'Filters' : 'Фильтры');
		const resetText = resetBtnText || (locale === 'en' ? 'Reset filters' : 'Сбросить все фильтры');

		const [shown, setShown] = useState(false);
		const expanded = typeof isActive !== 'undefined' ? isActive : shown;

		const handleToggle = useCallback(
			(flag?: boolean) => {
				toggleActive && toggleActive();
				setShown((prev) => (typeof flag !== 'undefined' ? flag : !prev));
			},
			[toggleActive]
		);

		const [active, setActive] = useState(false);

		useEffect(() => {
			if (!filters) return;

			const activeKeys = Object.keys(query);
			const filtersKeys = Object.values(filters).map((f) => f.name);
			const keysIntersect = activeKeys.some((key) => filtersKeys.includes(key));

			setActive(keysIntersect);
		}, [filters, query]);

		/*
		 * Сброс фильтров
		 */
		const handleResetFilters = useCallback(() => {
			const filtersKeys = Object.values(filters).map((f) => f.name);

			const q = Object.entries(query)
				.filter((item) => item[0] !== 'slug')
				.filter((item) => item[1] && !filtersKeys.includes(item[0]))
				.map((item) => item[0] + '=' + item[1])
				.join('&');

			const pathname = asPath.split('?')[0];
			const url = pathname + (q ? '?' + q : '');

			push(url, undefined, { shallow: true }).then();

			handleToggle(false);
		}, [filters, query, asPath, push, handleToggle]);

		return (
			<aside
				className={classnames(css.filters, { [css.isActive]: active, [css.isExpanded]: expanded })}>
				<button className={css.toggle} type="button" onClick={() => handleToggle()}>
					<span>{toggleText}</span>
					<Icon id="filters" />
				</button>
				<AnimateHeight height={expanded ? 'auto' : 0} animateOpacity={true} duration={600}>
					<div className={css.container}>
						{filters?.map((group, i) => (
							<VacanciesFiltersGroup
								key={`filters-group-${group.caption}-${i}`}
								onFilterChange={onFilterChange}
								{...group}
							/>
						))}
						<AnimateHeight height={active ? 'auto' : 0} duration={400}>
							<Button
								size="sm"
								className={classnames(css.resetBtn, { [css.isShown]: active })}
								onClick={handleResetFilters}>
								{resetText}
							</Button>
						</AnimateHeight>
					</div>
				</AnimateHeight>
			</aside>
		);
	}
);

export const VacanciesFiltersGroup: FC<
	FilterGroupType & Pick<VacanciesFiltersProps, 'onFilterChange'>
> = memo(({ name, caption, options = [], onFilterChange }) => {
	const { query, asPath, push: pushRoute } = useRouter();
	const pathname = asPath.split('?')[0];

	const handleClick = useCallback(
		(value: string | null, isActive: boolean) => {
			const set = new Set(
				!query[name] || Array.isArray(query[name])
					? query[name]
					: (query[name] as string).split(',')
			);

			if (isActive) {
				value && set.delete(value);
			} else {
				value && set.add(value);
			}

			const q = Object.entries({ ...query, [name]: Array.from(set).join(',') })
				.filter((item) => item[0] !== 'slug')
				.filter((item) => item[1])
				.map((item) => item[0] + '=' + item[1])
				.join('&');

			const pathname = asPath.split('?')[0];
			const url = pathname + (q ? '?' + q : '');

			pushRoute(url, undefined, { shallow: true }).then();
			onFilterChange && onFilterChange();
		},
		[name, onFilterChange, query, asPath, pushRoute]
	);

	const [search, setSearch] = useState('');

	useEffect(() => {
		setSearch(prepareSearchString(asPath));
	}, [query, asPath]);

	return (
		<Box className={css.group}>
			<Box className={css.groupCaption}>{caption}</Box>
			<Flex className={css.groupLinks}>
				{options.map(({ value, label }, i) => {
					const params = value ? `?${name}=${value}` : ``;
					const href = `${pathname}${params}`;

					const isActive =
						value === null
							? !query[name]
							: search.includes(`${name}=`) && search.includes(`${value}`);
					const className = classnames(css.groupLink, {
						[css.groupLinkActive]: isActive,
					});

					return (
						<a
							key={`filters-group-link-${label}-${i}`}
							href={href}
							onClick={(e) => {
								e.preventDefault();
								handleClick(value, isActive);
							}}
							className={className}>
							{label}
						</a>
					);
				})}
			</Flex>
		</Box>
	);
});
