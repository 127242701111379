import css from './StripsVertical.module.scss';
import classnames from 'classnames';
import fastdom from 'fastdom';
import React, {
	FC,
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useScrollWithEase, useStripedTail } from '@hooks';
import { ViewportContext } from '@context';
import { PageTransitionContext } from '@components/Layout/components/PageTransition/PageTransition';

interface Props {
	color?: string;
	startScreen?: boolean;
	isOuter?: boolean;
	hideFirst?: boolean;
	shift?: number;
	multiplier?: number;
	paddingTop?: boolean;
	paddingBottom?: boolean;
	withStripedTail?: boolean;
}

const stripsRange = [0.15, 0.2, 0.3, 0.4, 0.5];
const stripsRangeMob = [0.1, 0.15, 0.25, 0.35, 0.45];

export const StripsVertical: FC<PropsWithChildren<Props>> = ({
	color = 'white',
	shift = 0,
	multiplier = 1,
	startScreen,
	isOuter,
	hideFirst,
	paddingTop = true,
	paddingBottom = false,
	withStripedTail,
	children,
}) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const [translate, setTranslate] = useState(0);

	const { vh, bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	const onScroll = useCallback(
		(current: number) => {
			fastdom.measure(() => {
				if (!ref.current) return;

				const rect = ref.current.getBoundingClientRect();
				const heightLimit = rect.height * (isMob ? 0.75 : 1);
				const posY = rect.top + window.scrollY;

				let seek;
				let translate;

				if (shift) {
					current = current - shift;
				}

				if (startScreen) {
					current = Math.max(current, 0);
					seek = (posY - current) / posY;
					translate = Math.max(seek * Math.min(heightLimit, vh * 1.5), 0);
				} else {
					current = Math.max(current * 0.85, 0);
					seek = 1 - Math.min(current, vh) / vh;
					translate = Math.max(seek * Math.min(heightLimit, vh * 3), 0);
				}

				setTranslate(translate);
			});
		},
		[startScreen, shift, vh, isMob]
	);

	useScrollWithEase(ref, onScroll, {
		ease: 0.25,
		edge: 'top',
	});

	const stripClass = classnames(css.strip, css[color]);
	const contentClass = classnames(
		css.inner,
		{ [css.innerPadTop]: paddingTop, [css.innerPadBottom]: paddingBottom },
		css[color]
	);

	const [shown, setShown] = useState(false);
	const transition = useContext(PageTransitionContext);

	useEffect(() => {
		if (startScreen) {
			setTimeout(() => {
				setShown(true);
			}, 800);
		}
	}, [startScreen]);

	useEffect(() => {
		const wrap = ref.current;
		const strips = wrap?.querySelectorAll<HTMLDivElement>(`.${css.strip}`);
		const range = bp === 'xs' ? stripsRangeMob : stripsRange;

		fastdom.mutate(() => {
			strips?.forEach((el, i) => {
				el.style.transform = `translate3d(0, ${translate * -range[i] * multiplier}px, 0)`;
			});
		});
	}, [ref, translate, bp, multiplier]);

	const stripedContainerRef = useRef<HTMLDivElement | null>(null);

	useStripedTail({
		ref: withStripedTail ? stripedContainerRef : { current: null },
	});

	return (
		<div
			className={classnames(
				css.outer,
				'strips-vertical',
				{
					[css.startScreen]: startScreen,
					[css.startScreenShown]:
						startScreen && shown && transition !== 'inter' && transition !== 'exit',
					[css.withStripedTail]: withStripedTail,
					'with-striped-tail': withStripedTail,
				},
				!isOuter && `is-${color}-section`
			)}
			ref={ref}>
			<Strips className={stripClass} hideFirst={hideFirst} />
			<div className="clip-path-wrapper" ref={stripedContainerRef}>
				<Content className={contentClass}>{children}</Content>
			</div>
		</div>
	);
};

const Content: FC<PropsWithChildren<{ className?: string }>> = React.memo(
	({ className, children }) => {
		return <div className={className}>{children}</div>;
	}
);

const Strips: FC<{ className?: string; hideFirst?: boolean }> = React.memo(
	({ className, hideFirst }) => {
		return (
			<div className="strips">
				<div className={classnames(className, { [css.hideStrip]: hideFirst })} />
				<div className={className} />
				<div className={className} />
				<div className={className} />
				<div className={className} />
			</div>
		);
	}
);
