import css from './TitleLink.module.scss';
import React, { FC, PropsWithChildren } from 'react';
import Link from 'next/link';
import { RawHtml } from '@core';

interface Props {
	url?: string;
	content?: string;
}

export const TitleLink: FC<PropsWithChildren<Props>> = ({ url, content, children }) => {
	const icon = `<svg aria-hidden="true"><use xlink:href="#link" /></svg>`;
	const html = [content || children, icon].join('');

	return url ? (
		<Link href={url}>
			<a className={css.titleLink} dangerouslySetInnerHTML={{ __html: html }} />
		</Link>
	) : (
		<RawHtml>{content || children}</RawHtml>
	);
};
