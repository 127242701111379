import css from './FormError.module.scss';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';

interface FormErrorProps {
	text?: string | string[];
	active?: boolean;
}

export const FormError: FC<PropsWithChildren<FormErrorProps>> = ({ text, active = false }) => {
	const [message, setMessage] = useState<string[] | undefined>();

	useEffect(() => {
		const message = text ? (Array.isArray(text) ? text : [text]) : undefined;

		if (message?.length) {
			setMessage(message);
		}
	}, [text]);

	return (
		<AnimateHeight height={active && message?.length ? 'auto' : 0} duration={400}>
			<div className={css.formError}>
				{message?.map((item, i) => (
					<p key={`form-error-line-${i}-${item}`}>{item.replace(/\.$/, '')}</p>
				))}
			</div>
		</AnimateHeight>
	);
};
