export type EventEmitterHandler<D> = (data: D) => void;

export class EventEmitter<T, H extends EventEmitterHandler<T> = EventEmitterHandler<T>> {
	private _handlers: H[];
	constructor() {
		this._handlers = [];
	}

	public dispatch(...args: T[]) {
		for (const argument of args) {
			this._handlers.forEach((handler) => handler(argument));
		}
	}
	public addListener(handler: H) {
		if (!this._handlers.includes(handler)) this._handlers.push(handler);

		return this;
	}
	public removeListener(handler: H) {
		const index = this._handlers.indexOf(handler);

		if (index >= 0) this._handlers.splice(index, 1);

		return this;
	}
}
