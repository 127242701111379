import css from './VideoCursor.module.scss';
import classnames from 'classnames';
import React, { FC, memo, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { Spinner } from '@core';
import { ViewportContext } from '@context';
import fastdom from 'fastdom';

interface Props {
	x?: number;
	y?: number;
	playing?: boolean;
	loading?: boolean;
	className?: string;
}

const play = 'M 12,26 18.5,22 18.5,14 12,10 z M 18.5,22 25,18 25,18 18.5,14 z';
const pause = 'M 12,26 16,26 16,10 12,10 z M 21,26 25,26 25,10 21,10 z';

export const VideoCursor: FC<PropsWithChildren<Props>> = memo(
	({ x = 0, y = 0, playing, loading, children, className }) => {
		const [ref, setRef] = useState<HTMLDivElement | null>(null);
		const { vw, vh } = useContext(ViewportContext);

		useEffect(() => {
			if (ref) {
				const xlim = ref.offsetWidth / 2 / vw;
				const ylim = ref.offsetHeight / 2 / vh;

				const xPos = Math.min(Math.max(xlim, x), 1 - xlim);
				const yPos = Math.min(Math.max(ylim, y), 1 - ylim);

				fastdom.mutate(() => {
					ref.style.left = `${xPos * 100}%`;
					ref.style.top = `${(1 - yPos) * 100}%`;
				});
			}
		}, [ref, vw, vh, x, y]);

		return (
			<div ref={setRef} className={classnames(css.cursor, className)} aria-hidden="true">
				<div className={css.cursorText}>
					<span>{children}</span>
				</div>
				<div className={css.cursorIcon}>
					{!(loading && playing) && (
						<svg viewBox="0 0 36 36">
							<path d={playing ? pause : play} />
						</svg>
					)}
					{loading && playing && <Spinner className={css.cursorSpinner} />}
				</div>
			</div>
		);
	}
);
