export type CookieOptions = {
	expires?: string | number | Date;
	'max-age'?: string | number;
	path?: string;
	domain?: string;
	secure?: boolean;
	samesite?: 'strict' | 'lax';
};

export const getCookie = (name: string): string | undefined => {
	let matches = document.cookie.match(
		new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
	);
	return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (name: string, value: string, options?: CookieOptions): void => {
	options = {
		path: '/',
		...options,
	};

	let expires = options.expires;

	if (typeof expires === 'number') {
		let d = new Date();
		d.setTime(d.getTime() + expires * 86400000);
		expires = options.expires = d;
	}
	if (typeof expires === 'object' && 'toUTCString' in expires) {
		options.expires = expires.toUTCString();
	}

	let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

	let propName: keyof typeof options;
	for (propName in options) {
		if (options.hasOwnProperty(propName)) {
			updatedCookie += '; ' + propName;
			let propValue = options[propName];
			if (propValue !== true) {
				updatedCookie += '=' + propValue;
			}
		}
	}

	document.cookie = updatedCookie;
};

export const deleteCookie = (name: string): void => {
	setCookie(name, '', {
		expires: -1,
	});
};

/*
 * Cookie consent
 */
const defaultCookieConsentName = 'CookieConsent';

export const getCookieConsentValue = (name: string = defaultCookieConsentName) => {
	const cookieValue = getCookie(name);

	if (cookieValue === undefined) {
		return getCookie(getLegacyCookieName(name));
	}

	return cookieValue;
};

export const resetCookieConsentValue = (name: string = defaultCookieConsentName) => {
	deleteCookie(name);
};

export const setCookieConsent = (cookieName: string, cookieValue: string) => {
	const options = {
		expires: 30,
		samesite: 'strict' as const,
		secure: location ? location.protocol === 'https:' : true,
	};

	setCookie(cookieName, cookieValue, options);
	setCookie(getLegacyCookieName(cookieName), cookieValue, options);
};

export const getLegacyCookieName = (name: string) => {
	return `${name}-legacy`;
};
