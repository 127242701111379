import css from './TextBlock.module.scss';
import React, { FC } from 'react';
import { Box, RawHtml, Tag } from '@core';

interface Props {
	title?: string;
	html?: string;
	className?: string;
}

export const TextBlock: FC<Props> = ({ title, html, className }) => {
	return (
		<Box className={className}>
			{title && <Tag type="h1" className="magazine-offset" content={title} isHTML={true} />}
			{html && <RawHtml className={css.text}>{html}</RawHtml>}
		</Box>
	);
};
