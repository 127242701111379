import css from '../TechPreamble.module.scss';
import classnames from 'classnames';
import type { TechPreambleProps } from '@components/TechPreamble/TechPreamble';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Container, Section, Box, Tag, TickerText } from '@core';
import useIntersectionObserver from '@hooks/useIntersectionObserver';
import { Performance } from '@utils';

export const TechPreambleMobile: FC<TechPreambleProps> = ({ text, sentence, ticker }) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const [seek, setSeek] = useState(0);

	const entry = useIntersectionObserver(ref, {
		threshold: 0.5,
		freezeOnceVisible: true,
	});

	useEffect(() => {
		const play = entry?.isIntersecting;
		if (!play) return;

		let time = 0;

		const onTick = () => {
			time = Math.min(1, time + 0.002);

			if (time === 1) {
				time = 0;
			}

			setSeek(time);
		};

		Performance.addListener(onTick);

		return () => {
			Performance.removeListener(onTick);
		};
	}, [entry?.isIntersecting]);

	return (
		<Section className={classnames(css.outer, { [css.moreText]: !!sentence }, css.mobile)}>
			<Box ref={ref} className={css.wrap}>
				<Container className={css.content}>
					{text && <Tag type="h2" content={text} isHTML={true} className="indent-text" />}
					{sentence && (
						<Tag
							type="sentence"
							className={classnames(css.sentence, 'indent-margin')}
							content={sentence}
						/>
					)}
					<TickerText seek={seek} overlap={0} loop={true} text={ticker} className={css.ticker} />
				</Container>
			</Box>
		</Section>
	);
};
