import type { SplitViewSlidesProps } from '@components/SplitViewSlides/SplitViewSlides';
import React, { FC } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { SplitViewSlidesItem } from '@components/SplitViewSlides/components/SplitViewSlidesItem';

export const SplitViewSlidesMobileSplide: FC<{
	slides: SplitViewSlidesProps['slides'];
	onUpdate: (splide: any) => void;
}> = React.memo(({ slides, onUpdate }) => {
	return (
		<Splide
			onMove={onUpdate}
			onMounted={onUpdate}
			options={{
				type: 'slide',
				autoWidth: true,
				arrows: false,
				pagination: false,
				speed: 800,
				rewindSpeed: 1200,
			}}>
			{slides?.map((slide, i) => (
				<SplideSlide key={'SplitViewSlidesMobile-slide-' + i}>
					<SplitViewSlidesItem index={i} content={slide} />
				</SplideSlide>
			))}
		</Splide>
	);
});
