import css from '../StoryLine.module.scss';
import classnames from 'classnames';
import React, { createContext, FC, useCallback, useContext, useRef, useState } from 'react';
import { Flex } from '@core';
import { StoryLineProps } from '@components/StoryLine/StoryLine';
import { StoryLineFeed } from '@components/StoryLine/components/StoryLineFeed';
import { StoryLinePictures } from '@components/StoryLine/components/StoryLinePictures';
import { useScrollWithEase } from '@hooks';
import { ViewportContext } from '@context';
import { StoryLineProgress } from '@components/StoryLine/components/StoryLineProgress';

export interface StoryLineLayoutProps extends StoryLineProps {}

export const StoryLineWayContext = createContext<{
	value: Array<number>;
	setValue: React.Dispatch<React.SetStateAction<number[]>>;
}>({
	value: [],
	setValue: () => [],
});
export const StoryLineSeekContext = createContext<number>(0);

export const StoryLineLayout: FC<StoryLineLayoutProps> = ({ sections }) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const pictures = sections?.map((item) => item.picture) || [];

	const { vw, vh, bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	const [seek, setSeek] = useState(0);

	const onScroll = useCallback(
		(current: number, height: number) => {
			const cursor = Math.min(Math.max(-(current - vh / 2), 0), height);
			setSeek(cursor / height);
		},
		// eslint-disable-next-line
		[vw, vh]
	);

	useScrollWithEase(ref, onScroll, {
		ease: 1, // 0.125,
		edge: 'top',
	});

	const [heightsSpreading, setHeightsSpreading] = useState<Array<number>>([]);

	return (
		<StoryLineSeekContext.Provider value={seek}>
			<StoryLineWayContext.Provider
				value={{ value: heightsSpreading, setValue: setHeightsSpreading }}>
				<Flex ref={ref} className={css.layout} direction="row" wrap="nowrap">
					<StoryLineProgress />
					<StoryLineFeed sections={sections} />
					{!isMob && <StoryLinePictures pictures={pictures} />}
				</Flex>
			</StoryLineWayContext.Provider>
		</StoryLineSeekContext.Provider>
	);
};
