import css from '../SplitViewSlides.module.scss';
import type { SplitViewSlidesProps } from '@components/SplitViewSlides/SplitViewSlides';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@core';
import { animationComponents } from '@components/animations/Animation/Animation';
import { SplitViewSlidesMobileSplide } from '@components/SplitViewSlides/components/SplitViewSlidesMobileSplide';
import { Performance } from '@utils';

const ease = 0.075;

export const SplitViewSlidesMobileSlider: FC<SplitViewSlidesProps> = React.memo(
	({ slides, settings }) => {
		const AnimationCanvas =
			settings?.animation && slides?.length !== 0 && animationComponents[settings.animation];
		const withCssTransition = settings?.animation === 'careerFeaturesView';

		const size = useMemo(() => slides?.length || 0, [slides]);
		const [current, setCurrent] = useState(0);
		const [target, setTarget] = useState(0);

		const handleSliderUpdate = useCallback(
			(splide: any) => {
				if (splide) {
					const current = splide.Components.Controller.getIndex();
					setTarget(current / size);
				}
			},
			[size]
		);

		useEffect(() => {
			const onTick = () => {
				if (target !== current) {
					const diff = target - current;
					const delta = Math.abs(diff) < 0.0001 ? 0 : diff * ease;

					if (delta) {
						const next = current + delta;
						setCurrent(next);
					} else {
						setCurrent(target);
					}
				}
			};

			Performance.addListener(onTick);

			return () => {
				Performance.removeListener(onTick);
			};
		}, [current, target]);

		return (
			<Box className={css.touchSlider}>
				{AnimationCanvas && (
					<AnimationCanvas
						seek={current}
						target={withCssTransition ? target : undefined}
						slides={slides}
					/>
				)}
				<SplitViewSlidesMobileSplide slides={slides} onUpdate={handleSliderUpdate} />
			</Box>
		);
	}
);
