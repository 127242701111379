import type { InfoBlockProps } from '@components/InfoBlock/InfoBlock';
import type { BenefitsListProps } from '@components/BenefitsList/BenefitsList';
import type { TraineeshipTransitionProps } from '@components/Traineeship/components/TraineeshipTransition/TraineeshipTransition';
import React, { FC } from 'react';
import dynamic from 'next/dynamic';
import { TraineeshipPreamble } from '@components/Traineeship/components/TraineeshipPreamble/TraineeshipPreamble';
import { TraineeshipMain } from '@components/Traineeship/components/TraineeshipMain/TraineeshipMain';

export interface TraineeshipProps extends InfoBlockProps {
	main?: BenefitsListProps;
	picture?: Picture;
}

export const Traineeship: FC<TraineeshipProps> = React.memo(
	({ title, subtitle, text, sentence, picture, main }) => {
		return (
			<>
				<TraineeshipPreamble title={title} subtitle={subtitle} sentence={sentence} text={text} />
				<DynamicTraineeshipTransition title={main?.title} picture={picture} />
				<TraineeshipMain {...main} />
			</>
		);
	}
);

export const DynamicTraineeshipTransition = dynamic<TraineeshipTransitionProps>(
	() =>
		import('@components/Traineeship/components/TraineeshipTransition/TraineeshipTransition').then(
			(mod) => mod.TraineeshipTransition
		),
	{
		ssr: true,
		loading: () => <></>,
	}
);
