import { PageData } from '@api/hooks/types';

export const tech: PageData = {
	meta: {
		title: 'Более 150 единиц техники',
		description:
			'Компания Fensma владеет современным оборудованием и новейшими механизмами для строительства подземных сооружений',
	},
	data: [
		{
			type: 'intro',
			content: {
				title: 'Более 150<br /><color-accent>единиц техники</color-accent>',
				breadcrumbs: [{ text: 'О компании' }, { text: 'Парк техники' }],
			},
		},

		{
			type: 'aboutContent',
			content: {
				subtitle:
					'Компания <color-accent>Fensma</color-accent> владеет современным оборудованием и новейшими механизмами для строительства подземных сооружений',
				text:
					'<p>/ Компания Фенсма выполняет проекты строительства по устройству ограждающих конструкций и фундаментов («стена в грунте», устройство ГЦС и БНС, сваи Юркевича и многое другое). Компания имеет в своем парке буровые установки и вспомогательное оборудование таких мировых лидеров по производству как: LIEBHERR GmbH, BAUER Maschinen GmbH, SOIL MEC S.p.a., MAT, SOTRES, Tsurumi Pump.</p>' +
					'<p>Компания выполняет большой спектр буровых работ с применением высокотехнологичного оборудования ведущих мировых производителей. Деятельность компании основана на применении современных достижений геотехники. Одно из направлений компании повышение несущей способности грунтовых оснований, укрепление фундаментов, сооружение подземных ограждающих конструкций в сложных геологических условиях и на стесненных строительных площадках. Для выполнения специальных буровых работ применяются современные буровые установки фирмы KLEMM Bohrtechnik GmbH в комплекте с оборудованием для цементации грунтов по технологии Jet grouting и MINI JET фирмы ТЕСNIWELL Srl</p>',
				pictures: [
					[
						{
							type: 'picture',
							src: '/temp/pic-33@2x.jpg',
							width: 480,
							height: 400,
							alt: '',
						},
						{
							type: 'picture',
							src: '/temp/pic-34@2x.jpg',
							width: 480,
							height: 288,
							alt: '',
						},
					],
					[
						{
							type: 'picture',
							src: '/temp/pic-34@2x.jpg',
							width: 480,
							height: 288,
							alt: '',
						},
						{
							type: 'picture',
							src: '/temp/pic-33@2x.jpg',
							width: 480,
							height: 400,
							alt: '',
						},
					],
				],
			},
			wrapper: {
				type: 'stripsVertical',
				props: {
					color: 'white',
					startScreen: true,
					paddingBottom: true,
				},
			},
		},

		{
			type: 'bigSlider',
			content: {
				title: 'Парк техники',
				items: [
					{
						odometer: '17',
						text: 'буровых установок',
					},
					{
						odometer: '7',
						text: 'крановых (грейферных) установок',
					},
					{
						odometer: '>50',
						text: 'единиц вспомогательной техники',
					},
				],
				video: {
					type: 'video',
					mp4: '/video/footage.mp4',
				},
			},
		},

		{
			type: 'aboutContent',
			content: {
				sentence:
					'Fensma производит своевременно, быстро, а главное качественно обслуживание своей буровой техники и вспомогательного оборудования. Выполнение существенных строительных работ в стесненных условия, условиях города в ограниченные сроки, было бы невозможным без вышеперечисленного парка строительной техники. ',
				text: '<p>/ Немало важным фактором является наличие площадки для хранения и проведения ремонта на базе в Fensma. Так же в компании заключены договоры на поставку запчастей с такими мировыми лидерами производства строительной техники как: LIEBHERR GmbH, BAUER Maschinen GmbH, KLEMM Bohrtechnik GmbH, ТЕСNIWELL Srl. непосредственно именно по этому на складе всегда в наличии есть материалы для проведения технического обслуживания, а так же запчасти необходимого наминала для проведения промежуточного и экстренного ремонта. В случаи отсутствия запчастей в наличии на складе,  фирмы производители максимально быстро привозят запчасти на склад.</p>',
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'dark',
				},
			},
		},
		{
			type: 'benefitsFeedLarge',
			content: {
				title: 'Преимущества',
				items: [
					{
						title: 'Преимущества собственного парка техники',
						content: [
							{
								type: 'html',
								content:
									'<ul>' +
									'<li><p>Нет необходимости платить аренду</p></li>' +
									'<li>Не возникает вопросов по поломке наемной техники</li>' +
									'<li>Оперативная отправка техники на объекты и обратно</li>' +
									'<li>Чувство ответственности машинистов и водителей за технику</li>' +
									'</ul>',
							},
						],
					},
					{
						title: 'Преимущества собственной МТБ',
						content: [
							{
								type: 'html',
								content:
									'<ul>' +
									'<li>Нет необходимости платить аренду</li>' +
									'<li>Собственные капитальные пункты обслуживания и ремонта техники</li>' +
									'<li>Размещение техники между периодами строительства</li>' +
									'<li>Оперативность отправки техники на объекты и обратно</li>' +
									'</ul>',
							},
						],
					},
					{
						title: 'Возможности к оперативной мобилизации',
						content: [
							{
								type: 'html',
								content:
									'<ul>' +
									'<li>Приобретение 1-2 тралов повысит самостоятельность и оперативность перевозок техники</li>' +
									'</ul>',
							},
						],
					},
				],
			},
			wrapper: {
				type: 'stripsHorizontalLeft',
				props: {
					color: 'dark',
				},
			},
		},
	],
};
