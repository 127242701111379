/*
 * Документация по компоненту карт:
 * https://react-google-maps-api-docs.netlify.app/
 *
 * Ключ от клиента со старого сайта:
 * AIzaSyACz-Dg1L2Qf67BDmOn8khoGC0DFSv9oSM
 */
import css from './GMap.module.scss';
import classnames from 'classnames';
import React, { FC } from 'react';
import dynamic from 'next/dynamic';
import { GMapProps, Box } from '@core';

export const GMap: FC<GMapProps> = ({ className, ...rest }) => {
	return (
		<Box className={classnames(className, css.module)}>
			<GMapDynamic {...rest} />
		</Box>
	);
};

export const GMapDynamic = dynamic<GMapProps>(
	() => import('@core/GMap/components/GMapComponent').then((mod) => mod.GMapComponent),
	{
		ssr: false,
		loading: () => <></>,
	}
);

export * from './components';
