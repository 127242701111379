import css from './Select.module.scss';
import React, { PropsWithChildren, FC, HTMLAttributes, useCallback, useState, useRef } from 'react';
import { Box } from '@core';
import classnames from 'classnames';
import { useOutsideClick, useDelayUnmount } from '@hooks';

interface Props {
	selected: SelectOption | undefined;
	setSelected: (selected: SelectOption | undefined) => void;
}

export const Select: FC<PropsWithChildren<Props & SelectType & HTMLAttributes<HTMLDivElement>>> =
	React.memo(({ selected, setSelected, options, className, placeholder = '' }) => {
		const ref = useRef<HTMLDivElement | null>(null);

		const [active, setActive] = useState(false);
		const shouldRender = useDelayUnmount(active, 600);

		const handleToggle = useCallback(() => {
			setActive((prev) => !prev);
		}, []);

		const handleClose = useCallback(() => {
			setActive(false);
		}, []);

		useOutsideClick<HTMLDivElement | null>(ref, handleClose);

		// const [selected, setSelected] = useState<SelectOption | null>();

		const handleSelect = useCallback(
			(option: SelectOption) => {
				setSelected(option);
				setActive(false);
			},
			[setSelected]
		);

		return (
			<Box className={classnames(className, css.select)} ref={ref}>
				<button
					type="button"
					className={classnames(css.header, { [css.isActive]: !!selected || active })}
					onClick={handleToggle}>
					<span>{selected?.label || placeholder}</span>
				</button>
				{shouldRender && (
					<Box className={classnames(css.drop, { [css.isActive]: active })}>
						<Box className={css.list}>
							{options?.map((option) => {
								const isHidden = option.value === null && !selected?.value;
								return !isHidden ? (
									<button
										type="button"
										key={option.value + option.label}
										className={classnames(css.option, {
											[css.selected]: selected?.value === option.value,
										})}
										onClick={() => handleSelect(option)}>
										{option.label}
									</button>
								) : null;
							})}
						</Box>
					</Box>
				)}
			</Box>
		);
	});
