import css from './FormResponse.module.scss';
import classnames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { Box, RawHtml, Tag, Text } from '@core';
import { useDelayUnmount } from '@hooks';

export interface FormResponseProps {
	active: boolean;
	response: FormResponse | null;
}

export const FormResponse: FC<FormResponseProps> = ({ active, response }) => {
	const [data, setData] = useState<FormResponse | null>(null);
	const shouldRender = useDelayUnmount(active, 800);

	useEffect(() => {
		if (response && response?.status === 'success') {
			setData(response);
		}
	}, [response]);

	return (
		<>
			{shouldRender && (
				<Box className={classnames(css.response, { [css.isShown]: active })}>
					{typeof data?.response === 'string' ? (
						<Text size="md" color="gray">
							<RawHtml content={data.response} />
						</Text>
					) : (
						<>
							{data?.response?.title && (
								<Tag
									className={css.title}
									type="h2"
									isHTML={true}
									content={data?.response?.title}
								/>
							)}
							<Text className={css.text} size="md" color="gray">
								<RawHtml content={data?.response?.text} />
							</Text>
						</>
					)}
				</Box>
			)}
		</>
	);
};
