import { VacancyPreviewType } from '@components/Vacancies/components/VacancyPreview/VacancyPreview';

export const vacanciesEntityA = {
	date: '2021-09-17 02:34:56',
	title: 'Специалист отдела <nobr>материально-технического</nobr> снабжения',
	url: '/m-career/vacancy/id/1',
	records: [
		{
			type: 'location',
			caption: 'Местонахождение',
			content: 'Египет, Каир',
		},
		{
			type: 'experience',
			caption: 'Опыт работы',
			content: '3 – 6 лет',
		},
		{
			type: 'employment',
			caption: 'Тип занятости',
			content: 'Полная занятость',
		},
	],
};

export const vacanciesEntityB = {
	date: '2021-07-15 02:34:56',
	title: 'Инженер отдела снабжения',
	url: '/m-career/vacancy/id/2',
	records: [
		{
			type: 'location',
			caption: 'Местонахождение',
			content: 'Москва',
		},
		{
			type: 'experience',
			caption: 'Опыт работы',
			content: 'Более 6 лет',
		},
		{
			type: 'employment',
			caption: 'Тип занятости',
			content: 'Вахтовый метод',
		},
	],
};

export const vacanciesList: PaginatedData<VacancyPreviewType> = {
	data: [
		vacanciesEntityA,
		vacanciesEntityB,
		vacanciesEntityA,
		vacanciesEntityB,
		vacanciesEntityA,
		vacanciesEntityB,
		vacanciesEntityA,
		vacanciesEntityB,
	],
};

export const vacanciesListOther: PaginatedData<VacancyPreviewType> = {
	data: [
		vacanciesEntityB,
		vacanciesEntityA,
		vacanciesEntityB,
		vacanciesEntityA,
		vacanciesEntityB,
		vacanciesEntityA,
	],
};
