import { PageData } from '@api/hooks/types';

export const students: PageData = {
	meta: {
		title: 'Студентам и выпускникам',
		description:
			'Компания Fensma приглашает студентов профильных учебных заведений пройти производственную практику на строительных объектах компании, принять участие в программах стажировок.',
	},
	data: [
		{
			type: 'intro',
			content: {
				title: 'Начни<br /><color-accent>успешную карьеру</color-accent>',
				breadcrumbs: [{ text: 'Карьера', url: '/career' }, { text: 'Студентам и выпускникам' }],
			},
		},

		{
			type: 'traineeship',
			content: {
				title: 'Профессиональная<br /><color-accent>подготовка</color-accent>',
				sentence:
					'Компания Fensma приглашает студентов профильных учебных заведений пройти производственную практику на строительных объектах компании, принять участие в программах стажировок.',
				picture: {
					src: '/temp/pic-42.jpg',
					width: 1920,
					height: 1080,
					alt: 'Стажировка',
				},
				main: {
					title: 'Internship',
					// title: 'Internship', // for en-GB version
					items: [
						{
							title: 'Наставничество',
							text: 'Наш обзор охватывает самые прогрессивные и востребованные IT-технологии и инновационные материалы в строительстве, которые с каждым годом все больше интегрируются в строительную сферу, реализуя самые смелые идеи будущего.',
						},
						{
							title: 'Повышение квалификации в учебных центрах',
							text: 'Наш обзор охватывает самые прогрессивные и востребованные IT-технологии и инновационные материалы в строительстве, которые с каждым годом все больше интегрируются в строительную сферу, реализуя самые смелые идеи будущего.',
						},
						{
							title: 'Участие в тренингах и конференциях',
							text: 'Наш обзор охватывает самые прогрессивные и востребованные IT-технологии и инновационные материалы в строительстве, которые с каждым годом все больше интегрируются в строительную сферу, реализуя самые смелые идеи будущего.',
						},
					],
				},
			},
			wrapper: {
				type: 'stripsVertical',
				props: {
					color: 'white',
					startScreen: true,
					withStripedTail: true,
				},
			},
		},

		{
			type: 'stepsSlider',
			content: {
				title: 'Как стать<br /><color-accent>стажером</color-accent>',
				items: [
					{
						odometer: '01',
						text: 'пройди %br% интервью',
					},
					{
						odometer: '02',
						text: 'выполни тестовое %br% задание',
					},
					{
						odometer: '03',
						text: 'приступай %br% к работе',
					},
				],
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'transparent',
					paddingTop: false,
					paddingBottom: false,
				},
			},
		},

		{
			type: 'personsGallery',
			content: {
				title: 'Вдохновляющий<br /><color-accent>опыт</color-accent>',
				items: [
					{
						title: 'Мария Иванова',
						subtitle: 'Главный менеджер по охране окружающей среды',
						text: '<p>Маша закончила факультет строительства и была первым консультантом по вопросам окружающей среды по вопросам строительства, когда она присоединилась к команде в 2011 году. Сейчас она является менеджером по охране окружающей среды на самых крупных и значимых строительных объектов.</p>',
						picture: {
							src: '/temp/pic-31.jpg',
							width: 320,
							height: 408,
							alt: 'Мария Иванова',
						},
					},
					{
						title: 'Алиса Демидова',
						subtitle: 'Руководитель',
						text: '<p>Алиса закончила факультет строительства и была первым консультантом по вопросам окружающей среды по вопросам строительства, когда она присоединилась к команде в 2011 году. Сейчас она является менеджером по охране окружающей среды на самых крупных и значимых строительных объектов.</p>',
						picture: {
							src: '/temp/pic-32.jpg',
							width: 320,
							height: 408,
							alt: 'Мария Иванова',
						},
					},
					{
						title: 'Мария Иванова',
						subtitle: 'Главный менеджер по охране окружающей среды',
						text: '<p>Маша закончила факультет строительства и была первым консультантом по вопросам окружающей среды по вопросам строительства, когда она присоединилась к команде в 2011 году. Сейчас она является менеджером по охране окружающей среды на самых крупных и значимых строительных объектов.</p>',
						picture: {
							src: '/temp/pic-31.jpg',
							width: 320,
							height: 408,
							alt: 'Мария Иванова',
						},
					},
					{
						title: 'Алиса Демидова',
						subtitle: 'Руководитель',
						text: '<p>Алиса закончила факультет строительства и была первым консультантом по вопросам окружающей среды по вопросам строительства, когда она присоединилась к команде в 2011 году. Сейчас она является менеджером по охране окружающей среды на самых крупных и значимых строительных объектов.</p>',
						picture: {
							src: '/temp/pic-32.jpg',
							width: 320,
							height: 408,
							alt: 'Мария Иванова',
						},
					},
					{
						title: 'Мария Иванова',
						subtitle: 'Главный менеджер по охране окружающей среды',
						text: '<p>Маша закончила факультет строительства и была первым консультантом по вопросам окружающей среды по вопросам строительства, когда она присоединилась к команде в 2011 году. Сейчас она является менеджером по охране окружающей среды на самых крупных и значимых строительных объектов.</p>',
						picture: {
							src: '/temp/pic-31.jpg',
							width: 320,
							height: 408,
							alt: 'Мария Иванова',
						},
					},
					{
						title: 'Алиса Демидова',
						subtitle: 'Руководитель',
						text: '<p>Алиса закончила факультет строительства и была первым консультантом по вопросам окружающей среды по вопросам строительства, когда она присоединилась к команде в 2011 году. Сейчас она является менеджером по охране окружающей среды на самых крупных и значимых строительных объектов.</p>',
						picture: {
							src: '/temp/pic-32.jpg',
							width: 320,
							height: 408,
							alt: 'Мария Иванова',
						},
					},
				],
				forcedScroll: false,
				verticalOnMob: false,
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'transparent',
					paddingTop: false,
					paddingBottom: false,
					paddingTopShort: true,
				},
			},
		},

		{
			type: 'faqFeed',
			content: {
				title: 'Наш <color-accent>вклад</color-accent>',
				items: [
					{
						title: 'Программа 1',
						content: [
							{
								type: 'html',
								content:
									'<p>Обычно мы рассматриваем отклики около недели, затем возвращаемся с фидбеком. Если сейчас нет подходящей позиции, мы сохраним твое резюме на будущее и свяжемся с тобой, как только откроются новые позиции.</p>',
							},
						],
					},
					{
						title: 'Программа 2',
						content: [
							{
								type: 'html',
								content:
									'<p>Обычно мы рассматриваем отклики около недели, затем возвращаемся с фидбеком. Если сейчас нет подходящей позиции, мы сохраним твое резюме на будущее и свяжемся с тобой, как только откроются новые позиции.</p>',
							},
						],
					},
					{
						title: 'Программа 3',
						content: [
							{
								type: 'html',
								content:
									'<p>Обычно мы рассматриваем отклики около недели, затем возвращаемся с фидбеком. Если сейчас нет подходящей позиции, мы сохраним твое резюме на будущее и свяжемся с тобой, как только откроются новые позиции.</p>',
							},
						],
					},
				],
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'white',
				},
			},
		},

		{
			type: 'vacanciesGallery',
			content: {
				title: 'Вакансии',
				titleUrl: '/career/vacancies',
				items: [
					{
						title: 'Стажировка 1',
						text: 'Оплачиваемая стажировка для студентов строительных специальностей',
						url: '/career/vacancies/vacancy',
					},
					{
						title: 'Стажировка 2',
						text: 'Оплачиваемая стажировка для студентов строительных специальностей',
						url: '/career/vacancies/vacancy',
					},
					{
						title: 'Стажировка 3',
						text: 'Оплачиваемая стажировка для студентов строительных специальностей',
						url: '/career/vacancies/vacancy',
					},
					{
						title: 'Стажировка 4',
						text: 'Оплачиваемая стажировка для студентов строительных специальностей',
						url: '/career/vacancies/vacancy',
					},
					{
						title: 'Стажировка 5',
						text: 'Оплачиваемая стажировка для студентов строительных специальностей',
						url: '/career/vacancies/vacancy',
					},
				],
				forcedScroll: false,
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'white',
					paddingTop: true,
				},
			},
		},

		{
			type: 'feedback',
			content: {
				title: 'Присоединяйся<br /><color-accent>к&nbsp;команде</color-accent>',
				text: '<p>Ознакомьтесь с нашими актуальными <a href="/career/vacancies">Вакансиями</a> или отправьте нам заявку и мы свяжемся с вами</p>',
				form: {
					action: '/forms/feedback',
					method: 'post',
					grcKey: '6LfURdcZAAAAAAz9aJ1T0WeUAeVGQI3RsarvmhRi',
					items: [
						{
							type: 'text',
							name: 'name',
							label: 'ФИО',
							required: true,
							validation: {
								required: 'Заполните это поле',
							},
						},
						{
							type: 'email',
							name: 'email',
							label: 'Электронная почта',
							required: true,
							pattern: '[0-9a-z_.-]+@[0-9a-z_]+\\.[a-z]{2,5}',
							validation: {
								required: 'Заполните это поле',
								pattern: 'Некорректный адрес email',
							},
						},
						{
							type: 'tel',
							name: 'phone',
							label: 'Номер телефона',
							required: true,
							mask: '+0 000 0000000',
							pattern: '^(\\+\\d)\\s\\d{3}\\s\\d{7}$',
							validation: {
								required: 'Заполните это поле',
								pattern: 'Некорректный номер телефона',
							},
						},
						{
							type: 'file',
							name: 'file',
							placeholder: 'Прикрепить резюме',
							accept: 'application/pdf',
							acceptLabel: 'pdf',
							sizeLimit: 52428800,
							sizeLimitLabel: 'До 50 Мб',
							validation: {
								accept: 'Недопустимый формат файла',
								size: 'Превышен допустимый размер файла',
							},
						},
					],
					button: 'Отправить',
					notice:
						'Нажимая «Отправить», я соглашаюсь с условиями<br /><a href="/" target="_blank">Политики конфиденциальности</a> и <a href="/" target="_blank">Пользовательского соглашения</a>',
				},
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'white',
					paddingTop: false,
					paddingBottom: false,
				},
			},
		},

		{
			type: 'goToNext',
			content: {
				pre: 'Перейти к следующему разделу',
				text: 'Истории сотрудников',
				url: '/career/stories',
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'transparent',
					paddingTop: false,
					paddingBottom: false,
				},
			},
		},
	],
};
