import css from './PostPreview.module.scss';
import classnames from 'classnames';
import React, { FC, useContext, useMemo, useState } from 'react';
import Link from 'next/link';
import { ViewportContext } from '@context';
import { Box, Icon, RawHtml } from '@core';
import { padForNum, shortFormatDate } from '@utils';

export interface PostPreviewProps {
	title?: string;
	text?: string;
	date?: string;
	url?: string;
	picture?: Picture;
	className?: string;
}

export const PostPreview: FC<PostPreviewProps> = React.memo(({ title, url, date, className }) => {
	const [element, setElement] = useState<HTMLAnchorElement | null>(null);

	const { bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	const { datetime, day, month } = useMemo(() => shortFormatDate(date), [date]);

	const entity = (
		<a ref={setElement} className={classnames(className, css.post)}>
			<Box className={css.left}>
				{day && month && (
					<time className={css.date} dateTime={datetime}>
						<span>{padForNum(day, '00')}</span> {month}
					</time>
				)}
			</Box>
			<Box className={css.main}>
				<RawHtml content={title} />
				<Icon className={css.arrow} id="link" />
			</Box>
		</a>
	);

	return url ? <Link href={url}>{entity}</Link> : entity;
});
