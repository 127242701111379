import { PageData } from '@api/hooks/types';

export const home: PageData = {
	meta: {
		title: 'Fensma',
		description: '',
		langSwitch: 'https://fensma-com.rdclr.ru/',
	},
	data: [
		{
			type: 'homeIntro',
			content: {
				features: [
					{
						text: 'Большой парк собственной техники',
						media: [
							{
								type: 'video',
								mp4: '/video/technika-480.mp4',
								width: 640,
								height: 328,
							},
							{
								type: 'picture',
								src: '/temp/splash-cover-1.jpg',
								width: 400,
								height: 232,
								alt: 'Современные технологии строительства',
							},
							// {
							// 	type: 'picture',
							// 	src: '/temp/splash-cover-5.jpg',
							// 	width: 400,
							// 	height: 232,
							// 	alt: 'Обучение сотрудников',
							// },
						],
					},
					{
						text: 'Современные технологии строительства',
						media: [
							{
								type: 'video',
								mp4: '/video/footage-480.mp4',
								width: 640,
								height: 328,
							},
							{
								type: 'picture',
								src: '/temp/splash-cover-2.jpg',
								width: 400,
								height: 232,
								alt: 'Современные технологии строительства',
							},
							// {
							// 	type: 'picture',
							// 	src: '/temp/splash-cover-3.jpg',
							// 	width: 240,
							// 	height: 160,
							// 	alt: 'Забота об окружающей среде',
							// },
						],
					},
					{
						text: 'Забота об окружающей среде',
						media: [
							{
								type: 'video',
								mp4: '/video/nature-480.mp4',
								width: 640,
								height: 328,
							},
							{
								type: 'picture',
								src: '/temp/splash-cover-3.jpg',
								width: 240,
								height: 160,
								alt: 'Забота об окружающей среде',
							},
							// {
							// 	type: 'picture',
							// 	src: '/temp/splash-cover-2.jpg',
							// 	width: 400,
							// 	height: 232,
							// 	alt: 'Современные технологии строительства',
							// },
						],
					},
					{
						text: 'Современные технологии в строительстве',
						media: [
							{
								type: 'video',
								mp4: '/video/construct-480.mp4',
								width: 640,
								height: 328,
							},
							{
								type: 'picture',
								src: '/temp/splash-cover-4.jpg',
								width: 400,
								height: 232,
								alt: 'Современные технологии в строительстве',
							},
							// {
							// 	type: 'picture',
							// 	src: '/temp/splash-cover-2.jpg',
							// 	width: 400,
							// 	height: 232,
							// 	alt: 'Современные технологии строительства',
							// },
						],
					},
					{
						text: 'Обучение сотрудников',
						media: [
							{
								type: 'video',
								mp4: '/video/career-480.mp4',
								width: 640,
								height: 328,
							},
							{
								type: 'picture',
								src: '/temp/splash-cover-5.jpg',
								width: 400,
								height: 232,
								alt: 'Обучение сотрудников',
							},
							// {
							// 	type: 'picture',
							// 	src: '/temp/splash-cover-1.jpg',
							// 	width: 400,
							// 	height: 232,
							// 	alt: 'Современные технологии строительства',
							// },
						],
					},
				],
				slider: {
					items: [
						{
							gt: false,
							odometer: 12,
							text: 'лет успешного опыта',
						},
						{
							gt: true,
							odometer: 200,
							text: 'построенных объектов',
						},
						{
							gt: true,
							odometer: 1500,
							text: 'компетентных сотрудников',
						},
					],
					ticker: 'Современные строительные технологии и опыт команды',
					video: {
						type: 'video',
						mp4: '/video/footage.mp4',
					},
					// picture: {
					// 	src: '/temp/video-cover.jpeg',
					// 	width: 320,
					// 	height: 180,
					// 	alt: 'Современные строительные технологии и опыт команды',
					// },
				},
			},
		},

		{
			type: 'iconicProjects',
			content: {
				aside: {
					title: 'Знаковые<br /><color-accent>объекты</color-accent>',
					url: '/projects',
					bg: {
						src: '/temp/iconic-projects-home.jpg',
						width: 640,
						height: 3096,
					},
				},
				items: [
					{
						title: 'Спортивный комплекс<br /><color-accent>«Олимпийский»</color-accent>',
						text: 'Устройство ограждения конструкций котлована при реконструкции здания бассейна. Устройство сайного основания при строительстве подземной части сооружения',
						picture: {
							src: '/temp/project-01.jpg',
							width: 1280,
							height: 904,
							alt: 'Спортивный комплекс «Олимпийский»',
						},
					},
					{
						title: 'Новая штаб-квартира<br /><color-accent>«Яндекса»</color-accent>',
						text: 'Устройство ограждения котлована и анкерной системы',
						picture: {
							src: '/temp/project-02.jpg',
							width: 1280,
							height: 904,
							alt: 'Новая штаб-квартира «Яндекса»',
						},
					},
					{
						title: 'Павильон<br /><color-accent>«Атомной энергии»</color-accent>',
						text: 'Устройство ограждения конструкций котлована при реконструкции здания бассейна. Устройство сайного основания при строительстве подземной части сооружения',
						picture: {
							src: '/temp/project-03.jpg',
							width: 1280,
							height: 904,
							alt: 'Пафильон «Атомной энергии»',
						},
					},
					{
						title: '<color-accent>«Москва-Сити»</color-accent><br />one tower',
						text: 'Устройство ограждения котлована из буросекущихся свай при строительстве многофункционального высотного жилого комплекса с подземной автостоянкой',
						picture: {
							src: '/temp/project-04.jpg',
							width: 1280,
							height: 904,
							alt: '«Москва-Сити» one tower',
						},
					},
					{
						title: 'Автовокзал в составе ТПУ <color-accent>«Щелковский»</color-accent>',
						text: 'Устройство свайных фундаментов под башенные краны, «стена в грунте» траншейного типа глубиной 53м, устройство баретт траншейного типа',
						picture: {
							src: '/temp/project-05.jpg',
							width: 1280,
							height: 904,
							alt: 'Автовокзал в составе транспортно-пересадочного узла «Щелковский»',
						},
					},
				],
			},
			wrapper: {
				type: 'stripsVerticalDouble',
				props: {
					color: 'white',
					before: 'dark',
					hideFirst: true,
					paddingTop: false,
				},
			},
		},

		{
			type: 'activityScopes',
			content: {
				title: 'Сферы<br /><color-accent>деятельности</color-accent>',
				titleUrl: '/activities/general-contract',
				items: [
					{
						title: 'Генеральный<br />подряд',
						url: '/activities/general-contract',
						shape: 'triangle',
					},
					{
						title: 'Генеральное<br />проектирование',
						url: '/activities/2',
						shape: 'point',
					},
					{
						title: 'Охрана<br />окружающей среды',
						url: '/activities/conversation',
						shape: 'pentagon',
					},
					{
						title: 'Техника<br />безопасности',
						url: '/activities/4',
						shape: 'ellipse',
					},
					{
						title: 'EPC<br />контракт',
						url: '/activities/5',
						shape: 'diamond',
					},
					{
						title: 'Работы<br />нулевого цикла',
						url: '/activities/6',
						shape: 'cross',
					},
					{
						title: 'Культура<br />производства',
						url: '/activities/7',
						shape: 'hex',
					},
				],
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'white',
				},
			},
		},

		// Если скрыть этот блок
		{
			type: 'homeCareer',
			content: {
				title: 'Карьера<br /><color-accent>в&nbsp;Fensma</color-accent>',
				text: 'Наша команда — это специалисты с высокой квалификацией, создающие объекты, которыми ежедневно пользуются миллионы людей.',
				url: '/career',
				video: {
					mp4: '/video/career.mp4',
				},
			},
		},

		// то здесь slideOnPrev: false
		// если homCareer есть, то ставь slideOnPrev: true
		{
			type: 'splitViewSlides',
			content: {
				slides: [
					{
						title: 'Финансовая стабильность',
						text: 'Гарантированная защита экономических интересов компании и работников',
						icon: {
							src: '/figures/white/points.svg',
						},
					},
					{
						title: 'Новаторский подход',
						text: 'Внедрение в производство инновационных технологий и управленческих решений',
						icon: {
							src: '/figures/white/atom.svg',
						},
					},
					{
						title: 'Единство целей',
						text: 'Командная заинтересованность в результате всех работников компании',
						icon: {
							src: '/figures/white/graph.svg',
						},
					},
					{
						title: 'Мастерство сотрудников',
						text: 'Максимально продуктивная реализация и развитие опыта и компетенций работников',
						icon: {
							src: '/figures/white/chart.svg',
						},
					},
					{
						title: 'Современные технологии',
						text: 'Использование уникальных методов и оборудования при выполнении работ',
						icon: {
							src: '/figures/white/solar.svg',
						},
					},
					{
						title: 'Авторитет в отрасли',
						text: 'Значимость компании на рынке, не требующая доказательств',
						icon: {
							src: '/figures/white/pie.svg',
						},
					},
				],
				settings: {
					strips: 'normal',
					slideOnPrev: true,
					animation: 'careerFeaturesView',
				},
			},
		},

		{
			type: 'partners',
			content: {
				title: 'Доверие<br /><color-accent>партнеров</color-accent>',
				items: [
					{
						title: 'Росатом',
						logo: {
							src: '/partners/rosatom.svg',
						},
						url: 'https://www.rosatom.ru/',
					},
					{
						title: 'Evraz',
						logo: {
							src: '/partners/evraz.svg',
						},
						url: 'https://www.evraz.com/ru/',
					},
					{
						title: 'МКБ',
						logo: {
							src: '/partners/mkb.svg',
						},
						url: 'https://mkb.ru/',
					},
					{
						title: 'Атомстройэкспорт',
						logo: {
							src: '/partners/ase.svg',
						},
						url: 'http://www.atomex.ru/',
					},
					{
						title: 'Альфабанк',
						logo: {
							src: '/partners/alfabank.svg',
						},
						url: 'https://alfabank.ru/',
					},
					{
						title: 'ФОДД',
						logo: {
							src: '/partners/fodd.svg',
						},
						url: 'https://www.fodd.ru/',
					},
					{
						title: 'ВТБ',
						logo: {
							src: '/partners/vtb.svg',
						},
						url: 'https://www.vtb.ru/',
					},
					{
						title: 'Bergauf',
						logo: {
							src: '/partners/bergauf.svg',
						},
						url: 'https://bergauf.ru/',
					},
					{
						title: 'MR Group',
						logo: {
							src: '/partners/mr-group.svg',
						},
						url: 'https://www.mr-group.ru/',
					},
					{
						title: 'ICG',
						logo: {
							src: '/partners/icg.svg',
						},
						url: 'https://www.icgam.com/',
					},
				],
				settings: {
					colorMob: 'white',
					topCollapse: false,
					topStripsMobile: true,
				},
			},
		},

		{
			type: 'eventsFeed',
			links: {
				self: '/events',
			},
			content: {
				title: 'Последние <color-accent>события</color-accent>',
				titleUrl: '/events',
				filters: [
					{
						text: 'Все события',
						key: 'event_type',
						value: null,
					},
					{
						text: 'Мероприятия',
						key: 'event_type',
						value: '1',
					},
					{
						text: 'Старт работ',
						key: 'event_type',
						value: '2',
					},
				],
				items: [
					{
						title:
							'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip в качестве делового партнёра',
						url: '/events/event',
						date: '2021-09-28 02:34:56',
						picture: {
							src: '/temp/event-1.png',
							width: 1280,
							height: 920,
							alt: 'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip',
						},
					},
					{
						title:
							'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
						url: '/events/event',
						date: '2021-09-25 02:34:56',
						picture: {
							src: '/temp/event-2.png',
							width: 1280,
							height: 920,
							alt: 'Участие в международной выставке GEOFLUID 2021',
						},
					},
					{
						title:
							'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
						url: '/events/event',
						date: '2021-09-20 02:34:56',
						picture: {
							src: '/temp/event-1.png',
							width: 1280,
							height: 920,
							alt: 'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip',
						},
					},
					{
						title:
							'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip в качестве делового партнёра',
						url: '/events/event',
						date: '2021-08-18 02:34:56',
						picture: {
							src: '/temp/event-2.png',
							width: 1280,
							height: 920,
							alt: 'Участие в международной выставке GEOFLUID 2021',
						},
					},
					{
						title:
							'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip в качестве делового партнёра',
						url: '/events/event',
						date: '2021-08-12 02:34:56',
						picture: {
							src: '/temp/event-1.png',
							width: 1280,
							height: 920,
							alt: 'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip',
						},
					},
					{
						title:
							'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
						url: '/events/event',
						date: '2021-07-06 02:34:56',
						picture: {
							src: '/temp/event-2.png',
							width: 1280,
							height: 920,
							alt: 'Участие в международной выставке GEOFLUID 2021',
						},
					},
					{
						title:
							'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
						url: '/events/event',
						date: '2021-05-02 02:34:56',
						picture: {
							src: '/temp/event-1.png',
							width: 1280,
							height: 920,
							alt: 'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip',
						},
					},
				],
				hasTrailAnimation: true,
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'transparent',
				},
			},
		},
	],
};
