import css from './ReportsMainChart.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@core';
import { normalize, bisector, range } from '@utils';

export interface ReportsMainChart extends HTMLAttributes<HTMLDivElement> {
	seek: number;
	years?: string[];
	onSetStages?: (stages: number[]) => void;
}

export const getPointOnPathByX = (path: SVGPathElement | null, x: number) => {
	if (!path) return null;

	const array = range(path.getTotalLength());
	const bisect = bisector((d: number) => path.getPointAtLength(d).x);
	const len = (x: number) => bisect.right(array, x);

	return path.getPointAtLength(len(x));
};

export const ReportsMainChart: FC<ReportsMainChart> = React.memo(
	({ seek, years = ['2012', '2014', '2016', '2018', '2020', '2021'], onSetStages, className }) => {
		const [stroke, setStroke] = useState<SVGPathElement | null>(null);
		const [strokeLength, setStrokeLength] = useState(0);

		useEffect(() => {
			if (!stroke) return;
			setStrokeLength(stroke.getTotalLength());
		}, [stroke]);

		const [svg, setSvg] = useState<HTMLDivElement | null>(null);
		const [svgRatio, setSvgRatio] = useState(1);
		const [svgBounding, setSvgBounding] = useState<DOMRect | null>(null);
		const [path, setPath] = useState<SVGPathElement | null>(null);

		useEffect(() => {
			if (svg) {
				const onResize = () => {
					setSvgRatio(svg.offsetWidth / 1166);
					setSvgBounding(svg.getBoundingClientRect());
				};

				onResize();
				window.addEventListener('resize', onResize);

				return () => {
					window.removeEventListener('resize', onResize);
				};
			}
		}, [svg]);

		const [stages, setStages] = useState<Array<number>>([]);
		const [stagesPoints, setStagesPoint] = useState<Map<number, number>>(new Map());

		const onCalculatePosition = useCallback((index: number, stage: number) => {
			setStagesPoint((prev) => {
				const map = prev.set(index, stage);
				setStages(Array.from(map.values()));
				return map;
			});
		}, []);

		useEffect(() => {
			onSetStages && onSetStages(stages);
		}, [onSetStages, stages]);

		return (
			<Box className={classnames(className, css.canvas, { [css.isVisible]: seek > 0 })}>
				<Box ref={setSvg} className={css.svg}>
					<svg
						preserveAspectRatio="none"
						className={classnames(css.curveGray, css.curve)}
						viewBox="0 0 1166 506">
						<path
							ref={setPath}
							d="M3 501s310-12 648-178S1163 3 1163 3"
							fillRule="evenodd"
							strokeDashoffset={strokeLength * (seek > 0 ? 0 : 1)}
							strokeDasharray={strokeLength + ',' + strokeLength}
						/>
					</svg>
					<svg
						preserveAspectRatio="none"
						className={classnames(css.curveAccent, css.curve)}
						viewBox="0 0 1166 506">
						<path
							ref={setStroke}
							d="M3 501s310-12 648-178S1163 3 1163 3"
							fillRule="evenodd"
							strokeDashoffset={strokeLength * (1 - seek)}
							strokeDasharray={strokeLength + ',' + strokeLength}
						/>
					</svg>
				</Box>
				<Box className={css.columns}>
					{years?.map((year, i) => {
						const heightStep = years.length > 1 ? (98 - 37) / (years.length - 1) : 0;
						const height = heightStep * i + 37;

						return (
							<ReportsMainChartCol
								key={`reports-chart-col-${i}-${year}`}
								index={i}
								onCalculatePosition={onCalculatePosition}
								seek={seek}
								stages={stages}
								path={path}
								svgRatio={svgRatio}
								svgBounding={svgBounding}
								height={`${height}%`}
								text={year}
							/>
						);
					})}
				</Box>
			</Box>
		);
	}
);

interface ReportsMainChartColProps {
	index: number;
	seek: number;
	stages: number[];
	height: string;
	path?: SVGPathElement | null;
	svgBounding?: DOMRect | null;
	svgRatio?: number;
	text?: string;
	onCalculatePosition?: (i: number, s: number) => void;
}

export const ReportsMainChartCol: FC<ReportsMainChartColProps> = React.memo(
	({ index, seek, stages, height, text, path, svgBounding, svgRatio = 1, onCalculatePosition }) => {
		const [element, setElement] = useState<HTMLDivElement | null>(null);
		const [pointPosY, setPointPosY] = useState<number | string | undefined>(undefined);

		useEffect(() => {
			if (path && svgBounding && element) {
				const { x: sx, width } = svgBounding;
				const { x: gx } = element.getBoundingClientRect();
				const posX = gx - sx;
				const svgY = getPointOnPathByX(path, posX / svgRatio)?.y;
				const posY = svgY ? svgY * svgRatio : svgY;

				setPointPosY(posY);
				onCalculatePosition && onCalculatePosition(index, posX / width);
			}
		}, [index, path, svgBounding, svgRatio, onCalculatePosition, element]);

		const itemSeek = normalize(seek, stages[index], stages[index - 1] || 0, true);

		return (
			<Box ref={setElement} className={css.column}>
				<div className={css.columnStroke} style={{ height }} />
				<div className={css.columnPoint} style={{ top: pointPosY }} />
				<div
					className={css.columnFill}
					style={{ top: pointPosY, transform: `scaleY(${itemSeek})` }}
				/>
				<div className={classnames(css.columnLabel, { [css.columnLabelActive]: itemSeek === 1 })}>
					{text}
				</div>
			</Box>
		);
	}
);
