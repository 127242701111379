import { PageData } from '@api/hooks/types';

export const career: PageData = {
	meta: {
		title: 'Работа у нас',
		description:
			'Наши ценности - это больше, чем просто слова. Они формируются исходя из взягдов и опыта нашей команды, определяют каждое принимаемое нами решение, служа путем, которым мы будем следовать для достижения наших целей и миссии, помогая нам завоевать доверие наших партнеров и поддерживать гармоничные и доверительные отношения в команде. Качественное строительство начинается с качественных отношений.',
	},
	data: [
		{
			type: 'intro',
			content: {
				title: 'Построй<br /><color-accent>карьеру в Fensma</color-accent>',
				breadcrumbs: [{ text: 'Карьера' }, { text: 'Работа у нас' }],
			},
		},

		{
			type: 'preambleMedia',
			content: {
				subtitle:
					'<color-accent>Качественное строительство</color-accent> начинается с качественных отношений',
				text: '/ Наши ценности - это больше, чем просто слова. Они формируются исходя из взягдов и опыта нашей команды, определяют каждое принимаемое нами решение, служа путем, которым мы будем следовать для достижения наших целей и миссии, помогая нам завоевать доверие наших партнеров и поддерживать гармоничные и доверительные отношения в команде. Качественное строительство начинается с качественных отношений.',
				video: {
					mp4: '/video/career.mp4',
				},
				afterword: {
					title: 'Карьера<br /><color-accent>в&nbsp;Fensma</color-accent>',
					text: 'Наша команда — это специалисты с высокой квалификацией, создающие объекты, которыми ежедневно пользуются миллионы людей.',
				},
			},
			wrapper: {
				type: 'stripsVertical',
				props: {
					color: 'white',
					startScreen: true,
					withStripedTail: false,
				},
			},
		},

		{
			type: 'splitViewSlides',
			content: {
				slides: [
					{
						title: 'Доверие и честность',
						text: 'Наши отношения в команде крепкие и дружеские. Наша преданность делу и уважение к друг другу создают ценные продукты',
						icon: {
							src: '/figures/white/diamond.svg',
						},
					},
					{
						title: 'Инновации и технологии',
						text: 'Наши отношения в команде крепкие и дружеские. Наша преданность делу и уважение к друг другу создают ценные продукты',
						icon: {
							src: '/figures/white/atom.svg',
						},
					},
					{
						title: 'Опыт команды',
						text: 'Наши отношения в команде крепкие и дружеские. Наша преданность делу и уважение к друг другу создают ценные продукты',
						icon: {
							src: '/figures/white/chart.svg',
						},
					},
					{
						title: 'Высокое качество работы',
						text: 'Наши отношения в команде крепкие и дружеские. Наша преданность делу и уважение к друг другу создают ценные продукты',
						icon: {
							src: '/figures/white/quality.svg',
						},
					},
					{
						title: 'Уважение к людям',
						text: 'Наши отношения в команде крепкие и дружеские. Наша преданность делу и уважение к друг другу создают ценные продукты',
						icon: {
							src: '/figures/white/team.svg',
						},
					},
					{
						title: 'Постоянное улучшение',
						text: 'Наши отношения в команде крепкие и дружеские. Наша преданность делу и уважение к друг другу создают ценные продукты',
						icon: {
							src: '/figures/white/increase.svg',
						},
					},
				],
				settings: {
					strips: 'normal',
					slideOnPrev: true,
					animation: 'careerFeaturesView',
				},
			},
		},

		{
			type: 'stepsSlider',
			content: {
				title: 'Карьерное<br /><color-accent>продвижение</color-accent>',
				items: [
					{
						odometer: '01',
						text: 'повышение квалификации и обучение',
					},
					{
						odometer: '02',
						text: 'повышение квалификации и обучение',
					},
					{
						odometer: '03',
						text: 'повышение квалификации и обучение',
					},
				],
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'transparent',
					paddingTop: false,
					paddingBottom: false,
				},
			},
		},

		{
			type: 'employeeStories',
			content: {
				title: '<color-accent>Наши сотрудники&nbsp;—</color-accent><br />наш главный капитал',
				pictures: [
					[
						{
							type: 'picture',
							src: '/temp/pic-37.jpg',
							width: 480,
							height: 280,
							alt: '',
						},
					],
					[
						{
							type: 'picture',
							src: '/temp/pic-38.jpg',
							width: 320,
							height: 414,
							alt: '',
						},
					],
					[
						{
							type: 'picture',
							src: '/temp/pic-39.jpg',
							width: 320,
							height: 320,
							alt: '',
						},
					],
					[
						{
							type: 'picture',
							src: '/temp/pic-40.jpg',
							width: 320,
							height: 320,
							alt: '',
						},
					],
					[
						{
							type: 'picture',
							src: '/temp/pic-41.jpg',
							width: 480,
							height: 280,
							alt: '',
						},
					],
				],
			},
			wrapper: {
				type: 'stripsVertical',
				props: {
					color: 'dark',
					startScreen: false,
					paddingTop: true,
					withStripedTail: false,
				},
			},
		},

		{
			type: 'benefitsTabs',
			content: {
				title: 'Условия<br /><color-accent>работы</color-accent>',
				sections: [
					{
						id: 1,
						title: 'Для офиса',
						items: [
							{
								title: 'Обучение и рост',
								text: 'Развитие каждого сотрудника - польза для всей компании. Мы помогаем получать знания и строить карьеру. У нас можно развиваться в любом направлении - компания поможет проследовать по ней.',
							},
							{
								title: 'Комфортный и эргономичный офис',
								text: 'Забота о комфорте команды в офисе - один из важных для нас приоритетов. Для максимально эффективного выполнения задачи важно иметь правильно организованное рабочее место.',
							},
							{
								title: 'Здоровье сртрудников',
								text: 'Наша ценность — это люди, поэтому мы серьезно относимся к здоровью. Для всех сотрудников из всех регионов наша программа ДМС.',
							},
						],
					},
					{
						id: 2,
						title: 'Для стройплощадки',
						items: [
							{
								title: 'Здоровье сртрудников',
								text: 'Наша ценность — это люди, поэтому мы серьезно относимся к здоровью. Для всех сотрудников из всех регионов наша программа ДМС.',
							},
							{
								title: 'Комфортный и эргономичный офис',
								text: 'Забота о комфорте команды в офисе - один из важных для нас приоритетов. Для максимально эффективного выполнения задачи важно иметь правильно организованное рабочее место.',
							},
						],
					},
				],
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'dark',
					paddingTop: false,
				},
			},
		},

		// Если понадобится вместо табов вывести фильтрующийся список
		//
		// {
		// 	type: 'rulesFeed',
		// 	links: {
		// 		self: '/career/rules',
		// 	},
		// 	content: {
		// 		title: 'Условия<br /><color-accent>работы</color-accent>',
		// 		filters: [
		// 			{
		// 				text: 'Для офиса',
		// 				key: 'rules_section',
		// 				value: null,
		// 			},
		// 			{
		// 				text: 'Для стройплощадки',
		// 				key: 'rules_section',
		// 				value: 'building-yard',
		// 			},
		// 		],
		// 		items: [
		// 			{
		// 				title: 'Обучение и рост',
		// 				text: 'Развитие каждого сотрудника - польза для всей компании. Мы помогаем получать знания и строить карьеру. У нас можно развиваться в любом направлении - компания поможет проследовать по ней.',
		// 			},
		// 			{
		// 				title: 'Комфортный и эргономичный офис',
		// 				text: 'Забота о комфорте команды в офисе - один из важных для нас приоритетов. Для максимально эффективного выполнения задачи важно иметь правильно организованное рабочее место.',
		// 			},
		// 			{
		// 				title: 'Здоровье сртрудников',
		// 				text: 'Наша ценность — это люди, поэтому мы серьезно относимся к здоровью. Для всех сотрудников из всех регионов наша программа ДМС.',
		// 			},
		// 		],
		// 	},
		// 	wrapper: {
		// 		type: 'simpleWrapper',
		// 		props: {
		// 			color: 'dark',
		// 		},
		// 	},
		// },

		{
			type: 'faqFeed',
			content: {
				title: 'Как попасть<br /><color-accent>в команду?</color-accent>',
				items: [
					{
						title: 'Отклик на нашу вакансию',
						content: [
							{
								type: 'html',
								content:
									'<p>Зайдите в раздел <a href="/career/vacancies">Вакансии</a> и выберите интересующую вас вакансию, заполните все поля анкеты и не забудьте прикрепить резюме. Ожидай ответ. Обычно мы рассматриваем отклики около недели, затем возвращаемся с фидбеком. Если сейчас нет подходящей позиции, мы сохраним твое резюме на будущее и свяжемся с тобой, как только откроются новые позиции</p>',
							},
						],
					},
					{
						title: 'Интервью',
						content: [
							{
								type: 'html',
								content:
									'<p>Проведение научных исследований и разработок, направленных на повышение качества окружающей среды и обеспечение экологической безопасности, в том числе научное обеспечение разработки государственной политики в сфере снижения негативного воздействия на атмосферный воздух, включая разработку предложений по установлению технических нормативов выбросов загрязняющих веществ, механизмов экономического и правового стимулирования, совершенствованию механизмов взимания платы за негативное воздействие на окружающую среду.</p>',
							},
						],
					},
					{
						title: 'Решение',
						content: [
							{
								type: 'html',
								content:
									'<p>Проведение научных исследований и разработок, направленных на повышение качества окружающей среды и обеспечение экологической безопасности, в том числе научное обеспечение разработки государственной политики в сфере снижения негативного воздействия на атмосферный воздух, включая разработку предложений по установлению технических нормативов выбросов загрязняющих веществ, механизмов экономического и правового стимулирования, совершенствованию механизмов взимания платы за негативное воздействие на окружающую среду.</p>',
							},
						],
					},
					{
						title: 'Выход на работу',
						content: [
							{
								type: 'html',
								content:
									'<p>Проведение научных исследований и разработок, направленных на повышение качества окружающей среды и обеспечение экологической безопасности, в том числе научное обеспечение разработки государственной политики в сфере снижения негативного воздействия на атмосферный воздух, включая разработку предложений по установлению технических нормативов выбросов загрязняющих веществ, механизмов экономического и правового стимулирования, совершенствованию механизмов взимания платы за негативное воздействие на окружающую среду.</p>',
							},
						],
					},
				],
			},
			wrapper: {
				type: 'stripsHorizontalLeft',
				props: {
					color: 'dark',
					baseColor: 'white',
				},
			},
		},

		{
			type: 'feedback',
			content: {
				title: 'Присоединяйся<br /><color-accent>к&nbsp;команде</color-accent>',
				text: '<p>Ознакомьтесь с нашими актуальными <a href="/career/vacancies">Вакансиями</a> или отправьте нам заявку и мы свяжемся с вами</p>',
				form: {
					action: '/forms/feedback',
					method: 'post',
					grcKey: '6LfURdcZAAAAAAz9aJ1T0WeUAeVGQI3RsarvmhRi',
					items: [
						{
							type: 'text',
							name: 'name',
							label: 'ФИО',
							required: true,
							validation: {
								required: 'Заполните это поле',
							},
						},
						{
							type: 'email',
							name: 'email',
							label: 'Электронная почта',
							required: true,
							pattern: '[0-9a-z_.-]+@[0-9a-z_]+\\.[a-z]{2,5}',
							validation: {
								required: 'Заполните это поле',
								pattern: 'Некорректный адрес email',
							},
						},
						{
							type: 'tel',
							name: 'phone',
							label: 'Номер телефона',
							required: true,
							mask: '+0 000 0000000',
							pattern: '^(\\+\\d)\\s\\d{3}\\s\\d{7}$',
							validation: {
								required: 'Заполните это поле',
								pattern: 'Некорректный номер телефона',
							},
						},
						{
							type: 'file',
							name: 'file',
							placeholder: 'Прикрепить резюме',
							accept: 'application/pdf',
							acceptLabel: 'pdf',
							sizeLimit: 52428800,
							sizeLimitLabel: 'До 50 Мб',
							validation: {
								accept: 'Недопустимый формат файла',
								size: 'Превышен допустимый размер файла',
							},
						},
					],
					button: 'Отправить',
					notice:
						'Нажимая «Отправить», я соглашаюсь с условиями<br /><a href="/" target="_blank">Политики конфиденциальности</a> и <a href="/" target="_blank">Пользовательского соглашения</a>',
				},
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'white',
					paddingTop: false,
					paddingBottom: false,
				},
			},
		},

		{
			type: 'goToNext',
			content: {
				pre: 'Перейти к следующему разделу',
				text: 'Жизнь в Fensma',
				url: '/career/corp',
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'transparent',
					paddingTop: false,
					paddingBottom: false,
				},
			},
		},
	],
};
