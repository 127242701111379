import css from './FilterSelects.module.scss';
import React, { PropsWithChildren, FC, useState, useEffect } from 'react';
import { Box, Select } from '@core';
import { useRouter } from 'next/router';

interface Props {
	items?: Array<SelectType>;
}

export const FilterSelects: FC<PropsWithChildren<Props>> = React.memo(({ items }) => {
	const router = useRouter();

	const handleFilterChange = (name: string, value: string | null) => {
		const query = Object.entries({ ...router.query, [name]: value })
			.filter((item) => item[0] !== 'slug')
			.filter((item) => item[1])
			.map((item) => item[0] + '=' + item[1])
			.join('&');

		const pathname = router.asPath.split('?')[0]; // router.pathname;
		const url = pathname + (query ? '?' + query : '');

		router.push(url, undefined, { shallow: true }).then();
	};

	return (
		<Box className={css.module}>
			{items?.map((item, i) => (
				<Box key={i} className={css.element}>
					<FilterSelect onChange={handleFilterChange} {...item} />
				</Box>
			))}
		</Box>
	);
});

export const FilterSelect: FC<
	SelectType & { onChange: (name: string, value: string | null) => void }
> = React.memo(({ name, options, onChange, placeholder }) => {
	const { query } = useRouter();

	const [selected, setSelected] = useState<SelectOption | undefined>(undefined);

	const handleSelected = (selected: SelectOption | undefined) => {
		onChange(name, selected?.value || null);
	};

	useEffect(() => {
		const queryByName = query[name];
		const selected = options.find((option) => option.value === queryByName);

		setSelected(selected);
	}, [name, options, query]);

	return (
		<>
			<Select
				selected={selected}
				setSelected={handleSelected}
				name={name}
				placeholder={placeholder}
				options={options}
			/>
		</>
	);
});
