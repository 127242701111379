import css from '../GMap.module.scss';
import classnames from 'classnames';
import React, { useContext, useEffect, useCallback } from 'react';
import { useGoogleMap } from '@react-google-maps/api';
import { GMapProps, GMapContext } from '@core';

export const GMapControls = React.forwardRef<
	HTMLDivElement,
	Pick<GMapProps, 'objects' | 'defaultZoom' | 'defaultCenter' | 'setCenterOnActive'>
>(({ objects, defaultZoom, defaultCenter, setCenterOnActive }, ref) => {
	const map = useGoogleMap();
	const { activeObject, setActiveObject } = useContext(GMapContext);

	const handleZoom = (num: number) => {
		if (map) {
			const zoom = map.getZoom() || 10;
			map.setZoom(zoom + num);
		}
	};

	const setBounds = useCallback(() => {
		if (map) {
			const amount = Array.isArray(objects) ? objects.length : 0;

			switch (amount) {
				case 0:
					if (defaultZoom && defaultCenter?.length === 2) {
						map.setZoom(13);
						map.setCenter({
							lat: defaultCenter[0],
							lng: defaultCenter[1],
						});
					}
					break;
				case 1:
					if (defaultZoom && objects[0]?.coordinates) {
						map.setZoom(defaultZoom || 15);
						map.setCenter({
							lat: objects[0].coordinates[0],
							lng: objects[0].coordinates[1],
						});
					}
					break;
				default:
					const bounds = new google.maps.LatLngBounds();

					if (Array.isArray(objects)) {
						objects.forEach((object) => {
							bounds.extend({
								lat: object.coordinates[0],
								lng: object.coordinates[1],
							});
						});
					}

					map.fitBounds(bounds, 100);
					break;
			}
		}
	}, [map, defaultCenter, defaultZoom, objects]);

	// Установка начальной области
	useEffect(() => {
		setBounds();
	}, [setBounds]);

	// Установка центра
	useEffect(() => {
		if (map && setCenterOnActive) {
			if (activeObject) {
				map.setZoom(defaultZoom || 15);
				map.panTo({
					lat: activeObject.coordinates[0],
					lng: activeObject.coordinates[1],
				});
			} else {
				setBounds();
			}
		}
		// if (map && !setCenterOnActive) {
		// 	setBounds();
		// }
	}, [map, activeObject, setBounds, defaultZoom, setCenterOnActive, objects]);

	// Сбрасывать активный маркер кликом по карте
	// useEffect(() => {
	// 	const container = (ref as MutableRefObject<HTMLDivElement>)?.current;
	// 	if (!container) return;
	//
	// 	const onMapClick = (e: MouseEvent) => {
	// 		const isMarker = (e.target as HTMLElement).closest(`.${css.marker}`);
	//
	// 		if (!isMarker && setActiveObject) {
	// 			setActiveObject(undefined);
	// 		}
	// 	};
	//
	// 	container.addEventListener('click', onMapClick);
	//
	// 	return () => {
	// 		container.removeEventListener('click', onMapClick);
	// 	};
	// }, [ref, setActiveObject]);

	return (
		<>
			<button
				className={classnames(css.zoomBtn, css.zoomIn)}
				type="button"
				onClick={() => handleZoom(1)}
			/>
			<button
				className={classnames(css.zoomBtn, css.zoomOut)}
				type="button"
				onClick={() => handleZoom(-1)}
			/>
		</>
	);
});
