import css from './Flex.module.scss';
import classnames from 'classnames';
import React, {
	ComponentPropsWithoutRef,
	PropsWithChildren,
	forwardRef,
	CSSProperties,
} from 'react';

interface Props {
	direction?: 'row' | 'column' | 'rowReverse' | 'columnReverse';
	wrap?: 'wrap' | 'nowrap';
	align?: CSSProperties['alignItems'];
	justify?: CSSProperties['justifyContent'];
}

export const Flex = forwardRef<
	HTMLDivElement,
	PropsWithChildren<ComponentPropsWithoutRef<'div'> & Props>
>((props, ref) => {
	const { direction = 'row', wrap = 'wrap', align, justify } = props;
	const classes = classnames(props.className, css.flex, css[direction], css[wrap]);

	return (
		<div
			ref={ref}
			{...props}
			className={classes}
			style={{
				alignItems: align,
				justifyContent: justify,
			}}>
			{props.children}
		</div>
	);
});
