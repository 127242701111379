import css from './CardsTicker.module.scss';
import classnames from 'classnames';
import React, { FC, PropsWithChildren, HTMLAttributes } from 'react';
import { Box } from '@core';

interface CardsTickerFeedProps {
	items: Array<any>;
	ChildCard: FC<any>;
	classNameCard?: string;
	autoHeight?: boolean;
}

export const CardsTickerFeed: FC<
	PropsWithChildren<CardsTickerFeedProps & HTMLAttributes<HTMLElement>>
> = React.memo(({ items, className, classNameCard, autoHeight, ChildCard }) => {
	return (
		<Box className={classnames(className, css.feed)}>
			{items?.map((card, i) => (
				<ChildCard
					key={`cards-ticker-${i}-${className}`}
					className={classnames(classNameCard, css.card, { [css.cardAutoHeight]: autoHeight })}
					index={i}
					total={items.length}
					{...card}
				/>
			))}
		</Box>
	);
});
