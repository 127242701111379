import { getScrollBarWidth } from '@utils';

interface IOptions {
	elsToCorrect?: string;
	elsForPadding?: string;
	iosFix?: boolean;
}

interface IIndexable {
	[key: string]: any;
}

type BodyState = {
	overflow: string | undefined;
};

const defaultOptions: IOptions = {
	elsToCorrect: '.fixed-correct',
	elsForPadding: '.page__header',
	iosFix: true,
};

export class ScrollLocker {
	private _options: IOptions;
	public _state: boolean;
	public _shift: number | undefined;
	private _bodyState: BodyState;
	private _timeout: number | undefined;
	private _timeoutFinally: number | undefined;
	private readonly _sbWidth: number;
	private readonly _isIOS: boolean;
	private readonly _sb: number;

	private _elsToCorrect: NodeListOf<HTMLElement> | undefined;
	private _elsForPadding: NodeListOf<HTMLElement> | undefined;

	constructor(options?: IOptions) {
		this._options = Object.assign({}, defaultOptions, options);

		this._state = false;
		this._shift = undefined;
		this._timeout = undefined;
		this._timeoutFinally = undefined;
		this._sbWidth = getScrollBarWidth();
		this._isIOS =
			typeof window !== 'undefined' ? /iPad|iPhone|iPod/.test(window.navigator.userAgent) : false;
		this._sb = getScrollBarWidth();

		this._elsToCorrect = undefined;
		this._elsForPadding = undefined;

		this._bodyState = {
			overflow: undefined,
		};

		this.lock = this.lock.bind(this);
		this.unlock = this.unlock.bind(this);
		this.indents = this.indents.bind(this);
		this.setPositionFixed = this.setPositionFixed.bind(this);
		this.resetPositionFixed = this.resetPositionFixed.bind(this);
		this.getData = this.getData.bind(this);
	}

	lock({ fixed = true } = {}) {
		const body = document.body;
		// const hasScrollBar = document.body.scrollHeight > window.innerHeight;

		if (!this._state) {
			this._state = true;
			this._bodyState = {
				overflow: body.style.overflow,
			};

			body.style.overflow = 'hidden';
			body.style.paddingRight = `${this._sbWidth}px`;

			body.classList.add('scroll-locked');

			this.indents(true);

			if (fixed) {
				// this.setPositionFixed();
			}
		}
	}

	unlock({ delay = 0, fixed = true } = {}) {
		const body = document.body;

		let resolver: ((value: unknown) => void) | null = null;

		if (this._timeout !== null) window.clearTimeout(this._timeout);

		this._timeout = window.setTimeout(() => {
			if (this._state) {
				body.style.overflow = this._bodyState.overflow || '';
				body.style.paddingRight = '';

				body.classList.remove('scroll-locked');

				this.indents(false);

				if (fixed) {
					this.resetPositionFixed();
				}
			}

			this._state = false;
			resolver && resolver(true);
		}, delay);

		return new Promise((resolve) => {
			resolver = resolve;
		});
	}

	setPositionFixed() {
		const body = document.body;
		const scrollY = document.documentElement.scrollTop || document.body.scrollTop;

		if (this._timeoutFinally) window.clearTimeout(this._timeoutFinally);

		this._shift = this._shift || scrollY;

		window.requestAnimationFrame(() => {
			document.documentElement.classList.add('ios-scroll-fixed');

			body.style.position = 'fixed';
			body.style.top = `-${this._shift}px`;
			body.style.left = '0px';
			body.style.width = '100%';
			body.style.height = 'auto';
			body.style.overflowX = 'hidden';
			body.style.overflowY = 'hidden';
			body.style.paddingRight = `${this._sbWidth}px`;

			// const { innerHeight } = window;
			// setTimeout(() => window.requestAnimationFrame(() => {
			//     if (this._shift) {
			//         const bottomBarHeight = innerHeight - window.innerHeight;
			//         if (bottomBarHeight && scrollY >= innerHeight) {
			//             body.style.top = `-${this._shift + bottomBarHeight}px`;
			//         }
			//     }
			// }), 300);
		});
	}

	resetPositionFixed() {
		const body = document.body;

		document.documentElement.classList.remove('ios-scroll-fixed');

		body.style.position = '';
		body.style.top = '';
		body.style.left = '';
		body.style.bottom = '';
		body.style.width = '';
		body.style.height = '';
		body.style.overflowX = '';
		body.style.overflowY = '';
		body.style.paddingRight = '';

		if (this._shift) window.scrollTo(0, this._shift);

		this._timeoutFinally = window.setTimeout(() => {
			this._shift = undefined;
		}, 400);
	}

	indents(flag: boolean) {
		if (this._options.elsToCorrect && this._options.elsToCorrect.length)
			this._elsToCorrect = document.querySelectorAll<HTMLElement>(this._options.elsToCorrect);

		if (this._options.elsForPadding && this._options.elsForPadding.length)
			this._elsForPadding = document.querySelectorAll<HTMLElement>(this._options.elsForPadding);

		if (this._elsToCorrect)
			this._elsToCorrect.forEach((el) => {
				const type: string = el?.dataset?.indent || 'margin';
				const prop = type !== 'right' ? type + 'Right' : type;

				(el.style as IIndexable)[prop] = flag ? `${this._sb}px` : ``;
			});

		if (this._elsForPadding)
			this._elsForPadding.forEach((el) => {
				el.style.paddingRight = flag ? `${this._sb}px` : ``;
			});
	}

	getData() {
		return {
			shift: this._shift,
		};
	}
}

export default new ScrollLocker();
