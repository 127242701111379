import css from './StripsVerticalDouble.module.scss';
import React, { FC, PropsWithChildren, useContext } from 'react';
import { Box, StripsVertical } from '@core';
import { ViewportContext } from '@context';

interface Props {
	color?: string;
	before?: string;
	startScreen?: boolean;
	hideFirst?: boolean;
	paddingTop?: boolean;
	paddingBottom?: boolean;
}

export const StripsVerticalDouble: FC<PropsWithChildren<Props>> = ({
	color = 'white',
	before,
	startScreen,
	hideFirst,
	paddingTop = false,
	paddingBottom = false,
	children,
}) => {
	const { bp, vh } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	return (
		<>
			{!isMob && (
				<>
					<StripsVertical
						color={before}
						startScreen={startScreen}
						isOuter={true}
						paddingTop={false}
						paddingBottom={false}>
						<Box className={css.spacer} />
						<StripsVertical
							color={color}
							startScreen={startScreen}
							paddingTop={paddingTop}
							paddingBottom={paddingBottom}
							hideFirst={hideFirst}
							multiplier={0.9}
							shift={vh * 0.4}>
							{children}
						</StripsVertical>
					</StripsVertical>
				</>
			)}
			{isMob && (
				<>
					<StripsVertical
						color={color}
						startScreen={startScreen}
						paddingTop={paddingTop}
						paddingBottom={paddingBottom}
						hideFirst={hideFirst}
						multiplier={1}
						shift={vh * 0.4}>
						{children}
					</StripsVertical>
				</>
			)}
		</>
	);
};
