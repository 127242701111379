import { useEffect } from 'react';

export const useResize = (cb: () => void, deps: any[] = []) => {
	useEffect(() => {
		const isSSR = typeof window === 'undefined';
		if (isSSR) return;

		cb();
		window.addEventListener('resize', cb, { passive: true });

		return () => {
			window.removeEventListener('resize', cb);
		};
		// eslint-disable-next-line
	}, [cb, ...deps]);
};
