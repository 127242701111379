import React, { FC } from 'react';
import dynamic from 'next/dynamic';

export const spheres: Record<ServiceType, FC> = {
	triangle: dynamic(() => import('./TriangleSphere').then((mod) => mod.TriangleSphere), {
		ssr: false,
		loading: () => <></>,
	}) as FC,
	diamond: dynamic(() => import('./DiamondSphere').then((mod) => mod.DiamondSphere), {
		ssr: false,
		loading: () => <></>,
	}) as FC,
	pentagon: dynamic(() => import('./PentagonSphere').then((mod) => mod.PentagonSphere), {
		ssr: false,
		loading: () => <></>,
	}) as FC,
	hex: dynamic(() => import('./HexSphere').then((mod) => mod.HexSphere), {
		ssr: false,
		loading: () => <></>,
	}) as FC,
	ellipse: dynamic(() => import('./EllipseSphere').then((mod) => mod.EllipseSphere), {
		ssr: false,
		loading: () => <></>,
	}) as FC,
	cross: dynamic(() => import('./CrossSphere').then((mod) => mod.CrossSphere), {
		ssr: false,
		loading: () => <></>,
	}) as FC,
	point: dynamic(() => import('./PointSphere').then((mod) => mod.PointSphere), {
		ssr: false,
		loading: () => <></>,
	}) as FC,
};
