import React, { FC, HTMLAttributes } from 'react';
import { FormElement } from '@core/Form/FormElement/FormElement';
import { FormChild } from '@core/Form/FormComponent/components/FormChild/FormChild';

interface FormChildProps extends HTMLAttributes<HTMLDivElement> {
	props: FormGroupType;
}

export const FormGroup: FC<FormChildProps> = ({ props }) => {
	return props ? (
		<>
			<FormElement>
				{props?.items?.map((field, i) => (
					<FormChild key={`form-group-${i}`} props={field} />
				))}
			</FormElement>
		</>
	) : null;
};
