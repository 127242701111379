import css from './VideoSequence.module.scss';
import classnames from 'classnames';
import fastdom from 'fastdom';
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { PrimitiveKeys, Box, DragCursor, Flex, StripsVertical, Tag, Text, Icon } from '@core';
import { ViewportContext } from '@context';
import { normalize, padForNum } from '@utils';
import useIntersectionObserver from '@hooks/useIntersectionObserver';
import { useMousePosition } from '@hooks';

type TimeRange = [number, number];

type PointType = {
	time: string | number;
	title?: string;
	content?: Array<{ type: PrimitiveKeys; content?: string }>;
};

export interface VideoSequenceProps {
	video?: Video;
	points?: PointType[];
	sticky?: boolean;
}

export interface VideoSequenceNavProps {
	timepoints?: TimeRange[];
	autoplay?: boolean;
	current?: number;
	isLocked?: boolean;
	onSelect?: (index: number, range: TimeRange) => void;
}

export const VideoSequence: FC<VideoSequenceProps> = React.memo(
	({ video: videoSource, points = [], sticky = true }) => {
		const timepoints: TimeRange[] = useMemo(() => {
			const list = points.length ? [{ time: 0 }, ...points] : [];
			return list.map((item, i) => {
				const start = list[i - 1] ? Number(list[i - 1].time) : 0;
				const end = Number(item.time);
				return [start, end];
			});
		}, [points]);

		const ref = useRef<HTMLDivElement | null>(null);

		const [lock, setLock] = useState(false);
		const [proceed, setProceed] = useState(false);
		const [video, setVideo] = useState<HTMLVideoElement | null>(null);
		const [currentTime, setCurrentTime] = useState(0);
		const [currentIndex, setCurrentIndex] = useState(0);

		const handleVideoNav = useCallback(
			(index: number, [from, end]) => {
				const shallow = Math.abs(currentIndex - index) === 1 && index > currentIndex;
				// from = shallow ? currentTime : typeof from !== 'undefined' ? from : currentTime;

				if (video && !lock) {
					setLock(true);
					setCurrentIndex(index);

					goToTimecode(video, currentIndex, index, [from, end], shallow).then(() => {
						setLock(false);
						setCurrentTime(end);
					});
				}
			},
			[video, currentIndex, lock]
		);

		const entry = useIntersectionObserver(ref, {
			threshold: 0.5,
			freezeOnceVisible: false,
		});
		const entryOnce = useIntersectionObserver(ref, {
			threshold: 0.5,
			freezeOnceVisible: true,
		});

		useEffect(() => {
			if (entryOnce?.isIntersecting && !proceed) {
				setProceed(true);
				handleVideoNav(1, timepoints[1]);
			}
		}, [handleVideoNav, timepoints, proceed, entryOnce?.isIntersecting]);

		return (
			<VideoSequenceWrap>
				<Box className={classnames(css.section, sticky && css.sticky)}>
					<Flex ref={ref} className={css.screen}>
						<Box className={classnames(css.content, css.column, css.dark)}>
							<VideoSequenceSlides points={points} active={currentIndex - 1} />
							<VideoSequenceNav
								autoplay={entry?.isIntersecting}
								timepoints={timepoints}
								current={currentIndex}
								onSelect={handleVideoNav}
								isLocked={lock}
							/>
						</Box>
						<Box className={classnames(css.viewBox, css.column, css.white)}>
							{videoSource?.mp4 && (
								<video
									ref={setVideo}
									className={css.video}
									preload="preload"
									playsInline={true}
									muted={true}>
									<source src={videoSource.mp4} type="video/mp4" />
								</video>
							)}
						</Box>
					</Flex>
				</Box>
			</VideoSequenceWrap>
		);
	}
);

export const VideoSequenceWrap: FC = React.memo(({ children }) => {
	const { bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	return (
		<>
			{isMob && (
				<StripsVertical color="dark" paddingTop={false} paddingBottom={false}>
					{children}
				</StripsVertical>
			)}
			{!isMob && children}
		</>
	);
});

export const VideoSequenceNav: FC<VideoSequenceNavProps> = React.memo(
	({ timepoints = [], onSelect, autoplay, isLocked }) => {
		const { bp } = useContext(ViewportContext);
		const isMob = bp === 'xs';

		const ref = useRef<HTMLDivElement | null>(null);
		const rafId = useRef<any>(null);

		const splide = useRef<Splide | null>(null);
		const [index, setIndex] = useState(0);

		const handleSelect = useCallback(
			(index: number) => {
				onSelect && onSelect(index, timepoints[index]);
			},
			[onSelect, timepoints]
		);

		const handleElements = useCallback(() => {
			const root = ref.current;
			if (root) {
				let rootRect: DOMRect;
				let rootCenter = 0;

				fastdom.measure(() => {
					rootRect = root.getBoundingClientRect();
					rootCenter = rootRect.width / 2 + rootRect.left;
				});

				const elements = root.querySelectorAll<HTMLDivElement>(`.${css.navBtn}`);

				elements.forEach((el) => {
					fastdom.measure(() => {
						const { left, width } = el.getBoundingClientRect();
						const center = width / 2 + left;
						const distance = Math.abs(center - rootCenter);
						const scale = 1 - Math.min(0.999, normalize(distance, width * 3.3, 0, true));

						fastdom.mutate(() => {
							el.style.setProperty('--scale', scale.toString());
						});
					});
				});
			}
		}, [ref]);

		const handleMoveStart = (splide: any) => {
			if (rafId.current) {
				window.cancelAnimationFrame(rafId.current);
			}

			const tick = () => {
				handleElements();
				rafId.current = window.requestAnimationFrame(tick);
			};

			tick();

			const autoplay = splide?.Components.Autoplay;
			if (autoplay) autoplay?.pause();
		};

		const handleMoveEnd = (splide: any) => {
			if (rafId.current) {
				window.cancelAnimationFrame(rafId.current);
			}
			if (splide) {
				const autoplay = splide?.Components.Autoplay;
				autoplay?.play();

				const target = splide.Components.Controller.getIndex() + 1;
				setIndex(target);
			}
		};

		const handleArrow = useCallback(
			(dir: number) => {
				const instance = splide?.current;
				if (instance) {
					const autoplay = instance.splide?.Components.Autoplay;
					const controller = instance.splide?.Components.Controller;
					autoplay?.pause();
					controller?.go(dir > 0 ? '>' : '<');
				}
			},
			[splide]
		);

		useEffect(() => {
			handleElements();
		}, [handleElements]);

		useEffect(() => {
			handleSelect(index);
			// eslint-disable-next-line
		}, [index]);

		const { px, py } = useMousePosition<HTMLDivElement | null>(ref);

		useEffect(() => {
			const instance = splide?.current;
			if (instance) {
				const autoplayController = instance.splide?.Components.Autoplay;
				if (autoplayController && autoplay) {
					autoplayController.play();
				} else if (autoplayController && !autoplay) {
					autoplayController.pause();
				}
			}
		}, [splide, autoplay]);

		return (
			<>
				<Box className={classnames(css.navWrap, { [css.isLocked]: isLocked })}>
					<Box ref={ref} className={css.nav}>
						<Splide
							ref={splide}
							onDrag={handleMoveStart}
							onMove={handleMoveStart}
							onMoved={handleMoveEnd}
							options={{
								type: 'loop',
								clones: timepoints.length,
								focus: 'center',
								autoWidth: true,
								arrows: false,
								pagination: false,
								speed: 800,
								rewindSpeed: 1200,
								autoplay: false,
								interval: isMob ? 4000 : 4000,
							}}>
							{timepoints?.map((time, i) => {
								return i > 0 ? (
									<SplideSlide key={`video-model-time-${i}`}>
										<button type="button" className={css.navBtn}>
											<span>{padForNum(i, '00')}</span>
										</button>
									</SplideSlide>
								) : null;
							})}
						</Splide>
					</Box>
					<DragCursor className={css.cursor} x={px} y={py} />
				</Box>
				<Flex className={classnames(css.navArrows)}>
					<button
						type="button"
						className={classnames(css.navArrow, css.navArrowPrev)}
						onClick={() => handleArrow(-1)}>
						<Icon id="dart-prev" />
					</button>
					<button
						type="button"
						className={classnames(css.navArrow, css.navArrowNext)}
						onClick={() => handleArrow(1)}>
						<Icon id="dart-next" />
					</button>
				</Flex>
			</>
		);
	}
);

export const VideoSequenceSlides: FC<Pick<VideoSequenceProps, 'points'> & { active?: number }> =
	React.memo(({ points = [], active = 0 }) => {
		active = active < 0 ? 0 : active;

		const [slide, setSlides] = useState<HTMLDivElement | null>(null);
		const [height, setHeight] = useState<number | 'auto'>('auto');

		useEffect(() => {
			if (slide) {
				const activeSlide = slide.querySelector<HTMLDivElement>(`.${css.slideActive}`);
				setHeight(activeSlide?.offsetHeight || 'auto');
			}
		}, [slide, active]);

		return (
			<Box ref={setSlides} className={css.slides} style={{ height }}>
				{points?.map((slide, i) => (
					<VideoSequenceSlide
						key={`video-sequence-slide-${i}`}
						isActive={i === active}
						{...slide}
					/>
				))}
			</Box>
		);
	});

export const VideoSequenceSlide: FC<PointType & { isActive: boolean }> = React.memo(
	({ title, content, isActive }) => {
		return (
			<Box className={classnames(css.slide, { [css.slideActive]: isActive })}>
				{title && <Tag className={css.slideTitle} type="h3" isHTML={true} content={title} />}
				{content && content.length && (
					<Text className={css.slideText} color="gray">
						{content?.map((item, i) => {
							return <Tag key={item?.content?.slice(i, i + 8) + '-' + i} {...item} />;
						})}
					</Text>
				)}
			</Box>
		);
	}
);

const goToTimecode = (
	video: HTMLVideoElement,
	from: number,
	to: number,
	timeRange: [number, number],
	shallow = true
) => {
	let raf = 0;
	let start = timeRange[0];
	let end = timeRange[1];

	const play = (direction: 'forward' | 'backward', resolve: () => void) => {
		video.currentTime = start;

		setTimeout(() => {
			const onTimeUpdate = () => {
				if (video.currentTime >= end - 0.05) {
					video.pause();
					video.currentTime = end;
					resolve();
					window.cancelAnimationFrame(raf);
				} else {
					raf = window.requestAnimationFrame(onTimeUpdate);
				}
			};

			raf = window.requestAnimationFrame(onTimeUpdate);
			video.play().then();
		}, 10);
	};

	return new Promise<void>((resolve) => {
		const direction = end - start >= 0 ? 'forward' : 'backward';

		if (!shallow) {
			video.classList.add('is-faded');

			window.setTimeout(() => {
				video.currentTime = start;
				play(direction, resolve);

				window.setTimeout(() => {
					video.classList.remove('is-faded');
				}, 400);
			}, 800);
		} else {
			play(direction, resolve);
		}
	});
};

// const goToTimecodeWithInterval = (
// 	video: HTMLVideoElement,
// 	from: number,
// 	to: number,
// 	timeRange: [number, number],
// 	shallow = true
// ) => {
// 	const fps = 20;
//
// 	let start = timeRange[0];
// 	let end = timeRange[1];
// 	let target = start;
//
// 	const play = (direction: 'forward' | 'backward', resolve: () => void) => {
// 		const interval = window.setInterval(() => {
// 			const shift = 1 / fps;
//
// 			switch (direction) {
// 				case 'forward':
// 					target = Math.min(target + shift, end);
// 					break;
// 				case 'backward':
// 					target = Math.max(target - shift, end);
// 					break;
// 			}
//
// 			video.currentTime = target;
//
// 			if (end === target) {
// 				window.clearInterval(interval);
// 				video.pause();
// 				resolve();
// 			}
// 		}, 1000 / fps);
// 	};
//
// 	return new Promise<void>((resolve) => {
// 		const direction = end - start >= 0 ? 'forward' : 'backward';
//
// 		if (!shallow) {
// 			video.classList.add('is-faded');
//
// 			window.setTimeout(() => {
// 				video.currentTime = start;
// 				play(direction, resolve);
//
// 				window.setTimeout(() => {
// 					video.classList.remove('is-faded');
// 				}, 400);
// 			}, 800);
// 		} else {
// 			play(direction, resolve);
// 		}
// 	});
// };
