import css from './EmployeeStories.module.scss';
import classnames from 'classnames';
import fastdom from 'fastdom';
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Image from 'next/image';
import { Section, Container, Box, RawHtml, Icon } from '@core';
import { useScrollWithEase } from '@hooks';
import { ViewportContext } from '@context';
import { Move } from '@utils';
import { nanoid } from 'nanoid';

export type PicturesGroupType = Array<Picture>;

export interface StoriesIntroProps {
	title?: string;
	pictures?: Array<PicturesGroupType>;
	wrapperProps?: {
		startScreen?: boolean;
	};
}

export interface StoriesIntroPicturesProps {
	items?: Array<PicturesGroupType>;
}

export const EmployeeStories: FC<StoriesIntroProps> = React.memo(
	({ title, pictures = [], wrapperProps }) => {
		const stickyRefCallback = useCallback((node) => {
			if (node) {
				node.style.top = `calc(50% - ${node.offsetHeight / 2}px)`;
			}
		}, []);

		return (
			<Section
				className={classnames(css.section, {
					[css.main]: !!wrapperProps?.startScreen,
					[css.inter]: !wrapperProps?.startScreen,
				})}>
				<StoriesIntroPictures items={pictures} />
				<Box className={css.track}>
					<Box ref={stickyRefCallback} className={css.sticky}>
						<Container>
							<blockquote className={css.sentence}>
								<Icon className={css.sentenceIcon} id="qt-stroke" />
								<RawHtml>{title}</RawHtml>
							</blockquote>
						</Container>
					</Box>
				</Box>
			</Section>
		);
	}
);

export const StoriesIntroPictures: FC<StoriesIntroPicturesProps> = React.memo(({ items }) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const uid = useMemo(nanoid, [items]);

	useEffect(() => {
		const container = ref?.current;
		if (!container) return;

		const onTick = ({ x, y }: { x: number; y: number }) => {
			fastdom.mutate(() => {
				container.style.setProperty('--mx', `${x}`);
				container.style.setProperty('--my', `${y}`);
			});
		};

		Move.addListener(onTick);

		return () => {
			Move.removeListener(onTick);
		};
	}, [ref]);

	return (
		<Box ref={ref} className={css.mediaFeed}>
			{items?.map((group, i) => (
				<StoriesIntroPicturesGroup key={`media-feed-group-${uid}-${i}`} pictures={group} />
			))}
		</Box>
	);
});

export const StoriesIntroPicturesGroup: FC<{ pictures?: PicturesGroupType }> = React.memo(
	({ pictures }) => {
		const uid = useMemo(nanoid, [pictures]);
		const ref = useRef<HTMLDivElement | null>(null);
		const [counter, setState] = useState(0);
		const [lock, setLock] = useState(false);

		const handleHoverIn = useCallback(() => {
			if (lock || !pictures?.length) return;
			setState((prev) => {
				return prev + 1 > pictures.length - 1 ? 0 : prev + 1;
			});
		}, [pictures, lock]);

		useEffect(() => {
			let timer = 0;
			const container = ref?.current;

			fastdom.mutate(() => {
				if (container) {
					setLock(true);

					const entities = container.querySelectorAll(`.${css.mediaItemEntity}`);
					const target = entities[counter];

					if (target) {
						target.classList.add(css.isShown);

						entities.forEach((el) => {
							if (el !== target) {
								el.classList.add(css.isDown);
							}
						});

						window.clearTimeout(timer);

						timer = window.setTimeout(() => {
							entities.forEach((el) => {
								if (el !== target) {
									el.classList.remove(css.isShown);
								}
								el.classList.remove(css.isDown);
								setLock(false);
							});
						}, 800);
					}
				}
			});

			return () => {
				window.clearTimeout(timer);
			};
		}, [counter]);

		const { vh, bp } = useContext(ViewportContext);
		const isMob = bp === 'xs' || bp === 'sm';

		const onScroll = useCallback(
			(current: number) => {
				const mid = Math.min(-Math.min(current - vh / 2, 0), vh / 3);

				/*
				 * На мобиле shift принимает отрицательное, на десктопе до 0
				 */
				const shiftEnd = isMob ? vh / 3 : vh / 2;
				const shift = Math.min(Math.max(current, shiftEnd), vh) - vh / 2;

				fastdom.mutate(() => {
					if (ref?.current) {
						ref.current.style.setProperty('--fy', `${mid / (vh / 3)}`);
						ref.current.style.setProperty('--sy', `${shift / (vh / 2)}`);
					}
				});
			},
			[isMob, vh]
		);

		useScrollWithEase(ref, onScroll, {
			ease: 0.25,
			edge: 'top',
		});

		return (
			<Box ref={ref} className={css.mediaItem} onMouseEnter={handleHoverIn}>
				<Box className={css.mediaItemFrame}>
					<Box className={css.mediaItemBox}>
						{pictures?.map((item, i) => (
							<Box key={`${item.src}-${uid}-${i}`} className={css.mediaItemEntity}>
								<Image src={item.src} alt={item.alt} layout="fill" priority={true} />
							</Box>
						))}
					</Box>
				</Box>
			</Box>
		);
	}
);
