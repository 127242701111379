import React, { FC, useContext } from 'react';
import { ViewportContext } from '@context';
import type { ProjectPreviewProps } from '@components/ProjectsPreviews/components/ProjectPreview/ProjectPreview';
import { ProjectsPreviewsMobile } from '@components/ProjectsPreviews/components/ProjectsPreviewsMobile/ProjectsPreviewsMobile';
import { ProjectsPreviewsRegular } from '@components/ProjectsPreviews/components/ProjectsPreviewsRegular/ProjectsPreviewsRegular';

export interface ProjectsPreviewsProps {
	title?: string;
	titleUrl?: string;
	items?: ProjectPreviewProps[];
}

export const ProjectsPreviews: FC<ProjectsPreviewsProps> = React.memo((props) => {
	const { bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	return (
		<>
			{isMob && <ProjectsPreviewsMobile {...props} />}
			{!isMob && <ProjectsPreviewsRegular {...props} />}
		</>
	);
});
