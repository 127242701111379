import { ForwardedRef, MutableRefObject, useEffect } from 'react';
import { Scroll, Performance, isTouchDevice } from '@utils';
import fastdom from 'fastdom';

type RefType = MutableRefObject<HTMLElement | null>;

interface Options {
	ease: number;
	edge: 'top' | 'bottom';
	limit?: number;
	checkInView?: boolean;
}

const isTouch = isTouchDevice() || false;

export const useScrollWithEase = (
	ref: RefType | ForwardedRef<HTMLElement | null>,
	callback: (bottom: number, height: number, posY?: number) => void,
	options: Options
): void => {
	let { ease = 0.075, edge = 'bottom', limit, checkInView = true } = options;
	ease = isTouch ? 1 : ease;

	useEffect(() => {
		const node = (ref as RefType)?.current;

		let inView = true;
		let vh = window.innerHeight;

		let timer = 0;
		let target = 0;
		let current = 0;
		let height = 0;
		let posY = 0;

		const bounding = node?.getBoundingClientRect();
		if (!bounding) return;

		const onResize = () => {
			if (node) vh = window.innerHeight;
		};

		const onScroll = () => {
			const { top } = Scroll.getData();

			fastdom.measure(() => {
				if (node) {
					const bounding = node.getBoundingClientRect();
					target = bounding[edge];

					if (checkInView) {
						inView = bounding.top - vh * 2 < 0 && bounding.bottom + vh > 0;
					}

					if (limit) {
						target = Math.min(bounding[edge], limit);
					}

					posY = bounding.top + top;
				}
			});
		};

		const onTick = () => {
			if (checkInView && !inView) return;

			const diff = target - current;
			const delta = Math.abs(diff) < 0.01 ? 0 : diff * ease;

			if (current.toFixed(0) === target.toFixed(0)) return;

			if (delta) {
				const next = current + delta;
				current = parseFloat(next.toFixed(2));
			} else {
				current = target;
			}

			fastdom.measure(() => {
				callback(current, height, posY);
			});
		};

		target = bounding[edge];
		current = bounding[edge];
		height = bounding.height;

		onScroll();

		timer = window.setTimeout(() => {
			onScroll();
			callback(current, height, posY);
		}, 500);

		Scroll.addListener(onScroll);
		Performance.addListener(onTick);

		onResize();
		window.addEventListener('resize', onResize);

		return () => {
			Scroll.removeListener(onScroll);
			Performance.removeListener(onTick);

			window.clearTimeout(timer);
			window.removeEventListener('resize', onResize);
		};
	}, [ref, callback, edge, ease, limit, checkInView]);
};
