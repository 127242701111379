import css from './SquareArrowLink.module.scss';
import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';
import Link from 'next/link';
import { Icon } from '@core';

interface TextInterface {
	url: string;
	className?: string;
}

export const SquareArrowLink: FC<PropsWithChildren<TextInterface>> = React.memo(
	({ url, className }) => {
		return (
			<Link href={url}>
				<a className={classNames(className, css.link)}>
					<Icon id="link" />
				</a>
			</Link>
		);
	}
);
