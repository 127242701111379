import css from './PostsFeed.module.scss';
import classnames from 'classnames';
import type { PageSectionProps } from '@api/hooks/types';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Heading, FilterLinks, Container, Section } from '@core';
import {
	PostPreviewProps,
	PostPreview,
} from '@components/PostsFeed/components/PostPreview/PostPreview';
import { useData } from '@api';
import { useFilterQuery, useReloadTimeout, useIntersectionObserver } from '@hooks';
// import { useRouter } from 'next/router';

export interface PostsFeedProps extends PageSectionProps {
	title?: string;
	titleUrl?: string;
	filters: Array<FilterLinkType>;
	items?: PostPreviewProps[];
}

type PostsFeedHeadingProps = Pick<PostsFeedProps, 'title' | 'titleUrl' | 'filters'>;

const delay = 800;

export const PostsFeed: FC<PostsFeedProps> = React.memo(({ links, title, filters, items }) => {
	/*
	 * Data fetching
	 */
	const { query } = useFilterQuery();
	const { data: fetched, isLoading } = useData<PaginatedData<PostPreviewProps>>({
		url: links?.self,
		query,
	});

	const [fetching, setFetching] = useState(false);

	const handleFilterClick = useCallback(() => {
		let timer = 0;
		if (ref.current) {
			setFetching(true);

			timer = window.setTimeout(() => {
				setFetching(false);
			}, 100);
		}
		return () => {
			if (timer) window.clearTimeout(timer);
		};
	}, []);

	// const handleMoreClick = useCallback(() => {
	// 	const nextLink = fetched?.links?.next;
	// 	if (nextLink) {
	// 		const params = nextLink.split('?')[1];
	// 	}
	// }, [fetched]);

	/*
	 * Start animation
	 */
	const ref = useRef<HTMLDivElement | null>(null);
	const entry = useIntersectionObserver(ref, {
		threshold: 0.5,
		freezeOnceVisible: true,
	});

	useEffect(() => {
		if (ref?.current && entry?.isIntersecting) {
			ref.current.classList.add(css.visible);
		}
	}, [entry?.isIntersecting]);

	return (
		<Section ref={ref}>
			<Container>
				<PostsFeedHeading title={title} filters={filters} onFiltersChange={handleFilterClick} />
				<PostsFeedContent isLoading={isLoading || fetching} data={fetched?.data || items} />
				{/*<PostsFeedFooter onClick={handleMoreClick} />*/}
			</Container>
		</Section>
	);
});

const PostsFeedHeading: FC<PostsFeedHeadingProps & { onFiltersChange?: () => void }> = React.memo(
	({ title, titleUrl, filters, onFiltersChange }) => {
		return (
			<Heading
				className={classnames(css.heading, 'indent-margin')}
				title={title}
				titleUrl={titleUrl}>
				{filters && filters.length && <FilterLinks onClick={onFiltersChange} items={filters} />}
			</Heading>
		);
	}
);

const PostsFeedContent: FC<{
	data?: PostsFeedProps['items'];
	isLoading?: boolean;
}> = React.memo(({ data, isLoading }) => {
	const loading = useReloadTimeout(!!isLoading, delay);
	const [items, setItems] = useState(data);

	useEffect(() => {
		const timer = window.setTimeout(
			() => {
				setItems(data);
			},
			loading ? delay : 50
		);
		return () => {
			if (timer) window.clearTimeout(timer);
		};
	}, [loading, data]);

	return (
		<Box className={classnames(css.feed, { [css.isLoading]: loading }, 'indent-margin')}>
			<>
				{items?.map((item, i) => (
					<PostPreview key={item.title + '-' + i} className={css.card} {...item} />
				))}
			</>
		</Box>
	);
});

// const PostsFeedFooter: FC<{
// 	onClick?: () => void;
// 	isLoading?: boolean;
// }> = React.memo(({ onClick, isLoading }) => {
// 	const { locale } = useRouter();
// 	const label = locale === 'en' ? 'Load more' : 'Показать еще';
//
// 	return (
// 		<Box className={classnames(css.paging, { [css.isLoading]: isLoading }, 'indent-margin')}>
// 			<Button size="md" onClick={onClick}>
// 				{label}
// 			</Button>
// 		</Box>
// 	);
// });
