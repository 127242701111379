import css from './RulesFeed.module.scss';
import React, { FC } from 'react';
import type { BenefitsItemType } from '@components/BenefitsFeed/BenefitsFeed';
import { TabsSection } from '@core/TabsSection/TabsSection';

type BenefitsSectionType = {
	id: string;
	title: string;
	items: Array<BenefitsItemType>;
};

export interface BenefitsTabsProps {
	title?: string;
	titleUrl?: string;
	sections?: Array<BenefitsSectionType>;
}

export const BenefitsTabs: FC<BenefitsTabsProps> = React.memo(({ title, titleUrl, sections }) => {
	return <TabsSection title={title} titleUrl={titleUrl} sections={sections} />;
});
