import { DocumentPreviewProps } from '@components/DocumentsFeed/components/DocumentPreview/DocumentPreview';

export const documentsList: PaginatedData<DocumentPreviewProps> = {
	data: [
		{
			title: 'Сертификат соответствия (МОТ).pdf',
			url: '/dummy/dummy.pdf',
			date: '2021-09-19 00:00:00',
		},
		{
			title:
				'Лицензия Министерства культуры На реставрацию, консервацию и воссоздание памятников истории и культуры народов Российской Федерации.pdf',
			url: '/dummy/dummy.pdf',
			date: '2021-09-18 00:00:00',
		},
		{
			title: 'Сертификат соответствия (ISO).pdf',
			url: '/dummy/dummy.pdf',
			date: '2021-09-17 00:00:00',
		},
		{
			title: 'Сертификат соответствия_Название документа 3.pdf',
			url: '/dummy/dummy.pdf',
			date: '2021-08-15 00:00:00',
		},
		{
			title: 'Сертификат соответствия_Название документа 4.pdf',
			url: '/dummy/dummy.pdf',
			date: '2021-08-13 00:00:00',
		},
		{
			title: 'Сертификат соответствия_Название документа 5.pdf',
			url: '/dummy/dummy.pdf',
			date: '2021-08-08 00:00:00',
		},
	],
};

export const documentsListOne: PaginatedData<DocumentPreviewProps> = {
	data: [
		{
			title: 'Сертификат соответствия (ISO).pdf',
			url: '/dummy/dummy.pdf',
			date: '2021-09-17 00:00:00',
		},
		{
			title:
				'Лицензия Министерства культуры На реставрацию, консервацию и воссоздание памятников истории и культуры народов Российской Федерации.pdf',
			url: '/dummy/dummy.pdf',
			date: '2021-09-18 00:00:00',
		},
		{
			title: 'Сертификат соответствия (МОТ).pdf',
			url: '/dummy/dummy.pdf',
			date: '2021-09-19 00:00:00',
		},
		{
			title: 'Сертификат соответствия_Название документа 3.pdf',
			url: '/dummy/dummy.pdf',
			date: '2021-08-15 00:00:00',
		},
		{
			title: 'Сертификат соответствия_Название документа 4.pdf',
			url: '/dummy/dummy.pdf',
			date: '2021-08-13 00:00:00',
		},
	],
};

export const documentsListTwo: PaginatedData<DocumentPreviewProps> = {
	data: [
		{
			title: 'Сертификат соответствия (ISO).pdf',
			url: '/dummy/dummy.pdf',
			date: '2021-09-17 00:00:00',
		},
		{
			title: 'Сертификат соответствия (МОТ).pdf',
			url: '/dummy/dummy.pdf',
			date: '2021-09-19 00:00:00',
		},
		{
			title:
				'Лицензия Министерства культуры На реставрацию, консервацию и воссоздание памятников истории и культуры народов Российской Федерации.pdf',
			url: '/dummy/dummy.pdf',
			date: '2021-09-18 00:00:00',
		},
	],
};
