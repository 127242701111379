import useSWR from 'swr';
import { ReturnedSWRData } from '@api/hooks/types';
import { fetcher } from '@api/utils/fetcher';
import { FooterData, getMock } from '@api/mock';
import { useRouter } from 'next/router';
import { withLocale } from '@utils';

export const getFooterData = async (url: string, needMock: boolean = false) => {
	const mock = async () => getMock(url);
	if (needMock) return mock();

	return fetcher<any>(url);
};

export const useFooterData = (endpoint: string): ReturnedSWRData<FooterData> => {
	const { locale } = useRouter();

	const { data, error } = useSWR(withLocale(endpoint, locale), getFooterData, {
		revalidateIfStale: false,
		revalidateOnFocus: false,
		revalidateOnReconnect: false,
	});

	return {
		data: data,
		isLoading: !error && !data,
		isError: error,
	};
};
