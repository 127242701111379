import css from '../ProjectsGallery.module.scss';
import classnames from 'classnames';
import React, { FC, useRef } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Tag, Box, Icon } from '@core';

export interface ProjectInterface {
	url?: string;
	title?: string;
	picture?: Picture;
	className?: string;
}

export const ProjectsGalleryPreview: FC<ProjectInterface> = React.memo(
	({ title, url = '/', picture, className }) => {
		const ref = useRef<HTMLAnchorElement | null>(null);

		return (
			<Link href={url}>
				<a ref={ref} className={classnames(className, css.project)}>
					{picture && (
						<picture className={css.pic}>
							<Image
								src={picture.src}
								alt={picture?.alt || title}
								layout="fill"
								sizes="(max-width: 639px) 80vw, 40vw"
							/>
						</picture>
					)}
					<Box className={css.content}>
						{title && <Tag className={css.title} type="h3" content={title} isHTML={true} />}
						<Icon className={css.arrow} id="link" />
					</Box>
				</a>
			</Link>
		);
	}
);
