import css from '../StepsSlider.module.scss';
import classnames from 'classnames';
import React, { FC, useContext } from 'react';
import { Container, Heading } from '@core';
import { StepsSliderProps, StepsSliderContext } from '@components/StepsSlider/StepsSlider';
import { StepsSliderContent } from '@components/StepsSlider/components/StepsSliderContent';
import { StepsSliderContentList } from '@components/StepsSlider/components/StepsSliderContentList';
import { ViewportContext } from '@context';

export interface StepsSliderMainProps extends StepsSliderProps {}

export const StepsSliderMain: FC<StepsSliderMainProps> = React.memo(({ title, items = [] }) => {
	const { current } = useContext(StepsSliderContext);
	const { bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	return (
		<Container className={classnames(css.content, { [css.isActive]: current >= 0 })}>
			<Heading className={css.heading} classNameTitle="indent-margin" title={title} />
			{!isMob && <StepsSliderContent items={items} />}
			{isMob && <StepsSliderContentList items={items} />}
		</Container>
	);
});
