import css from './PartnersCard.module.scss';
import classnames from 'classnames';
import { PartnerType } from '@components/Partners/Partners';
import React, { FC, MutableRefObject, useCallback, useRef, useState } from 'react';
import Image from 'next/image';
import { Box } from '@core';
import { isTouchDevice } from '@utils';
import { useOutsideClick } from '@hooks';

export interface PartnersCardProps extends PartnerType {
	className?: string;
}

const isTouch = isTouchDevice() || false;

export const PartnersCard: FC<PartnersCardProps> = ({ className, url, logo, title, docType }) => {
	const ref = useRef<HTMLAnchorElement | null>(null);
	const [clicked, setClicked] = useState(false);

	const handleOutsideClick = useCallback(() => {
		ref?.current?.classList?.remove(css.clicked);
		setClicked(false);
	}, []);

	useOutsideClick(ref, handleOutsideClick);

	const handleClick = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			if (isTouch && !clicked) {
				e.preventDefault();

				ref?.current?.classList?.add(css.clicked);
				setClicked(true);
			}
		},
		[clicked]
	);

	return (
		<a
			ref={ref}
			onClick={handleClick}
			className={classnames(className, css.module)}
			href={url}
			target="_blank"
			rel="noreferrer noopener">
			<Box className={css.content}>
				<Box className={css.logo}>
					{logo && <Image src={logo.src} alt={logo.alt || title} layout="fill" />}
				</Box>
				<div className={css.docType}>{docType ? `.${docType}` : null}</div>
				<div className={css.title}>{title}</div>
			</Box>
		</a>
	);
};
