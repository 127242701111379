import css from './MapBox.module.scss';
import React, { FC } from 'react';
import { Box, GMap, GMapContextProvider } from '@core';
import { MapBoxInfo } from '@core/MapBox/MapBoxInfo';
import classnames from 'classnames';

export interface MapObject {
	id: string;
	coordinates: [number, number];
	data: {
		title?: string;
		text?: string;
		address?: string;
		url?: string;
	};
}

export interface MapBoxProps {
	objects: MapObject[];
	isLoading?: boolean;
}

export const MapBox: FC<MapBoxProps> = ({ objects, isLoading }) => {
	return (
		<GMapContextProvider>
			<Box className={classnames(css.mapBox, { [css.mapBoxIsLoading]: isLoading })}>
				<GMap className={css.mapBox} objects={objects} />
				<MapBoxInfo />
			</Box>
		</GMapContextProvider>
	);
};

// <Map2Gis className={css.mapBox} objects={objects} id="projects-on-map" />
