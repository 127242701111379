import React, { FC, useContext } from 'react';
import { ViewportContext } from '@context';
import { EventsFeedMobile } from '@components/EventsFeed/components/EventsFeedMobile/EventsFeedMobile';
import { EventsFeedRegular } from '@components/EventsFeed/components/EventsFeedRegular/EventsFeedRegular';
import { PageSectionProps } from '@api/hooks/types';

export interface EventType {
	title?: string;
	url?: string;
	date?: string;
	picture?: Picture;
}

export interface EventsFeedProps extends PageSectionProps {
	title?: string;
	titleUrl?: string;
	items: Array<EventType>;
	filters: Array<FilterLinkType>;
	hasTrailAnimation?: boolean;
}

export const EventsFeed: FC<EventsFeedProps> = React.memo((props) => {
	const { bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	return (
		<>
			{isMob && <EventsFeedMobile {...props} />}
			{!isMob && <EventsFeedRegular {...props} />}
		</>
	);
});
