import css from './PageUp.module.scss';
import classnames from 'classnames';
import React, { FC, useCallback, useContext } from 'react';
import { Button, Icon } from '@core';
import { ViewportContext } from '@context';
import { useScrollPosition } from '@hooks';
import { scrollToPosition } from '@utils';

export const PageUp: FC = () => {
	const { vh } = useContext(ViewportContext);
	const [scrollTop] = useScrollPosition();

	const handleClick = useCallback(() => {
		scrollToPosition(0);
	}, []);

	return (
		<Button
			className={classnames(
				css.pageUpBtn,
				{ [css.isShown]: vh > 0 && scrollTop > vh },
				'fixed-correct'
			)}
			onClick={handleClick}>
			<Icon id="page-up" />
		</Button>
	);
};
