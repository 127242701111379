import css from './StripsVerticalTail.module.scss';
import classnames from 'classnames';
import React, {
	FC,
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useScrollWithEase } from '@hooks';
import { ViewportContext } from '@context';
import fastdom from 'fastdom';

interface Props {
	color?: string;
	multiplier?: number;
	paddingTop?: boolean;
	paddingBottom?: boolean;
}

const stripsRange = [0.1, 0.15, 0.2, 0.25, 0.3];

export const StripsVerticalTail: FC<PropsWithChildren<Props>> = ({
	color = 'white',
	multiplier = 1,
	paddingTop = true,
	paddingBottom = true,
	children,
}) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const [translate, setTranslate] = useState(0);

	const { vh, bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	const onScroll = useCallback(
		(current: number, height: number) => {
			fastdom.measure(() => {
				const support = isMob ? vh * 0.85 : vh * 1.25;
				const limited = Math.max(Math.min(current, support), 0);
				const seek = 1 - limited / support;

				setTranslate(Math.max(seek * height, 0));
			});
		},
		[vh, isMob]
	);

	useScrollWithEase(ref, onScroll, {
		ease: 0.125,
		edge: 'bottom',
	});

	const stripClass = classnames(css.strip, css[color]);
	const contentClass = classnames(css.inner, {
		[css.innerPadTop]: paddingTop,
		[css.innerPadBottom]: paddingBottom,
	});

	const [strips, setStrips] = useState<NodeListOf<HTMLElement> | undefined>(undefined);
	useEffect(() => setStrips(ref?.current?.querySelectorAll<HTMLDivElement>(`.${css.strip}`)), []);

	useEffect(() => {
		fastdom.mutate(() => {
			strips?.forEach((el, i) => {
				el.style.transform = `translate3d(0, ${translate * -stripsRange[i] * multiplier}px, 0)`;
			});
		});
	}, [strips, translate, multiplier]);

	return (
		<div className={classnames(css.outer, `is-${color}-section`)} ref={ref}>
			<Strips className={stripClass} />
			<Content className={contentClass}>{children}</Content>
		</div>
	);
};

const Content: FC<PropsWithChildren<{ className?: string }>> = React.memo(
	({ className, children }) => {
		return <div className={className}>{children}</div>;
	}
);

const Strips: FC<{ className?: string }> = React.memo(({ className }) => {
	return (
		<div>
			<div className={className} />
			<div className={className} />
			<div className={className} />
			<div className={className} />
			<div className={className} />
		</div>
	);
});
