import { PageData } from '@api/hooks/types';

export const company: PageData = {
	meta: {
		title: 'Команда специалистов',
		description: 'Наш главный ресурс — опытная команда специалистов',
	},
	data: [
		{
			type: 'intro',
			content: {
				title: 'Команда<br /><color-accent>специалистов</color-accent>',
				breadcrumbs: [{ text: 'О компании' }, { text: 'Описание компании' }],
			},
		},

		{
			type: 'aboutContent',
			content: {
				subtitle:
					'Наш главный ресурс&nbsp;&mdash;<br /><color-accent>опытная</color-accent> команда специалистов',
				text:
					'<p>/ Компания Fensma была создана группой опытных специалистов в&nbsp;области проектирования и&nbsp;городского строительства. Существенной особенностью организации является молодой коллектив, способный решать задачи любой сложности, принимать быстрые взвешенные решения, основываясь как на&nbsp;знании существующих норм проектирования и&nbsp;производства работ, так и&nbsp;на&nbsp;новейшие разработки в&nbsp;области городского строительства.</p>' +
					'<p>Политика компании направлена на&nbsp;поддержание высоких стандартов управления, на&nbsp;постоянное повышение профессиональных навыков сотрудников, улучшение качества и&nbsp;эффективности всех видов работ за&nbsp;счет инвестиций в&nbsp;новые технологии и&nbsp;современную строительную технику.</p>',
			},
			wrapper: {
				type: 'stripsVertical',
				props: {
					color: 'white',
					startScreen: true,
				},
			},
		},

		{
			type: 'taskGallery',
			content: {
				title: 'Статистика<br /><color-accent>компании</color-accent>',
				items: [
					{
						text: 'Существования компании',
						stats: '> 10 лет',
					},
					{
						text: 'Сотрудников в штате',
						stats: '> 1 100',
					},
					{
						text: 'Выполненных объектов',
						stats: '> 220',
					},
					{
						text: 'Единиц строительной техники',
						stats: '> 60',
					},
					{
						text: 'Оборот компании за 2020 год',
						stats: '> 4.5 млд. р.',
					},
				],
				forcedScroll: true,
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'white',
					paddingTop: true,
				},
			},
		},

		{
			type: 'mediaSection',
			content: {
				title: 'Видео <br />о компании',
				cursor: {
					play: 'Воспроизвести',
					stop: 'Остановить',
				},
				bg: {
					src: '/temp/pic-22.jpg',
					width: 1920,
					height: 1080,
				},
				youtube: 'https://www.youtube.com/watch?v=kYQ-ndY8Pyw',
			},
		},

		{
			type: 'aboutContent',
			content: {
				sentence:
					'Комплексная специализация позволяет выполнять полный спектр работ, связанный со строительством зданий и сооружений различного назначения, а также осуществлять управление всеми этапами строительства.',
				text:
					'<p>/ Отлаженное взаимодействие между подразделениями компании, а&nbsp;также с&nbsp;подрядными организациями позволяет гарантировать высококачественное выполнение работ, гарантировать обеспечение сроков и&nbsp;соответствие нормам и&nbsp;требованиям предъявляемым строящимся объектам строительства.</p>' +
					'<p>Ответственность, как перед коллективом, так и&nbsp;перед заказчиком, требования поддержания авторитета компании и&nbsp;сохранения перспектив развития и&nbsp;расширения определяют стиль и&nbsp;подходы к&nbsp;работе. Прозрачность и&nbsp;открытость компании на&nbsp;уровне взаимодействия с&nbsp;партнерами создает основу для плодотворной и&nbsp;конструктивной работы, а&nbsp;устойчивость связей с&nbsp;субподрядчиками и&nbsp;поставщиками гарантирует высокое качество.</p>',
				pictures: [
					[
						{
							type: 'picture',
							src: '/temp/pic-33@2x.jpg',
							width: 480,
							height: 400,
							alt: '',
						},
						{
							type: 'picture',
							src: '/temp/pic-34@2x.jpg',
							width: 480,
							height: 288,
							alt: '',
						},
					],
					[
						{
							type: 'picture',
							src: '/temp/pic-34@2x.jpg',
							width: 480,
							height: 400,
							alt: '',
						},
						{
							type: 'picture',
							src: '/temp/pic-33@2x.jpg',
							width: 480,
							height: 288,
							alt: '',
						},
					],
				],
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'dark',
				},
			},
		},

		{
			type: 'valuablessFeed',
			content: {
				title: 'Ценности<br /><color-accent>Fensma</color-accent>',
				items: [
					{
						title: 'Доверие и честность',
						text: 'Наши отношения в команде крепкие и дружеские. Наша преданность делу и уважение к друг другу создают ценные продукты',
						icon: {
							src: '/figures/diamond.svg',
						},
					},
					{
						title: 'Инновации и технологии',
						text: 'Наши отношения в команде крепкие и дружеские. Наша преданность делу и уважение к друг другу создают ценные продукты',
						icon: {
							src: '/figures/atom.svg',
						},
					},
					{
						title: 'Опыт команды',
						text: 'Наши отношения в команде крепкие и дружеские. Наша преданность делу и уважение к друг другу создают ценные продукты',
						icon: {
							src: '/figures/chart.svg',
						},
					},
					{
						title: 'Высокое качество работы',
						text: 'Наши отношения в команде крепкие и дружеские. Наша преданность делу и уважение к друг другу создают ценные продукты',
						icon: {
							src: '/figures/quality.svg',
						},
					},
					{
						title: 'Уважение к людям',
						text: 'Наши отношения в команде крепкие и дружеские. Наша преданность делу и уважение к друг другу создают ценные продукты',
						icon: {
							src: '/figures/team.svg',
						},
					},
					{
						title: 'Постоянное улучшение',
						text: 'Наши отношения в команде крепкие и дружеские. Наша преданность делу и уважение к друг другу создают ценные продукты',
						icon: {
							src: '/figures/increase.svg',
						},
					},
				],
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'dark',
				},
			},
		},

		{
			type: 'activityScopes',
			content: {
				title: 'Сферы<br /><color-accent>деятельности</color-accent>',
				titleUrl: '/activities/general-contract',
				items: [
					{
						title: 'Генеральный<br />подряд',
						url: '/activities/general-contract',
						shape: 'triangle',
					},
					{
						title: 'Генеральное<br />проектирование',
						url: '/activities/2',
						shape: 'point',
					},
					{
						title: 'Охрана<br />окружающей среды',
						url: '/activities/conversation',
						shape: 'pentagon',
					},
					{
						title: 'Техника<br />безопасности',
						url: '/activities/4',
						shape: 'ellipse',
					},
					{
						title: 'EPC<br />контракт',
						url: '/activities/5',
						shape: 'diamond',
					},
					{
						title: 'Работы<br />нулевого цикла',
						url: '/activities/6',
						shape: 'cross',
					},
					{
						title: 'Культура<br />производства',
						url: '/activities/7',
						shape: 'hex',
					},
				],
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'dark',
				},
			},
		},

		{
			type: 'bigSlider',
			content: {
				title: 'Парк техники',
				titleUrl: '/company/tech',
				items: [
					{
						odometer: '17',
						text: 'буровых установок',
					},
					{
						odometer: '7',
						text: 'крановых (грейферных) установок',
					},
					{
						odometer: '>50',
						text: 'единиц вспомогательной техники',
					},
				],
				video: {
					type: 'video',
					mp4: '/video/footage.mp4',
				},
				// picture: {
				// 	src: '/temp/video-cover.jpeg',
				// 	width: 320,
				// 	height: 180,
				// 	alt: 'Современные строительные технологии и опыт команды',
				// },
			},
		},
	],
};
