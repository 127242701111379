import css from './EventsFeedMobile.module.scss';
import classnames from 'classnames';
import type { EventsFeedProps } from '@components/EventsFeed/EventsFeed';
import type { PostPreviewProps } from '@components/PostsFeed/components/PostPreview/PostPreview';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box, CardsSlider } from '@core';
import { EventsFeedCard } from '@components/EventsFeed/components/EventsFeedCard/EventsFeedCard';
import { EventsFeedHeading } from '@components/EventsFeed/components/EventsFeedRegular/EventsFeedRegular';
import { useFilterQuery, useReloadTimeout } from '@hooks';
import { useData } from '@api';

const delay = 800;

export const EventsFeedMobile: FC<EventsFeedProps> = ({
	links,
	title,
	titleUrl,
	items,
	filters,
}) => {
	/*
	 * Data fetching
	 */
	const { query } = useFilterQuery();
	const { data: fetched, isLoading } = useData<PaginatedData<PostPreviewProps>>({
		url: links?.self,
		query,
	});

	const [data, setData] = useState(fetched?.data || items);
	const [fetching, setFetching] = useState(false);

	const loading = useReloadTimeout(isLoading || fetching, delay);

	useEffect(() => {
		const timer = window.setTimeout(() => {
			if (fetched?.data) {
				setData(fetched.data);
			}
		}, delay);
		return () => {
			if (timer) window.clearTimeout(timer);
		};
	}, [loading, fetching, fetched]);

	const handleFilterClick = useCallback(() => {
		let timer = 0;
		setFetching(true);

		timer = window.setTimeout(() => {
			setFetching(false);
		}, 100);
		return () => {
			if (timer) window.clearTimeout(timer);
		};
	}, []);

	return (
		<Box className={classnames(css.module, { [css.loading]: loading })}>
			<EventsFeedHeading
				className={css.head}
				title={title}
				titleUrl={titleUrl}
				filters={filters}
				onFiltersChange={handleFilterClick}
			/>
			<CardsSlider classNameCard={css.card} items={data} ChildCard={EventsFeedCard} />
		</Box>
	);
};
