import css from './PartnersFlow.module.scss';
import classnames from 'classnames';
import fastdom from 'fastdom';
import { PartnersProps } from '@components/Partners/Partners';
import React, {
	FC,
	HTMLAttributes,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { Flex } from '@core';
import { ViewportContext } from '@context';
import { useScrollWithEase } from '@hooks';
import { PartnersCard } from '@components/Partners/components/PartnersCard/PartnersCard';

export const PartnersFlow: FC<PartnersProps & HTMLAttributes<HTMLDivElement>> = (props) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const [seek, setSeek] = useState(0);

	const { vh } = useContext(ViewportContext);

	const onScroll = useCallback(
		(current: number) => {
			const start = vh;
			const end = current - vh * 1.5; // + (vh - height) / 2;
			const absolute = end / start;
			const relative = Math.min(0.5, Math.max(-1, absolute));

			setSeek(relative);
		},
		[vh]
	);

	useScrollWithEase(ref, onScroll, {
		ease: 0.25,
		edge: 'bottom',
	});

	useEffect(() => {
		const module = ref.current;
		fastdom.mutate(() => {
			module?.style?.setProperty('--q', `${seek}`);
		});
	}, [seek]);

	return (
		<Flex
			ref={ref}
			className={classnames(
				css.flow,
				{
					[css.darkEnd]: props?.settings?.colorEnd === 'dark',
					[css.whiteEnd]: props?.settings?.colorEnd === 'white',
				},
				props.className
			)}>
			<PartnersFlowCards {...props} />
		</Flex>
	);
};

export const PartnersFlowCards: FC<PartnersProps> = React.memo(({ items }) => {
	return (
		<>
			{items.map((card, i) => (
				<PartnersCard key={card.logo + '-' + i} className={css.card} {...card} />
			))}
		</>
	);
});
