import { MapObject } from '@core';

export const mapPoints: PaginatedData<MapObject> = {
	data: [
		{
			id: '001',
			coordinates: [55.781105, 37.626287],
			data: {
				title: 'Спортивный комплекс<br /><color-accent>«Олимпийский»</color-accent>',
				text: 'Устройство ограждения конструкций котлована при реконструкции здания бассейна. Устройство сайного основания при строительстве подземной части сооружения',
				url: 'https://fensma.ru/',
				address: 'г. Москва, Олимпийский проспект, 16с1',
			},
		},
		{
			id: '002',
			coordinates: [55.705087, 37.564757],
			data: {
				title: 'Новая штаб-квартира<br /><color-accent>«Яндекса»</color-accent>',
				text: '19 июня 2020 года. Яндекс представил проект новой штаб-квартиры в Гагаринском районе Москвы. Здание будет расположено на месте гостиницы «Корстон».',
				address: 'г. Москва, ул. Косыгина, 15',
			},
		},
		{
			id: '003',
			coordinates: [55.832332, 37.624675],
			data: {
				title: 'Павильон<br /><color-accent>«Атомной энергии»</color-accent>',
				text: 'Устройство ограждения конструкций котлована при реконструкции здания бассейна. Устройство сайного основания при строительстве подземной части сооружения',
				url: 'https://fensma.ru/',
				address: 'г. Москва, проспект Мира, 119с19А',
			},
		},
		{
			id: '004',
			coordinates: [55.751463, 37.539446],
			data: {
				title: '<color-accent>«Москва-Сити»</color-accent><br />one tower',
				text: 'Устройство ограждения котлована из буросекущихся свай при строительстве многофункционального высотного жилого комплекса с подземной автостоянкой',
				url: 'https://fensma.ru/',
				address: 'г. Москва, 1-й Красногвардейский пр-д, 13',
			},
		},
		{
			id: '005',
			coordinates: [55.714698, 37.516485],
			data: {
				title: 'Автовокзал в составе ТПУ<br /><color-accent>«Щелковский»</color-accent>',
				text: 'Устройство свайных фундаментов под башенные краны, «стена в грунте» траншейного типа глубиной 53м, устройство баретт траншейного типа',
				url: 'https://fensma.ru/',
				address: 'г. Москва, ул. Мосфильмовская, д. 17Б',
			},
		},
	],
};

export const mapPointsFiltered: PaginatedData<MapObject> = {
	data: [
		{
			id: '002',
			coordinates: [55.705087, 37.564757],
			data: {
				title: 'Новая штаб-квартира<br /><color-accent>«Яндекса»</color-accent>',
				text: '19 июня 2020 года. Яндекс представил проект новой штаб-квартиры в Гагаринском районе Москвы. Здание будет расположено на месте гостиницы «Корстон».',
				address: 'г. Москва, ул. Косыгина, 15',
			},
		},
		{
			id: '004',
			coordinates: [55.751463, 37.539446],
			data: {
				title: '<color-accent>«Москва-Сити»</color-accent><br />one tower',
				text: 'Устройство ограждения котлована из буросекущихся свай при строительстве многофункционального высотного жилого комплекса с подземной автостоянкой',
				url: 'https://fensma.ru/',
				address: 'г. Москва, 1-й Красногвардейский пр-д, 13',
			},
		},
		{
			id: '005',
			coordinates: [55.714698, 37.516485],
			data: {
				title: 'Автовокзал в составе ТПУ<br /><color-accent>«Щелковский»</color-accent>',
				text: 'Устройство свайных фундаментов под башенные краны, «стена в грунте» траншейного типа глубиной 53м, устройство баретт траншейного типа',
				url: 'https://fensma.ru/',
				address: 'г. Москва, ул. Мосфильмовская, д. 17Б',
			},
		},
	],
};
