import { PostPreviewProps } from '@components/PostsFeed/components/PostPreview/PostPreview';

export const eventsAll: PaginatedData<PostPreviewProps> = {
	data: [
		{
			title:
				'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip в качестве делового партнёра',
			url: '/events/event',
			date: '2021-09-28 02:34:56',
			picture: {
				src: '/temp/event-1.png',
				width: 1280,
				height: 920,
				alt: 'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip',
			},
		},
		{
			title:
				'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
			url: '/events/event',
			date: '2021-09-25 02:34:56',
			picture: {
				src: '/temp/event-2.png',
				width: 1280,
				height: 920,
				alt: 'Участие в международной выставке GEOFLUID 2021',
			},
		},
		{
			title:
				'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
			url: '/events/event',
			date: '2021-09-20 02:34:56',
			picture: {
				src: '/temp/event-1.png',
				width: 1280,
				height: 920,
				alt: 'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip',
			},
		},
		{
			title:
				'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip в качестве делового партнёра',
			url: '/events/event',
			date: '2021-08-18 02:34:56',
			picture: {
				src: '/temp/event-2.png',
				width: 1280,
				height: 920,
				alt: 'Участие в международной выставке GEOFLUID 2021',
			},
		},
		{
			title:
				'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip в качестве делового партнёра',
			url: '/events/event',
			date: '2021-08-12 02:34:56',
			picture: {
				src: '/temp/event-1.png',
				width: 1280,
				height: 920,
				alt: 'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip',
			},
		},
		{
			title:
				'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
			url: '/events/event',
			date: '2021-07-06 02:34:56',
			picture: {
				src: '/temp/event-2.png',
				width: 1280,
				height: 920,
				alt: 'Участие в международной выставке GEOFLUID 2021',
			},
		},
		{
			title:
				'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
			url: '/events/event',
			date: '2021-05-02 02:34:56',
			picture: {
				src: '/temp/event-1.png',
				width: 1280,
				height: 920,
				alt: 'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip',
			},
		},
	],
};

export const eventsNews: PaginatedData<PostPreviewProps> = {
	data: [
		{
			title:
				'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
			url: '/events/event',
			date: '2021-09-20 02:34:56',
			picture: {
				src: '/temp/event-1.png',
				width: 1280,
				height: 920,
				alt: 'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip',
			},
		},
		{
			title:
				'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip в качестве делового партнёра',
			url: '/events/event',
			date: '2021-08-18 02:34:56',
			picture: {
				src: '/temp/event-2.png',
				width: 1280,
				height: 920,
				alt: 'Участие в международной выставке GEOFLUID 2021',
			},
		},
		{
			title:
				'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip в качестве делового партнёра',
			url: '/events/event',
			date: '2021-08-12 02:34:56',
			picture: {
				src: '/temp/event-1.png',
				width: 1280,
				height: 920,
				alt: 'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip',
			},
		},
		{
			title:
				'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
			url: '/events/event',
			date: '2021-07-06 02:34:56',
			picture: {
				src: '/temp/event-2.png',
				width: 1280,
				height: 920,
				alt: 'Участие в международной выставке GEOFLUID 2021',
			},
		},
	],
};

export const eventsTurnToWork: PaginatedData<PostPreviewProps> = {
	data: [
		{
			title:
				'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip в качестве делового партнёра',
			url: '/events/event',
			date: '2021-09-28 02:34:56',
			picture: {
				src: '/temp/event-1.png',
				width: 1280,
				height: 920,
				alt: 'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip',
			},
		},
		{
			title:
				'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
			url: '/events/event',
			date: '2021-09-25 02:34:56',
			picture: {
				src: '/temp/event-2.png',
				width: 1280,
				height: 920,
				alt: 'Участие в международной выставке GEOFLUID 2021',
			},
		},
		{
			title:
				'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
			url: '/events/event',
			date: '2021-05-02 02:34:56',
			picture: {
				src: '/temp/event-1.png',
				width: 1280,
				height: 920,
				alt: 'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip',
			},
		},
	],
};
