import css from './FeaturesList.module.scss';
import classnames from 'classnames';
import React, { FC } from 'react';
import { Box, RawHtml, Tag } from '@core';
import { padForNum } from '@utils';

interface Props {
	title?: string;
	list?: string[];
	className?: string;
}

export const FeaturesList: FC<Props> = ({ title, list, className }) => {
	return (
		<Box className={classnames(css.module, className)}>
			{title && <Tag type="h1" className="magazine-offset" content={title} isHTML={true} />}

			<div className={css.list}>
				{list?.map((item, i) => {
					return (
						<Box key={`fl-item-${i}`} className={classnames(css.item, 'item')}>
							<Box className={css.itemNum}>{padForNum(i + 1, '00')}.</Box>
							<RawHtml className={css.itemText}>{item}</RawHtml>
						</Box>
					);
				})}
			</div>
		</Box>
	);
};

FeaturesList.displayName = 'Intro';
