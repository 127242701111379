import css from './Vacancies.module.scss';
import classnames from 'classnames';
import type { PageSectionProps } from '@api/hooks/types';
import type { VacancyPreviewType } from '@components/Vacancies/components/VacancyPreview/VacancyPreview';
import React, { FC, memo, useCallback, useMemo, useRef, useState } from 'react';
import { Section, Container, Heading, FilterLinks } from '@core';
import { VacanciesFeed } from '@components/Vacancies/components/VacanciesFeed/VacanciesFeed';
import { VacanciesFilters } from '@components/Vacancies/components/VacanciesFilters/VacanciesFilters';
import { useFilterQuery } from '@hooks';
import { useData } from '@api';
import {
	VacancyDetails,
	VacancyDetailsType,
} from '@components/Vacancies/components/VacancyDetails/VacancyDetails';
import { useRouter } from 'next/router';

export interface VacanciesProps extends PageSectionProps {
	backLink?: string | null;
	title?: string;
	filters?: Array<FilterLinkType>;
	extendedFilters?: Array<FilterGroupType>;
	extendedFiltersCaption?: string;
	items?: Array<VacancyPreviewType>;
	details?: VacancyDetailsType;
	emptyText?: string;
}

export const Vacancies: FC<VacanciesProps> = memo(
	({
		links,
		backLink,
		title,
		filters,
		extendedFilters,
		extendedFiltersCaption,
		items,
		details,
		emptyText,
	}) => {
		const ref = useRef<HTMLDivElement | null>(null);

		/*
		 * Filtered data fetching
		 */
		const { query } = useFilterQuery();
		const { data: fetched, isLoading } = useData<PaginatedData<VacancyPreviewType>>({
			url: links?.self,
			query,
		});
		const fetchedItems = useMemo(
			() => (Array.isArray(fetched) ? fetched : fetched?.data),
			[fetched]
		);

		const [fetching, setFetching] = useState(false);

		/*
		 * Filters
		 */
		const handleFilterChange = useCallback(() => {
			let timer = 0;
			if (ref.current) {
				setFetching(true);

				timer = window.setTimeout(() => {
					setFetching(false);
				}, 100);
			}
			return () => {
				if (timer) window.clearTimeout(timer);
			};
		}, []);

		return (
			<>
				<VacancyDetails data={details} baseUrl={backLink} />
				<Section
					ref={ref}
					className={classnames(css.module, { [css.loading]: isLoading || fetching })}>
					<Container>
						<Heading className={css.heading} title={title}>
							{filters && filters.length && (
								<FilterLinks onClick={handleFilterChange} items={filters} />
							)}
						</Heading>
						{extendedFilters && (
							<VacanciesFilters
								filters={extendedFilters}
								onFilterChange={handleFilterChange}
								caption={extendedFiltersCaption}
							/>
						)}
						<VacanciesFeed
							data={fetchedItems || items}
							isLoading={isLoading || fetching}
							emptyText={emptyText}
						/>
					</Container>
				</Section>
			</>
		);
	}
);
