import { ContentTypeKeys, WrappersKeys } from '@api/hooks/types';
import { FC } from 'react';
import {
	Empty,
	SimpleWrapper,
	StripsHorizontalLeft,
	StripsTwoAxis,
	StripsVertical,
	StripsVerticalTail,
	StripsVerticalDouble,
} from '@core';
import { Intro } from '@components/Intro/Intro';
import { HomeIntro } from '@components/HomeIntro/HomeIntro';
import { TechPreamble } from '@components/TechPreamble/TechPreamble';
import { TechDetails } from '@components/TechDetails/TechDetails';
import { BrioMRS } from '@components/BrioMRS/BrioMRS';
import { SplitViewSlides } from '@components/SplitViewSlides/SplitViewSlides';
import { InfoBlock } from '@components/InfoBlock/InfoBlock';
import { BenefitsFeed } from '@components/BenefitsFeed/BenefitsFeed';
import { BenefitsList } from '@components/BenefitsList/BenefitsList';
import { BenefitsTabs } from '@components/BenefitsTabs/BenefitsTabs';
import { EventsFeed } from '@components/EventsFeed/EventsFeed';
import { StatsGallery } from '@components/StatsGallery/StatsGallery';
import { PicturesGallery } from '@components/PicturesGallery/PicturesGallery';
import { ProjectsGallery } from '@components/ProjectsGallery/ProjectsGallery';
import { ProjectsPreviews } from '@components/ProjectsPreviews/ProjectsPreviews';
import { IconicProjects } from '@components/IconicProjects/IconicProjects';
import { ActivityScopes } from '@components/ActivityScopes/ActivityScopes';
import { ActivityAbout } from '@components/ActivityAbout/ActivityAbout';
import { HomeCareer } from '@components/HomeCareer/HomeCareer';
import { Partners } from '@components/Partners/Partners';
import { ProjectsOnMap } from '@components/ProjectsOnMap/ProjectsOnMap';
import { MediaSection } from '@components/MediaSection/MediaSection';
import { PostsFeed } from '@components/PostsFeed/PostsFeed';
import { AnnouncesFeed } from '@components/AnnouncesFeed/AnnouncesFeed';
import { Article } from '@components/Article/Article';
import { TaskGallery } from '@components/TaskGallery/TaskGallery';
import { SplitViewSection } from '@components/SplitViewSection/SplitViewSection';
import { FaqFeed } from '@components/FaqFeed/FaqFeed';
import { AboutContent } from '@components/AboutContent/AboutContent';
import { ValuablessFeed } from '@components/ValuablessFeed/ValuablessFeed';
import { BigSlider } from '@components/BigSlider/BigSlider';
import { StoryLine } from '@components/StoryLine/StoryLine';
import { ReportsMain } from '@components/ReportsMain/ReportsMain';
import { ReportsFeed } from '@components/ReportsFeed/ReportsFeed';
import { DocumentsFeed } from '@components/DocumentsFeed/DocumentsFeed';
import { TestimonialsFeed } from '@components/TestimonialsFeed/TestimonialsFeed';
import { ContactsLinks } from '@components/ContactsLinks/ContactsLinks';
import { ContactsMap } from '@components/ContactsMap/ContactsMap';
import { Feedback } from '@components/Feedback/Feedback';
import { Vacancies } from '@components/Vacancies/Vacancies';
import { VacancyDetails } from '@components/Vacancies/components/VacancyDetails/VacancyDetails';
import { VacanciesGallery } from '@components/VacanciesGallery/VacanciesGallery';
import { GoToNext } from '@components/GoToNext/GoToNext';
import { EmployeeStories } from '@components/EmployeeStories/EmployeeStories';
import { PersonsGallery } from '@components/PersonsGallery/PersonsGallery';
import { Traineeship } from '@components/Traineeship/Traineeship';
import { StepsSlider } from '@components/StepsSlider/StepsSlider';
import { PreambleMedia } from '@components/PreambleMedia/PreambleMedia';
import { RulesFeed } from '@components/RulesFeed/RulesFeed';
import { PreambleTicker } from '@components/PreambleTicker/PreambleTicker';
import { VideoSequence } from '@components/VideoSequence/VideoSequence';
import { BenefitsFeedLarge } from '@components/BenefitsFeed/component/BenefitsFeedLarge';

export const sections: Record<ContentTypeKeys, FC<any>> = {
	empty: Empty,
	intro: Intro,
	homeIntro: HomeIntro,
	techPreamble: TechPreamble,
	techDetails: TechDetails,
	brioMRS: BrioMRS,
	splitViewSlides: SplitViewSlides,
	splitViewSection: SplitViewSection,
	infoBlock: InfoBlock,
	benefitsFeed: BenefitsFeed,
	benefitsFeedLarge: BenefitsFeedLarge,
	benefitsList: BenefitsList,
	benefitsTabs: BenefitsTabs,
	eventsFeed: EventsFeed,
	statsGallery: StatsGallery,
	taskGallery: TaskGallery,
	picturesGallery: PicturesGallery,
	projectsGallery: ProjectsGallery,
	projectsPreviews: ProjectsPreviews,
	iconicProjects: IconicProjects,
	activityScopes: ActivityScopes,
	activityAbout: ActivityAbout,
	homeCareer: HomeCareer,
	partners: Partners,
	projectsOnMap: ProjectsOnMap,
	mediaSection: MediaSection,
	postsFeed: PostsFeed,
	announcesFeed: AnnouncesFeed,
	article: Article,
	faqFeed: FaqFeed,
	aboutContent: AboutContent,
	valuablessFeed: ValuablessFeed,
	bigSlider: BigSlider,
	storyLine: StoryLine,
	reportsMain: ReportsMain,
	reportsFeed: ReportsFeed,
	documentsFeed: DocumentsFeed,
	testimonialsFeed: TestimonialsFeed,
	contactsLinks: ContactsLinks,
	contactsMap: ContactsMap,
	feedback: Feedback,
	goToNext: GoToNext,
	vacancies: Vacancies,
	vacancyDetails: VacancyDetails,
	vacanciesGallery: VacanciesGallery,
	employeeStories: EmployeeStories,
	personsGallery: PersonsGallery,
	traineeship: Traineeship,
	stepsSlider: StepsSlider,
	preambleMedia: PreambleMedia,
	preambleTicker: PreambleTicker,
	rulesFeed: RulesFeed,
	videoSequence: VideoSequence,
};

export const wrappers: Record<WrappersKeys, FC> = {
	stripsVertical: StripsVertical,
	stripsVerticalTail: StripsVerticalTail,
	stripsVerticalDouble: StripsVerticalDouble,
	stripsHorizontalLeft: StripsHorizontalLeft,
	stripsTwoAxis: StripsTwoAxis,
	simpleWrapper: SimpleWrapper,
};
