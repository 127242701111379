import css from '../Header.module.scss';
import classnames from 'classnames';
import React, {
	FC,
	FocusEventHandler,
	HTMLAttributes,
	MouseEvent,
	MouseEventHandler,
	useContext,
	useState,
} from 'react';
import AnimateHeight from 'react-animate-height';
import type { HeaderContats, HeaderLink } from '@api/mock';
import { Box } from '@core';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Sandwich } from '@components/Layout/components/Header/components/Sandwich';
import { PageLoaderContext, ViewportContext } from '@context';
import { useIsomorphicLayoutEffect } from '@hooks';
import { padForNum } from '@utils';
import { HeaderProps } from '@components/Layout/components/Header/Header';
import { PathLocale } from 'next/dist/shared/lib/i18n/normalize-locale-path';

interface NavBoxProps extends Pick<HeaderProps, 'langSwitch'> {
	menu?: Array<HeaderLink>;
	contacts?: HeaderContats;
}

interface ForwardsListeners {
	onClick: (event?: MouseEvent<HTMLAnchorElement>) => void;
}

interface MenuItem {
	item: HeaderLink;
}

export const NavBox: FC<NavBoxProps> = React.memo(({ menu, contacts, langSwitch }) => {
	const [navBoxState, setNavBoxState] = useState(false);

	const handleSandwichClick = () => {
		setNavBoxState((prev) => !prev);
	};

	const handleLinkClick = () => {
		setNavBoxState(false);
	};

	return (
		<>
			<Box className={classnames(css.navBox, { [css.navBoxShown]: navBoxState })}>
				<Box className={classnames(css.navBoxInner)}>
					<nav className={classnames(css.navMenu)}>
						<MenuLevel onClick={handleLinkClick} menu={menu} />
					</nav>
					<LangSelector langSwitch={langSwitch} />
					<ContactsBlock contacts={contacts} />
				</Box>
			</Box>
			<Sandwich active={navBoxState} onClick={handleSandwichClick} />
		</>
	);
});

export const MenuLevel: FC<
	NavBoxProps & ForwardsListeners & Omit<HTMLAttributes<HTMLUListElement>, 'onClick'>
> = ({ menu, onClick, className }) => {
	return (
		<ul className={classnames(className, css.navMenuList)}>
			{menu?.map((item, i) => (
				<MenuLevelItem key={`header-menu-${i}-item-${item.text}`} item={item} onClick={onClick} />
			))}
		</ul>
	);
};

export const MenuLevelLink: FC<
	MenuItem & ForwardsListeners & Omit<HTMLAttributes<HTMLAnchorElement>, 'onClick'>
> = ({ item, onClick, onFocus, onBlur, className, children }) => {
	return (
		<>
			{item.url && (
				<Link href={item.url}>
					<a
						{...item.props}
						className={className}
						onClick={onClick as MouseEventHandler}
						onFocus={onFocus}
						onBlur={onBlur}>
						{children}
					</a>
				</Link>
			)}
			{!item.url && (
				<span
					{...item.props}
					className={className}
					onClick={onClick as MouseEventHandler}
					onFocus={onFocus}
					onBlur={onBlur}
					tabIndex={0}>
					{children}
				</span>
			)}
		</>
	);
};

export const MenuLevelItem: FC<MenuItem & ForwardsListeners> = ({ item, onClick }) => {
	const router = useRouter();
	const isCurrent = router.asPath.startsWith(item.path || item.url || '');

	const { bp } = useContext(ViewportContext);
	const isMob = bp === 'xs' || bp === 'sm';

	const [expand, setExpand] = useState(isCurrent);
	// const [clicked, setClicked] = useState(false);
	const [focused, setFocused] = useState(false);

	const handleClick = (e?: MouseEvent) => {
		if (isMob) {
			if (item.nested && !expand) {
				e && e.preventDefault();

				setExpand((prev) => !prev);

				// if (item.url) {
				// 	setClicked(true);
				// }
			}

			if (!item.nested || expand) {
				onClick && onClick();
			}
		}
	};

	const handleFocus: FocusEventHandler = (e) => setFocused(e.target.matches(':focus-within'));
	const handleBlur: FocusEventHandler = (e) => setFocused(e.target.matches(':focus-within'));

	return (
		<li
			className={classnames({ 'is-current': isCurrent })}
			onFocus={handleFocus}
			onBlur={handleBlur}>
			<MenuLevelLink
				item={item}
				onClick={handleClick}
				className={classnames(css.navMenuLink, { [css.navMenuLinkCurrent]: isCurrent })}>
				{item.text}
			</MenuLevelLink>
			{item.nested && isMob && <MenuLevelFold item={item} expand={expand} onClick={onClick} />}
			{item.nested && !isMob && <MenuLevelDrop item={item} tabIndex={focused ? 0 : -1} />}
		</li>
	);
};

export const MenuLevelFold: FC<MenuItem & ForwardsListeners & { expand: boolean }> = ({
	item,
	expand,
	onClick,
}) => {
	return (
		<AnimateHeight height={expand ? 'auto' : 0} duration={400} animateOpacity={true}>
			<MenuLevel className={css.navMenuListSubMob} menu={item.nested} onClick={onClick} />
		</AnimateHeight>
	);
};

export const MenuLevelDrop: FC<{ item: HeaderLink; onClick?: () => void; tabIndex?: number }> = ({
	item,
	onClick,
	tabIndex,
}) => {
	const [rect, setRect] = useState<HTMLDivElement | null>(null);

	const router = useRouter();
	const { vw } = useContext(ViewportContext);
	const { ready } = useContext(PageLoaderContext);

	useIsomorphicLayoutEffect(() => {
		let timer = 0;
		const bounding = rect?.getBoundingClientRect();

		if (rect && bounding) {
			timer = window.setTimeout(() => {
				const left = bounding.left;
				const right = vw - (left + bounding.width);

				rect.style.setProperty('--left', left.toString());
				rect.style.setProperty('--right', right.toString());
			}, 200);
		}

		return () => window.clearTimeout(timer);
	}, [rect, vw, ready]);

	return (
		<Box ref={setRect} className={css.navMenuListSub}>
			<Box className={css.navMenuListSubInner}>
				{item.nested?.map((item, i) => {
					const isCurrent = router.asPath.split('?')[0] === item.url;

					return (
						<Link href={item.url || '/'} key={`header-menu-${i}-item-${item.text}`}>
							<a
								{...item.props}
								tabIndex={tabIndex}
								onClick={onClick}
								className={classnames(css.navMenuListSubLink, {
									[css.navMenuListSubLinkCurrent]: isCurrent,
								})}>
								<em>{padForNum(i + 1, '00')}</em>
								<span>{item.text}</span>
							</a>
						</Link>
					);
				})}
			</Box>
		</Box>
	);
};

export const LangSelector: FC<NavBoxProps & { className?: string }> = ({ langSwitch }) => {
	const { locale } = useRouter();

	return (
		<div className={classnames(css.navLang, css.flexFlow)}>
			<LangSelectorLink langSwitch={langSwitch} locale="en">
				<a className={classnames(css.navLangLink, { [css.navLangLinkCurrent]: locale === 'en' })}>
					En
				</a>
			</LangSelectorLink>
			<LangSelectorLink langSwitch={langSwitch} locale="ru">
				<a className={classnames(css.navLangLink, { [css.navLangLinkCurrent]: locale === 'ru' })}>
					Рус
				</a>
			</LangSelectorLink>
		</div>
	);
};

export const LangSelectorLink: FC<NavBoxProps & { locale: 'ru' | 'en' }> = ({
	langSwitch,
	locale,
	children,
}) => {
	const { query: queryObj, asPath } = useRouter();

	/*
	 * Получаем параметр langSwitch из мета-данных страницы
	 * Если он есть, то переключение языка осуществляем простым переходом по ссылке
	 * Если нет, то стандартное поведение next.js согласно конфигу
	 * Дополнительно проверяем process.env.NODE_ENV
	 */
	const pathname = langSwitch || asPath.split('?')[0];

	const query = { ...queryObj };
	delete query.slug;

	return (
		<Link href={{ pathname, query }} locale={locale}>
			{children}
		</Link>
	);
};

export const ContactsBlock: FC<Pick<NavBoxProps, 'contacts'>> = ({ contacts }) => {
	return (
		<div className={classnames(css.contacts)}>
			{contacts?.social?.map((link, i) => (
				<p key={`header-social-${i}`}>
					<a href={link.url || ''} target="_blank" rel="noreferrer noopener">
						{link.text}
					</a>
				</p>
			))}
			{contacts?.phone && (
				<p>
					<a href={`tel:${contacts.phone.replace(/\D/gi, '')}`}>{contacts.phone}</a>
				</p>
			)}
			{contacts?.email && (
				<p>
					<a href={`mailto:${contacts.email}`}>{contacts.email}</a>
				</p>
			)}
		</div>
	);
};
