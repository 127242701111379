import { EventEmitter } from './EventEmitter';
import fastdom from 'fastdom';

export interface ScrollData {
	top: number;
	center: number;
	bottom: number;
	prev: number;
	dir: 'up' | 'down';
}

export type ScrollHandler = (e: ScrollData) => void;

const isSSR = typeof window === 'undefined';

export class ScrollClass extends EventEmitter<ScrollData, ScrollHandler> {
	private _vh: number = 0;
	private _prev: number = 0;
	private _store: ScrollData;

	constructor() {
		super();

		this._store = {
			top: 0,
			center: 0,
			bottom: 0,
			prev: 0,
			dir: 'down',
		};

		this.onScroll = this.onScroll.bind(this);
		this.onResize = this.onResize.bind(this);
		this.getData = this.getData.bind(this);

		this.enable();
	}

	private onResize() {
		fastdom.measure(() => {
			this._vh = document.documentElement.clientHeight || window.innerHeight;
		});
	}

	private onScroll() {
		if (isSSR) return;

		fastdom.measure(() => {
			this._store.top = document.documentElement.scrollTop || document.body.scrollTop;
			this._store.center = this._store.top + this._vh / 2;
			this._store.bottom = this._store.top + this._vh;
			this._store.dir = this._store.top < this._prev ? 'up' : 'down';
			this._store.prev = this._prev;

			this.dispatch(this._store);

			this._prev = this._store.top;
		});
	}

	public update(): void {
		this.onScroll();
	}

	public getData(): ScrollData {
		return this._store;
	}

	public enable() {
		if (isSSR) return;

		this.onResize();
		window.addEventListener('resize', this.onResize);

		this.onScroll();
		window.addEventListener('scroll', this.onScroll, { passive: true });
	}

	public disable() {
		if (isSSR) return;

		window.removeEventListener('resize', this.onResize);
		window.removeEventListener('scroll', this.onResize);
	}
}
