import css from '../PersonsGallery.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, memo } from 'react';
import { Box, RawHtml, Tag, Text } from '@core';
import { padForNum } from '@utils';
import Image from 'next/image';

export interface PersonsGalleryPreviewProps extends HTMLAttributes<HTMLDivElement> {
	title?: string;
	subtitle?: string;
	text?: string;
	picture?: Picture;
	index?: number;
	total?: number;
}

export const PersonsGalleryPreview: FC<PersonsGalleryPreviewProps> = memo(
	({ title, subtitle, text, picture, index, total, className }) => {
		return (
			<article className={classnames(css.preview, className)}>
				<picture className={css.previewPic}>
					{picture && (
						<Image
							src={picture.src}
							alt={picture.alt}
							width={picture.width}
							height={picture.height}
							layout="intrinsic"
						/>
					)}
					{/*<div style={{ aspectRatio: '320 / 408', background: 'tomato' }} />*/}
				</picture>
				<Box className={css.previewContent}>
					{typeof index === 'number' && typeof total === 'number' && (
						<Text className={css.previewCounter} size="sm" color="inherit">
							{padForNum(index + 1, '00')}
							<span> — {total}</span>
						</Text>
					)}
					<header className={css.previewContentTop}>
						<Tag className={css.previewTitle} type="h3" content={title} isHTML={true} />
						<Text className={css.previewSubtitle} size="sm" color="gray">
							{subtitle}
						</Text>
					</header>
					<Text className={css.previewText} size="sm" color="inherit">
						<RawHtml content={text} />
					</Text>
				</Box>
			</article>
		);
	}
);
