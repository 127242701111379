import { MutableRefObject, useContext, useEffect, useState } from 'react';
import { ViewportContext } from '@context';

interface Position {
	x: number;
	y: number;
	px: number;
	py: number;
}

export const useMousePosition = <T extends HTMLElement | null = HTMLElement>(
	ref?: MutableRefObject<T>
): Position => {
	const { vw, vh } = useContext(ViewportContext);
	const [position, setPosition] = useState<Position>({ x: 0, y: 0, px: 0.5, py: 0.5 });

	useEffect(() => {
		const target = ref?.current;

		const setFromEvent = (e: MouseEvent) => {
			const x = e.clientX;
			const y = e.clientY;

			setPosition({ x, y, px: x / vw, py: 1 - y / vh });
		};

		if (target) {
			target.addEventListener('mousemove', setFromEvent);
		} else {
			window.addEventListener('mousemove', setFromEvent);
		}

		return () => {
			if (target) {
				target.removeEventListener('mousemove', setFromEvent);
			} else {
				window.removeEventListener('mousemove', setFromEvent);
			}
		};
	}, [vw, vh, ref]);

	return position;
};
