import css from './TraineeshipMain.module.scss';
import classnames from 'classnames';
import React, { FC } from 'react';
import { TraineeshipProps } from '@components/Traineeship/Traineeship';
import { BenefitsList } from '@components/BenefitsList/BenefitsList';

interface InternshipMainProps {}

export const TraineeshipMain: FC<InternshipMainProps & TraineeshipProps['main']> = React.memo(
	({ title, items }) => {
		return (
			<BenefitsList
				className={classnames(css.section, 'is-dark-section')}
				classNameTitle={css.title}
				classNameFeed={css.feed}
				title={title}
				items={items}
			/>
		);
	}
);
