type Offset = {
	top: number;
	left: number;
};

export const getOffset = (el: HTMLElement): Offset => {
	const box = el.getBoundingClientRect();

	return {
		top: box.top + window.scrollY,
		left: box.left + window.scrollY,
	};
};

export const getOffsetRecursively = (
	el: HTMLElement,
	ref?: HTMLElement
): { top: number; left: number } => {
	let top = 0;
	let left = 0;

	while (el && (ref ? el !== ref : true)) {
		top = top + parseInt(el.offsetTop.toString());
		left = left + parseInt(el.offsetLeft.toString());

		el = el.offsetParent as HTMLElement;
	}

	return { top, left };
};

export const getPrevElement = (el: HTMLElement) => {
	let sibling = el.previousSibling;

	while (sibling && sibling.nodeType != 1) {
		sibling = sibling.previousSibling;
	}

	return sibling as HTMLElement;
};

export const getNextElement = (el: HTMLElement) => {
	let sibling = el.nextSibling;

	while (sibling && sibling.nodeType != 1) {
		sibling = sibling.nextSibling;
	}

	return sibling as HTMLElement;
};
