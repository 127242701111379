export const capitalizeFirstLetter = (string: string): string => {
	string = string.toLowerCase();
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const wrapLetters = (
	string?: string,
	delay?: number,
	style?: Partial<CSSStyleDeclaration>
): string | undefined => {
	if (!string || typeof document === 'undefined') return string;

	const container = document.createElement('div');
	container.innerHTML = string;

	container.childNodes.forEach((node) => {
		if (node.nodeType === 3 && node.textContent) {
			const el = document.createElement('span');
			el.classList.add('node');
			el.innerHTML = splitByWords(node.textContent);
			node.parentNode?.insertBefore(el, node);
			node.parentNode?.removeChild(node);
		}
		if (node.nodeType === 1) {
			const el = node as HTMLElement;
			el.classList.add('node');
			el.innerHTML = splitByWords(el.innerHTML);
		}
	});

	if (style || delay) {
		container.querySelectorAll<HTMLElement>('letter').forEach((node, i) => {
			if (style) {
				node.style.cssText = Object.keys(style)
					.map((key) => {
						return `${key}: ${style[key as any]};`;
					})
					.join(' ');
			}
			if (delay) {
				node.style.transitionDelay = `${delay * (i + 1)}ms`;
			}
		});
	}

	return container.innerHTML;
};

export const splitByWords = (string: string): string => {
	return string
		.split(/[\s\n]/g)
		.map((word) => `<word>${splitByLetters(word)}</word> `)
		.join(' ');
};

export const splitByLetters = (string: string): string => {
	return string.replace(/\S/g, '<letter>$&</letter>');
};

const BR_MARK = '%br%';

export const splitByLines = (text: string, helper: HTMLElement): string[] => {
	const words = text.split(/[\s\n]/g);

	let line: string[] = [];
	let testLine: string[] = [];
	let output: string[] = [];

	words.forEach((word, i) => {
		// const isLast = i === words.length - 1;

		if (word === BR_MARK) {
			output.push(line.join(' '));
			testLine = [];
			line = [];

			return;
		}

		testLine.push(word);
		helper.innerHTML = testLine.join(' ');

		if (helper.scrollWidth > helper.clientWidth) {
			output.push(line.join(' '));

			// if (isLast) {
			// 	output.push(word);
			// }

			testLine = [word];
			line = [];
		}

		line.push(word);
	});

	if (output.length === 0) {
		output = [line.join(' ')];
	} else {
		output.push(line.join(' '));
	}

	return output;
};
