import css from './Blockquote.module.scss';
import React, { FC, PropsWithChildren } from 'react';
import { Box, Icon, RawHtml } from '@core';
import Image from 'next/image';

interface Props {
	text?: string;
	author?: string;
	position?: string;
	content?: string;
	picture?: Picture;
}

export const Blockquote: FC<PropsWithChildren<Props>> = ({
	content,
	text,
	author,
	position,
	picture,
}) => {
	return (
		<blockquote className={css.quote}>
			{picture && (
				<picture className={css.picture}>
					<Image
						src={picture.src}
						alt={picture.alt}
						width={picture.width}
						height={picture.height}
						layout="intrinsic"
						sizes="(max-width: 639px) 100vw, 30vw"
					/>
				</picture>
			)}
			<Box className={css.content}>
				<Icon className={css.qt} id="qt" />
				{content && <RawHtml content={content} />}
				{!content && (
					<>
						<Box className="text">{text}</Box>
						{author && (
							<Box className="author">
								<Box className="author__name">{author}</Box>
								{position && <Box className="author__position">{position}</Box>}
							</Box>
						)}
					</>
				)}
			</Box>
		</blockquote>
	);
};
