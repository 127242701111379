import css from './DragCursor.module.scss';
import classnames from 'classnames';
import React, { FC, useEffect, useRef } from 'react';
import { Icon } from '@core/Icon/Icon';

interface Props {
	x?: number;
	y?: number;
	className?: string;
}

export const DragCursor: FC<Props> = React.memo(({ x = 0, y = 0, className }) => {
	const ref = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const onMove = (e: MouseEvent) => {
			if (ref?.current) {
				const target = e.target as HTMLElement;
				const isAccenCard = !!target.closest('.is-accent-card');

				ref.current.classList.toggle(css.reverse, isAccenCard);
			}
		};

		window.addEventListener('mousemove', onMove);

		return () => {
			window.removeEventListener('mousemove', onMove);
		};
	}, []);

	return (
		<div
			ref={ref}
			className={classnames(css.cursor, className)}
			aria-hidden="true"
			style={{
				display: 'none',
				left: `${x * 100}%`,
				top: `${(1 - y) * 100}%`,
			}}>
			<Icon id="drag" />
		</div>
	);
});

DragCursor.displayName = 'DragCursor';
