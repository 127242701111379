import css from './PreambleMediaAfterword.module.scss';
import React, { FC } from 'react';
import { HomeCareerContent } from '@components/HomeCareer/components/HomeCareerRegular/HomeCareerRegular';

interface PreambleMediaAfterword {
	title?: string;
	text?: string;
	url?: string;
}

export const PreambleMediaAfterword: FC<PreambleMediaAfterword> = React.memo((props) => {
	return <HomeCareerContent className={css.track} {...props} />;
});
