import { MutableRefObject, useContext, useEffect } from 'react';
import { ViewportContext } from '@context';
import { Performance, Scroll } from '@utils';
import fastdom from 'fastdom';

type RefType = MutableRefObject<HTMLElement | null>;

interface UseStripedTailArgs {
	ref: RefType;
	forParent?: boolean;
}

// const range = [5, 15, 25, 35, 45];
const range = [5, 10, 15, 20, 25];

const get5Strips = (seek: number, range: number[]) => {
	const points = [
		`0 0`,
		`100% 0`,
		`100% ${100 - seek * range[4]}%`,
		`81.5% ${100 - seek * range[4]}%`,
		`81.5% ${100 - seek * range[3]}%`,
		`61.5% ${100 - seek * range[3]}%`,
		`61.5% ${100 - seek * range[2]}%`,
		`41.5% ${100 - seek * range[2]}%`,
		`41.5% ${100 - seek * range[1]}%`,
		`21.5% ${100 - seek * range[1]}%`,
		`21.5% ${100 - seek * range[0]}%`,
		`0 ${100 - seek * range[0]}%`,
	];

	return `polygon(${points.join(', ')})`;
};

const get2Strips = (seek: number, range: number[]) => {
	const points = [
		`0 0`,
		`100% 0`,
		`100% ${100 - seek * range[3]}%`,
		`51% ${100 - seek * range[3]}%`,
		`51% ${100 - seek * range[1]}%`,
		`0 ${100 - seek * range[1]}%`,
	];

	return `polygon(${points.join(', ')})`;
};

export const useStripedTail = ({ ref, forParent }: UseStripedTailArgs) => {
	const { vh, bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	const ease = 0.25;
	const edge = 'bottom';

	useEffect(() => {
		const node = (ref as RefType)?.current;
		const targetNode = forParent ? node?.closest<HTMLElement>(`[class*="-section"]`) : node;

		let timer = 0;
		let target = 0;
		let current = 0;
		let posY = 0;
		let seek = 0;

		const bounding = node?.getBoundingClientRect();

		if (!bounding) return;

		const onChangePosition = () => {
			const qvh = vh / 4;
			const pos = Math.min(Math.max(-(current - vh), 0), qvh * 4);

			seek = pos / (qvh * 4);

			fastdom.mutate(() => {
				if (targetNode) {
					targetNode.style.clipPath = isMob ? get2Strips(seek, range) : get5Strips(seek, range);
				}
			});
		};

		const onScroll = () => {
			const { top } = Scroll.getData();

			fastdom.measure(() => {
				if (node && targetNode) {
					const bounding = node.getBoundingClientRect();
					target = bounding[edge];

					posY = bounding.top + top;
				}
			});
		};

		const onTick = () => {
			const diff = target - current;
			const delta = Math.abs(diff) < 0.01 ? 0 : diff * ease;

			if (current.toFixed(0) === target.toFixed(0)) return;

			if (delta) {
				const next = current + delta;
				current = parseFloat(next.toFixed(2));
			} else {
				current = target;
			}

			onChangePosition();
		};

		target = bounding[edge];
		current = bounding[edge];

		onScroll();
		timer = window.setTimeout(onScroll, 100);

		Scroll.addListener(onScroll);
		Performance.addListener(onTick);

		return () => {
			Scroll.removeListener(onScroll);
			Performance.removeListener(onTick);
			window.clearTimeout(timer);
		};
	}, [ref, forParent, edge, ease, vh, isMob]);
};
