import css from './ReportsGroup.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, useRef } from 'react';
import { Box, Flex, Icon, LinkTag, Tag } from '@core';
import { padForNum } from '@utils';
import useIntersectionObserver from '@hooks/useIntersectionObserver';

export interface ReportsGroupItemProps {
	title?: string;
	url?: string;
}

export interface ReportsGroupProps extends HTMLAttributes<HTMLDivElement> {
	items: Array<ReportsGroupItemProps>;
	index?: number;
	sublabel?: string;
}

export const ReportsGroup: FC<ReportsGroupProps> = React.memo(
	({ index, items, sublabel, className }) => {
		const ref = useRef<HTMLDivElement | null>(null);

		const entry = useIntersectionObserver(ref, {
			threshold: 0.25,
			freezeOnceVisible: true,
		});

		return (
			<Flex
				ref={ref}
				className={classnames(className, css.section, { [css.isVisible]: entry?.isIntersecting })}>
				<Box className={css.sectionLeft}>
					<Box className={css.sectionLeftCell}>
						{typeof index !== 'undefined' && (
							<>
								{sublabel && <Box className={css.sectionCaption}>{sublabel}</Box>}
								{!sublabel && (
									<Tag className={css.sectionNum} type="h2" content={padForNum(index, '00')} />
								)}
							</>
						)}
						{/*
						{typeof index !== 'undefined' && (
							<Tag className={css.sectionNum} type="h2">
								{padForNum(index, '00')}
							</Tag>
						)}
						{sublabel && <Box className={css.sectionSublabel}>{sublabel}</Box>}
						*/}
					</Box>
				</Box>
				<Box className={css.sectionRight}>
					{items?.map((report, i) => (
						<ReportsGroupItem key={report.title + '-' + i} {...report} />
					))}
				</Box>
			</Flex>
		);
	}
);

export const ReportsGroupItem: FC<ReportsGroupItemProps> = React.memo(({ title, url }) => {
	const content = (
		<>{title && <Tag className={css.caption} type="h4" content={title} isHTML={true} />}</>
	);

	return url ? (
		<LinkTag className={css.item} href={url}>
			<Icon className={css.arrow} id="link" />
			{content}
		</LinkTag>
	) : (
		<Box className={css.item}>{content}</Box>
	);
});
