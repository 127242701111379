import css from './FaqFeed.module.scss';
import classnames from 'classnames';
import React, { FC, useRef } from 'react';
import { SpoilerProps, Spoiler, Section, Heading, Container, Box } from '@core';
import useIntersectionObserver from '@hooks/useIntersectionObserver';

interface FaqItem extends SpoilerProps {}

interface FaqFeedProps {
	title?: string;
	titleUrl?: string;
	items?: Array<FaqItem>;
	indent?: boolean;
}

export const FaqFeed: FC<FaqFeedProps> = React.memo(
	({ items = [], title, titleUrl, indent = true }) => {
		const ref = useRef<HTMLDivElement | null>(null);

		const entry = useIntersectionObserver(ref, {
			threshold: 0.25,
			freezeOnceVisible: true,
		});

		return (
			<Section
				className={classnames(css.section, {
					'is-visible': entry?.isIntersecting,
				})}>
				<Container>
					<Box className={classnames({ 'indent-margin': indent })}>
						<Heading
							className={css.heading}
							offsetType="regular"
							title={title}
							titleUrl={titleUrl}
						/>
						{items.map((item, i) => (
							<Spoiler key={item.title + '-' + i} className={css.item} {...item} />
						))}
					</Box>
				</Container>
			</Section>
		);
	}
);
