import css from './Spoiler.module.scss';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
import React, {
	FC,
	HTMLAttributes,
	PropsWithChildren,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { PrimitiveKeys, SpecificPrimitivesKeys, Box, RawHtml, Tag, Text } from '@core';
import { checkKeyCode } from '@utils';

export interface SpoilerProps {
	title: string;
	content?: string | Array<{ type: PrimitiveKeys | SpecificPrimitivesKeys; content?: string }>;
	isActive?: boolean;
	toggleActive?: () => void;
}

export const Spoiler: FC<PropsWithChildren<SpoilerProps & HTMLAttributes<HTMLDivElement>>> =
	React.memo(({ title, content, className, isActive, toggleActive, children }) => {
		const [active, setActive] = useState(false);
		const state = typeof isActive !== 'undefined' ? isActive : active;

		const handleToggle = useCallback(() => {
			toggleActive && toggleActive();
			setActive((prev) => !prev);
		}, [toggleActive]);

		const [head, setHead] = useState<HTMLElement | null>(null);

		useEffect(() => {
			if (!head) return;

			const onKeypress = (e: KeyboardEvent) => {
				if (e.target === head && checkKeyCode(e, 32)) {
					e.preventDefault();
					handleToggle();
				}
			};

			document.documentElement.addEventListener('keydown', onKeypress);
			return () => {
				document.documentElement.removeEventListener('keydown', onKeypress);
			};
		}, [head, handleToggle]);

		return (
			<article className={classnames(className, css.spoiler, { [css.active]: state })}>
				<h2
					ref={setHead}
					tabIndex={0}
					className={classnames(css.head, 'h2')}
					onClick={handleToggle}>
					<RawHtml content={title} />
					<span className={css.plus} />
				</h2>
				<Box className={css.body}>
					<AnimateHeight height={state ? 'auto' : 0} duration={600} animateOpacity={true}>
						<Text className={classnames(css.inner, 'wysiwyg')} size="md">
							{content && typeof content === 'string' && <RawHtml content={content} />}
							{content && Array.isArray(content) && (
								<>
									{content?.map((item, i) => (
										<Tag key={item?.content?.slice(i, i + 8) + '-' + i} {...item} />
									))}
								</>
							)}
							{children}
						</Text>
					</AnimateHeight>
				</Box>
			</article>
		);
	});
