import React, { FC, useEffect, useState, createContext } from 'react';

export type LoadingState = 'waiting' | 'playing' | 'complete';

interface ContextProps {
	ready: boolean;
	setReadyState?: React.Dispatch<React.SetStateAction<boolean>>;
	loadingState: LoadingState;
	setLoadingState?: React.Dispatch<React.SetStateAction<LoadingState>>;
	firstLoaded: boolean;
	setFirstLoaded?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PageLoaderContext = createContext<ContextProps>({
	ready: false,
	setReadyState: undefined,
	loadingState: 'waiting',
	setLoadingState: undefined,
	firstLoaded: false,
	setFirstLoaded: undefined,
});

// const duration = 4000;

export const PageLoaderProvider: FC = ({ children }) => {
	const [ready, setReady] = useState(false);
	const [loadingState, setLoadingState] = useState<LoadingState>('waiting');
	const [firstLoaded, setFirstLoaded] = useState(false);

	// Ожидание ready-состояния
	// useEffect(() => {
	// 	let mounted = true;
	// 	let loaded = false;
	// 	let timer = 0;
	//
	// 	const isSSR = typeof window === 'undefined';
	// 	if (isSSR || !mounted) return;
	//
	// 	const transitionLoop = () => {
	// 		const interval = (resolve: (value?: unknown) => void, timeout?: number) => {
	// 			timer = window.setTimeout(() => {
	// 				if (loaded) {
	// 					resolve();
	// 				} else {
	// 					interval(resolve);
	// 				}
	// 			}, timeout || duration);
	// 		};
	// 		return new Promise((resolve) => {
	// 			interval(resolve, duration * 2.75);
	// 		});
	// 	};
	// 	transitionLoop().then(() => {
	// 		setReady(true);
	// 	});
	//
	// 	const onLoad = () => {
	// 		loaded = true;
	// 	};
	//
	// 	window.addEventListener('load', onLoad);
	//
	// 	return () => {
	// 		window.clearTimeout(timer);
	// 		window.removeEventListener('load', onLoad);
	// 	};
	// }, []);

	useEffect(() => {
		if (loadingState === 'complete') {
			document.documentElement.classList.add('is-loaded');
			document.documentElement.classList.add('is-ready-to-scroll');
		}
	}, [loadingState]);

	return (
		<PageLoaderContext.Provider
			value={{
				ready,
				setReadyState: setReady,
				firstLoaded,
				setFirstLoaded,
				loadingState,
				setLoadingState,
			}}>
			{children}
		</PageLoaderContext.Provider>
	);
};
