// import { useEffect, useState } from 'react';
import { useDelayUnmount } from '@hooks/useDelayUnmount';

export const useReloadTimeout = (isLoading: boolean, delay: number = 2000, url?: string) => {
	// const loading = useDelayUnmount(isLoading, delay);
	//
	// const [wasRequested, setWasRequested] = useState(false);
	// const [shouldMount, setShouldMount] = useState(true);
	//
	// useEffect(() => {
	// 	if (loading) {
	// 		setShouldMount(false);
	// 		setWasRequested(true);
	// 		console.log('Loading!');
	// 	} else if (wasRequested) {
	// 		setShouldMount(true);
	// 		setWasRequested(false);
	// 		console.log('Loaded!');
	// 	}
	// }, [loading, wasRequested]);
	//
	// useEffect(() => {
	// 	console.log('Changed!');
	// }, [url]);

	return useDelayUnmount(isLoading, delay); // { loading, shouldMount };
};
