import { PageData } from '@api/hooks/types';

export const conversation: PageData = {
	meta: {
		title: 'Помощь планете и людям',
		description:
			'Забота об окружающей среде является неотъемлемой частью корпоративной культуры и социальной ответственности',
	},
	data: [
		{
			type: 'intro',
			content: {
				title: 'Помощь<br /><color-accent>планете и людям</color-accent>',
				breadcrumbs: [{ text: 'Деятельность' }, { text: 'Охрана окружающей среды' }],
			},
		},

		{
			type: 'infoBlock',
			content: {
				subtitle:
					'<color-accent>Забота об окружающей среде</color-accent> является неотъемлемой частью корпоративной культуры и социальной ответственности',
			},
			wrapper: {
				type: 'stripsVertical',
				props: {
					color: 'white',
					startScreen: true,
				},
			},
		},

		{
			type: 'taskGallery',
			content: {
				title: 'Как мы помогаем<br /><color-accent>планете</color-accent>',
				items: [
					{
						text: 'Разработка проектов нормативных правовых актов, направленных на обеспечение функционирования эффективной системы нормирования ',
					},
					{
						text: 'Разработка проектов нормативных правовых актов, направленных на обеспечение функционирования эффективной системы нормирования негативного воздействия на окружающую среду',
					},
					{
						text: 'Разработка проектов нормативных правовых актов, направленных на обеспечение',
					},
					{
						text: 'Разработка проектов нормативных правовых актов, направленных на обеспечение функционирования эффективной системы нормирования ',
					},
					{
						text: 'Разработка проектов нормативных правовых актов, направленных на обеспечение функционирования эффективной системы нормирования негативного воздействия на окружающую среду',
					},
					{
						text: 'Разработка проектов нормативных правовых актов, направленных на обеспечение',
					},
				],
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'white',
					paddingTop: true,
				},
			},
		},

		{
			type: 'infoBlock',
			content: {
				sentence:
					'Компанией на регулярной основе проводится масштабная работа по обеспечению экологической безопасности, сохранению и восстановлению природных ресурсов. Для достижения лучших показателей компания постоянно совершенствует подходы к управлению природоохранной деятельностью, наращивает масштабы экологических мероприятий и необходимые инвестиции.',
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'dark',
				},
			},
		},

		{
			type: 'picturesGallery',
			content: {
				title: 'Галерея',
				items: [
					{
						src: '/temp/pic-03.jpg',
						width: 692,
						height: 576,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-04.jpg',
						width: 692,
						height: 718,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-05.jpg',
						width: 692,
						height: 474,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-06.jpg',
						width: 692,
						height: 576,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-03.jpg',
						width: 692,
						height: 576,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-04.jpg',
						width: 692,
						height: 718,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-05.jpg',
						width: 692,
						height: 474,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-06.jpg',
						width: 692,
						height: 576,
						alt: 'Название картинки',
					},
				],
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'dark',
				},
			},
		},

		{
			type: 'faqFeed',
			content: {
				title: 'Наш вклад',
				items: [
					{
						title: 'Программа повышения экологической эффективности',
						content: [
							{
								type: 'html',
								content:
									'<p>Проведение научных исследований и разработок, направленных на повышение качества окружающей среды и обеспечение экологической безопасности, в том числе научное обеспечение разработки государственной политики в сфере снижения негативного воздействия на атмосферный воздух, включая разработку предложений по установлению технических нормативов выбросов загрязняющих веществ, механизмов экономического и правового стимулирования, совершенствованию механизмов взимания платы за негативное воздействие на окружающую среду.</p>',
							},
						],
					},
					{
						title: 'Участие в общероссийских и&nbsp;локальных экологических акциях',
						content: [
							{
								type: 'html',
								content:
									'<p>Проведение научных исследований и разработок, направленных на повышение качества окружающей среды и обеспечение экологической безопасности, в том числе научное обеспечение разработки государственной политики в сфере снижения негативного воздействия на атмосферный воздух, включая разработку предложений по установлению технических нормативов выбросов загрязняющих веществ, механизмов экономического и правового стимулирования, совершенствованию механизмов взимания платы за негативное воздействие на окружающую среду.</p>',
							},
						],
					},
					{
						title: 'Инновации для охраны окружающей среды',
						content: [
							{
								type: 'html',
								content:
									'<p>Проведение научных исследований и разработок, направленных на повышение качества окружающей среды и обеспечение экологической безопасности, в том числе научное обеспечение разработки государственной политики в сфере снижения негативного воздействия на атмосферный воздух, включая разработку предложений по установлению технических нормативов выбросов загрязняющих веществ, механизмов экономического и правового стимулирования, совершенствованию механизмов взимания платы за негативное воздействие на окружающую среду.</p>',
							},
						],
					},
					{
						title: 'Борьба с&nbsp;изменением климата',
						content: [
							{
								type: 'html',
								content:
									'<p>Проведение научных исследований и разработок, направленных на повышение качества окружающей среды и обеспечение экологической безопасности, в том числе научное обеспечение разработки государственной политики в сфере снижения негативного воздействия на атмосферный воздух, включая разработку предложений по установлению технических нормативов выбросов загрязняющих веществ, механизмов экономического и правового стимулирования, совершенствованию механизмов взимания платы за негативное воздействие на окружающую среду.</p>',
							},
						],
					},
					{
						title: 'Особо охраняемые природные территории',
						content: [
							{
								type: 'html',
								content:
									'<p>Проведение научных исследований и разработок, направленных на повышение качества окружающей среды и обеспечение экологической безопасности, в том числе научное обеспечение разработки государственной политики в сфере снижения негативного воздействия на атмосферный воздух, включая разработку предложений по установлению технических нормативов выбросов загрязняющих веществ, механизмов экономического и правового стимулирования, совершенствованию механизмов взимания платы за негативное воздействие на окружающую среду.</p>',
							},
						],
					},
				],
			},
			wrapper: {
				type: 'stripsHorizontalLeft',
				props: {
					color: 'dark',
				},
			},
		},
	],
};
