import css from './TabsSection.module.scss';
import classnames from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Container, Section, Heading, Box } from '@core';
import useIntersectionObserver from '@hooks/useIntersectionObserver';
import { BenefitsItemType } from '@components/BenefitsFeed/BenefitsFeed';
import { useReloadTimeout } from '@hooks';
import { BenefitsListItem } from '@components/BenefitsList/components/BenefitsListItem';

type SectionType = {
	id: string | number;
	title: string;
	items: Array<BenefitsItemType>;
};

export interface TabsSectionProps {
	title?: string;
	titleUrl?: string;
	sections?: Array<SectionType>;
	hasIndent?: boolean;
}

type CurrentTabType = string | number | null;

type TabsSectionLinksProps = {
	currentId?: CurrentTabType;
	onClick?: React.Dispatch<React.SetStateAction<CurrentTabType>>;
} & Pick<TabsSectionProps, 'sections'>;

const delay = 600;

export const TabsSection: FC<TabsSectionProps> = React.memo(
	({ title, titleUrl, sections = [], hasIndent = true }) => {
		const [currentTab, setCurrentTab] = useState<string | number | null>(sections[0]?.id || null);

		/*
		 * Start animation
		 */
		const ref = useRef<HTMLDivElement | null>(null);
		const entry = useIntersectionObserver(ref, {
			threshold: 0.5,
			freezeOnceVisible: true,
		});

		useEffect(() => {
			if (ref?.current && entry?.isIntersecting) {
				ref.current.classList.add(css.isVisible);
			}
		}, [entry?.isIntersecting]);

		return (
			<Section ref={ref}>
				<Container>
					<Heading
						className={classnames(css.heading, hasIndent && 'indent-margin')}
						title={title}
						titleUrl={titleUrl}>
						<TabsSectionLinks sections={sections} currentId={currentTab} onClick={setCurrentTab} />
					</Heading>
					<Box className={classnames(hasIndent && 'indent-margin')}>
						<TabsSectionContent data={sections.find((item) => item.id === currentTab)} />
					</Box>
				</Container>
			</Section>
		);
	}
);

export const TabsSectionLinks: FC<TabsSectionLinksProps> = React.memo(
	({ sections = [], currentId, onClick }) => {
		return (
			<Box className={css.navPanel}>
				{sections.map(({ id, title }, i) => (
					<button
						type="button"
						key={`tabs-section-nav-btn-${i}-${id}`}
						onClick={() => {
							onClick && onClick(id);
						}}
						className={classnames(css.navBtn, { [css.isCurrent]: id === currentId })}>
						{title}
					</button>
				))}
			</Box>
		);
	}
);

const TabsSectionContent: FC<{
	data?: SectionType;
}> = React.memo(({ data }) => {
	const [changing, setChanging] = useState(false);
	const [displayData, setDisplayData] = useState(data);
	const transition = useReloadTimeout(changing, delay);

	useEffect(() => {
		setChanging(true);

		const timer = window.setTimeout(() => {
			setDisplayData(data);
			setChanging(false);
		}, delay);

		return () => {
			if (timer) window.clearTimeout(timer);
		};
	}, [data]);

	return (
		<Box className={classnames(css.feed, { [css.isTransition]: transition }, 'is-visible')}>
			<>
				{displayData?.items?.map((item, i) => (
					<BenefitsListItem key={`rules-feed-${i}-${item.title}`} index={i} {...item} />
				))}
			</>
		</Box>
	);
});
