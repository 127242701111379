import { PageData } from '@api/hooks/types';

export const report: PageData = {
	meta: {
		title: 'Данные по отчётам',
		description:
			'Fensma владеет современным оборудованием и новейшими механизмами для строительства ',
	},
	data: [
		{
			type: 'intro',
			content: {
				title: 'Данные<br /><color-accent>по отчётам</color-accent>',
				breadcrumbs: [{ text: 'О компании' }, { text: 'Отчёты и документы' }],
			},
		},

		{
			type: 'reportsMain',
			content: {
				title:
					'<color-accent>Fensma</color-accent> владеет современным оборудованием и новейшими механизмами для строительства',
				indicator: {
					value: '&gt;13',
					label: 'млрд руб.<br />ожидаемый оборот группы компаний<br />за 2022 год',
				},
				charts: [
					{
						year: '2012',
						caption: '&gt; 4 млрд руб.',
						text: '<p>Оборот компании за 2012 год</p>',
					},
					{
						year: '2014',
						caption: '&gt; 4,5 млрд руб.',
						text: '<p>Оборот компании за 2014 год</p>',
					},
					{
						year: '2016',
						caption: '&gt; 4,5 млрд руб.',
						text: '<p>Оборот компании за 2016 год</p>',
					},
					{
						year: '2018',
						caption: '&gt; 5,5 млрд руб.',
						text: '<p>Оборот компании за 2018 год</p>',
					},
					{
						year: '2020',
						caption: '&gt; 5,5 млрд руб.',
						text: '<p>Оборот компании за 2020 год</p>',
					},
					{
						year: '2021',
						caption: '&gt; 5,5 млрд руб.',
						text: '<p>Оборот компании за 2021 год</p>',
					},
				],
			},
			wrapper: {
				type: 'stripsVertical',
				props: {
					color: 'white',
					startScreen: true,
				},
			},
		},

		{
			type: 'reportsFeed',
			links: {
				self: '/company/reports',
			},
			content: {
				title: 'Финансовые<br /><color-accent>отчёты</color-accent>',
				filters: [
					{
						text: '2021',
						key: 'year',
						value: null,
					},
					{
						text: '2020',
						key: 'year',
						value: '2020',
					},
					{
						text: '2019',
						key: 'year',
						value: '2019',
					},
					{
						text: '2018',
						key: 'year',
						value: '2018',
					},
				],
				sections: [
					{
						sublabel: 'ООО "Фенсма"',
						items: [
							{
								title: 'Финансовый отчет от 01 окт. 2021',
								url: '/dummy/dummy.pdf',
							},
							{
								title: 'Отчет от 01.09.2021',
								url: '/dummy/dummy.pdf',
							},
							{
								title: 'Приложение к отчету от 01.09.2021',
								url: '/dummy/dummy.pdf',
							},
						],
					},
					{
						sublabel: 'ООО "СК Фенсма"',
						items: [
							{
								title: 'Финансовый отчет от 01 окт. 2021',
								url: '/dummy/dummy.pdf',
							},
							{
								title: 'Отчет от 01.09.2021',
								url: '/dummy/dummy.pdf',
							},
							{
								title: 'Приложение к отчету от 01.09.2021',
								url: '/dummy/dummy.pdf',
							},
						],
					},
				],
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'dark',
				},
			},
		},

		{
			type: 'documentsFeed',
			links: {
				self: '/company/documents',
			},
			content: {
				title: 'Документы',
				filters: [
					{
						text: 'Все документы',
						key: 'documents_section',
						value: null,
					},
					{
						text: 'Документы 1',
						key: 'documents_section',
						value: '1',
					},
					{
						text: 'Документы 2',
						key: 'documents_section',
						value: '2',
					},
				],
				items: [
					{
						title: 'Сертификат соответствия (МОТ).pdf',
						url: '/dummy/dummy.pdf',
						date: '2021-09-19T00:00:00-03:00',
					},
					{
						title:
							'Лицензия Министерства культуры На реставрацию, консервацию и воссоздание памятников истории и культуры народов Российской Федерации.pdf',
						url: '/dummy/dummy.pdf',
						date: '2021-09-18T00:00:00-03:00',
					},
					{
						title: 'Сертификат соответствия (ISO).pdf',
						url: '/dummy/dummy.pdf',
						date: '2021-09-17T00:00:00-03:00',
					},
					{
						title: 'Сертификат соответствия_Название документа 3.pdf',
						url: '/dummy/dummy.pdf',
						date: '2021-08-15T00:00:00-03:00',
					},
					{
						title: 'Сертификат соответствия_Название документа 4.pdf',
						url: '/dummy/dummy.pdf',
						date: '2021-08-13T00:00:00-03:00',
					},
					{
						title: 'Сертификат соответствия_Название документа 5.pdf',
						url: '/dummy/dummy.pdf',
						date: '2021-08-08T00:00:00-03:00',
					},
				],
			},
			wrapper: {
				type: 'stripsHorizontalLeft',
				props: {
					color: 'dark',
				},
			},
		},
	],
};
