import css from './VacanciesFeed.module.scss';
import classnames from 'classnames';
import type { VacancyPreviewType } from '@components/Vacancies/components/VacancyPreview/VacancyPreview';
import React, { FC, memo, useEffect, useState } from 'react';
import { Box } from '@core';
import { useReloadTimeout } from '@hooks';
import { VacancyPreview } from '@components/Vacancies/components/VacancyPreview/VacancyPreview';
import { useRouter } from 'next/router';

export interface VacanciesFeedProps {
	data?: Array<VacancyPreviewType>;
	isLoading?: boolean;
	handleFilterChange?: () => void;
	emptyText?: string;
}

const delay = 800;

export const VacanciesFeed: FC<VacanciesFeedProps> = memo(({ data, emptyText, isLoading }) => {
	const loading = useReloadTimeout(!!isLoading, delay);
	const [items, setItems] = useState(data);

	useEffect(() => {
		const timer = window.setTimeout(
			() => {
				setItems(data);
			},
			loading ? delay : 50
		);
		return () => {
			if (timer) window.clearTimeout(timer);
		};
	}, [loading, data]);

	/*
	 * Fallback values for @emptyText
	 */
	const { locale } = useRouter();

	emptyText =
		emptyText ||
		(locale === 'en'
			? 'No vacancies were found matching your search. Please change your filter settings to see other vacancies.'
			: 'По вашему запросу вакансии не найдены. Пожалуйста, измените настройки фильтров, чтобы увидеть другие вакансии.');

	const notEmpty = items && items.length > 0;

	return (
		<Box className={classnames(css.feed, { [css.isLoading]: loading })}>
			<>
				{notEmpty &&
					items.map((item, i) => (
						<VacancyPreview key={`vacancies-item-${i}-${item.date}`} idx={i} {...item} />
					))}
				{!notEmpty && <Box className={css.empty}>{emptyText}</Box>}
			</>
		</Box>
	);
});
