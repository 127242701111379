import { BenefitsItemType } from '@components/BenefitsFeed/BenefitsFeed';

export const rulesList: PaginatedData<BenefitsItemType> = {
	data: [
		{
			title: 'Обучение и рост',
			text: 'Развитие каждого сотрудника - польза для всей компании. Мы помогаем получать знания и строить карьеру. У нас можно развиваться в любом направлении - компания поможет проследовать по ней.',
		},
		{
			title: 'Комфортный и эргономичный офис',
			text: 'Забота о комфорте команды в офисе - один из важных для нас приоритетов. Для максимально эффективного выполнения задачи важно иметь правильно организованное рабочее место.',
		},
		{
			title: 'Здоровье сртрудников',
			text: 'Наша ценность — это люди, поэтому мы серьезно относимся к здоровью. Для всех сотрудников из всех регионов наша программа ДМС.',
		},
	],
};

export const rulesListBuildingYard: PaginatedData<BenefitsItemType> = {
	data: [
		{
			title: 'Здоровье сртрудников',
			text: 'Наша ценность — это люди, поэтому мы серьезно относимся к здоровью. Для всех сотрудников из всех регионов наша программа ДМС.',
		},
		{
			title: 'Комфортный и эргономичный офис',
			text: 'Забота о комфорте команды в офисе - один из важных для нас приоритетов. Для максимально эффективного выполнения задачи важно иметь правильно организованное рабочее место.',
		},
	],
};
