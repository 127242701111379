import css from '../SplitViewSlides.module.scss';
import type { SlideType } from '@components/SplitViewSlides/SplitViewSlides';
import React, { FC } from 'react';
import { Tag } from '@core';
import { padForNum } from '@utils';

interface Props {
	index: number;
	content: SlideType;
}

export const SplitViewSlidesItem: FC<Props> = React.memo(({ index, content }) => {
	const { title, text } = content;
	return (
		<div className={css.slideContent}>
			<Tag className={css.slideIndex} type="h4" isDiv={true}>
				{padForNum(index + 1, '00')}.
			</Tag>
			{title && (
				<Tag type="h3" className={css.slideTitle}>
					{title}
				</Tag>
			)}
			{text && <div className={css.slideText}>{text}</div>}
		</div>
	);
});
