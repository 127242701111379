import css from '../SplitViewSlides.module.scss';
import type { SplitViewSlidesProps } from '@components/SplitViewSlides/SplitViewSlides';
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Container, Section } from '@core';
import { getNextElement, getPrevElement, normalize } from '@utils';
import { useScrollWithEase } from '@hooks';
import { SplitViewSlidesMobileSlider } from '@components/SplitViewSlides/components/SplitViewSlidesMobileSlider';
import { useRouter } from 'next/router';

export const SplitViewSlidesMobile: FC<SplitViewSlidesProps> = (props) => {
	const { locale } = useRouter();
	const swipeTip =
		locale === 'en' ? 'Swipe to next slide' : 'Смахнуть, чтобы смотреть следущий слайд';

	const ref = useRef<HTMLDivElement | null>(null);

	const [seek, setSeek] = useState(0);
	const [nextElement, setNextElement] = useState<HTMLElement | null>(null);
	const [prevElement, setPrevElement] = useState<HTMLElement | null>(null);

	useEffect(() => {
		const element = ref?.current;

		if (element) {
			let next: HTMLElement | null = getNextElement(element);

			if (next && !next.classList.contains('clip-path-wrapper')) {
				next = next.querySelector<HTMLDivElement>('.clip-path-wrapper');
			}

			let prev: HTMLElement | null = getPrevElement(element);

			if (prev && !prev.classList.contains('clip-path-wrapper')) {
				prev = prev.querySelector<HTMLDivElement>('.clip-path-wrapper');
			}

			setNextElement(next);
			setPrevElement(prev);
		}
	}, [ref]);

	const handleScroll = useCallback((current: number, height: number) => {
		const relative = 1 - Math.max(Math.min((current - height) / height, 1), 0);
		const normalized = normalize(relative, 1, 0.15, true);

		setSeek(normalized);
	}, []);

	useScrollWithEase(ref, handleScroll, {
		ease: 0.25,
		edge: 'bottom',
	});

	useEffect(() => {
		if (prevElement) {
			const relToTop = Math.max(Math.min(seek, 1), 0);
			prevElement.style.clipPath = getPolygonPrev(relToTop);
		}
	}, [prevElement, nextElement, seek]);

	return (
		<>
			<Section ref={ref} className={css.outer}>
				<SplitViewSlidesMobileSlider {...props} />
				<Container className={css.swipeTip}>{swipeTip}</Container>
			</Section>
		</>
	);
};

const getPolygonPrev = (seek: number) => {
	const points = [
		`100% 0`,
		`100% calc(102% - ${50 * seek}vh)`,
		`50.1% calc(102% - ${50 * seek}vh)`,
		`50.1% calc(102% - ${20 * seek}vh)`,
		`0 calc(102% - ${20 * seek}vh)`,
		`0 0`,
	];

	return `polygon(${points.join(', ')})`;
};
