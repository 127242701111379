import css from './ValuablessFeed.module.scss';
import classnames from 'classnames';
import React, { FC, memo, useEffect, useRef } from 'react';
import { Section, Container, Heading, Flex } from '@core';
import {
	ValuablessItemProps,
	ValuablessItem,
} from '@components/ValuablessFeed/component/ValuablessItem';
import useIntersectionObserver from '@hooks/useIntersectionObserver';

export interface BenefitsFeedProps {
	title?: string;
	items?: Array<ValuablessItemProps>;
	className?: string;
	EntityComponent?: FC<any>;
}

export const ValuablessFeed: FC<BenefitsFeedProps> = memo(
	({ title, items, className, EntityComponent = ValuablessItem }) => {
		const ref = useRef<HTMLDivElement | null>(null);

		const entry = useIntersectionObserver(ref, {
			threshold: 0.15,
			freezeOnceVisible: true,
		});

		useEffect(() => {
			if (ref?.current && entry?.isIntersecting) {
				ref.current.classList.add(`is-visible`);
			}
		}, [entry?.isIntersecting]);

		return (
			<Section ref={ref} className={classnames(className, css.module)}>
				<Container>
					<Heading className={css.heading} offsetType="regular" title={title} />
					<Flex className={css.feed}>
						{items?.map((item, i) => (
							<EntityComponent key={item.title + '-' + i} {...item} />
						))}
					</Flex>
				</Container>
			</Section>
		);
	}
);
