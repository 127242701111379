import css from './TickerText.module.scss';
import classnames from 'classnames';
import fastdom from 'fastdom';
import type { Breakpoint } from '@utils';
import React, {
	FC,
	useContext,
	useRef,
	useState,
	createContext,
	HTMLAttributes,
	useCallback,
	useEffect,
	useMemo,
	MutableRefObject,
} from 'react';
import { useResize } from '@hooks';
import { ViewportContext } from '@context';

interface TickerTextProps extends HTMLAttributes<HTMLDivElement> {
	seek: number;
	overlap?: number;
	text?: string;
	loop?: boolean;
	limit?: boolean;
	clipper?: {
		id: string;
		posY: number;
		scale?: number;
		scaleOrigin?: string;
		scaleAnchorLetter?: number;
	};
}

const indents: Record<Breakpoint, number> = {
	xs: 0.2,
	sm: 0.2,
	md: 0.25,
	lg: 0.3,
	hd: 0.3,
};

const TickerTextContext = createContext({ seek: 0, frag: 0 });

export const TickerText: FC<TickerTextProps> = React.memo(
	({ seek, text = '', overlap = 0, loop, limit, clipper, className }) => {
		seek *= 1 - overlap;
		seek = limit ? Math.min(seek, 1) : seek;

		const { scale = 1, scaleOrigin = '50% 50%', scaleAnchorLetter } = clipper || {};

		const chars = text.split('');

		const wrap = useRef<HTMLDivElement | null>(null);
		const track = useRef<HTMLDivElement | null>(null);
		const svgClipPath = useRef<SVGClipPathElement | null>(null);
		const svgText = useRef<SVGTextElement | null>(null);

		const { vh, vw, bp } = useContext(ViewportContext);
		const [wayPixels, setWayPixels] = useState(0);

		const frag = 1 / chars.length;
		const textIndent = (bp ? indents[bp] * vw : 0) + (bp === 'xs' ? 16 : bp === 'sm' ? 32 : 0);

		const onResize = useCallback(() => {
			const trackWidth = track.current?.offsetWidth || 0;
			const wrapWidth = wrap.current?.offsetWidth || 0;

			let way = Math.max(trackWidth - wrapWidth, 0);

			if (loop) {
				way = trackWidth;
			}

			setWayPixels(way);
		}, [loop]);

		useResize(onResize);

		useEffect(() => {
			fastdom.mutate(() => {
				if (track?.current) {
					track.current.style.transform = `translate3d(${-wayPixels * seek}px, 0, 0)`;
				}
				if (!!clipper && svgText?.current) {
					svgText?.current?.setAttribute('x', `${textIndent + -wayPixels * seek}`);
				}
			});
		}, [wayPixels, textIndent, clipper, seek]);

		useEffect(() => {
			if (!clipper) return;
			fastdom.mutate(() => {
				if (svgClipPath?.current) {
					svgClipPath.current.style.transform = `scale(${scale + 1}) translateZ(0)`;
					svgClipPath.current.style.transformOrigin = scaleOrigin;
				}
			});
		}, [clipper, svgClipPath, scale, scaleOrigin]);

		if (chars.length === 0) return null;

		return (
			<TickerTextContext.Provider value={{ seek, frag }}>
				<div ref={wrap} className={classnames(css.module, className)}>
					<div ref={track} className={css.track}>
						{loop ? (
							<>
								<div className={css.trackPart}>
									<TickerTextContent ref={track} text={text} />
								</div>
								<div className={css.trackPart}>
									<TickerTextContent ref={track} text={text} seek={0} />
								</div>
							</>
						) : (
							<TickerTextContent ref={track} text={text} scaleAnchorLetter={scaleAnchorLetter} />
						)}
					</div>
					{clipper && (
						<svg className={css.svgClipPath} viewBox={`0 0 ${vw} ${vh}`}>
							<clipPath ref={svgClipPath} id={clipper.id}>
								<text
									ref={svgText}
									x={0}
									y={clipper.posY}
									dx=".1%"
									dy="-.9%"
									textAnchor="start"
									dominantBaseline="text-before-edge">
									{text}
								</text>
							</clipPath>
						</svg>
					)}
				</div>
			</TickerTextContext.Provider>
		);
	}
);

export const TickerTextContentDyn = React.memo(
	React.forwardRef<HTMLDivElement, { text: string; seek?: number; scaleAnchorLetter?: number }>(
		({ text, seek, scaleAnchorLetter = 3 }, ref) => {
			const { seek: mainSeek, frag } = useContext(TickerTextContext);
			const prog = seek !== undefined ? seek : mainSeek;

			let index = 0;
			const words = useMemo(() => text.split(/[\s\n]/g).map((word) => word.split('')), [text]);

			return (
				<>
					{words.map((word, i) => {
						const isLast = words.length === i + 1;
						return (
							<div key={`ticker-line-word-${i}-${word.join()}`} className={css.word}>
								{word.map((char, i) => {
									index++;
									const end = Math.min(frag * (index + 1), 1);
									const fill = Math.min(prog / end, 1);
									const progress = (1 - fill) * 100;
									const isUppercase = char === char.toUpperCase();
									const fillerHeight = isUppercase ? 90 : 70;
									const isScaleAnchor = isLast && word.length - i === scaleAnchorLetter;
									return (
										<div
											key={`ticker-line-char-${i}-${char}`}
											className={classnames(css.letter, {
												'anchor-letter': isScaleAnchor,
												'anchor-uppercase': isUppercase,
											})}
											data-char={char}>
											<div
												style={{
													height: `${fillerHeight}%`,
													clipPath: `polygon(0 ${progress}%, 100% ${progress}%, 100% 110%, 0% 110%)`,
												}}>
												{char}
											</div>
										</div>
									);
								})}
								<>{!isLast && <div>&nbsp;</div>}</>
							</div>
						);
					})}
				</>
			);
		}
	)
);

export const TickerTextContent = React.memo(
	React.forwardRef<HTMLDivElement, { text: string; seek?: number; scaleAnchorLetter?: number }>(
		({ text, seek, scaleAnchorLetter = 3 }, ref) => {
			const { seek: mainSeek, frag } = useContext(TickerTextContext);
			const prog = seek !== undefined ? seek : mainSeek;

			const words = useMemo(() => text.split(/[\s\n]/g), [text]);
			const wordsByChars = useMemo(() => words.map((word) => word.split('')), [words]);

			const [letters, setLetters] = useState<HTMLElement[]>([]);

			useEffect(() => {
				const root = (ref as MutableRefObject<HTMLDivElement | null>)?.current;
				setLetters(Array.from(root?.querySelectorAll<HTMLDivElement>(`.${css.letter} div`) || []));
			}, [ref]);

			useEffect(() => {
				fastdom.mutate(() => {
					letters.forEach((letter, i) => {
						const end = Math.min(frag * (i + 1), 1);
						const fill = Math.min(prog / (end * 1.5), 1);
						const progress = (1 - fill) * 100;

						letter.style.clipPath = `polygon(0 ${progress}%, 100% ${progress}%, 100% 110%, 0% 110%)`;
					});
				});
			}, [letters, prog, frag]);

			return <TickerTextContentStatic words={wordsByChars} scaleAnchorLetter={scaleAnchorLetter} />;
		}
	)
);

export const TickerTextContentStatic: FC<{ words: string[][]; scaleAnchorLetter?: number }> =
	React.memo(({ words, scaleAnchorLetter = 3 }) => {
		return (
			<>
				{words.map((word, i) => {
					const isLast = words.length === i + 1;
					return (
						<div key={`ticker-line-word-${i}-${word}`} className={css.word}>
							{word.map((char, i) => {
								const isUppercase = char === char.toUpperCase();
								const isScaleAnchor = isLast && word.length - i === scaleAnchorLetter;
								return (
									<div
										key={`ticker-line-char-${i}-${char}`}
										className={classnames(css.letter, {
											'anchor-letter': isScaleAnchor,
											'anchor-uppercase': isUppercase,
										})}
										data-char={char}>
										<div>{char}</div>
									</div>
								);
							})}
							<>{!isLast && <div>&nbsp;</div>}</>
						</div>
					);
				})}
			</>
		);
	});
