import css from '../StepsSlider.module.scss';
import classnames from 'classnames';
import React, { FC, useContext } from 'react';
import { StepsSliderContentProps } from '@components/StepsSlider/components/StepsSliderContent';
import { Box, Odometer } from '@core';
import { StepsSliderContext } from '@components/StepsSlider/StepsSlider';

export const StepsSliderContentList: FC<StepsSliderContentProps> = React.memo(({ items = [] }) => {
	const { current } = useContext(StepsSliderContext);

	return (
		<Box className={css.slidesList}>
			{items?.map((slide, i) => {
				const odometer = slide?.odometer;
				const digits = odometer?.replace(/>/gi, '') || '00';

				return (
					<Box
						key={`steps-slider-list-${i}-${slide.text}`}
						className={classnames(css.slidesListItem, {
							[css.slidesListItemActive]: current >= i + 1,
						})}>
						<Box className={css.slidesListItemOdometer}>
							<Odometer
								value={current >= i + 1 ? Number(digits) : undefined}
								length={digits.length}
							/>
						</Box>
						<Box className={css.slidesListItemLabel}>
							<Box className={css.slidesListItemLabelInner}>{slide.text?.replace('%br%', '')}</Box>
						</Box>
					</Box>
				);
			})}
		</Box>
	);
});
