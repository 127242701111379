import css from './EventsFeedCard.module.scss';
import classnames from 'classnames';
import React, { FC, useCallback, useRef, useState } from 'react';
import Link from 'next/link';
import { Box, Icon, RawHtml } from '@core';
import { isTouchDevice, shortFormatDate } from '@utils';
import { useOutsideClick } from '@hooks';
import { EventType } from '@components/EventsFeed/EventsFeed';
import Image from 'next/image';

export interface EventTypeProps extends EventType {
	className?: string;
}

const isTouch = isTouchDevice() || false;

export const EventsFeedCard: FC<EventTypeProps> = ({ className, title, url, date, picture }) => {
	const ref = useRef<HTMLAnchorElement | null>(null);
	const [clicked, setClicked] = useState(false);

	const handleOutsideClick = useCallback(() => {
		ref?.current?.classList?.remove(css.clicked);
		ref?.current?.closest('li')?.classList?.remove('is-clicked');
		setClicked(false);
	}, []);

	useOutsideClick(ref, handleOutsideClick);

	const { day, month } = shortFormatDate(date);

	return (
		<Link href={url || ''}>
			<a
				ref={ref}
				className={classnames(className, css.module)}
				onClick={(e) => {
					if (isTouch && !clicked) {
						e.preventDefault();

						ref?.current?.classList?.add(css.clicked);
						ref?.current?.closest('li')?.classList?.add('is-clicked');
						setClicked(true);
					}
				}}>
				{picture && (
					<picture className={css.pic}>
						<Image
							src={picture.src}
							alt={picture?.alt || title}
							layout="fill"
							sizes="(max-width: 639px) 80vw, 40vw"
						/>
					</picture>
				)}
				<Box className={css.content}>
					<RawHtml className={css.title} content={title} />
					{day && month && (
						<Box className={css.date}>
							<span>{day}</span> {month}
						</Box>
					)}
					<Icon className={css.arrow} id="link" />
				</Box>
			</a>
		</Link>
	);
};
