import css from './StepsSlider.module.scss';
import classnames from 'classnames';
import React, { createContext, FC, useCallback, useContext, useRef, useState } from 'react';
import { Box, Section } from '@core';
import { StepsSliderMain } from '@components/StepsSlider/components/StepsSliderMain';
import { ViewportContext } from '@context';
import { useScrollWithEase } from '@hooks';
import { findLastIndex, normalize } from '@utils';
import { getStages } from '@components/HomeIntro/utils';

export interface StepsSlideProps {
	text?: string;
	odometer?: string;
}

export interface StepsSliderProps {
	title?: string;
	items?: Array<StepsSlideProps>;
}

export const StepsSliderContext = createContext({ seek: 0, current: -1, stages: [0] });

export const StepsSlider: FC<StepsSliderProps> = React.memo(({ title, items = [] }) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const { vh, bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	const screens = items.length * (isMob ? 1 : 2);
	const frag = 1 / screens;

	const [seek, setSeek] = useState(0);

	const onScroll = useCallback(
		(current: number, height: number) => {
			const overlap = 0;
			const start = height - vh;
			const end = current - vh;
			const absolute = 1 - end / start;
			const relative = Math.max(Math.min(absolute, 1 / (1 - overlap)), 0);

			const seek = normalize(relative, 1 - frag, 0, true);

			setSeek(seek);
		},
		[vh, frag]
	);

	useScrollWithEase(ref, onScroll, {
		ease: 1, // 0.25,
		edge: 'bottom',
	});

	const stages = getStages(seek, items.length * 2);

	const current = Math.min(
		findLastIndex(stages, (value) => value >= 1),
		items.length
	);

	return (
		<Section
			ref={ref}
			style={{
				height: `${screens * 100}vh`,
			}}
			className={css.section}>
			<Box className={css.sticky}>
				<StepsSliderContext.Provider value={{ seek, current, stages }}>
					<StepsSliderMain items={items} title={title} />
				</StepsSliderContext.Provider>
			</Box>
		</Section>
	);
});
