import React, { ComponentPropsWithoutRef, PropsWithChildren } from 'react';
import classnames from 'classnames';

export const Box = React.forwardRef<
	HTMLDivElement,
	PropsWithChildren<ComponentPropsWithoutRef<'div'>>
>((props, ref) => {
	return (
		<div ref={ref} {...props} className={classnames(props.className)}>
			{props.children}
		</div>
	);
});
