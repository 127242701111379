export interface StaticPath {
	pathname: string;
	locale?: 'ru' | 'en';
}

export const paths: Array<StaticPath> = [
	{ pathname: '/m-projects/project' },

	{ pathname: '/m-activities/general-contract' },
	{ pathname: '/m-activities/conversation' },
	{ pathname: '/m-activities/zero-cycle' },
	{ pathname: '/m-activities/general-design' },
	{ pathname: '/m-activities/epc' },

	{ pathname: '/m-career' },
	{ pathname: '/m-career/corp' },
	{ pathname: '/m-career/prof' },
	{ pathname: '/m-career/students' },
	{ pathname: '/m-career/stories' },
	{ pathname: '/m-career/vacancies' },

	{ pathname: '/m-company' },
	{ pathname: '/m-company/tech' },
	{ pathname: '/m-company/story' },
	{ pathname: '/m-company/report' },
	{ pathname: '/m-company/partners' },
	{ pathname: '/m-company/control' },

	{ pathname: '/m-perspectives/bim' },
	{ pathname: '/m-perspectives/program' },

	{ pathname: '/m-events/event' },

	{ pathname: '/m-contacts' },
];
