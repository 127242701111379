import css from './FormChoice.module.scss';
import classnames from 'classnames';
import React, { PropsWithChildren, ComponentPropsWithoutRef } from 'react';

export interface FormChoiceProps {
	type: 'radio' | 'checkbox';
	name: string;
	value?: string | number;
	disabled?: boolean;
	className?: string;
}

export const FormChoice = React.forwardRef<
	HTMLInputElement,
	PropsWithChildren<FormChoiceProps & ComponentPropsWithoutRef<'input'>>
>(
	(
		{ type = 'checkbox', name, value, disabled = false, className, children, ...props },
		forwardedRef
	) => {
		return (
			<label className={classnames(css.choice, className)}>
				<input
					{...props}
					type={type}
					name={name}
					value={value}
					disabled={disabled}
					ref={forwardedRef}
				/>
				<span>{children}</span>
			</label>
		);
	}
);
