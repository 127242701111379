import css from '../GMap.module.scss';
import classnames from 'classnames';
import React, { FC, useContext, useEffect, useCallback, useState } from 'react';
import { OverlayView } from '@react-google-maps/api';
import { MapObject, GMapContext } from '@core';
import { checkKeyCode } from '@utils';

export interface GMapMarkerProps {
	object: MapObject;
	onClick?: () => void;
}

export const GMapMarker: FC<GMapMarkerProps> = ({ object, onClick }) => {
	const { activeObject, setActiveObject } = useContext(GMapContext);

	const handleClick = useCallback(() => {
		const isCurrent = activeObject?.id === object.id;
		setActiveObject && setActiveObject(isCurrent ? undefined : object);
		onClick && onClick();
	}, [object, onClick, setActiveObject, activeObject?.id]);

	const [el, setEl] = useState<HTMLElement | null>(null);

	useEffect(() => {
		if (!el) return;

		const onKeypress = (e: KeyboardEvent) => {
			if (e.target === el && checkKeyCode(e, 32)) {
				e.preventDefault();
				handleClick();
			}
		};

		document.documentElement.addEventListener('keydown', onKeypress);

		return () => {
			document.documentElement.removeEventListener('keydown', onKeypress);
		};
	}, [el, handleClick]);

	return (
		<OverlayView
			position={{
				lat: object.coordinates[0],
				lng: object.coordinates[1],
			}}
			mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
			<div
				ref={setEl}
				tabIndex={0}
				className={classnames(css.marker, { [css.markerActive]: activeObject?.id === object.id })}
				onClick={handleClick}>
				<svg viewBox="0 0 48 58">
					<path fillRule="evenodd" d="M48 0H0v48h17.143l5.714 10h2.286l5.714-10H48z" />
				</svg>
				<svg viewBox="0 0 48 58">
					<path fillRule="evenodd" d="M48 0H0v48h17.143l5.714 10h2.286l5.714-10H48z" />
					<path
						fill="#FFF"
						fillRule="nonzero"
						d="M18 33V15h14v3.041H21.643v4h8.915v3.041h-8.915V33z"
					/>
				</svg>
			</div>
		</OverlayView>
	);
};
