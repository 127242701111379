import css from './Background.module.scss';
import React, { FC, useEffect, useState } from 'react';
import { WavesCanvas } from './webgl/waves';
// import { Move } from '@utils';

export const Background: FC = React.memo(() => {
	const [canvas, setCanvas] = useState<HTMLCanvasElement | null>();

	useEffect(() => {
		if (!canvas) return;

		const wavesCanvas = new WavesCanvas(canvas);
		wavesCanvas.init();

		// const onMouseMove = ({ x, y }: { x: number; y: number }) => {
		// 	wavesCanvas.mx = x + 0.5;
		// 	wavesCanvas.my = 1 - (y + 0.5);
		// };
		//
		// Move.addListener(onMouseMove);

		return () => {
			wavesCanvas.destroy();
			// Move.removeListener(onMouseMove);
		};
	}, [canvas]);

	return (
		<div className={css.background}>
			<canvas className={css.canvas} ref={setCanvas} />
		</div>
	);
});
