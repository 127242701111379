import css from './ProjectsPreviewsMobile.module.scss';
import classnames from 'classnames';
import React, { FC, useRef } from 'react';
import { Section, Box, Heading, Container } from '@core';
import { ProjectsPreviewsProps } from '@components/ProjectsPreviews/ProjectsPreviews';
import { ProjectPreview } from '@components/ProjectsPreviews/components/ProjectPreview/ProjectPreview';
import useIntersectionObserver from '@hooks/useIntersectionObserver';

export const ProjectsPreviewsMobile: FC<ProjectsPreviewsProps> = ({ title, titleUrl, ...rest }) => {
	return (
		<Section className={css.module}>
			<Container>
				{title && <Heading title={title} titleUrl={titleUrl} offsetType="regular" />}
			</Container>
			<Box>
				<ProjectsPreviewsMobileContent {...rest} />
			</Box>
		</Section>
	);
};

const ProjectsPreviewsMobileContent: FC<ProjectsPreviewsProps> = React.memo(({ items }) => {
	return (
		<>
			{Array.isArray(items) &&
				items?.map((project, i) => (
					<ProjectPreviewWrap key={project.title + '-' + i}>
						<ProjectPreview className={css.preview} num={i + 1} {...project} />
					</ProjectPreviewWrap>
				))}
		</>
	);
});

export const ProjectPreviewWrap: FC = React.memo(({ children }) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const picEntry = useIntersectionObserver(ref, {
		threshold: 0.25,
		rootMargin: '-20% 0% -20% 0%',
		freezeOnceVisible: false,
	});

	return (
		<Box
			ref={ref}
			className={classnames(css.card, { [css.cardActive]: !!picEntry?.isIntersecting })}>
			{children}
		</Box>
	);
});
