import css from './PopUp.module.scss';
import classnames from 'classnames';
import React, {
	FC,
	PropsWithChildren,
	useEffect,
	useRef,
	useMemo,
	useState,
	useCallback,
	useContext,
	ReactNode,
} from 'react';
import { Container, Icon } from '@core';
import { Portal, CloseButton } from './shared';
import { useDelayUnmount, useOutsideClick, useEscape } from '@hooks';
import scrollLocker from '@utils/utils.scroll/ScrollLocker';

// Usage:
// <PopUp active={popup} closeButton={true} closeHandler={closePopup} className="error-popup" />

type RefType = HTMLDivElement | null;

interface PopUpProps {
	active?: boolean;
	name?: string;
	duration?: number;
	boxWidth?: number | string;
	className?: string;
	classNameBox?: string;
	overlay?: 'solid' | 'strips' | null;
	closeHandler?: (e?: MouseEvent | TouchEvent) => void;
	closeButton?: boolean;
	closeButtonIcon?: ReactNode;
	closeButtonClassName?: string;
	closeButtonPosition?: 'box' | 'overlay';
	label?: string;
}

export const PopUpContext = React.createContext<{
	key: string | null;
	toggle?: (key?: string) => void;
	data?: any;
	setData?: React.Dispatch<any>;
}>({
	key: null,
});

export const PopUpProvider: FC = ({ children }) => {
	const [data, setData] = useState<any>();
	const [currentKey, setCurrentKey] = useState<string | null>(null);

	const togglePopUp = useCallback((key?: string) => {
		setCurrentKey((prev) => {
			return !key || prev === key ? null : key;
		});
	}, []);

	return (
		<PopUpContext.Provider value={{ key: currentKey, toggle: togglePopUp, data, setData }}>
			{children}
		</PopUpContext.Provider>
	);
};

export const PopUp = React.forwardRef<RefType, PropsWithChildren<PopUpProps>>(
	(
		{
			active,
			name,
			duration = 1200,
			boxWidth = 582,
			className,
			classNameBox,
			overlay = 'solid',
			closeHandler,
			closeButton = true,
			closeButtonIcon = <Icon id="close" />,
			closeButtonClassName,
			closeButtonPosition = 'overlay',
			label = 'Модальное окно',
			children,
		},
		ref
	) => {
		const { key: contextKey, toggle: contextToggle } = useContext(PopUpContext);
		const storeState = name ? contextKey === name : false;

		const boxRef = useRef<HTMLDivElement | null>(null);
		const isMount = active && !name ? active : name ? storeState : false;

		const shouldRender = useDelayUnmount(isMount, duration);
		const maxWidthCss = useMemo(() => ({ maxWidth: boxWidth }), [boxWidth]);

		/*
		 * Закрытие поп-апа
		 */
		const handleClose = useCallback(() => {
			closeHandler && closeHandler();
			if (name === contextKey) {
				contextToggle && contextToggle();
			}
		}, [closeHandler, name, contextKey, contextToggle]);

		useEscape(shouldRender, handleClose);
		useOutsideClick<HTMLDivElement | null>(boxRef, handleClose, [], ['[role="dialog"]']);

		/*
		 * Блокировка скролла
		 */
		const [locked, setLocked] = useState(false);

		useEffect(() => {
			let timer = 0;

			if (shouldRender) {
				setLocked(true);
				scrollLocker.lock();
			} else {
				timer = window.setTimeout(() => {
					const dialogs = document.querySelectorAll('[role="dialog"]');

					if (dialogs.length === 0 && locked) {
						scrollLocker.unlock().then();
					}
				}, 1);
			}

			return () => window.clearTimeout(timer);
		}, [locked, shouldRender]);

		/*
		 * Тип перехода
		 */
		const [transition, setTransition] = useState<'waiting' | 'enter' | 'exit'>('waiting');

		useEffect(() => {
			let timer = 0;

			if (isMount) {
				timer = window.setTimeout(() => {
					setTransition('enter');
				}, 100);
			}

			if (!isMount && shouldRender) {
				window.clearTimeout(timer);
				setTransition('exit');
			}

			if (!isMount && !shouldRender) {
				window.clearTimeout(timer);
				setTransition('waiting');
			}

			return () => window.clearTimeout(timer);
		}, [isMount, shouldRender]);

		const popup = useMemo(() => {
			const closeBtn = (
				<CloseButton
					icon={closeButtonIcon}
					className={classnames(closeButtonClassName, css.popup__close, {
						[css.isSticky]: closeButtonPosition === 'overlay',
					})}
					onClick={handleClose}
				/>
			);

			return (
				<aside
					role="dialog"
					aria-modal="true"
					aria-label={label}
					className={classnames(
						css.popup,
						css[transition],
						className,
						{
							[css.isShown]: isMount,
							[css.isFullScreen]: boxWidth === 'none',
						},
						overlay && css[overlay]
					)}
					ref={ref}>
					<PopUpOverlay overlay={overlay} />
					{closeButton && closeButtonPosition === 'overlay' && closeBtn}
					<div className={css.popup__scroll}>
						<div className={css.popup__capsule}>
							<Container className={css.popup__inner}>
								<div className={css.popup__perspective} style={maxWidthCss}>
									<div
										className={classnames(classNameBox, css.popup__box, {
											[css.withOverlay]: overlay,
										})}
										style={maxWidthCss}
										ref={boxRef}>
										{closeButton && closeButtonPosition === 'box' && closeBtn}
										{children}
									</div>
								</div>
							</Container>
						</div>
					</div>
				</aside>
			);
		}, [
			closeButtonIcon,
			closeButtonClassName,
			closeButtonPosition,
			handleClose,
			label,
			transition,
			className,
			isMount,
			boxWidth,
			ref,
			overlay,
			closeButton,
			maxWidthCss,
			classNameBox,
			children,
		]);

		return shouldRender ? <Portal>{popup}</Portal> : null;
	}
);

export const PopUpOverlay: FC<PropsWithChildren<Pick<PopUpProps, 'overlay'>>> = ({
	overlay,
	children,
}) => {
	if (!overlay) return null;

	return (
		<div className={classnames(css.popup__overlay, css[overlay])}>
			{overlay === 'strips' && (
				<>
					<div className={css.popup__strip} />
					<div className={css.popup__strip} />
					<div className={css.popup__strip} />
					<div className={css.popup__strip} />
					<div className={css.popup__strip} />
				</>
			)}
			{children}
		</div>
	);
};
