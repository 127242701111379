import { ReportsGroupProps } from '@components/ReportsFeed/components/ReportsGroup/ReportsGroup';

export const reportsList: PaginatedData<ReportsGroupProps> = {
	data: [
		{
			sublabel: 'ООО "Фенсма"',
			items: [
				{
					title: 'Финансовый отчет от 01 окт. 2021',
					url: 'https://fensma.ru',
				},
				{
					title: 'Отчет от 01.09.2021',
					url: 'https://fensma.ru',
				},
				{
					title: 'Приложение к отчету от 01.09.2021',
					url: 'https://fensma.ru',
				},
			],
		},
		{
			sublabel: 'ООО "СК Фенсма"',
			items: [
				{
					title: 'Финансовый отчет от 01 окт. 2021',
					url: 'https://fensma.ru',
				},
				{
					title: 'Отчет от 01.09.2021',
					url: 'https://fensma.ru',
				},
				{
					title: 'Приложение к отчету от 01.09.2021',
					url: 'https://fensma.ru',
				},
			],
		},
	],
};

export const reportsList2020: PaginatedData<ReportsGroupProps> = {
	data: [
		{
			sublabel: 'ООО "Фенсма"',
			items: [
				{
					title: 'Отчет от 01.09.2020',
					url: 'https://fensma.ru',
				},
				{
					title: 'Приложение к отчету от 01.09.2020',
					url: 'https://fensma.ru',
				},
				{
					title: 'Финансовый отчет от 01 окт. 2020',
					url: 'https://fensma.ru',
				},
			],
		},
		{
			sublabel: 'ООО "СК Фенсма"',
			items: [
				{
					title: 'Финансовый отчет от 01 окт. 2020',
					url: 'https://fensma.ru',
				},
				{
					title: 'Приложение к отчету от 01.09.2020',
					url: 'https://fensma.ru',
				},
			],
		},
	],
};

export const reportsList2019: PaginatedData<ReportsGroupProps> = {
	data: [
		{
			sublabel: 'ООО "Фенсма"',
			items: [
				{
					title: 'Отчет от 01.09.2019',
					url: 'https://fensma.ru',
				},
				{
					title: 'Финансовый отчет от 01 окт. 2019',
					url: 'https://fensma.ru',
				},
			],
		},
		{
			sublabel: 'ООО "СК Фенсма"',
			items: [
				{
					title: 'Финансовый отчет от 01 окт. 2019',
					url: 'https://fensma.ru',
				},
				{
					title: 'Приложение к отчету от 01.09.2019',
					url: 'https://fensma.ru',
				},
			],
		},
	],
};
