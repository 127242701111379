import { PageData } from '@api/hooks/types';

export const project: PageData = {
	meta: {
		title: 'Новая штаб-квартира «Яндекса»',
		description: '',
	},
	data: [
		{
			type: 'intro',
			content: {
				title: 'Новая штаб-квартира<br /><color-accent>«Яндекса»</color-accent>',
				breadcrumbs: [{ text: 'Вернуться ко всем проектам', url: '/projects' }],
				isDetailPage: true,
			},
		},

		{
			type: 'article',
			content: {
				items: [
					{
						type: 'header',
						title:
							'Необычный силуэт и фасад с отделкой из титановых панелей сделают новый дом <color-accent>Яндекса</color-accent> местной достопримечательностью, а сквозной диагональный проход свяжет здание с окружающим пространством',
					},
					{
						type: 'html',
						content:
							'<p>/ 19 июня 2020 года. Яндекс представил проект новой штаб-квартиры в Гагаринском районе Москвы. Здание будет расположено на месте гостиницы «Корстон».</p>' +
							'<p>Территория штаб-квартиры и первые два этажа будут открыты для всех. На нижних этажах будут работать кафе, аптеки, салоны красоты и другие организации, а вокруг офиса появится общественное пространство, где любой человек сможет отдохнуть, перекусить или позаниматься спортом. Яндекс также благоустроит большую территорию возле штаб-квартиры: между улицами Академика Зелинского и Академика Зельдовича появится зелёный парк, который отлично подойдёт для прогулок на свежем воздухе.</p>',
					},
					{
						type: 'property',
						label: 'Сроки выполнения работ',
						text: '2020 – 2021',
					},
					{
						type: 'property',
						label: 'Генеральный подрядчик',
						text: 'Fensma',
					},
					{
						type: 'property',
						label: 'Заказчик',
						text: 'Яндекс',
						url: 'https://yandex.ru/',
					},
				],
				pictures: [
					{
						src: '/temp/pic-12.jpg',
						width: 480,
						height: 540,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-13.jpg',
						width: 480,
						height: 540,
						alt: 'Название картинки 2',
					},
				],
			},
			wrapper: {
				type: 'stripsVertical',
				props: {
					color: 'white',
					startScreen: true,
				},
			},
		},

		{
			type: 'statsGallery',
			content: {
				title: 'Проект<br /><color-accent>в цифрах</color-accent>',
				items: [
					{
						type: 'head',
						title: 'Тип выполненяемых работ',
						text: 'Устройство монолитной конструкции <br />«стены в грунте»',
					},
					{
						type: 'child',
						title: 'Ширина траншеи',
						text: '800 мм',
					},
					{
						type: 'child',
						title: 'Объём бетонирования СВГ',
						text: '24 200 м3',
					},
					{
						type: 'head',
						title: 'Тип выполненяемых работ',
						text: 'Разработка грунта котлована',
					},
					{
						type: 'child',
						title: 'Объем грунта',
						text: '423 100 м3',
					},
					{
						type: 'head',
						title: 'Тип выполненяемых работ',
						text: 'Устройство грунтовых анкеров',
					},
					{
						type: 'child',
						title: 'Количество анкеров',
						text: '2 467 шт',
					},
					{
						type: 'head',
						title: 'Тип выполненяемых работ',
						text: 'Демонтаж существующих зданий и сооружений',
					},
					{
						type: 'child',
						title: 'Объем надземной части',
						text: '216 000 м3',
					},
					{
						type: 'child',
						title: 'Объем подземной части',
						text: '50 155 м3',
					},
				],
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'white',
					paddingTop: true,
				},
			},
		},

		{
			type: 'picturesGallery',
			content: {
				title: 'Галерея',
				items: [
					{
						src: '/temp/pic-07.jpg',
						width: 432,
						height: 448,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-08.jpg',
						width: 432,
						height: 360,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-09.jpg',
						width: 432,
						height: 296,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-10.jpg',
						width: 432,
						height: 360,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-07.jpg',
						width: 432,
						height: 448,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-08.jpg',
						width: 432,
						height: 360,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-09.jpg',
						width: 432,
						height: 296,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-10.jpg',
						width: 432,
						height: 360,
						alt: 'Название картинки',
					},
				],
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'dark',
					paddingBottom: true,
				},
			},
		},

		{
			type: 'mediaSection',
			content: {
				title: 'Визуализация интеграции <br />с BRIO MRS',
				cursor: {
					play: 'Воспроизвести',
					stop: 'Остановить',
				},
				bg: {
					src: '/temp/pic-11.jpg',
					width: 1920,
					height: 1080,
				},
				video: {
					type: 'video',
					mp4: '/video/footage.mp4',
					width: 1920,
					height: 1080,
				},
			},
		},
	],
};
