import type { AnimationType } from '@components/animations';
import React, { FC, useContext } from 'react';
import { ViewportContext } from '@context';
import { SplitViewSlidesRegular } from '@components/SplitViewSlides/components/SplitViewSlidesRegular';
import { SplitViewSlidesMobile } from '@components/SplitViewSlides/components/SplitViewSlidesMobile';

export type SlideType = {
	title?: string;
	text?: string;
	icon?: Picture;
};

export interface SplitViewSlidesProps {
	slides?: Array<SlideType>;
	settings?: {
		/*
		 * @strips: 'normal' | 'reverse' | 'none', стиль окончания блока
		 * @strips: на десктопе подтягивает блок так, чтобы он лег поверх предыдущей секции для перехода со скроллом
		 * @animation: тип анимации
		 */
		strips: 'normal' | 'reverse' | 'none';
		slideOnPrev?: boolean;
		animation?: AnimationType;
	};
}

export const SplitViewSlides: FC<SplitViewSlidesProps> = React.memo((props) => {
	const { bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	const { slides = [] } = props;
	if (slides.length === 0) return null;

	return (
		<>
			{isMob && <SplitViewSlidesMobile {...props} />}
			{!isMob && <SplitViewSlidesRegular {...props} />}
		</>
	);
});
