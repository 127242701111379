import css from './Testimonial.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, useContext, useEffect, useRef } from 'react';
import Image from 'next/image';
import { Box, Flex, LinkTag, RawHtml, Tag, Text } from '@core';
import useIntersectionObserver from '@hooks/useIntersectionObserver';
import { Performance } from '@utils';
import { ViewportContext } from '@context';

export interface TestimonialProps extends HTMLAttributes<HTMLDivElement> {
	title?: string;
	text?: string;
	url?: string;
	picture?: Picture;
}

const resistance = 0.075;

export const Testimonial: FC<TestimonialProps> = React.memo(
	({ title, text, url, picture, className }) => {
		const ref = useRef<HTMLDivElement | null>(null);

		const entry = useIntersectionObserver(ref, {
			threshold: 0.25,
			freezeOnceVisible: true,
		});

		const { bp } = useContext(ViewportContext);
		const isMob = bp === 'xs';

		useEffect(() => {
			const element = ref?.current;
			if (!element || isMob) return;

			let center = {
				x: 0,
				y: 0,
			};
			const current = {
				x: 0,
				y: 0,
			};
			const next = {
				x: 0,
				y: 0,
			};

			const pic = element.querySelector(`.${css.picture}`);

			const onMove = (e: MouseEvent) => {
				if (!pic) return;

				const { left: parentLeft, top: parentTop } = element.getBoundingClientRect();
				const { left, top } = pic.getBoundingClientRect();

				center.x = left - parentLeft + 40;
				center.y = top - parentTop + 40;

				next.x = center.x - (e.clientX - parentLeft);
				next.y = center.y - (e.clientY - parentTop);

				next.x = next.x * 0.25;
				next.y = next.y * 0.35;
			};

			const onLeave = () => {
				next.x = 0;
				next.y = 0;
			};

			const onTick = () => {
				const diffX = next.x - current.x;
				const deltaX = Math.abs(diffX) < 0.01 ? 0 : diffX * resistance;

				const diffY = next.y - current.y;
				const deltaY = Math.abs(diffX) < 0.01 ? 0 : diffY * resistance;

				if (current.x === next.x && current.y === next.y) return;

				if (deltaX) {
					current.x = current.x + deltaX;
				} else {
					current.x = next.x;
				}

				if (deltaY) {
					current.y = current.y + deltaY;
				} else {
					current.y = next.y;
				}

				element.style.setProperty('--mx', `${current.x}`);
				element.style.setProperty('--my', `${current.y}`);
			};

			element.addEventListener('mousemove', onMove);
			element.addEventListener('mouseleave', onLeave);

			Performance.addListener(onTick);

			return () => {
				element.removeEventListener('mousemove', onMove);
				element.removeEventListener('mouseleave', onLeave);

				Performance.removeListener(onTick);
			};
		}, [isMob]);

		const content = (
			<Flex className={css.grid} ref={ref}>
				<Box className={css.grid__left}>
					{title && <Tag className={css.caption} type="h3" content={title} isHTML={true} />}
					<Text className={css.text} size="md">
						<RawHtml content={text} />
					</Text>
				</Box>
				{picture && (
					<Box className={css.grid__right}>
						<picture className={css.picture}>
							<Image
								src={picture.src}
								alt={picture.alt}
								width={picture.width}
								height={picture.height}
								layout="intrinsic"
								sizes="(max-width: 639px) 50vw, 15vw"
							/>
						</picture>
					</Box>
				)}
			</Flex>
		);

		const classNames = classnames(className, css.item, { [css.isVisible]: entry?.isIntersecting });

		return url ? (
			<LinkTag className={classNames} href={url}>
				{content}
			</LinkTag>
		) : (
			<Box className={classNames}>{content}</Box>
		);
	}
);
