import { PageData } from '@api/hooks/types';

export const contacts: PageData = {
	meta: {
		title: 'Контакты',
		description:
			'Ознакомьтесь с нашими актуальными Вакансиями или отправьте нам заявку и мы свяжемся с вами.',
	},
	data: [
		{
			type: 'intro',
			content: {
				title: 'Контакты',
				indent: false,
				isSimplePage: true,
			},
		},

		{
			type: 'contactsLinks',
			content: {
				items: [
					{
						caption: 'Поставщикам',
						links: [
							{
								label: 'corp@fensma.ru',
								url: 'mailto:corp@fensma.ru',
							},
						],
					},
					{
						caption: 'Подрядным организациям',
						links: [
							{
								label: 'hr@fensma.ru',
								url: 'mailto:hr@fensma.ru',
							},
						],
					},
					{
						caption: 'Подрядным организациям',
						links: [
							{
								label: 'hr@fensma.ru',
								url: 'mailto:hr@fensma.ru',
							},
						],
					},
					{
						caption: 'Поставщикам',
						links: [
							{
								label: '+7 (495) 780-48-14',
								url: 'tel:+74957804814',
							},
						],
					},
					{
						caption: 'Наши соцсети',
						links: [
							{
								label: 'telegram',
								url: 'https://t.me/rdclr_home',
							},
							{
								label: 'vkontakte',
								url: 'https://vk.com/redcollar',
							},
						],
					},
					{
						caption: 'Хотите у нас работать?',
						links: [
							{
								label: 'Вакансии',
								url: '/career/vacancies',
							},
						],
					},
				],
			},
			wrapper: {
				props: {
					startScreen: true,
				},
			},
		},

		{
			type: 'contactsMap',
			content: {
				items: [
					{
						id: '001',
						coordinates: [55.714698, 37.516485],
						data: {
							title: 'Россия, г. Москва',
							text: 'ул.&nbsp;Мосфильмовская, д.&nbsp;17Б, мансарда пом.&nbsp;II, комн.&nbsp;8',
						},
					},
					{
						id: '002',
						coordinates: [30.054828, 31.24862],
						data: {
							title: 'Египет, г. Каир',
							text: 'ул.&nbsp;Мосфильмовская, д.&nbsp;17Б, мансарда пом.&nbsp;II, комн.&nbsp;8',
						},
					},
					{
						id: '003',
						coordinates: [30.045718, 31.249359],
						data: {
							title: 'Египет, г. Каир',
							text: 'ул.&nbsp;Мосфильмовская, д.&nbsp;17Б, мансарда пом.&nbsp;II, комн.&nbsp;8',
						},
					},
				],
			},
		},

		{
			type: 'feedback',
			content: {
				title: 'Присоединяйся<br /><color-accent>к&nbsp;команде</color-accent>',
				text: '<p>Ознакомьтесь с нашими актуальными <a href="/career/vacancies">Вакансиями</a> или отправьте нам заявку и мы свяжемся с вами</p>',
				form: {
					action: '/forms/feedback',
					method: 'post',
					grcKey: '6LfURdcZAAAAAAz9aJ1T0WeUAeVGQI3RsarvmhRi',
					items: [
						{
							type: 'text',
							name: 'name',
							label: 'ФИО',
							required: true,
							validation: {
								required: 'Заполните это поле',
							},
						},
						{
							type: 'email',
							name: 'email',
							label: 'Электронная почта',
							required: true,
							pattern: '[0-9a-z_.-]+@[0-9a-z_]+\\.[a-z]{2,5}',
							validation: {
								required: 'Заполните это поле',
								pattern: 'Некорректный адрес email',
							},
						},
						{
							type: 'tel',
							name: 'phone',
							label: 'Номер телефона',
							required: true,
							mask: '+0 000 0000000',
							pattern: '^(\\+\\d)\\s\\d{3}\\s\\d{7}$',
							validation: {
								required: 'Заполните это поле',
								pattern: 'Некорректный номер телефона',
							},
						},
						{
							type: 'file',
							name: 'file',
							placeholder: 'Прикрепить резюме',
							accept: 'application/pdf,image/jpeg',
							acceptLabel: 'pdf',
							sizeLimit: 52428800,
							sizeLimitLabel: 'До 50 Мб',
							validation: {
								accept: 'Недопустимый формат файла',
								size: 'Превышен допустимый размер файла',
							},
						},
					],
					button: 'Отправить',
					notice:
						'Нажимая «Отправить», я соглашаюсь с условиями<br /><a href="/" target="_blank">Политики конфиденциальности</a> и <a href="/" target="_blank">Пользовательского соглашения</a>',
				},
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'white',
					paddingTop: false,
					paddingBottom: false,
				},
			},
		},
	],
};
