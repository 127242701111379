import { PageData } from '@api/hooks/types';

export const events: PageData = {
	meta: {
		title: 'События компании',
		description: '',
	},
	data: [
		{
			type: 'intro',
			content: {
				title: 'События<br /><color-accent>компании</color-accent>',
				breadcrumbs: [{ text: 'Новости' }],
			},
		},

		{
			type: 'postsFeed',
			links: {
				self: '/events/news',
				next: '/events/news?page[offset]=10',
			},
			content: {
				title: 'Новости',
				filters: [
					{
						text: '2021',
						key: 'year',
						value: null,
					},
					{
						text: '2020',
						key: 'year',
						value: '2020',
					},
					{
						text: '2019',
						key: 'year',
						value: '2019',
					},
					{
						text: '2018',
						key: 'year',
						value: '2018',
					},
				],
				items: [
					{
						title:
							'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip в качестве делового партнёра',
						url: '/events/event',
						date: '2020-08-02 13:00:00',
					},
					{
						title:
							'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
						url: '/events/event',
						date: '2020-09-25 13:00:00',
					},
					{
						title:
							'Этим летом Генеральный директор компании FENSMA совершил поход на самую высокую точку Европы — Эльбрус',
						url: '/events/event',
						date: '2020-09-28 13:00:00',
					},
					{
						title:
							'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip в качестве делового партнёра',
						url: '/events/event',
						date: '2020-10-12 13:00:00',
					},
				],
			},
			wrapper: {
				type: 'stripsVertical',
				props: {
					color: 'white',
					startScreen: true,
				},
			},
		},

		{
			type: 'announcesFeed',
			content: {
				title: 'Анонсы<br /><color-accent>мероприятий</color-accent>',
				items: [
					{
						title: 'Чемпионат <color-accent>«Worldconchip»</color-accent>',
						text: 'Компания FENSMA принимала участие в международном строительном чемпионате в качестве делового партнёра',
						url: '/events/event',
						date: '2020-08-02 13:00:00',
						picture: {
							src: '/temp/pic-18.jpg',
							width: 480,
							height: 480,
							alt: '',
						},
					},
					{
						title: 'Чемпионат <color-accent>«Worldconchip»</color-accent>',
						text: 'Компания FENSMA принимала участие в международном строительном чемпионате в качестве делового партнёра',
						url: '/events/event',
						date: '2020-08-02 13:00:00',
						picture: {
							src: '/temp/pic-19.jpg',
							width: 480,
							height: 480,
							alt: '',
						},
					},
					{
						title: 'Чемпионат <color-accent>«Worldconchip»</color-accent>',
						text: 'Компания FENSMA принимала участие в международном строительном чемпионате в качестве делового партнёра',
						url: '/events/event',
						date: '2020-08-02 13:00:00',
						picture: {
							src: '/temp/pic-18.jpg',
							width: 480,
							height: 480,
							alt: '',
						},
					},
					{
						title: 'Чемпионат <color-accent>«Worldconchip»</color-accent>',
						text: 'Компания FENSMA принимала участие в международном строительном чемпионате в качестве делового партнёра',
						url: '/events/event',
						date: '2020-08-02 13:00:00',
						picture: {
							src: '/temp/pic-19.jpg',
							width: 480,
							height: 480,
							alt: '',
						},
					},
				],
			},
			wrapper: {
				type: 'stripsHorizontalLeft',
				props: {
					color: 'white',
				},
			},
		},

		{
			type: 'eventsFeed',
			content: {
				title: 'Старт работ',
				items: [
					{
						title:
							'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip в качестве делового партнёра',
						url: '/events/event',
						date: '2021-09-28 02:34:56',
						picture: {
							src: '/temp/event-1.png',
							width: 1280,
							height: 920,
							alt: 'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip',
						},
					},
					{
						title:
							'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
						url: '/events/event',
						date: '2021-09-25 02:34:56',
						picture: {
							src: '/temp/event-2.png',
							width: 1280,
							height: 920,
							alt: 'Участие в международной выставке GEOFLUID 2021',
						},
					},
					{
						title:
							'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
						url: '/events/event',
						date: '2021-09-20 02:34:56',
						picture: {
							src: '/temp/event-1.png',
							width: 1280,
							height: 920,
							alt: 'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip',
						},
					},
					{
						title:
							'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip в качестве делового партнёра',
						url: '/events/event',
						date: '2021-08-18 02:34:56',
						picture: {
							src: '/temp/event-2.png',
							width: 1280,
							height: 920,
							alt: 'Участие в международной выставке GEOFLUID 2021',
						},
					},
					{
						title:
							'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip в качестве делового партнёра',
						url: '/events/event',
						date: '2021-08-12 02:34:56',
						picture: {
							src: '/temp/event-1.png',
							width: 1280,
							height: 920,
							alt: 'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip',
						},
					},
					{
						title:
							'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
						url: '/events/event',
						date: '2021-07-06 02:34:56',
						picture: {
							src: '/temp/event-2.png',
							width: 1280,
							height: 920,
							alt: 'Участие в международной выставке GEOFLUID 2021',
						},
					},
					{
						title:
							'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
						url: '/events/event',
						date: '2021-05-02 02:34:56',
						picture: {
							src: '/temp/event-1.png',
							width: 1280,
							height: 920,
							alt: 'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip',
						},
					},
				],
				hasTrailAnimation: true,
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'transparent',
				},
			},
		},
	],
};
