import { PageData } from '@api/hooks/types';

export const standard: PageData = {
	meta: {
		title: 'Типовая контентная страница',
		description: '',
	},
	data: [
		{
			type: 'intro',
			content: {
				title: 'Типовая<br /><color-accent>контентная страница</color-accent>',
				breadcrumbs: [{ text: 'Вернуться на главную', url: '/' }],
			},
		},

		{
			type: 'article',
			content: {
				items: [
					{
						type: 'header',
						title: 'Заголовок <color-accent>статьи</color-accent>',
						date: '2020-08-02 13:00:00',
						url: 'https://ria.ru/',
					},
					{
						type: 'html',
						content:
							'<p>/ Интернет, 19 июня 2020 года. Яндекс представил проект новой штаб-квартиры в Гагаринском районе Москвы. Здание будет расположено на месте гостиницы «Корстон». Необычный силуэт и фасад с отделкой из титановых панелей сделают новый дом Яндекса местной достопримечательностью, а сквозной диагональный проход свяжет здание с окружающим пространством.</p>' +
							'<p>Территория штаб-квартиры и первые два этажа будут открыты для всех. На нижних этажах будут работать кафе, аптеки, салоны красоты и другие организации, а вокруг офиса появится общественное пространство, где любой человек сможет отдохнуть, перекусить или позаниматься спортом. Яндекс также благоустроит большую территорию возле штаб-квартиры: между улицами Академика Зелинского и Академика Зельдовича появится зелёный парк, который отлично подойдёт для прогулок на свежем воздухе.</p>',
					},
				],
				pictures: [
					{
						src: '/temp/pic-14.jpg',
						width: 480,
						height: 540,
						alt: 'Название картинки',
					},
				],
			},
			wrapper: {
				type: 'stripsVertical',
				props: {
					color: 'white',
					startScreen: true,
					paddingTop: true,
					paddingBottom: true,
				},
			},
		},

		{
			type: 'mediaSection',
			content: {
				title: 'Визуализация интеграции <br />с BRIO MRS',
				cursor: {
					play: 'Воспроизвести',
					stop: 'Остановить',
				},
				bg: {
					src: '/temp/pic-16.jpg',
					width: 1920,
					height: 1080,
				},
				video: {
					type: 'video',
					mp4: '/video/footage.mp4',
					width: 1920,
					height: 1080,
				},
			},
		},

		{
			type: 'article',
			content: {
				items: [
					{
						type: 'header',
						title: 'Заголовок статьи',
					},
					{
						type: 'html',
						content:
							'<p>/ Интернет, 19 июня 2020 года. Яндекс представил проект новой штаб-квартиры в Гагаринском районе Москвы. Здание будет расположено на месте гостиницы «Корстон». Необычный силуэт и фасад с отделкой из титановых панелей сделают новый дом Яндекса местной достопримечательностью, а сквозной диагональный проход свяжет здание с окружающим пространством.</p>' +
							'<p>Территория штаб-квартиры и первые два этажа будут открыты для всех. На нижних этажах будут работать кафе, аптеки, салоны красоты и другие организации, а вокруг офиса появится общественное пространство, где любой человек сможет отдохнуть, перекусить или позаниматься спортом. Яндекс также благоустроит большую территорию возле штаб-квартиры: между улицами Академика Зелинского и Академика Зельдовича появится зелёный парк, который отлично подойдёт для прогулок на свежем воздухе.</p>',
					},
				],
				pictures: [
					{
						src: '/temp/pic-17.jpg',
						width: 480,
						height: 540,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-14.jpg',
						width: 480,
						height: 540,
						alt: 'Название картинки 2',
					},
				],
				sharing: {
					title: 'Поделиться',
					items: [
						{
							text: 'telegram',
							href: 'https://t.me/share',
						},
						{
							text: 'vkontakte',
							href: 'https://vk.com/share.php',
						},
					],
				},
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'dark',
					paddingTop: true,
					paddingBottom: true,
				},
			},
		},
	],
};
