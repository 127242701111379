import css from './Video.module.scss';
import classnames from 'classnames';
import React, { PropsWithChildren, FC, HTMLAttributes, useState, useEffect } from 'react';
import { Box } from '@core';

interface VideoProps extends HTMLAttributes<HTMLVideoElement> {
	playing?: boolean;
}

export const Video: FC<PropsWithChildren<VideoProps & Video>> = ({
	className,
	style,
	mp4,
	playing = false,
	...rest
}) => {
	const [video, setVideo] = useState<HTMLVideoElement | null>(null);

	useEffect(() => {
		if (!video) return;

		if (playing) {
			video.play().then();
		} else {
			video.pause();
		}
	}, [video, playing]);

	return (
		<Box className={classnames(className, css.video)} style={style}>
			<video ref={setVideo} loop muted playsInline {...rest}>
				<source src={mp4} type="video/mp4" />
			</video>
		</Box>
	);
};
