import css from './Video.module.scss';
import classnames from 'classnames';
import fastdom from 'fastdom';
import React, { createContext, FC, useContext, useEffect, useRef } from 'react';
import { SliderProps } from '@components/HomeIntro/components/Slider/Slider';
import { Box, Container, Odometer } from '@core';
import { findLastIndex, normalize } from '@utils';
import { HomeIntroContext } from '@components/HomeIntro/HomeIntro';
import { MediaBg } from '@components/HomeIntro/components/MediaBg/MediaBg';
import { getStages } from '@components/HomeIntro/utils';

export const SliderContext = createContext(0);
export const TickerContext = createContext(0);

export const Video: FC<SliderProps> = React.memo(
	({ screens: { full: screens, before = 0 }, start, end, slider: { items, picture, video } }) => {
		const ref = useRef<HTMLDivElement | null>(null);

		const { seek: seekMain } = useContext(HomeIntroContext);
		const seek = normalize(seekMain, end, start + before / screens, true);
		const stages = getStages(seek, screens);

		useEffect(() => {
			const wrap = ref.current;
			if (!wrap) return;

			fastdom.mutate(() => {
				wrap.style.clipPath = `polygon(${50 - stages[0] * 50}% 0, ${50 + stages[0] * 50}% 0, ${
					50 + stages[0] * 50
				}% 100%, ${50 - stages[0] * 50}% 100%)`;
			});
		}, [stages]);

		const current = findLastIndex(stages.slice(1, -1), (value) => value >= 1);

		return (
			<SliderContext.Provider value={current}>
				<Box ref={ref} className={classnames(css.module)}>
					<MediaBg picture={picture} video={video} playing={seek >= 0 && seek !== 1} />
					<SlidesList items={items} />
				</Box>
			</SliderContext.Provider>
		);
	}
);

export const SlidesList: FC<SliderProps['slider']> = React.memo(({ items }) => {
	const current = useContext(SliderContext);

	return (
		<Container className={css.slides}>
			{items?.map((slide, i) => {
				return (
					<Box
						key={i + '-' + slide.text}
						className={classnames(css.slide, { [css.slideActive]: current >= i })}>
						<Box className={css.odometer}>
							{slide.gt && <span className={css.odometerGt}>&gt;</span>}
							<Odometer value={current >= i ? slide?.odometer : undefined} length={3} />
						</Box>
						<Box className={css.label}>
							<Box className={css.labelInner}>{slide.text}</Box>
						</Box>
					</Box>
				);
			})}
		</Container>
	);
});
