import { PageData } from '@api/hooks/types';

export const control: PageData = {
	meta: {
		title: 'Контроль на всех этапах',
		description:
			'Мы стремимся делать всё возможное для наших клиентов, укрепляя отношения, построенные на доверии, уверенности и честности. ',
	},
	data: [
		{
			type: 'intro',
			content: {
				title: 'Контроль<br /><color-accent>на всех этапах</color-accent>',
				breadcrumbs: [{ text: 'О компании' }, { text: 'Контролируем качество' }],
			},
		},

		{
			type: 'infoBlock',
			content: {
				subtitle:
					'Обеспечение контроля качества на всех этапах&nbsp;&mdash; <color-accent>залог успешного бизнеса</color-accent>',
				text: '<p>/ Мы стремимся делать всё возможное для наших клиентов, укрепляя отношения, построенные на доверии, уверенности и честности.</p>',
			},
			wrapper: {
				type: 'stripsVertical',
				props: {
					color: 'white',
					startScreen: true,
					paddingBottom: true,
				},
			},
		},

		{
			type: 'mediaSection',
			content: {
				title: 'Визуализация интеграции <br />с BRIO MRS',
				cursor: {
					play: 'Воспроизвести',
					stop: 'Остановить',
				},
				bg: {
					src: '/temp/pic-20.jpg',
					width: 1920,
					height: 1080,
				},
				youtube: 'https://www.youtube.com/watch?v=kYQ-ndY8Pyw',
			},
		},

		{
			type: 'infoBlock',
			content: {
				sentence:
					'Современная строительная отрасль уже оперирует более десятком технологий, максимально востребованными в строительстве. Наш обзор охватывает самые прогрессивные и востребованные IT-технологии и инновационные материалы в строительстве, которые с каждым годом все больше интегрируются в строительную сферу, реализуя самые смелые идеи будущего.',
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'dark',
				},
			},
		},

		{
			type: 'benefitsList',
			content: {
				title: 'Технологии',
				items: [
					{
						title: 'Bim-технологии',
						content: [
							{
								type: 'html',
								content:
									'<p>Наш обзор охватывает самые прогрессивные и востребованные IT-технологии и инновационные материалы в строительстве, которые с каждым годом все больше интегрируются в строительную сферу, реализуя самые смелые идеи будущего.</p>',
							},
						],
					},
					{
						title: 'Название второй технологии',
						content: [
							{
								type: 'html',
								content:
									'<p>Наш обзор охватывает самые прогрессивные и востребованные IT-технологии и инновационные материалы в строительстве, которые с каждым годом все больше интегрируются в строительную сферу, реализуя самые смелые идеи будущего.</p>',
							},
						],
					},
					{
						title: 'Название третьей технологии',
						content: [
							{
								type: 'html',
								content:
									'<p>Наш обзор охватывает самые прогрессивные и востребованные IT-технологии и инновационные материалы в строительстве, которые с каждым годом все больше интегрируются в строительную сферу, реализуя самые смелые идеи будущего.</p>',
							},
						],
					},
				],
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'dark',
				},
			},
		},

		{
			type: 'taskGallery',
			content: {
				title: 'Материалы',
				items: [
					{
						text: 'Важные материалы о контроле<br />безопасности',
						url: '/dummy/dummy.pdf',
					},
					{
						text: 'Материалы о контроле<br />безопасности',
						url: '/dummy/dummy.pdf',
					},
					{
						text: 'Важные материалы о контроле<br />безопасности',
						url: '/dummy/dummy.pdf',
					},
					{
						text: 'Материалы о контроле<br />безопасности',
						url: '/dummy/dummy.pdf',
					},
					{
						text: 'Важные материалы о контроле<br />безопасности',
						url: '/dummy/dummy.pdf',
					},
					{
						text: 'Материалы о контроле<br />безопасности',
						url: '/dummy/dummy.pdf',
					},
				],
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'dark',
				},
			},
		},
	],
};
