import useSWR from 'swr';
import { ReturnedSWRData } from '@api/hooks/types';
import { StaticPath, paths } from '@api/mock/paths';
import { fetcher } from '@api/utils/fetcher';
import { getMock } from '@api/mock';

export const getPaths = async (url?: string) => {
	const mock = async () => paths as StaticPath[];
	if (!url) return mock();

	return fetcher<StaticPath[]>(url);
};

export const getPageData = async (url: string, needMock: boolean = true) => {
	const mock = async () => getMock(url);
	if (needMock) return mock();

	return fetcher<any>(url);
};

export const usePageData = (endpoint: string | null, needMock: boolean = true): ReturnedSWRData => {
	const { data, error } = useSWR(endpoint, (url) => getPageData(url, needMock), {
		revalidateIfStale: false,
		revalidateOnFocus: false,
		revalidateOnReconnect: false,
	});

	return {
		data: data,
		isLoading: !error && !data,
		isError: error,
	};
};
