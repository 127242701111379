import css from './Odometer.module.scss';
import classnames from 'classnames';
import React, { FC, useEffect, useRef } from 'react';

interface Props {
	value?: number;
	length?: number;
	delay?: number;
}

const digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

const getArray = (length: number): Array<string | null> => {
	const array = [];
	for (let i = 0; i < length; i++) {
		array.push(null);
	}
	return array;
};

export const Odometer: FC<Props> = React.memo(({ value, length = 4, delay = 0 }) => {
	length = value && value.toString().length > length ? value.toString().length : length;

	const array = getArray(length);

	if (typeof value !== 'undefined') {
		const shift = length - value.toString().length;
		value
			.toString()
			.split('')
			.forEach((d, i) => {
				array[i + shift] = d;
			});
	}

	const ref = useRef<HTMLDivElement | null>(null);
	const timer = useRef(0);

	useEffect(() => {
		if (ref.current) {
			if (timer?.current) window.clearTimeout(timer.current);
			timer.current = window.setTimeout(() => {
				ref.current?.querySelectorAll<HTMLElement>(`.${css.rollTrack}`).forEach((el, i) => {
					const val = array[i];
					const current = value ? (val === null ? 1 : Number(val) + 1) : 0;
					el.style.transform = `translate3d(0, ${-9.090909090909091 * current}%, 0)`;
					el.classList.toggle(css.accent, val !== null);
				});
			}, delay);
		}
	}, [array, value, delay]);

	return <OdometerDigits ref={ref} length={length} />;
});

export const OdometerDigits = React.memo(
	React.forwardRef<HTMLDivElement, { length: number }>(({ length }, ref) => {
		const zeros = getArray(length);

		return (
			<div className={css.module} ref={ref}>
				{zeros.map(() => {
					return <OdometerRoll key={`digit-${Math.random()}`} />;
				})}
			</div>
		);
	})
);

export const OdometerRoll = React.memo(
	React.forwardRef<HTMLDivElement, any>(({}, ref) => {
		return (
			<div className={css.roll}>
				<div ref={ref} className={css.rollTrack}>
					<div className={css.digit}>&nbsp;</div>
					{digits.map((d, i) => {
						return (
							<div
								key={`digit-${Math.random()}`}
								className={classnames(css.digit, { [css.digitAccent]: i > 0 })}>
								{d}
							</div>
						);
					})}
				</div>
			</div>
		);
	})
);
