import css from './StripsTwoAxis.module.scss';
import classnames from 'classnames';
import fastdom from 'fastdom';
import React, {
	FC,
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useScrollWithEase } from '@hooks';
import { ViewportContext } from '@context';
import { PageTransitionContext } from '@components/Layout/components/PageTransition/PageTransition';

interface Props {
	color?: string;
	baseColor?: string;
	startScreen?: boolean;
	hideFirst?: boolean;
	multiplier?: number;
	paddingTop?: boolean;
	paddingBottom?: boolean;
}

const stripsVRange = [0.15, 0.2, 0.3, 0.4, 0.5];

const stripsHRangeLG = [0.05, 0.1, 0.125, 0.15];
const stripsHRangeMD = [0.02, 0.04, 0.06, 0.08];

export const StripsTwoAxis: FC<PropsWithChildren<Props>> = ({
	color = 'white',
	baseColor,
	multiplier = 1,
	startScreen,
	paddingTop = true,
	paddingBottom = true,
	children,
}) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const [seekX, setSeekX] = useState(0);
	const [translate, setTranslate] = useState(0);

	const { vh, bp } = useContext(ViewportContext);

	const onScroll = useCallback(
		(current: number, height: number) => {
			fastdom.measure(() => {
				if (!ref.current) return;

				const rect = ref.current.getBoundingClientRect();
				const posY = rect.top + window.scrollY;

				let seek;
				let translate;

				if (startScreen) {
					seek = (posY - Math.max(current, 0)) / posY;
				} else {
					current = Math.max(current * 0.85, 0);
					seek = 1 - Math.min(current, vh) / vh;
				}

				const base = height - vh;
				const pos = Math.min(Math.abs(Math.min(current, 0)), base);

				const seekX = pos / base;

				translate = Math.max(seek * Math.min(rect.height, vh * 3), 0);

				setSeekX(seekX);
				setTranslate(translate);
			});
		},
		[startScreen, vh]
	);

	useScrollWithEase(ref, onScroll, {
		ease: 0.25,
		edge: 'top',
	});

	const [shown, setShown] = useState(false);
	const transition = useContext(PageTransitionContext);

	useEffect(() => {
		if (startScreen) {
			setTimeout(() => {
				setShown(true);
			}, 800);
		}
	}, [startScreen]);

	const stripsHRange = bp === 'md' ? stripsHRangeMD : stripsHRangeLG;

	const [stripsH, setStripsH] = useState<NodeListOf<HTMLElement> | undefined>(undefined);
	const [stripsV, setStripsV] = useState<NodeListOf<HTMLElement> | undefined>(undefined);

	useEffect(() => setStripsH(ref?.current?.querySelectorAll<HTMLElement>(`.${css.stripH}`)), []);
	useEffect(() => setStripsV(ref?.current?.querySelectorAll<HTMLElement>(`.${css.stripV}`)), []);

	useEffect(() => {
		fastdom.mutate(() => {
			stripsV?.forEach((el, i) => {
				const trans = translate * -stripsVRange[i] * multiplier;
				el.style.transform = `translate3d(0, ${trans}px, 0)`;
			});
		});
	}, [stripsV, translate, multiplier]);

	useEffect(() => {
		fastdom.mutate(() => {
			stripsH?.forEach((el, i) => {
				const scale = 1 - seekX * stripsHRange[i] * multiplier;
				el.style.transform = `scaleX(${scale})`;
			});
		});
	}, [stripsH, seekX, stripsHRange, multiplier]);

	const contentClass = useMemo(
		() =>
			classnames(
				css.inner,
				'strips-two-axis',
				{ [css.innerPadTop]: paddingTop, [css.innerPadBottom]: paddingBottom },
				css[color]
			),
		[color, paddingTop, paddingBottom]
	);

	return (
		<div
			className={classnames(
				css.outer,
				{
					[css.startScreen]: startScreen,
					[css.startScreenShown]:
						startScreen && shown && transition !== 'inter' && transition !== 'exit',
				},
				`is-${color}-section`
			)}
			ref={ref}>
			{baseColor && <div className={classnames(css.base, css[baseColor])} />}
			<div className={classnames(css.bg, css[color])} />
			<StripsVertical color={color} />
			<StripsHorizontal color={color} />
			<Content className={contentClass}>{children}</Content>
		</div>
	);
};

const Content: FC<PropsWithChildren<{ className?: string }>> = React.memo(
	({ className, children }) => {
		return <div className={className}>{children}</div>;
	}
);

const StripsVertical: FC<{ color: string }> = React.memo(({ color }) => {
	const className = classnames(css.stripV, css[color]);
	return (
		<div>
			<div className={className} />
			<div className={className} />
			<div className={className} />
			<div className={className} />
			<div className={className} />
		</div>
	);
});

const StripsHorizontal: FC<{ color: string }> = React.memo(({ color }) => {
	const className = classnames(css.stripH, css[color]);
	return (
		<div>
			<div className={className} />
			<div className={className} />
			<div className={className} />
			<div className={className} />
		</div>
	);
});
