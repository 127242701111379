import css from './IconicProjectsRegular.module.scss';
import classnames from 'classnames';
import type { IconicProjectsProps } from '@components/IconicProjects/IconicProjects';
import React, {
	FC,
	MutableRefObject,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import Link from 'next/link';
import { Flex, Box, Tag, Icon, SquareArrowLink } from '@core';
import { useScrollWithEase } from '@hooks';
import { ViewportContext } from '@context';
import { normalize } from '@utils';
import { ProjectPreview } from '@components/ProjectsPreviews/components/ProjectPreview/ProjectPreview';
import Image from 'next/image';
import useIntersectionObserver from '@hooks/useIntersectionObserver';

export const IconicProjectsRegular: FC<IconicProjectsProps> = ({ aside, items }) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const picEntry = useIntersectionObserver(ref, {
		threshold: 0,
		rootMargin: '0px 0px -99.9% 0px',
		freezeOnceVisible: false,
	});

	useEffect(() => {
		if (ref?.current) {
			ref.current.classList.toggle(css.visibleBg, !!picEntry?.isIntersecting);
		}
	}, [picEntry?.isIntersecting]);

	const entry = useIntersectionObserver(ref, {
		threshold: 0.25,
		freezeOnceVisible: true,
	});

	useEffect(() => {
		if (ref?.current && entry?.isIntersecting) {
			ref.current.classList.add(css.visible);
		}
	}, [entry?.isIntersecting]);

	return (
		<Flex className={classnames(css.module)} ref={ref}>
			<Strips ref={ref} />
			<aside className={css.aside}>
				{aside?.bg && (
					<picture className={css.asideBg}>
						<Image src={aside?.bg?.src} alt={aside?.bg?.alt} layout="fill" />
					</picture>
				)}
				<Box className={css.asideInner}>
					<Tag className={css.asideTitle} type="h3" isHTML={true} content={aside?.title} />
					{aside?.url && <SquareArrowLink url={aside?.url} className={css.asideUrl} />}
				</Box>
			</aside>
			<Box className={css.content}>
				{items?.map((project, i) => (
					<ProjectPreview key={project.title} className={css.preview} num={i + 1} {...project} />
				))}
			</Box>
		</Flex>
	);
};

export const Strips = React.memo(
	React.forwardRef<HTMLElement, any>(({}, ref) => {
		const { vh } = useContext(ViewportContext);
		const [seek, setSeek] = useState(0);

		const onScroll = useCallback(
			(current: number, height: number) => {
				const start = height;
				const end = current - vh;
				const absolute = 1 - end / start;

				setSeek(normalize(absolute, 1.25, 0.75, true));
			},
			[vh]
		);

		useScrollWithEase(ref, onScroll, {
			ease: 0.25,
			edge: 'bottom',
		});

		useEffect(() => {
			const node = (ref as MutableRefObject<HTMLElement | null>)?.current;
			const strips = node?.querySelectorAll<HTMLDivElement>(`.${css.strip}`);

			if (strips && strips[0]) {
				strips[0].style.transform = `translate3d(${-5 * seek}%, 0, 0)`;
			}
			if (strips && strips[1]) {
				strips[1].style.transform = `translate3d(${-15 * seek}%, 0, 0)`;
			}
			if (strips && strips[2]) {
				strips[2].style.transform = `translate3d(${-25 * seek}%, 0, 0)`;
			}
		}, [ref, seek]);

		return (
			<>
				<Box className={css.strip} />
				<Box className={css.strip} />
				<Box className={css.strip} />
			</>
		);
	})
);
