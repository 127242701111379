import css from '../ValuablessFeed.module.scss';
import React, { FC, memo, useEffect, useRef } from 'react';
import { Box, Tag, Text, RawHtml } from '@core';
import Image from 'next/image';
import useIntersectionObserver from '@hooks/useIntersectionObserver';

export type ValuablessItemProps = {
	title?: string;
	text?: string;
	icon?: Picture;
};

export const ValuablessItem: FC<ValuablessItemProps> = memo(({ title, text, icon }) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const entry = useIntersectionObserver(ref, {
		threshold: 0.5,
		freezeOnceVisible: true,
	});

	useEffect(() => {
		if (ref?.current && entry?.isIntersecting) {
			ref.current.classList.add(css.itemVisible);
		}
	}, [entry?.isIntersecting]);

	return (
		<Box ref={ref} className={css.item}>
			<Box className={css.itemInner}>
				<Tag className={css.itemTitle} type="h3" isHTML={true} content={title} />
				<Text className={css.itemText} size="sm" color="gray">
					<RawHtml content={text} />
				</Text>
				{icon?.src && (
					<Box className={css.itemIcon}>
						<Image src={icon.src} alt={title} width="115" height="115" layout="intrinsic" />
					</Box>
				)}
			</Box>
		</Box>
	);
});
