import { useEffect, useState } from 'react';
import { Scroll } from '@utils';

export const useScrollPosition = () => {
	const [scrollPosition, setScrollPosition] = useState(0);
	const [prevScrollPosition, setPrevScrollPosition] = useState(0);

	useEffect(() => {
		const updatePosition = () => {
			const { top, prev } = Scroll.getData();

			setScrollPosition(top);
			setPrevScrollPosition(prev);
		};

		updatePosition();
		Scroll.addListener(updatePosition);

		return () => {
			Scroll.removeListener(updatePosition);
		};
	}, []);

	return [scrollPosition, prevScrollPosition];
};
