import { AnchorHTMLAttributes } from 'react';

export interface HeaderLink {
	text: string;
	url?: string;
	path?: string;
	props?: AnchorHTMLAttributes<HTMLAnchorElement>;
	nested?: Array<HeaderLink>;
}

export interface HeaderContats {
	phone?: string;
	email?: string;
	social: Array<HeaderLink>;
}

export interface HeaderData {
	menu: Array<HeaderLink>;
	contacts: HeaderContats;
}

export const header: HeaderData = {
	menu: [
		{
			text: 'Проекты',
			url: '/projects',
		},
		{
			text: 'Деятельность',
			url: '/general-contractor',
			nested: [
				{
					text: 'Генеральный подряд',
					url: '/general-contractor',
				},
				{
					text: 'Нулевой цикл',
					url: '/activities/zero-cycle',
				},
				{
					text: 'Генеральное проектирование',
					url: '/activities/general-design',
				},
				{
					text: 'EPC-контракт',
					url: '/activities/epc',
				},
				{
					text: 'Охрана окружающей среды',
					url: '/zabota-ob-okruzhayushej-srede',
				},
			],
		},
		{
			text: 'Карьера',
			url: '/work-us',
			nested: [
				{
					text: 'Работа у нас',
					url: '/work-us',
				},
				{
					text: 'Жизнь в Fensma',
					url: '/career/corp',
				},
				{
					text: 'Профессионалам',
					url: '/career/prof',
				},
				{
					text: 'Студентам и выпускникам',
					url: '/career/students',
				},
				{
					text: 'Истории сотрудников',
					url: '/career/stories',
				},
				{
					text: 'Вакансии',
					url: '/career/vacancies',
				},
			],
		},
		{
			text: 'О компании',
			url: '/about-us',
			nested: [
				{
					text: 'Описание компании',
					url: '/about-us',
				},
				{
					text: 'Парк техники',
					url: '/tech',
				},
				{
					text: 'История',
					url: '/story',
				},
				{
					text: 'Отчёты',
					url: '/report',
				},
				{
					text: 'Партнёры',
					url: '/reviews',
				},
				{
					text: 'Контролируемое качество',
					url: '/control',
				},
			],
		},
		{
			text: 'Переспективы',
			url: '/bim-modelirovanie',
			nested: [
				{
					text: 'Программа развития',
					url: '/perspectives/program',
				},
				{
					text: 'BIM-технологии',
					url: '/bim-modelirovanie',
				},
			],
		},
		{
			text: 'Новости',
			url: '/events',
		},
		{
			text: 'Контакты',
			url: '/contacts',
		},
	],
	contacts: {
		phone: '+7 (495) 780-48-14',
		email: 'info@fensma.ru',
		social: [
			{
				text: 'telegram',
				url: 'https://t.me/rdclr_home',
			},
			{
				text: 'vkontakte',
				url: 'https://vk.com/redcollar',
			},
		],
	},
};
