import css from './ContactsLinks.module.scss';
import classnames from 'classnames';
import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { Section, Container, Box, Flex, LinkTag } from '@core';
import useIntersectionObserver from '@hooks/useIntersectionObserver';

interface LinkProps {
	label?: string;
	url?: string;
}

interface ContactsLinkProps {
	caption?: string;
	links?: Array<LinkProps>;
}

interface ContactsLinksProps {
	items?: Array<ContactsLinkProps>;
	startScreen?: boolean;
}

export const ContactsLinks: FC<ContactsLinksProps> = React.memo(({ items = [], startScreen }) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const entry = useIntersectionObserver(ref, {
		threshold: 0.05,
		freezeOnceVisible: true,
	});

	const [shown, setShown] = useState(false);

	useEffect(() => {
		if (startScreen || entry?.isIntersecting) {
			setTimeout(() => setShown(true), 10);
		}
	}, [startScreen, entry?.isIntersecting]);

	return (
		<Section ref={ref} className={classnames(css.section, { [css.isShown]: shown })}>
			<Container>
				<Flex className={css.feed}>
					{items.map((item, i) => (
						<ContactsLinksItem key={`contacts-links-${i}-${item.caption}`} {...item} />
					))}
				</Flex>
			</Container>
		</Section>
	);
});

export const ContactsLinksItem: FC<ContactsLinkProps> = ({ caption, links = [] }) => {
	return (
		<Box className={css.item}>
			<div className={css.itemInner}>
				<div className={css.itemCaption}>{caption}</div>
				{links.length > 0 && (
					<Box className={css.itemLinks}>
						{links.map((item, i) => {
							return item.url && item.label ? (
								<Fragment key={`contacts-links-${i}-${item.label}`}>
									<LinkTag href={item.url}>{item.label}</LinkTag>
									<>{i !== links.length - 1 ? ', ' : ''}</>
								</Fragment>
							) : null;
						})}
					</Box>
				)}
			</div>
		</Box>
	);
};
