import css from './Announce.module.scss';
import classnames from 'classnames';
import React, { FC, useContext, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { ViewportContext } from '@context';
import { RawHtml, Tag } from '@core';

export interface AnnounceProps {
	title?: string;
	text?: string;
	date?: string;
	url?: string;
	picture?: Picture;
	className?: string;
}

export const Announce: FC<AnnounceProps> = React.memo(
	({ picture, title, text, url, className }) => {
		const [element, setElement] = useState<HTMLAnchorElement | null>(null);

		const { bp } = useContext(ViewportContext);
		const isMob = bp === 'xs';

		const entity = (
			<a ref={setElement} className={classnames(className, css.announce)}>
				{picture && (
					<picture className={css.picture}>
						<Image
							src={picture.src}
							alt={picture.alt}
							width={picture.width}
							height={picture.height}
							layout="intrinsic"
						/>
					</picture>
				)}
				{title && <Tag className={css.title} content={title} type="h3" isHTML={true} />}
				{text && <RawHtml className={css.text} content={text} />}
			</a>
		);

		return url ? <Link href={url}>{entity}</Link> : entity;
	}
);
