import css from './Tag.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { RawHtml } from '@core';
import { Header } from '@core/Tag/components/Header/Header';
import { Property } from '@core/Tag/components/Property/Property';
import { Blockquote } from '@core/Tag/components/Blockquote/Blockquote';
import { Sentence } from '@core/Tag/components/Sentence/Sentence';

export type PrimitiveKeys =
	| 'h1'
	| 'h2'
	| 'h3'
	| 'h4'
	| 'h5'
	| 'h6'
	| 'p'
	| 'ul'
	| 'ol'
	| 'li'
	| 'blockquote'
	| 'html';

export type SpecificPrimitivesKeys = 'header' | 'property' | 'blockquote' | 'sentence';

interface TagProps extends HTMLAttributes<HTMLElement> {
	type: PrimitiveKeys | SpecificPrimitivesKeys;
	content?: string;
	className?: string;
	isDiv?: boolean;
	isSpan?: boolean;
	isHTML?: boolean;
	visualType?: PrimitiveKeys | SpecificPrimitivesKeys;
}

const specific: Record<SpecificPrimitivesKeys, FC<any>> = {
	header: Header,
	property: Property,
	blockquote: Blockquote,
	sentence: Sentence,
};

export const Tag: FC<PropsWithChildren<TagProps>> = (props) => {
	const { type, className, style, isDiv, isSpan, isHTML, visualType, content, children } = props;

	if (type in specific) {
		const SpecificChild = specific[type as SpecificPrimitivesKeys];
		return <SpecificChild {...props} />;
	}

	if (type === 'html') {
		return <RawHtml className={className}>{content || children}</RawHtml>;
	}

	let Tag = type as keyof JSX.IntrinsicElements;
	let classes = classnames(visualType || type, className, css[type]);

	if (isDiv) {
		Tag = 'div';
	}

	if (isSpan) {
		Tag = 'div';
	}

	if (isHTML && content) {
		return <Tag className={classes} style={style} dangerouslySetInnerHTML={{ __html: content }} />;
	}

	return (
		<Tag className={classes} style={style}>
			{content || children}
		</Tag>
	);
};
