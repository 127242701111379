export const createRandomGetter = <T>(array: T[]): (() => T) => {
	let copy = array.slice(0);

	return () => {
		if (copy.length < 1) {
			copy = array.slice(0);
		}

		const index = Math.floor(Math.random() * copy.length);
		const item = copy[index];

		copy.splice(index, 1);

		return item;
	};
};

export const mapToArray = <K, V>(map: Map<K, V>) => {
	const array: Array<V | undefined> = [];

	Array.from(map.keys()).forEach((key) => {
		array.push(map.get(key));
	});

	return array;
};

export const findLastIndex = <T>(
	array: Array<T>,
	predicate: (value: T, index: number, obj: T[]) => boolean
): number => {
	let l = array.length;
	while (l--) {
		if (predicate(array[l], l, array)) return l;
	}
	return -1;
};

type ComparatorType = (a: number, b: number) => number;

export function ascending(a: number, b: number) {
	return a == null || b == null ? NaN : a < b ? -1 : a > b ? 1 : a >= b ? 0 : NaN;
}

export function descending(a: number, b: number) {
	return a == null || b == null ? NaN : b < a ? -1 : b > a ? 1 : b >= a ? 0 : NaN;
}

export function range(start: number, stop?: number, step: number = 1) {
	if (!stop) {
		stop = start;
		start = 0;
	}

	let i = -1;
	let n = Math.max(0, Math.ceil((stop - start) / step)) | 0;
	const range = new Array(n);

	while (++i < n) {
		range[i] = start + i * step;
	}

	return range;
}

export const bisector = (f: any) => {
	let compare1: ComparatorType, compare2: ComparatorType, delta: any;

	if (f.length !== 2) {
		compare1 = ascending;
		compare2 = (d, x) => ascending(f(d), x);
		delta = (d: number, x: number) => f(d) - x;
	} else {
		compare1 = f === ascending || f === descending ? f : () => 0;
		compare2 = f;
		delta = f;
	}

	function left(a: number[], x: number, lo = 0, hi = a.length) {
		if (lo < hi) {
			if (compare1(x, x) !== 0) return hi;
			do {
				const mid = (lo + hi) >>> 1;
				if (compare2(a[mid], x) < 0) lo = mid + 1;
				else hi = mid;
			} while (lo < hi);
		}
		return lo;
	}

	function right(a: number[], x: number, lo = 0, hi = a.length) {
		if (lo < hi) {
			if (compare1(x, x) !== 0) return hi;
			do {
				const mid = (lo + hi) >>> 1;
				if (compare2(a[mid], x) <= 0) lo = mid + 1;
				else hi = mid;
			} while (lo < hi);
		}
		return lo;
	}

	function center(a: number[], x: number, lo = 0, hi = a.length) {
		const i = left(a, x, lo, hi - 1);
		return i > lo && delta(a[i - 1], x) > -delta(a[i], x) ? i - 1 : i;
	}

	return { left, center, right };
};
