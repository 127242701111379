import css from '../TechPreamble.module.scss';
import type { TechPreambleProps } from '@components/TechPreamble/TechPreamble';
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Container, Section, Box, Tag, TickerText } from '@core';
import { useScrollWithEase } from '@hooks';
import { ViewportContext } from '@context';
import classnames from 'classnames';

const overlap = 0.1;

export const TechPreambleRegular: FC<TechPreambleProps> = ({ text, sentence, ticker }) => {
	const justifyByCenter = !!sentence;

	const ref = useRef<HTMLDivElement | null>(null);
	const [seek, setSeek] = useState(0);

	const { vh } = useContext(ViewportContext);
	const scrollWay = Math.max((ticker?.length || 1) * 20, 100);

	const onScroll = useCallback(
		(current: number) => {
			const height = (scrollWay / 100) * vh;
			const start = height - vh * (1 - overlap * 2);
			const end = current - vh;
			const absolute = 1 - end / start;
			const relative = Math.max(Math.min(absolute, 1 / (1 - overlap * 3)), 0);

			setSeek(relative);
		},
		[vh, scrollWay]
	);

	useScrollWithEase(ref, onScroll, {
		ease: 0.25,
		edge: 'bottom',
	});

	const [sticky, setSticky] = useState<HTMLDivElement | null>(null);
	const [stickyTop, setStickyTop] = useState(0);

	useEffect(() => {
		if (sticky) {
			const height = sticky.offsetHeight;
			const diff = Math.max(vh - height, 0);
			setStickyTop(diff / 2);
		}
	}, [vh, sticky]);

	return (
		<Section className={classnames(css.outer, { [css.moreText]: justifyByCenter })}>
			<Box
				ref={ref}
				className={css.inner}
				style={
					justifyByCenter
						? {
								height: `${scrollWay}vh`,
								minHeight: `100vh`,
						  }
						: {}
				}>
				<Box
					ref={setSticky}
					className={css.sticky}
					style={{
						top: stickyTop,
						height: 'auto',
					}}>
					<Container className={css.content}>
						{text && (
							<Tag
								className={classnames(css.title, 'indent-text')}
								type="h2"
								content={text}
								isHTML={true}
							/>
						)}
						{sentence && (
							<Tag
								type="sentence"
								className={classnames(css.sentence, 'indent-margin')}
								content={sentence}
							/>
						)}
						<TickerText seek={seek} overlap={overlap} text={ticker} className={css.ticker} />
					</Container>
				</Box>
			</Box>
		</Section>
	);
};
