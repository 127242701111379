import vs from './glsl/waves.vert.glsl';
import fs from './glsl/waves.frag.glsl';
import m4 from './utils/m4';
import { getVpHelpers } from '@utils';

type WavesOptionsType = {
	speed: number;
	hue: number;
	hueVariation: number;
	gradient: number;
	density: number;
	displacement: number;
};

const [helper] = getVpHelpers();

export class WavesCanvas {
	readonly canvas: HTMLCanvasElement | null = null;
	private parameters: WavesOptionsType;

	private _mx: number = 0.5;
	private _my: number = 0.5;

	private raf: number = 0;
	private time: number = 0;
	private destroyed: boolean = false;

	private gl: WebGLRenderingContext | null = null;

	private uTime: WebGLUniformLocation | null = null;
	private uHue: WebGLUniformLocation | null = null;
	private uHueVariation: WebGLUniformLocation | null = null;
	private uDensity: WebGLUniformLocation | null = null;
	private uDisplacement: WebGLUniformLocation | null = null;
	private uMousePosition: WebGLUniformLocation | null = null;
	private uMatrix: WebGLUniformLocation | null = null;

	private program: WebGLProgram | null = null;

	private vertexBuffer: WebGLBuffer | null = null;
	private verticesCount: number = 0;

	private matrix: Float32Array = new Float32Array();

	constructor(canvas: HTMLCanvasElement, options?: WavesOptionsType) {
		this.canvas = canvas;
		this.parameters = Object.assign(
			{},
			{
				speed: 0.085,
				hue: 0.56,
				hueVariation: 0.0,
				gradient: 0.5,
				density: 0.0,
				displacement: 0.25,
			},
			options
		);

		this.init = this.init.bind(this);
		this.destroy = this.destroy.bind(this);
		this.resize = this.resize.bind(this);
		this.drawScene = this.drawScene.bind(this);
		this.initVertexBuffers = this.initVertexBuffers.bind(this);
		this.createProgram = this.createProgram.bind(this);
	}

	init() {
		// Init WebGL context
		if (!this.canvas) {
			console.warn('Canvas element not found');
			return;
		}

		this.gl = this.canvas.getContext('webgl');
		if (!this.gl) {
			console.warn('Failed to get the rendering context for WebGL');
			return;
		}

		// On resize
		this.resize();
		window.addEventListener('resize', this.resize);

		// Init shaders
		if (!this.createProgram(vs, fs)) {
			console.log('Failed to intialize shaders.');
			return;
		}

		// Write the positions of vertices to a vertex shader
		this.verticesCount = this.initVertexBuffers();
		if (this.verticesCount < 0) {
			console.log('Failed to set the positions of the vertices');
			return;
		}

		// Clear canvas
		this.gl.clearColor(0.0, 0.0, 0.0, 1.0);
		this.gl.clear(this.gl.COLOR_BUFFER_BIT);

		// Draw
		this.drawScene();
	}

	destroy() {
		this.raf && window.cancelAnimationFrame(this.raf);

		if (!this.gl) return;

		this.gl.canvas.width = 1;
		this.gl.canvas.height = 1;
		this.gl.clear(this.gl.COLOR_BUFFER_BIT);

		this.gl.useProgram(null);

		if (this.vertexBuffer) {
			this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
			this.gl.bufferData(this.gl.ARRAY_BUFFER, 1, this.gl.STATIC_DRAW);
			this.gl.deleteBuffer(this.vertexBuffer);
		}

		if (this.program) {
			this.gl.deleteProgram(this.program);
		}
	}

	resize() {
		if (!this.gl || !this.gl.canvas || this.destroyed) return;

		const vw = window.innerWidth;
		const vh = helper ? helper.offsetHeight : document.documentElement.clientHeight;

		if (this.gl.canvas.width !== vw || this.gl.canvas.height !== vh) {
			this.gl.canvas.width = vw;
			this.gl.canvas.height = vh;

			this.gl.viewport(0, 0, this.gl.canvas.width, this.gl.canvas.height);
		}

		this.matrix = m4.orthographic(0, this.gl.canvas.width, this.gl.canvas.height, 0, -1, 1);
		this.matrix = m4.translate(this.matrix, 2, 2, 0);

		const tw = Math.min(800, this.gl.canvas.width);
		this.matrix = m4.scale(this.matrix, tw, tw * 0.5625, 1);
	}

	drawScene() {
		if (!this.gl || this.destroyed) return;

		// Clear canvas
		this.gl.clear(this.gl.COLOR_BUFFER_BIT);

		// Resize
		this.resize();

		// Set uniform value
		if (this.uTime) {
			this.gl.uniform1f(this.uTime, this.time);
		}
		if (this.uMousePosition) {
			this.gl.uniform2f(this.uMousePosition, this._mx, this._my);
		}

		// Matrix
		if (this.uMatrix) {
			this.gl.uniformMatrix4fv(this.uMatrix, false, this.matrix);
		}

		// Draw
		this.gl.drawArrays(this.gl.TRIANGLES, 0, this.verticesCount);

		// Call drawScene again in the next browser repaint
		this.time += this.parameters.speed;
		this.raf = window.requestAnimationFrame(() => this.drawScene());
	}

	initVertexBuffers() {
		if (!this.gl || !this.program) return 0;

		// Vertices
		const dim = 2;
		const vertices = new Float32Array([
			4.0, 4.0, -4.0, 4.0, -4.0, -4.0, -4.0, -4.0, 4.0, -4.0, 4.0, 4.0,
		]);
		// new Float32Array([-2, 2, 2, 2, 2, -2, -2, 2, 2, -2, -2, -2]);

		// Create a buffer object
		this.vertexBuffer = this.gl.createBuffer();
		if (!this.vertexBuffer) {
			console.warn('Failed to create the buffer object');
			return -1;
		}
		this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
		this.gl.bufferData(this.gl.ARRAY_BUFFER, vertices, this.gl.STATIC_DRAW);

		// Assign the vertices in buffer object to a_Position variable
		const a_Position = this.gl.getAttribLocation(this.program, 'a_Position');
		if (a_Position < 0) {
			console.warn('Failed to get the storage location of a_Position');
			return -1;
		}
		this.gl.vertexAttribPointer(a_Position, dim, this.gl.FLOAT, false, 0, 0);
		this.gl.enableVertexAttribArray(a_Position);

		// Assign texture coordinates in buffer
		const a_Uv = this.gl.getAttribLocation(this.program, 'a_Uv');
		if (a_Uv < 0) {
			console.warn('Failed to get the storage location of a_Uv');
			return -1;
		}
		this.gl.vertexAttribPointer(a_Uv, dim, this.gl.FLOAT, false, 0, 0);
		this.gl.enableVertexAttribArray(a_Uv);

		// Assign the uniforms
		this.uTime = this.gl.getUniformLocation(this.program, 'uTime');
		this.uHue = this.gl.getUniformLocation(this.program, 'uHue');
		this.uHueVariation = this.gl.getUniformLocation(this.program, 'uHueVariation');
		this.uDensity = this.gl.getUniformLocation(this.program, 'uDensity');
		this.uDisplacement = this.gl.getUniformLocation(this.program, 'uDisplacement');
		this.uMousePosition = this.gl.getUniformLocation(this.program, 'uMousePosition');
		this.uMatrix = this.gl.getUniformLocation(this.program, 'u_matrix');

		if (this.uTime) this.gl.uniform1f(this.uTime, 0);
		if (this.uHue) this.gl.uniform1f(this.uHue, this.parameters.hue);
		if (this.uHueVariation) this.gl.uniform1f(this.uHueVariation, this.parameters.hueVariation);
		if (this.uDensity) this.gl.uniform1f(this.uDensity, this.parameters.density);
		if (this.uDisplacement) this.gl.uniform1f(this.uDisplacement, this.parameters.displacement);
		if (this.uMousePosition) this.gl.uniform2f(this.uMousePosition, this._mx, this._my);
		if (this.uMatrix) this.gl.uniformMatrix4fv(this.uMatrix, false, this.matrix);

		// Return number of vertices
		return vertices.length / dim;
	}

	createProgram(vs: string, fs: string) {
		if (!this.gl) return;

		// Compile shaders
		const vertexShader = this.compileShader(this.gl, vs, this.gl.VERTEX_SHADER);
		const fragmentShader = this.compileShader(this.gl, fs, this.gl.FRAGMENT_SHADER);

		// Create program
		const glProgram = this.gl.createProgram();

		// Attach and link shaders to the program
		if (!glProgram || !vertexShader || !fragmentShader) return;
		this.gl.attachShader(glProgram, vertexShader);
		this.gl.attachShader(glProgram, fragmentShader);
		this.gl.linkProgram(glProgram);

		if (!this.gl.getProgramParameter(glProgram, this.gl.LINK_STATUS)) {
			console.warn(this.gl.getProgramInfoLog(glProgram));
			return false;
		}

		// Use program
		this.gl.useProgram(glProgram);
		this.program = glProgram;

		return true;
	}

	compileShader(gl: WebGLRenderingContext, src: string, type: GLenum) {
		const shader = gl.createShader(type);

		if (!shader) {
			console.warn('Create shader error');
			return;
		}

		gl.shaderSource(shader, src);
		gl.compileShader(shader);

		if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
			console.warn('Error compiling shader: ' + gl.getShaderInfoLog(shader));
			return;
		}

		return shader;
	}

	set mx(value: number) {
		this._mx = value;
	}

	set my(value: number) {
		this._my = value;
	}
}
