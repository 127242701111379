import css from './BenefitsFeed.module.scss';
import classnames from 'classnames';
import React, { FC, useEffect, useRef } from 'react';
import { Section, Container, Tag, Box, PrimitiveKeys } from '@core';
import { BenefitsItem } from '@components/BenefitsFeed/component/BenefitsItem';
import useIntersectionObserver from '@hooks/useIntersectionObserver';

export type BenefitsItemType = {
	title?: string;
	text?: string;
	content?: Array<{ type: PrimitiveKeys; content?: string }> | string;
	size?: 'sm' | 'md' | 'lg';
	color?: 'dark' | 'gray' | 'white' | 'inherit';
};

export interface BenefitsFeedProps {
	title?: string;
	items?: Array<BenefitsItemType>;
	style?: 'regular' | 'gray' | 'grayWithHover';
	textSize?: 'sm' | 'md' | 'lg';
	textColor?: 'dark' | 'gray' | 'white' | 'inherit';
	className?: string;
	classNameTitle?: string;
	classNameFeed?: string;
	EntityComponent?: FC<any>;
}

export const BenefitsFeed: FC<BenefitsFeedProps> = React.memo(
	({
		title,
		items,
		className,
		classNameTitle,
		classNameFeed,
		style = 'regular',
		textSize,
		textColor,
		EntityComponent = BenefitsItem,
	}) => {
		const ref = useRef<HTMLDivElement | null>(null);

		const entry = useIntersectionObserver(ref, {
			threshold: 0.25,
			freezeOnceVisible: true,
		});

		useEffect(() => {
			if (ref?.current && entry?.isIntersecting) {
				ref.current.classList.add(`is-visible`);
			}
		}, [entry?.isIntersecting]);

		return (
			<Section ref={ref} className={classnames(css.module, css[style], className)}>
				<Container>
					{title && (
						<Tag
							className={classnames(classNameTitle, css.title, 'indent-margin')}
							type="h1"
							content={title}
							isHTML={true}
						/>
					)}
					<Box className={classnames(classNameFeed, 'indent-margin')}>
						{items?.map((item, i) => (
							<EntityComponent
								key={item.title + '-' + i}
								index={i}
								size={textSize}
								color={textColor}
								{...item}
							/>
						))}
					</Box>
				</Container>
			</Section>
		);
	}
);
