import css from '../StepsSlider.module.scss';
import classnames from 'classnames';
import fastdom from 'fastdom';
import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@core';
import { StepsSliderProps, StepsSliderContext } from '@components/StepsSlider/StepsSlider';
import { padForNum, splitByLines } from '@utils';

export interface StepsSliderContentProps {
	items?: StepsSliderProps['items'];
}

export const StepsSliderContent: FC<StepsSliderContentProps> = React.memo(({ items = [] }) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const refText = useRef<HTMLDivElement | null>(null);

	const { stages } = useContext(StepsSliderContext);

	const [texts, setTexts] = useState<Array<string[]>>([]);

	useEffect(() => {
		let mount = true;
		const box = refText?.current;

		if (!box) return;

		const onResize = () => {
			if (mount) {
				const texts = items.map((slide) => splitByLines(slide?.text || '', box));
				setTexts(texts);
			}
		};

		const resizeObserver = new ResizeObserver(onResize);

		onResize();
		window.onload = onResize;

		resizeObserver.observe(box);

		return () => {
			mount = false;
			resizeObserver.unobserve(box);
			resizeObserver.disconnect();
		};
	}, [refText, items]);

	const comb = stages.reduce((acc, stage, i) => {
		const ev = i % 2 === 0;
		acc += ev ? stage : 0;
		return acc;
	}, 0);

	useEffect(() => {
		fastdom.mutate(() => {
			const wrap = ref.current;
			if (!wrap) return;

			wrap.style.setProperty('--position', `${comb * -100}%`);
			wrap.style.setProperty('--odometerA', `${comb * -100}%`);
			wrap.style.setProperty('--odometerB', `${comb * 1.3 * -100}%`);
			wrap.style.setProperty('--odometerC', `${comb * 1.8 * -100}%`);
			wrap.style.setProperty('--odometerD', `${comb * 2.3 * -100}%`);
		});
	}, [comb]);

	return (
		<Box ref={ref} className={css.layout}>
			<Box className={css.left}>
				<StepsSliderDigits items={items} />
			</Box>
			<Box className={css.right}>
				<Box className={css.text}>
					<Box className={css.textHelper} ref={refText} />
					<StepsSliderTexts texts={texts} />
				</Box>
				<StepsSliderCounter items={items} />
			</Box>
		</Box>
	);
});

export const StepsSliderDigits: FC<StepsSliderContentProps> = React.memo(({ items = [] }) => {
	const columns = useMemo(
		() =>
			items.reduce(
				(acc, item) => {
					if (item?.odometer) {
						padForNum(item.odometer, '--')
							.split('')
							.forEach((d, i) => {
								acc[i].push(d === '-' ? null : d);
							});
					}
					return acc;
				},
				[[], [], [], []] as Array<Array<string | null>>
			),
		[items]
	);

	const { stages } = useContext(StepsSliderContext);
	const interStages = stages.filter((item, i) => (i + 1) % 2 === 0);

	return (
		<Box className={classnames(css.odometerWrap, 'indent-margin')}>
			<Box className={css.odometer}>
				{columns.map((column, i) => {
					return (
						<Box key={`slide-odometer-column-${i}`} className={css.odometerC}>
							<Box>&nbsp;</Box>
							{column.map((d, j) => {
								const digit = Number(d);
								const notEmpty = d !== null && !Number.isNaN(digit);
								return (
									<Box
										key={`slide-odometer-d-${j}`}
										className={classnames(css.odometerD, {
											[css.odometerAccent]: notEmpty,
										})}>
										{d !== null ? d : 0}
										<span
											style={{
												clipPath: `polygon(0 ${(1 - interStages[j]) * 100}%, 100% ${
													(1 - interStages[j]) * 100
												}%, 100% 100%, 0% 100%)`,
											}}>
											{d !== null ? d : 0}
										</span>
									</Box>
								);
							})}
						</Box>
					);
				})}
			</Box>
		</Box>
	);
});

export const StepsSliderTexts: FC<{ texts: Array<string[]> }> = React.memo(({ texts }) => {
	const textGroupsByLines = useMemo(() => {
		const result = [];

		const maxLines = texts.reduce((acc, current, i) => {
			if (acc < current.length) {
				acc = current.length;
			}
			return acc;
		}, 0);

		for (let i = 0; i < maxLines; i++) {
			result.push(texts.map((text) => text[i] || ''));
		}

		return result;
	}, [texts]);

	return (
		<>
			{textGroupsByLines.map((line, i) => {
				return (
					<Box key={`group-${i}`} className={css.textLine}>
						<Box className={css.textTrack}>
							<div>&nbsp;</div>
							{line.map((item, j) => (
								<div key={`group-${i}-text-${j}-${item}`}>{item.length ? item : <>&nbsp;</>}</div>
							))}
						</Box>
					</Box>
				);
			})}
		</>
	);
});

export const StepsSliderCounter: FC<StepsSliderContentProps> = React.memo(({ items = [] }) => {
	return (
		<Box className={css.counter}>
			<div>0</div>
			<Box className={css.counterTrack}>
				<Box>0</Box>
				{items.map((_, i) => (
					<Box key={`slide-counter-${i}`}>{i + 1}</Box>
				))}
			</Box>
			<span>&mdash;</span>
			<span>{items.length}</span>
		</Box>
	);
});
