import React, { FC, useContext } from 'react';
import { ViewportContext } from '@context';
import { HomeCareerRegular } from '@components/HomeCareer/components/HomeCareerRegular/HomeCareerRegular';
import { HomeCareerMobile } from '@components/HomeCareer/components/HomeCareerMobile/HomeCareerMobile';

export interface HomeCareerProps {
	title?: string;
	text?: string;
	url?: string;
	cover?: Picture;
	video?: Video;
}

export const HomeCareer: FC<HomeCareerProps> = React.memo((props) => {
	const { bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	return (
		<>
			{isMob && <HomeCareerMobile {...props} />}
			{!isMob && <HomeCareerRegular {...props} />}
		</>
	);
});
