import { padForNum } from '@utils';

export type DateLangType = 'ru' | 'en';

type EuFormatOptions = {
	withTime?: boolean;
	withMonthName?: boolean;
	lang?: DateLangType;
};

type ReturnShortDate = {
	day?: number;
	dayFormatted?: string;
	month?: string;
	datetime?: string;
	concat?: string;
};

type DateDictType = {
	months: Array<string>;
	monthsShort: Array<string>;
};

const dict: Partial<Record<DateLangType, DateDictType>> = {
	ru: {
		months: [
			'января',
			'февраля',
			'марта',
			'апреля',
			'мая',
			'июня',
			'июля',
			'августа',
			'сентября',
			'октября',
			'ноября',
			'декабря',
		],
		monthsShort: [
			'янв.',
			'фев.',
			'мар.',
			'апр.',
			'мая',
			'июн.',
			'июл.',
			'авг.',
			'сен.',
			'окт.',
			'ноя.',
			'дек.',
		],
	},
	en: {
		months: [
			'january',
			'february',
			'march',
			'april',
			'may',
			'june',
			'july',
			'august',
			'september',
			'october',
			'november',
			'december',
		],
		monthsShort: [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec',
		],
	},
};

export const isValidDate = (d: any) =>
	// @ts-ignore
	d instanceof Date && !isNaN(d);

export const euFormatDate = (
	inputDate: string | Date,
	{ withTime, withMonthName, lang = 'ru' }: EuFormatOptions
): string | undefined => {
	if (!inputDate) {
		return undefined;
	}

	const date = new Date(inputDate);
	const year = date.getFullYear();
	const month = date.getMonth();
	const day = date.getDate();
	const time = withTime
		? ', ' + padForNum(date.getHours(), '00') + ':' + padForNum(date.getMinutes(), '00')
		: '';

	return withMonthName
		? `${day} ${dict[lang]?.monthsShort[month]} ${year}`
		: `${padForNum(day, '00')}.${padForNum(month + 1, '00')}.${year}${time}`;
};

export const fullFormatDate = (
	inputDate: undefined | string,
	lang: DateLangType = 'ru',
	shortMonth?: boolean
): ReturnShortDate => {
	if (!inputDate) {
		return {};
	}

	inputDate = inputDate.replace(' ', 'T');
	const date = new Date(inputDate);
	if (!isValidDate(date)) return {};

	const month = shortMonth
		? dict[lang]?.monthsShort[date.getMonth()].replace('.', '')
		: dict[lang]?.months[date.getMonth()];
	const day = date.getDate();
	const dayFormatted = padForNum(day, '00');
	const year = date.getFullYear();

	const datetime = inputDate.replace(' ', 'T');

	return {
		day,
		dayFormatted,
		month,
		datetime,
		concat: `${dayFormatted} ${month} ${year}`,
	};
};

export const shortFormatDate = (
	inputDate: undefined | string,
	lang: DateLangType = 'ru'
): ReturnShortDate => {
	if (!inputDate) {
		return {};
	}

	inputDate = inputDate.replace(' ', 'T');
	const date = new Date(inputDate);
	if (!isValidDate(date)) return {};

	const month = dict[lang]?.monthsShort[date.getMonth()] || '';
	const day = date.getDate();
	const dayFormatted = padForNum(day, '00');

	const datetime = inputDate.replace(' ', 'T');

	return {
		day,
		dayFormatted,
		month,
		datetime,
		concat: `${dayFormatted} ${month}`,
	};
};

export const convertHMS = (value?: number | string) => {
	if (!value) return `00:00`;

	if (typeof value === 'string') {
		value = parseInt(value, 10);
	}

	const hours = Math.floor((value % (60 * 60 * 24)) / (60 * 60));
	const minutes = Math.floor((value % (60 * 60)) / 60);
	const seconds = Math.floor(value % 60);

	return `${hours ? `${padForNum(hours, '00')}:` : ``}${padForNum(minutes, '00')}:${padForNum(
		seconds,
		'00'
	)}`;
};
