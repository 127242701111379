import React, { FC, memo } from 'react';
import { PopUp } from '@core/PopUp/PopUp';
import { FeedbackForm, FeedbackHeadline } from '@components/Feedback/Feedback';

export interface VacancyPopUpProps {
	name?: string;
	form?: {
		title?: string;
		form: FormType;
	};
	closeHandler?: (e?: MouseEvent | TouchEvent) => void;
}

export const VacancyPopUp: FC<VacancyPopUpProps> = memo(
	({ name = 'vacancy-popup', form, closeHandler }) => {
		return (
			<PopUp name={name} boxWidth="none" closeButton={true} closeHandler={closeHandler}>
				{form && <VacancyPopUpForm form={form} />}
			</PopUp>
		);
	}
);

export const VacancyPopUpForm: FC<VacancyPopUpProps> = memo(({ form: data }) => {
	return (
		<>
			<FeedbackHeadline title={data?.title} autoplay={true} />
			<FeedbackForm form={data?.form} />
		</>
	);
});
