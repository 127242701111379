import css from './ContactsMap.module.scss';
import classnames from 'classnames';
import React, { FC, useContext } from 'react';
import {
	MapObject,
	Section,
	Container,
	Box,
	Flex,
	Text,
	RawHtml,
	Tag,
	GMap,
	GMapContextProvider,
	GMapContext,
} from '@core';

interface ContactsMapProps {
	items?: Array<MapObject>;
}

export const ContactsMap: FC<ContactsMapProps> = React.memo(({ items = [] }) => {
	return (
		<GMapContextProvider>
			<Section className={css.section}>
				<ContactsMapPanel items={items} />
				<ContactsMapView items={items} />
			</Section>
		</GMapContextProvider>
	);
});

export const ContactsMapPanel: FC<ContactsMapProps> = ({ items }) => {
	const { activeObject: selected, setActiveObject: onSelect } = useContext(GMapContext);

	return (
		<Box className={css.panel}>
			<Container>
				<Flex className={css.panelFlow}>
					{items?.map((point, i) => {
						const isActive = selected?.id === point.id;
						return (
							<Box
								key={`contacts-map-panel-${i}-${point.data.title}`}
								className={classnames(css.panelCard, {
									[css.isActive]: isActive,
									[css.onlyTwo]: items.length < 3,
								})}
								onClick={() => {
									onSelect && onSelect(!isActive ? point : undefined);
								}}>
								{point.data?.title && (
									<Tag
										className={css.panelCardTitle}
										type="h4"
										isHTML={true}
										content={point.data?.title}
									/>
								)}
								{point?.data?.text && (
									<Text size="md" color="gray">
										<RawHtml content={point.data?.text} />
									</Text>
								)}
							</Box>
						);
					})}
				</Flex>
			</Container>
		</Box>
	);
};

export const ContactsMapView: FC<ContactsMapProps> = ({ items = [] }) => {
	return <GMap className={css.map} setCenterOnActive={true} objects={items} />;
};
