import css from './Intro.module.scss';
import classnames from 'classnames';
import fastdom from 'fastdom';
import type { Breadcrumb } from '@core';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Container, Section, Breadcrumbs, Tag, Box } from '@core';
import { wrapLetters } from '@utils';
import { useIsomorphicLayoutEffect, useScroll } from '@hooks';
import { PageLoaderContext } from '@context';
import { ScrollData } from '@utils/utils.performance/scroll';
import scrollLocker from '@utils/utils.scroll/ScrollLocker';

interface Props {
	title?: string;
	breadcrumbs?: Array<Breadcrumb>;
	indent?: boolean;
	shape?: ServiceType;
	isDetailPage?: boolean;
	isSimplePage?: boolean;
	isWhite?: boolean;
}

const shapes = {
	triangle: 'M0 0l16 5.333L5.334 16z',
	diamond: 'M8 0L0 8l8 8 8-8z',
	pentagon:
		'M8.252 0l3.827 3.243L16 6.36l-1.804 4.792L12.534 16l-4.94-.28-4.948-.124-1.25-4.966L0 5.707 4.168 2.92z',
	cross: 'M10.463 0H5.54v5.543H0v4.923h5.54V16h4.923v-5.534H16V5.543h-5.537z',
	ellipse:
		'M14.513 15.37c-2.115 1.716-6.612-.192-10.043-4.261C1.038 7.039-.03 2.348 2.087.63 4.202-1.086 8.699.82 12.13 4.89c3.432 4.07 4.499 8.761 2.383 10.478z',
	point:
		'M13.657 13.657a7.999 7.999 0 000-11.314 7.999 7.999 0 00-11.314 0 7.999 7.999 0 000 11.314 7.999 7.999 0 0011.314 0z',
	hex: 'M11.333 16H4.667L0 11.333V4.667L4.667 0h6.666L16 4.667v6.666z',
};

export const Intro: FC<Props> = React.memo(
	({ title, breadcrumbs, indent = true, shape, isDetailPage, isSimplePage, isWhite }) => {
		const [titleContent, setTitleContent] = useState(title);
		useEffect(
			() => setTitleContent(isSimplePage ? title : wrapLetters(title, 60) || title),
			[title, isSimplePage]
		);

		const { loadingState, setLoadingState, setReadyState } = useContext(PageLoaderContext);

		const [section, setSection] = useState<HTMLElement | null>(null);
		const [content, setContent] = useState<HTMLDivElement | null>(null);
		const [height, setHeight] = useState(0);

		const [shown, setShown] = useState(false);
		const [proceed, setProceed] = useState(false);

		useIsomorphicLayoutEffect(() => {
			fastdom.measure(() => {
				section && setHeight(section.offsetHeight);
			});
		}, [section]);

		useIsomorphicLayoutEffect(() => {
			if (content) {
				setTimeout(() => {
					setProceed(true);
					setReadyState && setReadyState(true);

					setTimeout(() => {
						setLoadingState && setLoadingState('complete');
					}, 2000);
				}, 600);

				setTimeout(() => {
					setShown(true);
				}, 1200);
			}
		}, [content]);

		const onScroll = useCallback(
			({ top: scrollTop }: ScrollData) => {
				const scrollIsLocked = scrollLocker._state;
				const seek = !scrollIsLocked ? (height - scrollTop) / height : 1;

				fastdom.mutate(() => {
					if (content) {
						content.style.opacity = seek.toFixed(2);
						content.style.transform = `translate3d(0, ${(1 - seek) * -60}%, 0)`;
					}
				});
			},
			[height, content]
		);

		useScroll(onScroll);

		const sectionClass = classnames(
			css.intro,
			css[loadingState],
			{
				[css.introSimple]: isSimplePage,
				[css.white]: isWhite,
				'is-white-section': isWhite,
				'is-shown': shown,
				'is-proceed': proceed,
			},
			'first-screen'
		);

		const titleClass = classnames(css.title, {
			h2: isDetailPage,
			'indent-text': isSimplePage && indent,
			'indent-margin': !isSimplePage && indent,
		});

		return (
			<Section ref={setSection} className={sectionClass}>
				<Container>
					{shape && (
						<div className={css.shape}>
							<svg viewBox="0 0 16 16">
								<path fillRule="nonzero" d={shapes[shape]} />
							</svg>
						</div>
					)}
					<Box ref={setContent} className={css.content}>
						<Breadcrumbs className={classnames({ 'indent-margin': indent })} items={breadcrumbs} />
						{title && <Tag type="h1" className={titleClass} content={titleContent} isHTML={true} />}
					</Box>
				</Container>
			</Section>
		);
	}
);
