import React, { FC, PropsWithChildren, useMemo } from 'react';
import Link from 'next/link';
import { Icon } from '@core/Icon/Icon';

interface LinkProps {
	href: string;
	className?: string;
	classNameOuter?: string;
	withIcon?: boolean;
}

const httpRegExp = /(http(s?)):\/\//i;
const docsRegExp = /\.(zip|ZIP|doc|DOC|docx|DOCX|pdf|PDF|jpg|JPG|jpeg|JPEG|webp|WEBP|png|PNG)$/;
const mailRegExp = /^[0-9a-z_.-]+@[0-9a-z_]+\.[a-z]{2,5}$/;

const handleNode = (item: React.ReactNode, i?: number) => {
	return typeof item === 'string' && /<.+>/g.exec(item) ? (
		<span key={item} dangerouslySetInnerHTML={{ __html: item }} />
	) : (
		<React.Fragment key={`link-child-node-${i}`}>{item}</React.Fragment>
	);
};

export const LinkTag: FC<PropsWithChildren<LinkProps>> = React.memo(
	({ href, className, classNameOuter, withIcon, children }) => {
		const { linkType, targetBlank } = useMemo(() => {
			const isTel = href.includes('tel:') || href.indexOf('+') === 0;
			const isMailto = href.includes('mailto:') || mailRegExp.test(href);
			const hasProtocol = httpRegExp.test(href);
			const hasDocumentsExt = docsRegExp.test(href);
			const isOuter = isTel || isMailto || hasProtocol || hasDocumentsExt;

			return {
				linkType: isOuter ? 'outer' : 'inner',
				targetBlank: isOuter && !(isTel || isMailto),
			};
		}, [href]);

		if (Array.isArray(children)) {
			children = children.map((item, i) => {
				return handleNode(item, i);
			});
		} else {
			children = handleNode(children);
		}

		return (
			<>
				{linkType === 'inner' && (
					<Link href={href}>
						<a className={className}>
							{children}
							{withIcon && <Icon id="link" width={32} height={32} />}
						</a>
					</Link>
				)}
				{linkType === 'outer' && (
					<a
						className={classNameOuter || className}
						href={href}
						target={targetBlank ? '_blank' : undefined}
						rel="noreferrer noopener">
						{children}
						{withIcon && <Icon id="outer-link" width={32} height={32} />}
					</a>
				)}
			</>
		);
	}
);
