import css from './SplitViewSection.module.scss';
import classnames from 'classnames';
import React, { FC } from 'react';
import { Box, Flex, RawHtml, Section, SquareArrowLink, Tag, Text } from '@core';
import Image from 'next/image';

export interface SplitViewSectionProps {
	title?: string;
	text?: string;
	url?: string;
	picture?: Picture;
	video?: Video;
}

export const SplitViewSection: FC<SplitViewSectionProps> = React.memo(
	({ title, text, url, picture }) => {
		return (
			<Section className={classnames(css.section)}>
				<Box className={css.sticky}>
					<Flex className={css.layout}>
						<Box className={css.left}>
							{picture && (
								<picture className={css.picture}>
									<Image src={picture.src} alt={picture.alt} layout="fill" quality={100} />
								</picture>
							)}
						</Box>
						<Box className={css.right}>
							<Box className={css.slide}>
								{title && <Tag className={css.title} type="h3" content={title} isHTML={true} />}
								{text && (
									<Text className={css.text} size="sm" color="gray">
										<RawHtml content={text} />
									</Text>
								)}
								{url && <SquareArrowLink url={url} className={css.arrow} />}
							</Box>
						</Box>
					</Flex>
				</Box>
			</Section>
		);
	}
);
