import css from './Input.module.scss';
import classnames from 'classnames';
import React, { FC, ComponentPropsWithoutRef, useState, useCallback } from 'react';
import { File } from '@core/Form/File/File';
import { useIMask } from 'react-imask';

export interface InputProps extends ComponentPropsWithoutRef<'input'> {
	label?: string;
	labelAsPlaceholder?: boolean;
	hasError?: boolean;
	mask?: string;
	IconLeft?: FC;
	IconRight?: FC;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
	(
		{
			type = 'text',
			label,
			labelAsPlaceholder = true,
			IconLeft,
			IconRight,
			hasError,
			className,
			mask,
			value,
			...props
		},
		forwardedRef
	) => {
		const [inputType] = useState(type);
		const [transitionClass, setTransitionClass] = useState(false);
		const [placeholderFocused, setPlaceholderFocused] = useState(false);

		const handleFocus = useCallback(
			(e) => {
				setTransitionClass(true);
				setPlaceholderFocused(true);
				props?.onFocus && props.onFocus(e);
			},
			[props]
		);

		const handleBlur = useCallback(
			(e) => {
				setPlaceholderFocused(!!e.target.value.length);
				props?.onBlur && props.onBlur(e);
			},
			[props]
		);

		const handleChange = useCallback(
			(e) => {
				if (e?.target) {
					setPlaceholderFocused(!!e.target.value.length || e.target === document.activeElement);
				}
				props?.onChange && props.onChange(e);
			},
			[props]
		);

		const { ref: maskedRef, value: maskedValue } = useIMask(
			{
				mask: mask ? mask : '',
			},
			{
				onAccept: (value, maskRef, e) => {
					handleChange(e);
				},
			}
		);

		const refCallback = useCallback(
			(node) => {
				if (!node) return;

				if (typeof forwardedRef === 'function') {
					forwardedRef(node);
				} else if (forwardedRef !== null) {
					forwardedRef.current = node;
				}

				if (labelAsPlaceholder) {
					setPlaceholderFocused(!!node.value.length);
				}

				if (mask) {
					(maskedRef as React.MutableRefObject<HTMLInputElement>).current = node;
				}
			},
			[forwardedRef, labelAsPlaceholder, mask, maskedRef]
		);

		const Tag = type === 'file' ? 'div' : 'label';

		return (
			<Tag className={classnames(css.input, { [css.hasError]: hasError })}>
				{label && (
					<div
						className={classnames(css.input__label, {
							[css.isPlaceholder]: labelAsPlaceholder,
							[css.isPlaceholderFocused]: labelAsPlaceholder && placeholderFocused,
							[css.hasTransition]: transitionClass,
						})}>
						{labelAsPlaceholder && props.placeholder ? props.placeholder : label}
					</div>
				)}
				<div className={css.input__field}>
					{IconLeft && <IconLeft />}
					<>
						{type !== 'file' && (
							<input
								{...props}
								ref={refCallback}
								type={inputType}
								className={className}
								onFocus={handleFocus}
								onBlur={handleBlur}
								onChange={handleChange}
								value={mask ? maskedValue : value}
								placeholder={
									mask ? (placeholderFocused ? mask.replace(/0/gi, '_') : '') : props.placeholder
								}
							/>
						)}
						{type === 'file' && <File ref={forwardedRef} className={className} {...props} />}
					</>
					{IconRight && <IconRight />}
				</div>
			</Tag>
		);
	}
);
