import css from './Spinner.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';

interface SpinnerProps {
	type?: 'ring';
}

export const Spinner: FC<SpinnerProps & HTMLAttributes<HTMLDivElement>> = ({
	type = 'ring',
	className,
}) => {
	return (
		<div className={classnames(className, css.spinner)}>
			<div className={css[type]} />
		</div>
	);
};
