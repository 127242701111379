import { PageData } from '@api/hooks/types';

export const generalContract: PageData = {
	meta: {
		title: 'Генеральный подряд',
		description:
			'Мы берем на себя полностью реализацию проекта «под ключ», имея опыт как в жилищном, так и промышленном строительстве.',
	},
	data: [
		{
			type: 'intro',
			content: {
				title: 'Генеральный<br /><color-accent>подряд</color-accent>',
				breadcrumbs: [{ text: 'Деятельность' }, { text: 'Генеральный подряд' }],
				shape: 'triangle',
			},
		},

		{
			type: 'activityAbout',
			content: {
				shape: 'triangle',
				title: 'Об услуге',
				sentence:
					'Мы берем на себя полностью реализацию проекта «под&nbsp;ключ», имея опыт как в жилищном,<br />так и промышленном строительстве.',
				text: '<p>/ Специально созданный орган, не только контролирующий все этапы выполнения работ и их пошаговую реализацию, но и также несущий полную ответственность за качество их выполнения своевременность и качество результата</p>',
			},
			wrapper: {
				type: 'stripsVertical',
				props: {
					color: 'white',
					startScreen: true,
				},
			},
		},

		{
			type: 'taskGallery',
			content: {
				title: 'Задачи<br /><color-accent>генподрядчика</color-accent>',
				items: [
					{
						text: 'Вся ответственность за качество ложится на генподрядчика',
					},
					{
						text: 'Возможность полного контроля действий субподрядчиков',
					},
					{
						text: 'Своевременное получение всех отчетов, позволяющих анализировать реальный',
					},
					{
						text: 'Вся ответственность за качество ложится на генподрядчика',
					},
					{
						text: 'Возможность полного контроля действий субподрядчиков',
					},
					{
						text: 'Своевременное получение всех отчетов, позволяющих анализировать реальный',
					},
				],
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'white',
					paddingTop: true,
				},
			},
		},

		{
			type: 'splitViewSection',
			content: {
				title: 'Мы используем<br />BIM моделирование',
				text: 'В компании работает высококвалифицированная молодая команда, выполняющие задачи, которыми ежедневно пользуются миллионы людей',
				url: '/perspectives/bim',
				picture: {
					src: '/temp/pic-20.jpg',
					width: 1920,
					height: 1080,
					alt: 'Мы используем BIM моделирование',
				},
			},
		},

		{
			type: 'benefitsList',
			content: {
				title: 'Преимущества<br /><color-accent>услуги</color-accent>',
				items: [
					{
						content: [
							{
								type: 'html',
								content:
									'<p>Обеспечивать своевременное выполнение всего списка требований по возведению и вводу в эксплуатацию строительного объекта, учитывая требования заказчика и принимая во внимание установленные в договоре сроки</p>',
							},
						],
					},
					{
						content: [
							{
								type: 'html',
								content:
									'<p>Согласовывать с заказчиком все основные вопросы, которые касаются цен на работы, оформления договора с учетом положенных нормативных документов, согласования списка действий и их документальное обоснование</p>',
							},
						],
					},
					{
						content: [
							{
								type: 'html',
								content:
									'<p>Проверять и оценивать совместно с заказчиком всех выполненных сотрудниками работ и отдельно взятых строительных или других конструкций</p>',
							},
						],
					},
					{
						content: [
							{
								type: 'html',
								content:
									'<p>Проверять и оценивать совместно с заказчиком всех выполненных сотрудниками работ и отдельно взятых строительных или других конструкций</p>',
							},
						],
					},
				],
			},
			wrapper: {
				type: 'stripsVertical',
				props: {
					color: 'dark',
				},
			},
		},

		{
			type: 'projectsPreviews',
			content: {
				title: 'Объекты',
				titleUrl: '/projects',
				items: [
					{
						title: 'Спортивный комплекс<br /><color-accent>«Олимпийский»</color-accent>',
						text: 'Устройство ограждения конструкций котлована при реконструкции здания бассейна. Устройство сайного основания при строительстве подземной части сооружения',
						url: '/projects/project',
						picture: {
							src: '/temp/project-01.jpg',
							width: 1280,
							height: 904,
							alt: 'Спортивный комплекс «Олимпийский»',
						},
					},
					{
						title: 'Новая штаб-квартира<br /><color-accent>«Яндекса»</color-accent>',
						text: 'Устройство ограждения котлована и анкерной системы',
						url: '/projects/project',
						picture: {
							src: '/temp/project-02.jpg',
							width: 1280,
							height: 904,
							alt: 'Новая штаб-квартира «Яндекса»',
						},
					},
					{
						title: 'Павильон<br /><color-accent>«Атомной энергии»</color-accent>',
						text: 'Устройство ограждения конструкций котлована при реконструкции здания бассейна. Устройство сайного основания при строительстве подземной части сооружения',
						url: '/projects/project',
						picture: {
							src: '/temp/project-03.jpg',
							width: 1280,
							height: 904,
							alt: 'Пафильон «Атомной энергии»',
						},
					},
				],
			},
			wrapper: {
				type: 'stripsHorizontalLeft',
				props: {
					color: 'dark',
				},
			},
		},
	],
};
