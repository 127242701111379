export type JWT = {
	access: string | null;
	refresh: string | null;
};
export type JWTKey = keyof JWT;

/*
 * Пишет токены в хранилище
 */
export const setJWT = ({ access, refresh }: Partial<JWT>): void => {
	if (access) {
		localStorage.setItem('access', access);
	} else {
		localStorage.removeItem('access');
	}

	if (refresh) {
		localStorage.setItem('refresh', refresh);
	}
};

/*
 * Сбрасывает токены
 */
export const resetJWT = (): void => {
	localStorage.removeItem('access');
	localStorage.removeItem('refresh');
};

/*
 * Получает токены из хранилища
 */
export const getJWT = (tokenName: JWTKey = 'access'): string | null => {
	const isSSR = typeof window === 'undefined';

	if (isSSR) return null;

	const token = window?.localStorage && localStorage.getItem(tokenName);
	return token || null;
};
