import { normalize } from '@utils';

export const getStages = (seek: number, screens: number) => {
	const stages = [];
	const frag = 1 / screens;

	for (let i = 0; i < screens; i++) {
		stages.push(normalize(seek, frag * (i + 1), frag * i, true));
	}

	return stages;
};

export const getFadeUpCss = (value: number, prevValue: number = 0) => {
	const prev = 0; // Math.max(prevValue - 0.6, 0);
	const current = Math.min(value + prev, 1);
	return `
		transform: translateY(${current * -110}%);
	`;
};
