import css from '../SplitViewSlides.module.scss';
import type { SlideType } from '@components/SplitViewSlides/SplitViewSlides';
import React, { FC, useEffect, useState } from 'react';
import { Box, Flex } from '@core';
import { AnimationType } from '@components/animations';
import { animationComponents } from '@components/animations/Animation/Animation';
import { SplitViewSlidesItem } from '@components/SplitViewSlides/components/SplitViewSlidesItem';

interface Props {
	seek: number;
	slides?: Array<SlideType>;
	animation?: AnimationType;
}

export const SplitViewSlidesContent: FC<Props> = React.memo(({ seek, slides, animation }) => {
	const size = slides?.length || 1;
	const weight = 1 / (size - 1);
	const seekBySlide = seek / weight;

	const [slidesContainer, setSlidesContainer] = useState<HTMLElement | null>();

	useEffect(() => {
		if (slidesContainer) {
			slidesContainer.querySelectorAll<HTMLElement>(`.${css.slide}`).forEach((el, i) => {
				// Clip path
				// const curr = 1 - Math.max(Math.min(seekBySlide - i, 1), 0);
				// el.style.clipPath = `polygon(0 0, ${curr * 100}% 0, ${curr * 100}% 100%, 0 100%)`;

				// Translate
				const curr = 1 - Math.max(Math.min(seekBySlide - (i - 1), 1), 0);
				el.style.transform = `translate3d(${curr * 100}%, 0, 0)`;

				// Background opacity
				const opacity = Math.max(Math.min(seekBySlide - (i - 1), 1), 0);
				const bg = el.querySelector<HTMLElement>(`.${css.slideBg}`);

				if (bg) {
					bg.style.opacity = opacity.toFixed(2);
				}
			});
		}
	}, [seekBySlide, slidesContainer]);

	const Animation = animation && slides?.length !== 0 && animationComponents[animation];

	return (
		<Flex className={css.slider}>
			<Box className={css.left}>{Animation && <Animation seek={seek} slides={slides} />}</Box>
			<Box className={css.right} ref={setSlidesContainer}>
				{slides?.map((slide, i) => {
					return (
						<Box key={i} className={css.slide}>
							<div className={css.slideBg} />
							<SplitViewSlidesItem index={i} content={slide} />
						</Box>
					);
				})}
			</Box>
		</Flex>
	);
});
