import css from './StatsGallery.module.scss';
import classnames from 'classnames';
import React, { FC, useMemo } from 'react';
import { Section, Container, Tag, Box, TitleLink, CardsSlider } from '@core';
import {
	StatsPreviewProps,
	StatsGalleryPreview,
} from '@components/StatsGallery/component/StatsGalleryPreview';

interface Props {
	title?: string;
	titleUrl?: string;
	items: Array<StatsPreviewProps>;
}

export const StatsGallery: FC<Props> = React.memo(({ title, titleUrl, items }) => {
	const slides = useMemo(() => {
		let prevSlideType: string;

		return items.map((item, i) => {
			const type =
				item.type === 'head'
					? item.type
					: prevSlideType === 'head' || prevSlideType === 'childB'
					? 'childA'
					: 'childB';
			const result = {
				...item,
				className: classnames(css[type], type === 'head' && 'is-accent-card'),
			};

			prevSlideType = item.type;

			return result;
		});
	}, [items]);

	return (
		<Section>
			<Box className={css.module}>
				<StatsGalleryHead title={title} titleUrl={titleUrl} />
				<CardsSlider items={slides} ChildCard={StatsGalleryPreview} />
			</Box>
		</Section>
	);
});

export const StatsGalleryHead: FC<Pick<Props, 'title' | 'titleUrl'>> = React.memo(
	({ title, titleUrl }) => {
		return (
			<Container className={css.heading}>
				<Tag type="h1">
					<TitleLink url={titleUrl} content={title} />
				</Tag>
			</Container>
		);
	}
);
