import css from './VacancyPreview.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, memo, useCallback, useRef } from 'react';
import { Box, Tag, Icon, Flex, RawHtml } from '@core';
import { DateLangType, fullFormatDate } from '@utils';
import useIntersectionObserver from '@hooks/useIntersectionObserver';
import { useRouter } from 'next/router';

export type VacancyRecordType = {
	type: string;
	caption: string;
	content: string;
};

export interface VacancyPreviewType extends HTMLAttributes<HTMLDivElement> {
	date: string;
	title: string;
	url?: string;
	records?: Array<VacancyRecordType>;
	idx?: number;
}

export const VacancyPreview: FC<VacancyPreviewType> = memo(
	({ title, date, url, records = [], idx = 0, className }) => {
		const ref = useRef<HTMLDivElement | null>(null);
		const router = useRouter();

		const entry = useIntersectionObserver(ref, {
			threshold: 0,
			rootMargin: `0px 0px 100px 0px`,
			freezeOnceVisible: true,
		});

		const fulldate = fullFormatDate(date, router.locale as DateLangType, true);

		const content = (
			<>
				<Box className={css.date}>
					{fulldate && <time dateTime={fulldate.datetime}>{fulldate.concat}</time>}
				</Box>
				<Box className={css.main}>
					{title && <Tag className={css.caption} type="h3" content={title} isHTML={true} />}
					{records.length > 0 && (
						<Flex className={css.records}>
							{records.map(({ caption, content }, i) => (
								<Box className={css.record} key={`vacancy-${idx}-${date}-record-${i}`}>
									<Box className={css.recordCaption}>{caption}</Box>
									<RawHtml className={css.recordContent} content={content} />
								</Box>
							))}
						</Flex>
					)}
				</Box>
			</>
		);

		const classNames = classnames(className, css.outer, {
			[css.isVisible]: entry?.isIntersecting,
		});

		const handleClick = useCallback(
			(url: string) => {
				const query = router.asPath.split('?')[1];
				const qs = query ? `?${query}` : ``;

				router.push(url + qs, undefined, { shallow: true }).then();
			},
			[router]
		);

		return (
			<article ref={ref} className={classNames}>
				{url && (
					<a
						className={classnames(css.inner, css.link)}
						href={url}
						onClick={(e) => {
							e.preventDefault();
							handleClick(url);
						}}>
						<Icon className={css.arrow} id="link" />
						{content}
					</a>
				)}
				{!url && <Box className={css.inner}>{content}</Box>}
			</article>
		);
	}
);
