import css from './Partners.module.scss';
import classnames from 'classnames';
import React, { FC, PropsWithChildren, useContext } from 'react';
import { ViewportContext } from '@context';
import { PartnersFlow } from '@components/Partners/components/PartnersFlow/PartnersFlow';
import { Heading, Section, StripsVertical } from '@core';

export interface PartnerType {
	url?: string;
	logo?: Picture;
	title?: string;
	docType?: string;
}

export interface PartnersProps {
	title?: string;
	items: Array<PartnerType>;
	settings: {
		titleIndent?: boolean;
		topCollapse?: boolean;
		topStripsMobile?: boolean;
		color?: 'white' | 'dark' | 'transparent';
		colorEnd?: 'white' | 'dark';
		colorMob?: 'white' | 'dark';
	};
}

export const Partners: FC<PartnersProps> = React.memo((props) => {
	return (
		<PartnersWrapper {...props}>
			<PartnersHead {...props} />
			<PartnersFlow {...props} className={css.feed} />
		</PartnersWrapper>
	);
});

export const PartnersWrapper: FC<PropsWithChildren<PartnersProps>> = React.memo(
	({ settings: { topCollapse, topStripsMobile, color = 'transparent', colorMob }, children }) => {
		const { bp } = useContext(ViewportContext);
		const isMob = bp === 'xs';
		const withStripsOnMob = isMob && topStripsMobile;
		const sectionColor = isMob && colorMob ? colorMob : color;

		return (
			<Section
				className={classnames(
					css.module,
					{
						[css.topCollapse]: topCollapse,
						[css.topStripsMobile]: topStripsMobile && isMob,
						[css[`${colorMob}-mob`]]: !!colorMob && isMob,
					},
					`is-${sectionColor}-section`
				)}>
				{withStripsOnMob && <StripsVertical color={colorMob}>{children}</StripsVertical>}
				{!withStripsOnMob && <>{children}</>}
			</Section>
		);
	}
);

export const PartnersHead: FC<PartnersProps> = React.memo(({ title, settings }) => {
	return (
		<Heading
			title={title}
			inContainer={true}
			className={css.heading}
			classNameTitle={classnames({ 'indent-margin': settings?.titleIndent })}
		/>
	);
});
