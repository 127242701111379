import css from './MediaBg.module.scss';
import React, { FC, useRef } from 'react';
import Image from 'next/image';
import { Video } from '@core';
import useIntersectionObserver from '@hooks/useIntersectionObserver';

export interface Props {
	picture?: Picture;
	video?: Video;
	playing?: boolean;
}

export const MediaBg: FC<Props> = React.memo(({ picture, video, playing }) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const entry = useIntersectionObserver(ref, {
		threshold: 0,
		freezeOnceVisible: false,
	});

	return (
		<>
			{picture && (
				<picture className={css.module}>
					<Image src={picture.src} alt={picture.alt} layout="fill" quality={100} priority={true} />
				</picture>
			)}
			{video && (
				<div ref={ref} className={css.module}>
					{video?.mp4 && (
						<Video className={css.video} playing={playing && entry?.isIntersecting} {...video} />
					)}
				</div>
			)}
		</>
	);
});
