import css from './TaskGallery.module.scss';
import React, { FC, useContext } from 'react';
import { Section, Box, CardsSlider, Heading, CardsTicker, CardsTickerFeed } from '@core';
import {
	TaskPreviewProps,
	TaskGalleryPreview,
} from '@components/TaskGallery/component/TaskGalleryPreview';
import { ViewportContext } from '@context';

interface Props {
	title?: string;
	titleUrl?: string;
	items: Array<TaskPreviewProps>;
	forcedScroll: boolean;
}

export const TaskGallery: FC<Props> = React.memo(({ title, titleUrl, items, forcedScroll }) => {
	const { bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	return (
		<Section>
			<Box className={css.module}>
				{forcedScroll && !isMob && (
					<CardsTicker
						endPad={1.5}
						size={items.length}
						className={css.module}
						before={
							<Heading
								className={css.heading}
								title={title}
								titleUrl={titleUrl}
								classNameTitle="indent-margin"
								inContainer={true}
							/>
						}>
						<CardsTickerFeed items={items} ChildCard={TaskGalleryPreview} />
					</CardsTicker>
				)}
				{(!forcedScroll || isMob) && (
					<>
						<Heading
							className={css.heading}
							title={title}
							titleUrl={titleUrl}
							classNameTitle="indent-margin"
							inContainer={true}
						/>
						<CardsSlider className={css.slider} items={items} ChildCard={TaskGalleryPreview} />
					</>
				)}
			</Box>
		</Section>
	);
});

// Для принудительного скролла заюзать:
//
// <CardsTicker
// 	endPad={1.5}
// 	size={items.length}
// 	className={css.module}
// 	before={<TaskGalleryHead title={title} titleUrl={titleUrl} />}>
// 	<CardsTickerFeed items={items} ChildCard={TaskGalleryPreview} />
// </CardsTicker>;
