import css from './ProgressBar.module.scss';
import React, { FC, useState, useEffect, useContext } from 'react';
import { useScrollPosition } from '@hooks';
import { ViewportContext } from '@context';
import { normalize } from '@utils';
import { ProgressBar } from '@core';

export const ProgressBarPage: FC = () => {
	const [pageH, setPageH] = useState(0);
	const [seek, setSeek] = useState(0);

	const { vh } = useContext(ViewportContext);
	const [scrollTop] = useScrollPosition();

	useEffect(() => {
		const onResize = () => {
			const body = document.body;
			const html = document.documentElement;

			const height = Math.max(
				body.scrollHeight,
				body.offsetHeight,
				html.clientHeight,
				html.scrollHeight,
				html.offsetHeight
			);

			setPageH(height);
		};

		onResize();
		window.addEventListener('resize', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	useEffect(() => {
		const seek = normalize(scrollTop + vh, pageH, vh, true);
		setSeek(seek);
	}, [vh, scrollTop, pageH]);

	return (
		<div className={css.fixed}>
			<ProgressBar seek={seek} />
		</div>
	);
};
