import css from './HomeCareerMobile.module.scss';
import classnames from 'classnames';
import type { HomeCareerProps } from '@components/HomeCareer/HomeCareer';
import React, { FC } from 'react';
import { Box, Container, RawHtml, Tag, TitleLink } from '@core';
import { SimpleWrapper } from '@core/SimpleWrapper/SimpleWrapper';
import { HomeCareerMedia } from '@components/HomeCareer/components/HomeCareerMedia/HomeCareerMedia';

export const HomeCareerMobile: FC<HomeCareerProps> = ({ title, text, url, cover, video }) => {
	return (
		<SimpleWrapper>
			<Box className={css.module}>
				<Container className={css.head}>
					<Tag className={css.title} type="h1">
						<TitleLink url={url} content={title} />
					</Tag>
					<RawHtml className={css.text} content={text} />
				</Container>
				<HomeCareerMedia cover={cover} video={video} />
			</Box>
		</SimpleWrapper>
	);
};
