import css from './Breadcrumbs.module.scss';
import React, { FC } from 'react';
import classnames from 'classnames';

export interface Breadcrumb {
	text: string;
	url?: string;
}

interface Props {
	items?: Array<Breadcrumb>;
	className?: string;
}

export const Breadcrumbs: FC<Props> = ({ items, className }) => {
	return (
		<ul className={classnames(css.breadcrumbs, className)}>
			{Array.isArray(items) &&
				items?.map((item, i) => (
					<li key={`breadcrumbs-${i}`} className={css.item}>
						{item?.url && (
							<a href={item.url} className={css.link}>
								{item.text}
							</a>
						)}
						{!item?.url && item.text}
					</li>
				))}
			{!items && <li className={css.item}>&nbsp;</li>}
		</ul>
	);
};
