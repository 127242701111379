import React, { FC } from 'react';

export type IconId =
	| 'close'
	| 'cross'
	| 'checkmark'
	| 'paperclip'
	| 'arrow-prev'
	| 'arrow-next'
	| 'drag'
	| 'link'
	| 'outer-link'
	| 'qt'
	| 'qt-stroke'
	| 'filters'
	| 'page-up'
	| 'dart-prev'
	| 'dart-next';

export const SvgSprite: FC = () => {
	return (
		<svg style={{ display: 'none' }} xmlns="http://www.w3.org/2000/svg">
			<symbol id="close" viewBox="0 0 48 48">
				<path
					d="M22.418 24.299C16.848 18.79 11.565 13.515 7.54 9.397 5.445 7.254 3.686 5.42 2.404 4.027A54.699 54.699 0 01.84 2.27C.475 1.836.15 1.424 0 1.121L1.731.252C1.73.25 1.74.268 1.77.313c.028.043.067.099.12.168.104.14.247.319.43.537.367.436.873 1.004 1.506 1.69 1.263 1.372 3.007 3.19 5.097 5.33 4.016 4.108 9.293 9.378 14.865 14.887L46.631 0 48 1.373 25.166 24.288a3615.094 3615.094 0 0022.822 22.315L46.644 48a3617.478 3617.478 0 01-22.846-22.339L1.55 47.988.18 46.615l22.237-22.316z"
					fillRule="nonzero"
				/>
			</symbol>
			<symbol id="cross" viewBox="0 0 32 32">
				<path
					d="M15.316 16.022L4.708 5.414 6.123 4 16.73 14.608l9.896-9.897 1.415 1.414-9.897 9.897L28.75 26.627l-1.414 1.415L16.73 17.436 5.414 28.753 4 27.338z"
					fillRule="nonzero"
				/>
			</symbol>
			<symbol id="checkmark" viewBox="0 0 10 8">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M3.93778 4.82258L8.49779 0L10 1.58871L3.93778 8L0 3.83548L1.50221 2.24677L3.93778 4.82258Z"
				/>
			</symbol>
			<symbol id="paperclip" viewBox="0 0 16 16">
				<path
					d="M14.53 7.061L8.374 13.04c-1.469 1.425-3.848 1.425-5.317 0a3.582 3.582 0 010-5.172l5.51-5.349a2.248 2.248 0 013.113 0 2.1 2.1 0 010 3.032l-5.51 5.35a.656.656 0 01-.909 0 .619.619 0 010-.893l5.418-5.26-1.29-1.252L3.965 8.76a2.345 2.345 0 000 3.386 2.528 2.528 0 003.5 0l5.51-5.349a3.826 3.826 0 000-5.526c-1.575-1.528-4.13-1.528-5.704 0l-5.51 5.35a5.308 5.308 0 000 7.665c2.183 2.119 5.724 2.119 7.907 0l6.153-5.973-1.29-1.252z"
					fillRule="nonzero"
				/>
			</symbol>
			<symbol id="arrow-next" viewBox="0 0 13 22">
				<path fillRule="nonzero" d="M9.967 11L0 1.453 1.517 0 13 11 1.517 22 0 20.547z" />
			</symbol>
			<symbol id="arrow-prev" viewBox="0 0 13 22">
				<path fillRule="nonzero" d="M3.033 11L13 20.547 11.483 22 0 11 11.483 0 13 1.453z" />
			</symbol>
			<symbol id="drag" viewBox="0 0 96 96">
				<path
					fillRule="nonzero"
					d="M69.414 48L58.707 58.707l-1.414-1.414L66.586 48l-9.293-9.293 1.414-1.414zM29.414 48l9.293 9.293-1.414 1.414L26.586 48l10.707-10.707 1.414 1.414z"
				/>
			</symbol>
			<symbol id="link" viewBox="0 0 32 32">
				<path fillRule="nonzero" d="M28.759 1.899H1V0h31v31h-1.899V3.241L1.343 32 0 30.657z" />
			</symbol>
			<symbol id="outer-link" viewBox="0 0 32 32">
				<path fillRule="nonzero" d="M17 2.037V0h15v15h-2V3.6L17.44 16 16 14.56 28.4 2z" />
				<path fillRule="nonzero" d="M30 30H2V2h10V0H0v32h32V20h-2z" />
			</symbol>
			<symbol id="qt" viewBox="0 0 74 52">
				<path
					d="M24.035 0L11.147 22.293h18.81V52.25H0V22.293L12.888 0h11.147zm44 0L55.147 22.293h18.81V52.25H44V22.293L56.888 0h11.147z"
					fillRule="evenodd"
				/>
			</symbol>
			<symbol id="qt-stroke" viewBox="0 0 74 52">
				<path
					d="M24.035 0L11.147 22.293h18.81V52.25H0V22.293L12.888 0h11.147zm-1.733 1h-8.837L1 22.562V51.25h27.957V23.293H9.413L22.302 1zm45.733-1L55.147 22.293h18.81V52.25H44V22.293L56.888 0h11.147zm-10.57 1L45 22.562V51.25h27.957V23.293H53.413L66.302 1h-8.837z"
					fillRule="nonzero"
				/>
			</symbol>
			<symbol id="filters" viewBox="0 0 32 28">
				<path
					d="M12.445 15.402h1l-.784.62-.216-.272.784-.62.62.784-.784.62-.62-.784v-.348zm0 .348l-.62-.784.686-.543.085-.067.006-.005-.018.014c-.023.02-.023.02-.235.291l.173 1.18-.093-.106.02.024-.007-.008-.004-.005-.018-.023-.075-.095-.288-.364-1.054-1.333A32013.599 32013.599 0 011.278 1.62L0 0h32l-1.282 1.62a12550.025 12550.025 0 00-9.722 12.306l-1.024 1.297-.416.527V28h-7.111V15.75zm6.492.785l-.785-.62.62-.784.784.62-.215.271-.785-.62h1v.348l-.62.785zM4.127 2a18940.412 18940.412 0 008.445 10.686l1.054 1.333.287.363.02.026.076.095.02.026-.07.053.151 1.021-.218.275a1.096 1.096 0 00-.018.016l-.02.017-.016.012-.086.069-.687.542-1.24-1.568 1.569-1.241.54.683.055.07.02.025.436.552V26h3.111V15.056l.429-.543.008-.01.019-.025.075-.094.287-.364 1.052-1.333A27592.205 27592.205 0 0127.868 2H4.127zm14.479 11.726l1.57 1.239-1.24 1.57-.686-.542-.087-.069-.016-.012-.021-.018c-.091-.086-.095-.09-.066-1.298l-.088-.066.013-.017.62-.787zm-.542.697l.708.707-.712-.534.004-.173zm-4.835.707l.707-.707.024.159-.73.548z"
					fillRule="nonzero"
				/>
			</symbol>
			<symbol id="page-up" viewBox="0 0 30 30">
				<path
					d="M14.027 3.801L1.377 16.415 0 15.043 14.312.773 15 0l.688.773L30 15.043l-1.376 1.372-12.65-12.614V30h-1.947z"
					fillRule="nonzero"
				/>
			</symbol>
			<symbol id="dart-next" viewBox="0 0 32 32">
				<path
					fillRule="nonzero"
					d="M0 17v-2h28.346L14.652 1.354 16.007 0 32 16 16.007 32l-1.355-1.354L28.346 17z"
				/>
			</symbol>
			<symbol id="dart-prev" viewBox="0 0 32 32">
				<path
					fillRule="nonzero"
					d="M32 17H3.654l13.694 13.646L15.993 32 0 16 15.993 0l1.355 1.354L3.654 15H32z"
				/>
			</symbol>
		</svg>
	);
};
