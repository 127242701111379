import css from './CookieConsent.module.scss';
import classnames from 'classnames';
import React, { FC, MouseEventHandler, useEffect, useState } from 'react';
import { Box, Button, Container, Tag, Text, RawHtml, Icon } from '@core';
import { useDelayUnmount } from '@hooks/useDelayUnmount';
import { getCookieConsentValue, setCookieConsent } from '@utils';
import { useSSR } from '@hooks';

export interface CookieConsentProps {
	title?: string;
	text?: string;
	accept?: string;
	decline?: string;
	cookieName?: string;
}

export const CookieConsent: FC<CookieConsentProps> = ({
	// title,
	text,
	accept,
	decline,
	cookieName = 'CookieConsent',
}) => {
	const [active, setActive] = useState(false);
	const shouldRender = useDelayUnmount(active, 800);

	useEffect(() => {
		let mount = true;
		const consent = getCookieConsentValue(cookieName);

		if (consent === undefined) {
			window.setTimeout(() => {
				mount && setActive(true);
			}, 2000);
		}

		return () => {
			mount = false;
		};
	}, [cookieName]);

	const acceptHandler: MouseEventHandler = () => {
		setCookieConsent(cookieName, 'true');
		setActive(false);
	};

	const declineHandler: MouseEventHandler = () => {
		setCookieConsent(cookieName, 'false');
		setActive(false);
	};

	const { isServer } = useSSR();
	if (isServer) return null;

	return (
		<aside
			className={classnames(
				css.component,
				{
					[css.isShown]: active,
					[css.hasTransition]: shouldRender,
				},
				'fixed-correct'
			)}>
			{shouldRender ? (
				<>
					<Container className={css.container}>
						<Box className={css.left}>
							{/*{title && <Tag className={css.title} type="h4" content={title} isHTML={true} />}*/}
							{text && (
								<Text className={css.text} size="sm" color="inherit">
									<RawHtml content={text} />
								</Text>
							)}
						</Box>
						<Box className={css.right}>
							<Button
								onClick={acceptHandler}
								className={css.acceptBtn}
								type="button"
								size="md"
								design="flat-white">
								{accept}
							</Button>
						</Box>
					</Container>
					<Button
						onClick={declineHandler}
						className={css.declineBtn}
						type="button"
						aria-label={decline}>
						<Icon id="cross" />
					</Button>
				</>
			) : null}
		</aside>
	);
};
