import React, { FC } from 'react'; // , useContext
import { PreambleTickerRegular } from '@components/PreambleTicker/components/PreambleTickerRegular';
// import { PreambleTickerMobile } from '@components/PreambleTicker/components/PreambleTickerMobile';
// import { ViewportContext } from '@context';

export interface PreambleTickerProps {
	text?: string;
	sentence?: string;
	ticker?: string;
	picture?: Picture;
	video?: Video;
}

export const PreambleTicker: FC<PreambleTickerProps> = React.memo((props) => {
	// const { bp } = useContext(ViewportContext);
	// const isMob = bp === 'xs';

	return (
		<PreambleTickerRegular {...props} />
		/*<>
			{isMob && <PreambleTickerMobile {...props} />}
			{!isMob && <PreambleTickerRegular {...props} />}
		</>*/
	);
});
