import { PageData } from '@api/hooks/types';

export const story: PageData = {
	meta: {
		title: 'Этапы становления',
		description:
			'Компания была основана в 2010 году группой специалистов в области геотехнических работ, имеющих опыт работы в крупных российских и международных компаниях. Fensma задумывалась как компания, которая выполняет проектирование и строительство подземных частей уникальных и технически сложных объектов, при новом строительстве и реконструкции зданий и сооружений в плотной городской застройке и действующих производственных предприятий.',
	},
	data: [
		{
			type: 'intro',
			content: {
				title: 'Этапы<br /><color-accent>становления</color-accent>',
				breadcrumbs: [{ text: 'О компании' }, { text: 'История' }],
			},
		},

		{
			type: 'techPreamble',
			content: {
				sentence:
					'Компания была основана в 2010 году группой специалистов в области геотехнических работ, имеющих опыт работы в крупных российских и международных компаниях. Fensma задумывалась как компания, которая выполняет проектирование и строительство подземных частей уникальных и технически сложных объектов, при новом строительстве и реконструкции зданий и сооружений в плотной городской застройке и действующих производственных предприятий.',
				ticker: '2010 год основания',
			},
			wrapper: {
				type: 'stripsVertical',
				props: {
					color: 'white',
					startScreen: true,
					paddingTop: false,
				},
			},
		},

		{
			type: 'infoBlock',
			content: {
				subtitle:
					'<color-accent>Мы компания полного цикла,</color-accent> объединяющая в себе проектирование, геотехническое строительство и строительство с функцией генподрядчика',
				text: '<p>/ За период своего развития компания смогла расширить географию своей работы, в 2021 году став международной компанией, укомплектовать штат специалистами различного профиля, наладить сотрудничество с ведущими производителями оборудования и создать собственную техническую базу для его ремонта и обслуживания.</p>',
			},
			wrapper: {
				type: 'stripsVertical',
				props: {
					color: 'dark',
				},
			},
		},

		{
			type: 'storyLine',
			content: {
				title: 'Ключевые этапы<br /><color-accent>становления компании</color-accent>',
				sections: [
					{
						title: '13 сентября 2010',
						text: 'Создание компании ФЕНСМА. В её состав вошли выходцы из компаний Солетанш и Гидротехстрой, штат компании составлял 30 человек',
						url: 'https://fensma.ru/',
						picture: {
							src: '/temp/pic-35.jpg',
							width: 420,
							height: 264,
							alt: '2010',
						},
						events: [
							{
								text: 'Первый заказ был на устройство распорной системы котлована',
								url: 'https://fensma.ru/',
							},
							{
								text: 'Первый заказчик и на сегодняшний день один из ключевых партнеров – компания ФОДД',
								url: 'https://fensma.ru/',
							},
						],
					},

					{
						title: '2011',
						text: 'Первая большая строительная техника – LIEBHERR HS 841 HD была куплена в 2011 году для устройства «стены в грунте» траншейного типа',
						url: 'https://fensma.ru/',
						picture: {
							src: '/temp/pic-36.jpg',
							width: 420,
							height: 264,
							alt: '2011',
						},
						events: [
							{
								text: 'Первый объект с использованием технологии «стена в грунте» — Тарный проезд',
								url: 'https://fensma.ru/',
							},
						],
					},

					{
						title: '2012',
						text: 'Первый объект с использованием фрезы и JET-свай – город Электросталь',
						url: 'https://fensma.ru/',
						picture: {
							src: '/temp/pic-35.jpg',
							width: 420,
							height: 264,
							alt: '2012',
						},
						events: [
							{
								text: 'Забивка железобетонных свай в г. Красногорск 1700 свай 35х35 сечение',
								url: 'https://fensma.ru/',
							},
							{
								text: 'Первые буронабивные сваи – Ярославское шоссе, эстакада',
								url: 'https://fensma.ru/',
							},
							{
								text: 'Кроме Москвы, строительство производилось, также, в Санкт-Петербурге, Волгограде и Туапсе',
								url: 'https://fensma.ru/',
							},
						],
					},

					{
						title: '2014',
						text: 'В 2014 году оборот компании превысил 1 млрд рублей',
						url: 'https://fensma.ru/',
						picture: {
							src: '/temp/pic-36.jpg',
							width: 420,
							height: 264,
							alt: '2014',
						},
					},

					{
						title: '2015',
						text: 'В 2015 году была открыта собственная материально-техническая база',
						url: 'https://fensma.ru/',
						picture: {
							src: '/temp/pic-35.jpg',
							width: 420,
							height: 264,
							alt: '2015',
						},
					},

					{
						title: '2016',
						text: 'Первые монолитные работы – Рязанский проспект, ЖК Среда',
						url: 'https://fensma.ru/',
						picture: {
							src: '/temp/pic-36.jpg',
							width: 420,
							height: 264,
							alt: '2016',
						},
						events: [
							{
								text: 'Принимали участие в строительстве метрополитена: станция Говорово (Терёшково), Нижегородская, Столбово, Академика Опарина, Внуково, а также, эстакады: Ярославская, Академика Янгеля и Волоколамское шоссе',
								url: 'https://fensma.ru/',
							},
							{
								text: 'В 2016 году штат компании впервые превысил 1000 сотрудников',
								url: 'https://fensma.ru/',
							},
							{
								text: 'Знаковые объекты: Щелковский автовокзал, Олимпийский, ВДНХ, Москва-Сити, Яндекс, Золотой остров, Сколково',
								url: 'https://fensma.ru/',
							},
						],
					},

					{
						title: '2018',
						text: 'В 2018 году объект строительства на Рязанском проспекте был признан лучшей площадкой города Москвы по техники безопасности и культуре производства',
						url: 'https://fensma.ru/',
						picture: {
							src: '/temp/pic-35.jpg',
							width: 420,
							height: 264,
							alt: '2018',
						},
					},

					{
						title: '2019',
						text: 'Первые монолитные работы – Рязанский проспект, ЖК Среда',
						url: 'https://fensma.ru/',
						picture: {
							src: '/temp/pic-36.jpg',
							width: 420,
							height: 264,
							alt: '2019',
						},
						events: [
							{
								text: 'С 2019 года привлекаем студентов из высших образовательных учреждений: Горный институт НИТУ «МИСиС», МГСУ, МИИГАиК',
								url: 'https://fensma.ru/',
							},
						],
					},

					{
						title: '2020',
						text: 'К 2020 году количество единиц специальной техники в парке компании составило: более 70 единиц',
						url: 'https://fensma.ru/',
						// picture: {
						// 	src: '/temp/pic-35.jpg',
						// 	width: 420,
						// 	height: 264,
						// 	alt: '2020',
						// },
						events: [
							{
								text: 'В 2020 году стали победителями в четвертом всероссийском конкурсе BIM-технологий',
								url: 'https://fensma.ru/',
							},
						],
					},

					{
						title: '2021',
						text: 'В 2021 году компания ФЕНСМА заключила контракт на работы нулевого цикла при строительстве первой Атомной электростанции в Египте. Для данного объекта была приобретена производителя LIEBHERR',
						url: 'https://fensma.ru/',
						// picture: {
						// 	src: '/temp/pic-36.jpg',
						// 	width: 420,
						// 	height: 264,
						// 	alt: '2021',
						// },
					},
				],
			},
			wrapper: {
				type: 'stripsVerticalTail',
				props: {
					color: 'dark',
				},
			},
		},

		{
			type: 'infoBlock',
			content: {
				subtitle:
					'Fensma, в первую очередь <br />не про строительство, <br />мы про <color-accent>людей, любящих свое дело</color-accent>',
				text: '<p>/ Мы стремимся делать всё возможное для наших клиентов, укрепляя отношения, построенные на доверии, уверенности и честности.</p>',
				indent: false,
				indentMargin: true,
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'transparent',
					paddingTop: false,
					paddingBottom: true,
				},
			},
		},

		{
			type: 'mediaSection',
			content: {
				title: 'Визуализация интеграции <br />с BRIO MRS',
				cursor: {
					play: 'Воспроизвести',
					stop: 'Остановить',
				},
				bg: {
					src: '/temp/pic-22.jpg',
					width: 1920,
					height: 1080,
				},
				youtube: 'https://www.youtube.com/watch?v=kYQ-ndY8Pyw',
			},
		},
	],
};
