import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { isEmptyObject } from '@utils';

export const useFilterQuery = (keys?: Set<string>) => {
	const { query: queryLiteral } = useRouter();

	const hasKeysInQuery = useMemo(() => {
		if (!keys) return false;
		return Object.keys(queryLiteral).some((key) => Array.from(keys).includes(key));
	}, [keys, queryLiteral]);

	const query = useMemo(
		() =>
			!isEmptyObject(queryLiteral)
				? Object.entries(queryLiteral)
						.filter((pair) => pair[0] !== 'slug')
						.map((pair) => `${pair[0]}=${pair[1]}`)
						.join('&')
				: undefined,
		[queryLiteral]
	);

	return {
		query,
		hasKeysInQuery,
	};
};
