import css from './VacancyDetails.module.scss';
import classnames from 'classnames';
import type { PageData, PageSectionProps } from '@api/hooks/types';
import type { VacancyPreviewType } from '@components/Vacancies/components/VacancyPreview/VacancyPreview';
import React, {
	FC,
	memo,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { PopUp, PopUpContext, PopUpProvider } from '@core/PopUp/PopUp';
import { Box, Button, Flex, RawHtml, Tag, Text } from '@core';
import { VacancyPopUp } from '@components/Vacancies/components/VacancyPopUp/VacancyPopUp';
import { useData } from '@api';
import { useRouter } from 'next/router';
import { fullFormatDate, prepareApiUrl } from '@utils';

export interface VacancyDetailsType extends VacancyPreviewType {
	content?: Array<{
		title?: string;
		text?: string;
	}>;
	feedbackButton?: string;
	feedbackForm?: {
		title?: string;
		form: FormType;
	};
}

export interface VacancyDetailsProps extends PageSectionProps {
	data?: VacancyDetailsType;
	baseUrl?: string | null;
}

export interface VacancyDetailsContentProps {
	data: VacancyDetailsType;
	popupName?: string;
	isLoading?: boolean;
}

export const VacancyDetails: FC<VacancyDetailsProps> = memo(({ data, baseUrl = '/' }) => {
	const { asPath, locale, push } = useRouter();
	const active = asPath.includes('vacancy/id');
	const url = prepareApiUrl(asPath, locale, '/pages');
	const back = baseUrl;

	const handleClose = useCallback(() => {
		if (back) {
			const query = asPath.split('?')[1];
			const qs = query ? `?${query}` : ``;

			push(back + qs, undefined, { shallow: true }).then();
		}
	}, [back, asPath, push]);

	const { data: fetched, isLoading } = useData<PageData>({
		url: active ? url : null,
	});

	const [vacancyData, setVacancyData] = useState<VacancyDetailsType | undefined>(data);

	useEffect(() => {
		const fetchedData = fetched?.data?.find((section) => section.type === 'vacancies');
		if (fetchedData?.content?.details) {
			setVacancyData(fetchedData?.content?.details);
		}
	}, [fetched, data]);

	return (
		<PopUpProvider>
			<PopUp
				active={active && !!vacancyData}
				overlay="strips"
				boxWidth="none"
				closeButton={true}
				closeHandler={handleClose}>
				{vacancyData && (
					<>
						<VacancyDetailsContent
							data={vacancyData}
							isLoading={isLoading}
							popupName="vacancy-details-popup"
						/>
						{vacancyData.feedbackForm && (
							<VacancyPopUp
								name="vacancy-details-popup"
								form={vacancyData.feedbackForm}
								// closeHandler={handleClose}
							/>
						)}
					</>
				)}
			</PopUp>
		</PopUpProvider>
	);
});

export const VacancyDetailsContent: FC<VacancyDetailsContentProps> = memo(
	({ data, isLoading, popupName }) => {
		const { locale } = useRouter();
		const btnText = data.feedbackButton || (locale === 'en' ? 'Apply for job' : 'Откликнуться');

		const { toggle } = useContext(PopUpContext);

		const handleApplyToJob = useCallback(() => {
			toggle && toggle(popupName);
		}, [popupName, toggle]);

		return (
			<article className={classnames(css.wrap, { [css.isLoading]: isLoading }, 'is-white-section')}>
				<VacancyDetailsMain {...data} />
				<Box className={css.content}>
					{data?.content?.map(({ title, text }, i) => {
						return (
							<Box key={`vacancy-details-section-${i}-${title}`} className={css.section}>
								{title && (
									<Tag className={css.sectionCaption} type="h4" content={title} isHTML={true} />
								)}
								{text && (
									<Text className={classnames(css.sectionText, 'wysiwyg')} size="sm" color="gray">
										<RawHtml content={text} />
									</Text>
								)}
							</Box>
						);
					})}
				</Box>
				{data.feedbackButton && (
					<Box className={css.footer}>
						<Button type="button" onClick={handleApplyToJob}>
							{btnText}
						</Button>
					</Box>
				)}
			</article>
		);
	}
);

export const VacancyDetailsMain: FC<Omit<VacancyDetailsType, 'url'>> = memo(
	({ title, date, records = [] }) => {
		const fulldate = fullFormatDate(date, 'ru', true);

		return (
			<Box className={css.preamble}>
				<Box className={css.date}>
					{fulldate && <time dateTime={fulldate.datetime}>{fulldate.concat}</time>}
				</Box>
				<Box className={css.main}>
					{title && <Tag className={css.title} type="h3" content={title} isHTML={true} />}
					{records.length > 0 && (
						<Flex className={css.records}>
							{records.map(({ caption, content }, i) => (
								<Box className={css.record} key={`vacancy-details-preamble-${date}-record-${i}`}>
									<Box className={css.recordCaption}>{caption}</Box>
									<RawHtml className={css.recordContent} content={content} />
								</Box>
							))}
						</Flex>
					)}
				</Box>
			</Box>
		);
	}
);
