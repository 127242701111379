import css from './StripsHorizontalLeft.module.scss';
import classnames from 'classnames';
import fastdom from 'fastdom';
import React, {
	FC,
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useScrollWithEase } from '@hooks';
import { ViewportContext } from '@context';

interface Props {
	color?: string;
	baseColor?: string;
	hideFirst?: boolean;
	multiplier?: number;
	paddingTop?: boolean;
	paddingBottom?: boolean;
}

const stripsHRangeLG = [0.05, 0.1, 0.125, 0.15];
const stripsHRangeMD = [0.02, 0.04, 0.06, 0.08];

export const StripsHorizontalLeft: FC<PropsWithChildren<Props>> = ({
	color = 'white',
	baseColor,
	multiplier = 1,
	paddingTop = true,
	paddingBottom = true,
	children,
}) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const [seekX, setSeekX] = useState(0);

	const { vh, bp } = useContext(ViewportContext);

	const onScroll = useCallback(
		(current: number, height: number) => {
			const base = height - vh;
			const pos = Math.min(Math.abs(Math.min(current, 0)), base);
			const seekX = pos / base;

			setSeekX(seekX);
		},
		[vh]
	);

	useScrollWithEase(ref, onScroll, {
		ease: 0.25,
		edge: 'top',
	});

	const stripsHRange = bp === 'md' ? stripsHRangeMD : stripsHRangeLG;

	const [strips, setStrips] = useState<NodeListOf<HTMLElement> | undefined>(undefined);
	useEffect(() => setStrips(ref?.current?.querySelectorAll<HTMLElement>(`.${css.stripH}`)), []);

	useEffect(() => {
		fastdom.mutate(() => {
			strips?.forEach((el, i) => {
				const scale = 1 - seekX * stripsHRange[i] * multiplier;
				el.style.transform = `scaleX(${scale})`;
			});
		});
	}, [strips, seekX, stripsHRange, multiplier]);

	const contentClass = classnames(
		css.inner,
		{ [css.innerPadTop]: paddingTop, [css.innerPadBottom]: paddingBottom },
		css[color]
	);

	return (
		<div className={classnames(css.outer, `is-${color}-section`)} ref={ref}>
			{baseColor && <div className={classnames(css.base, css[baseColor])} />}
			<div className={classnames(css.bg, css[color])} />
			<StripsHorizontal color={color} />
			<Content className={contentClass}>{children}</Content>
		</div>
	);
};

const Content: FC<PropsWithChildren<{ className?: string }>> = React.memo(
	({ className, children }) => {
		return <div className={className}>{children}</div>;
	}
);

const StripsHorizontal: FC<{ color: string }> = React.memo(({ color }) => {
	const className = classnames(css.stripH, css[color]);
	return (
		<div>
			<div className={className} />
			<div className={className} />
			<div className={className} />
			<div className={className} />
		</div>
	);
});
