import css from './EventsFeedRegular.module.scss';
import classnames from 'classnames';
import type { EventsFeedProps } from '@components/EventsFeed/EventsFeed';
import type { PostPreviewProps } from '@components/PostsFeed/components/PostPreview/PostPreview';
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CardsTicker, CardsTickerFeed, Container, Section, Tag, TitleLink } from '@core';
import { ViewportContext } from '@context';
import { useFilterQuery, useReloadTimeout, useScrollWithEase } from '@hooks';
import { EventsFeedCard } from '@components/EventsFeed/components/EventsFeedCard/EventsFeedCard';
import { FilterLinks } from '@core/FilterLinks/FilterLinks';
import { useData } from '@api';
import { scrollToElement } from '@utils';
import fastdom from 'fastdom';

type EventsFeedHeadingProps = Pick<EventsFeedProps, 'title' | 'titleUrl' | 'filters'> & {
	className?: string;
};

const delay = 800;

export const EventsFeedRegular: FC<EventsFeedProps> = ({
	links,
	title,
	titleUrl,
	items,
	filters,
	hasTrailAnimation,
}) => {
	/*
	 * Scroll animation
	 */
	const ref = useRef<HTMLDivElement | null>(null);

	const { vh } = useContext(ViewportContext);
	const [trailingSeek, setTrailingSeek] = useState(0);

	const onScroll = useCallback(
		(current: number) => {
			if (!hasTrailAnimation) return;

			const way = vh * 0.4;
			const destination = Math.min(Math.max(current + way - vh, 0), way);

			setTrailingSeek(1 - destination / way);
		},
		[hasTrailAnimation, vh]
	);

	useScrollWithEase(ref, onScroll, {
		ease: 0.5,
		edge: 'bottom',
	});

	useEffect(() => {
		fastdom.mutate(() => {
			const module = ref.current;
			if (!module || !hasTrailAnimation) return;

			module.style.setProperty('--slideTail', trailingSeek.toFixed(4));
		});
	}, [hasTrailAnimation, trailingSeek]);

	/*
	 * Data fetching
	 */
	const { query } = useFilterQuery();
	const { data: fetched, isLoading } = useData<PaginatedData<PostPreviewProps>>({
		url: links?.self,
		query,
	});

	const [data, setData] = useState(fetched?.data || items);
	const [fetching, setFetching] = useState(false);

	const loading = useReloadTimeout(isLoading || fetching, delay);

	useEffect(() => {
		const timer = window.setTimeout(() => {
			if (fetched?.data) {
				setData(fetched.data);
			}
		}, delay);
		return () => {
			if (timer) window.clearTimeout(timer);
		};
	}, [loading, fetching, fetched]);

	const handleFilterClick = useCallback(() => {
		let timer = 0;
		if (ref.current) {
			setFetching(true);
			scrollToElement(ref.current);

			timer = window.setTimeout(() => {
				setFetching(false);
			}, 100);
		}
		return () => {
			if (timer) window.clearTimeout(timer);
		};
	}, []);

	return (
		<Section
			ref={ref}
			className={classnames(css.module, {
				[css.hasTrailAnimation]: hasTrailAnimation,
				[css.loading]: loading,
			})}>
			<CardsTicker
				endPad={1.5}
				size={data.length}
				elevated={true}
				classNameWrap={css.wrap}
				classNameScreen={css.sticky}
				before={
					<EventsFeedHeading
						title={title}
						titleUrl={titleUrl}
						filters={filters}
						onFiltersChange={handleFilterClick}
					/>
				}>
				<CardsTickerFeed classNameCard={css.card} items={data} ChildCard={EventsFeedCard} />
			</CardsTicker>
		</Section>
	);
};

export const EventsFeedHeading: FC<EventsFeedHeadingProps & { onFiltersChange?: () => void }> =
	React.memo(({ title, titleUrl, filters, onFiltersChange, className }) => {
		return (
			<Container className={className}>
				<Tag className={css.title} type="h1">
					<TitleLink url={titleUrl} content={title} />
				</Tag>
				{filters && filters.length && <FilterLinks onClick={onFiltersChange} items={filters} />}
			</Container>
		);
	});
