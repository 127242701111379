import css from './ProjectsGallery.module.scss';
import classnames from 'classnames';
import type { ProjectInterface } from './component/ProjectsGalleryPreview';
import React, { FC } from 'react';
import { Section, Container, Tag, Box, TitleLink, CardsSlider } from '@core';
import { ProjectsGalleryPreview } from './component/ProjectsGalleryPreview';

interface Props {
	title?: string;
	titleUrl?: string;
	items: Array<ProjectInterface>;
}

export const ProjectsGallery: FC<Props> = React.memo(({ title, titleUrl, items }) => {
	return (
		<Section>
			<Box className={css.module}>
				<ProjectsGalleryHead title={title} titleUrl={titleUrl} />
				<CardsSlider classNameCard={css.card} items={items} ChildCard={ProjectsGalleryPreview} />
			</Box>
		</Section>
	);
});

export const ProjectsGalleryHead: FC<Pick<Props, 'title' | 'titleUrl'>> = React.memo(
	({ title, titleUrl }) => {
		return (
			<Container className={css.head}>
				<Box className={css.headWrap}>
					<Tag className={classnames('indent-margin')} type="h1">
						<TitleLink url={titleUrl} content={title} />
					</Tag>
				</Box>
			</Container>
		);
	}
);
