import css from './Section.module.scss';
import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
	className?: string;
}

export const Section = React.memo(
	React.forwardRef<HTMLElement, PropsWithChildren<Props>>(
		({ className, children, ...props }, ref) => {
			return (
				<section ref={ref} className={classnames(className, css.section)} {...props}>
					{children}
				</section>
			);
		}
	)
);
