import css from './ActivityAbout.module.scss';
import { InfoBlock, InfoBlockProps } from '@components/InfoBlock/InfoBlock';
import React, { FC, useRef } from 'react';
import { ActivitySphere } from '@components/ActivityScopes/components/ActivityScopesCard/ActivityScopesCard';
import useIntersectionObserver from '@hooks/useIntersectionObserver';
import classnames from 'classnames';

interface ActivityAboutProps extends InfoBlockProps {
	shape: ServiceType;
}

export const ActivityAbout: FC<ActivityAboutProps> = React.memo(
	({ shape, title, subtitle, sentence, text }) => {
		const ref = useRef<HTMLDivElement | null>(null);

		const entry = useIntersectionObserver(ref, {
			threshold: 0.25,
			freezeOnceVisible: true,
		});

		return (
			<InfoBlock
				className={classnames(css.module, { [css.visible]: entry?.isIntersecting })}
				title={title}
				subtitle={subtitle}
				sentence={sentence}
				text={text}>
				<ActivitySphere ref={ref} shape={shape} className={css.sphere} />
			</InfoBlock>
		);
	}
);
