import type { VacancyDetailsType } from '@components/Vacancies/components/VacancyDetails/VacancyDetails';

export const vacancyDetails: VacancyDetailsType = {
	id: 'vacancy-id',
	date: '2021-09-17 02:34:56',
	title: 'Специалист отдела <nobr>материально-технического</nobr> снабжения',
	records: [
		{
			type: 'location',
			caption: 'Местонахождение',
			content: 'Египет, Каир',
		},
		{
			type: 'experience',
			caption: 'Опыт работы',
			content: '3 – 6 лет',
		},
		{
			type: 'employment',
			caption: 'Тип занятости',
			content: 'Полная занятость',
		},
	],
	content: [
		{
			title: 'Что нужно будет делать',
			text:
				'<ul>\n' +
				'<li>Подготовка договоров, дополнительных соглашений и&nbsp;формирование комплекта документации для согласования;</li>\n' +
				'<li>Согласование сформированного комплекта договора в&nbsp;электронной системе, устранение замечаний;</li>\n' +
				'<li>Согласование договоров, дополнительных соглашений с&nbsp;контрагентами;</li>\n' +
				'<li>Ведение реестра договоров;</li>\n' +
				'<li>Сбор и&nbsp;хранение всех необходимых документов от&nbsp;контрагента (учредительные документы).</li>\n' +
				'</ul>',
		},
		{
			title: 'Что нужно знать и уметь',
			text:
				'<ul>\n' +
				'<li>Подготовка договоров, дополнительных соглашений и&nbsp;формирование комплекта документации для согласования;</li>\n' +
				'<li>Согласование сформированного комплекта договора в&nbsp;электронной системе, устранение замечаний;</li>\n' +
				'<li>Согласование договоров, дополнительных соглашений с&nbsp;контрагентами;</li>\n' +
				'<li>Ведение реестра договоров;</li>\n' +
				'<li>Сбор и&nbsp;хранение всех необходимых документов от&nbsp;контрагента (учредительные документы).</li>\n' +
				'</ul>',
		},
		{
			title: 'Что мы предлагаем',
			text:
				'<ul>\n' +
				'<li>Подготовка договоров, дополнительных соглашений и&nbsp;формирование комплекта документации для согласования;</li>\n' +
				'<li>Согласование сформированного комплекта договора в&nbsp;электронной системе, устранение замечаний;</li>\n' +
				'<li>Согласование договоров, дополнительных соглашений с&nbsp;контрагентами;</li>\n' +
				'<li>Ведение реестра договоров;</li>\n' +
				'<li>Сбор и&nbsp;хранение всех необходимых документов от&nbsp;контрагента (учредительные документы).</li>\n' +
				'</ul>',
		},
	],

	feedbackButton: 'Откликнуться',
	feedbackForm: {
		title: 'Отклик<br /><color-accent>на вакансию</color-accent>',
		form: {
			action: '/forms/feedback',
			method: 'post',
			grcKey: '6LfURdcZAAAAAAz9aJ1T0WeUAeVGQI3RsarvmhRi',
			providedData: {
				vacancyId: 'some-id',
			},
			items: [
				{
					type: 'text',
					name: 'name',
					label: 'ФИО',
					required: true,
					validation: {
						required: 'Заполните это поле',
					},
				},
				{
					type: 'email',
					name: 'email',
					label: 'Электронная почта',
					required: true,
					pattern: '[0-9a-z_.-]+@[0-9a-z_]+\\.[a-z]{2,5}',
					validation: {
						required: 'Заполните это поле',
						pattern: 'Некорректный адрес email',
					},
				},
				{
					type: 'tel',
					name: 'phone',
					label: 'Номер телефона',
					required: true,
					mask: '+0 000 0000000',
					pattern: '^(\\+\\d)\\s\\d{3}\\s\\d{7}$',
					validation: {
						required: 'Заполните это поле',
						pattern: 'Некорректный номер телефона',
					},
				},
				{
					type: 'file',
					name: 'file',
					placeholder: 'Прикрепить резюме',
					accept: 'application/pdf',
					acceptLabel: 'pdf',
					sizeLimit: 52428800,
					sizeLimitLabel: 'До 50 Мб',
					validation: {
						accept: 'Недопустимый формат файла',
						size: 'Превышен допустимый размер файла',
					},
				},
			],
			button: 'Отправить',
			notice:
				'Нажимая «Отправить», я соглашаюсь с условиями<br /><a href="/" target="_blank">Политики конфиденциальности</a> и <a href="/" target="_blank">Пользовательского соглашения</a>',
		},
	},
};
