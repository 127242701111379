import React, { FC } from 'react';
import classnames from 'classnames';
import css from './Container.module.scss';

interface Props {
	isWide?: boolean;
	isFull?: boolean;
	className?: string;
}

export const Container: FC<Props> = ({ className, isWide, isFull, children }) => {
	return (
		<div
			className={classnames(
				css.container,
				className,
				isWide && css.containerWide,
				isFull && css.containerFull
			)}>
			{children}
		</div>
	);
};
