import css from './Heading.module.scss';
import classnames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';
import { PrimitiveKeys, Container, Tag, TitleLink } from '@core';

interface Props {
	title?: string;
	titleUrl?: string;
	titleTag?: PrimitiveKeys;
	classNameTitle?: string;
	className?: string;
	inContainer?: boolean;
	offsetType?: 'regular' | 'magazine';
}

const DefaultWrapper: FC = ({ children = [] }) => {
	return <>{children}</>;
};

export const Heading: FC<PropsWithChildren<Props>> = React.memo(
	({
		title,
		titleUrl,
		titleTag = 'h1',
		className,
		classNameTitle,
		offsetType,
		inContainer,
		children,
	}) => {
		const Wrapper = inContainer ? Container : DefaultWrapper;

		return (
			<header className={classnames(className, css.heading, offsetType && css[offsetType])}>
				<Wrapper>
					{titleUrl ? (
						<Tag className={classnames(classNameTitle, css.title)} type={titleTag}>
							<TitleLink url={titleUrl} content={title} />
						</Tag>
					) : (
						<Tag
							className={classnames(classNameTitle, css.title)}
							type={titleTag}
							content={title}
							isHTML={true}
						/>
					)}
					{children}
				</Wrapper>
			</header>
		);
	}
);
