import css from './PersonsGallery.module.scss';
import React, { FC, useContext } from 'react';
import { Section, Container, Heading, CardsSlider, CardsTicker, CardsTickerFeed, Box } from '@core';
import {
	PersonsGalleryPreviewProps,
	PersonsGalleryPreview,
} from '@components/PersonsGallery/component/PersonsGalleryPreview';
import { ViewportContext } from '@context';
import classnames from 'classnames';

interface PersonsGalleryProps {
	title?: string;
	titleUrl?: string;
	items: Array<PersonsGalleryPreviewProps>;
	forcedScroll?: boolean;
	verticalOnMob?: boolean;
}

export const PersonsGallery: FC<PersonsGalleryProps> = React.memo(
	({ title, titleUrl, items, forcedScroll = false, verticalOnMob = false }) => {
		const { bp } = useContext(ViewportContext);
		const isMob = bp === 'xs' || bp === 'sm';

		return (
			<Section className={classnames(css.section, { [css.verticalOnMob]: verticalOnMob })}>
				{forcedScroll && !isMob && (
					<CardsTicker
						endPad={1.5}
						oneCardVh={75}
						size={items.length}
						inContainer={true}
						progressBar={true}
						classNameScreen={css.stickyScreen}
						classNameWrap={css.ticker}
						before={
							<Heading
								className={css.headingSticky}
								inContainer={true}
								title={title}
								titleUrl={titleUrl}
							/>
						}>
						<CardsTickerFeed autoHeight={true} items={items} ChildCard={PersonsGalleryPreview} />
					</CardsTicker>
				)}
				{(!forcedScroll || (isMob && !verticalOnMob)) && (
					<Container className={css.sliderContainer}>
						<Heading className={css.heading} title={title} titleUrl={titleUrl} />
						<CardsSlider
							className={css.slider}
							classNameProgress={css.sliderProgress}
							items={items}
							ChildCard={PersonsGalleryPreview}
							progressBar={true}
						/>
					</Container>
				)}
				{isMob && verticalOnMob && (
					<Container>
						<Heading className={css.heading} title={title} titleUrl={titleUrl} />
						<Box className={css.verticalFeed}>
							{items.map((card, i) => (
								<PersonsGalleryPreview
									key={`persons-vertical-${i}-${card.title}`}
									index={i}
									total={items.length}
									{...card}
								/>
							))}
						</Box>
					</Container>
				)}
			</Section>
		);
	}
);
