import { PageData } from '@api/hooks/types';

export const bim: PageData = {
	meta: {
		title: 'Информационное моделирование',
		description:
			'Безопасный доступ к данным и высокая скорость обмена информацией между всеми участниками процесса',
	},
	data: [
		{
			type: 'intro',
			content: {
				title: 'Информационное<br /><color-accent>моделирование</color-accent>',
				breadcrumbs: [{ text: 'Перспективы' }, { text: 'BIM-технологии' }],
			},
		},

		{
			type: 'techPreamble',
			content: {
				text: '<color-accent>Безопасный доступ к данным</color-accent> и&nbsp;высокая скорость обмена информацией между всеми участниками процесса',
				ticker: 'BIM технологии',
			},
			wrapper: {
				type: 'stripsVertical',
				props: {
					color: 'white',
					startScreen: true,
					paddingTop: false,
				},
			},
		},

		{
			type: 'videoSequence',
			content: {
				video: {
					mp4: '/video/video-model.mp4',
				},
				points: [
					{
						time: '1.45',
						title: 'Системная<br />панель витража',
						content: [
							{
								type: 'html',
								content:
									'<p>Код классификатора: <nobr>200.040.001.001</nobr>.003</p>' +
									'<p>Однокамерный стеклопакет из&nbsp;просветленного стекла (12&nbsp;м х&nbsp;3&nbsp;м)</p>' +
									'<p>Уровень: 1 Этаж, Площадь: 36&nbsp;м&sup2;, Номер захватки: <nobr>В1-3</nobr></p>' +
									'<p>Дата монтажа: 15ноября 2021</p>',
							},
						],
					},
					{
						time: '3.5',
						title: 'Револьверная<br />входная дверь наружная',
						content: [
							{
								type: 'html',
								content:
									'<p>Код классификатора: <nobr>200.020.001.002</nobr>.001</p>' +
									'<p>Однокамерный стеклопакет из&nbsp;просветленного стекла</p>' +
									'<p>Уровень: 1 Этаж, Высота: 2600,000&nbsp;мм, Радиус: 1340,000&nbsp;мм</p>' +
									'<p>Дата монтажа: 23 ноября 2021&nbsp;г.</p>',
							},
						],
					},
					{
						time: '5.7',
						title: 'Фундаментная плита',
						content: [
							{
								type: 'html',
								content:
									'<p>Код классификатора: 100.010.003</p>' +
									'<p>Шифр проекта: 320АР/<nobr>СПД-КЖ5</nobr></p>' +
									'<p>Материал: Бетон монолитный В40, F300, W12; Арматура &#8709;25А500С, &#8709;32А500С, &#8709;36А500С</p>' +
									'<p>Отметка низа съемки: +127.500&nbsp;м</p>' +
									'<p>Уровень: -3 Этаж, Толщина: 1500&nbsp;мм, Объем: 507,947&nbsp;м&sup3;</p>' +
									'<p>Номер захватки: <nobr>ФП-2</nobr></p>' +
									'<p>Дата заливки: 15 августа 2019&nbsp;г.</p>',
							},
						],
					},
					{
						time: '7.7',
						title: 'Плита перекрытия',
						content: [
							{
								type: 'html',
								content:
									'<p>Код классификатора: <nobr>100.030.002.400</nobr></p>' +
									'<p>Шифр проекта: 320АР/<nobr>СПД1-КЖ3</nobr></p>' +
									'<p>Материал: Бетон монолитный В40, F300, W12; Арматура &#8709;16А500С, &#8709;32А500С,</p>' +
									'<p>Отметка низа съемки: +143.300&nbsp;м</p>' +
									'<p>Уровень: 1 Этаж, Толщина: 400&nbsp;мм, Объем: 273,12&nbsp;м&sup3;,<br />Номер захватки: <nobr>ПП-1</nobr>.4</p>' +
									'<p>Дата заливки: 10 февраля 2021&nbsp;г.</p>',
							},
						],
					},
					{
						time: '9.6',
						title: 'Несущая колонна<br />прямоугольного сечения',
						content: [
							{
								type: 'html',
								content:
									'<p>Код классификатора: <nobr>100.030.003.002</nobr>.03</p>' +
									'<p>Шифр проекта: 320АР/<nobr>СПД1-КЖ9</nobr></p>' +
									'<p>Материал: Бетон монолитный В60, F300, W20; Арматура &#8709;25А500С, &#8709;32А500С,</p>' +
									'<p>Уровень: 2&ndash;3 Этаж, Размер: 1500&times;900&nbsp;мм, Объем: 11,12&nbsp;м&sup3;,</p>' +
									'<p>Номер захватки: <nobr>К-2</nobr>.9</p>' +
									'<p>Дата заливки: 12 апреля 2021&nbsp;г.</p>',
							},
						],
					},
					{
						time: '11.65',
						title: 'Фасадные ламели',
						content: [
							{
								type: 'html',
								content:
									'<p>Код классификатора: <nobr>200.110.004.001</nobr>.003</p>' +
									'<p>Шифр проекта: <nobr>320АР-22-АР3</nobr></p>' +
									'<p>Материал: Алюминий</p>' +
									'<p>Дата монтажа: 25&nbsp;мая 2021&nbsp;г.</p>',
							},
						],
					},
					{
						time: '13.75',
						title: 'Прижимная стена',
						content: [
							{
								type: 'html',
								content:
									'<p>Код классификатора: <nobr>100.020.001.001</nobr></p>' +
									'<p>Шифр проекта: <nobr>320АР-22-КЖ4</nobr>.1</p>' +
									'<p>Материал: Бетон монолитный В60, F200, W12; Арматура &#8709;12А500С, &#8709;16А500С,</p>' +
									'<p>Уровень: -1 Этаж, Толщина:250&nbsp;мм, Объем: 44,17&nbsp;м&sup3;,</p>' +
									'<p>Номер захватки: <nobr>НК-1</nobr>.6</p>' +
									'<p>Дата заливки: 12 января 2020&nbsp;г.</p>',
							},
						],
					},
					{
						time: '16.25',
						title: 'Решетчатая<br />стальная ферма',
						content: [
							{
								type: 'html',
								content:
									'<p>Код классификатора: 100.040.005</p>' +
									'<p>Шифр проекта: <nobr>320АР-22-КМ2</nobr></p>' +
									'<p>Материал: Сталь С390, Вес: 2342кг</p>' +
									'<p>Номер захватки: <nobr>Ф-5</nobr>.2</p>' +
									'<p>Дата монтажа: 26 июля 2021&nbsp;г.</p>',
							},
						],
					},
					{
						time: '18.25',
						title: 'Экспуатируемая кровля<br />в зоне декинга',
						content: [
							{
								type: 'html',
								content:
									'<p>Код классификатора: <nobr>200.060.008.002</nobr></p>' +
									'<p>Шифр проекта: <nobr>320АР-22-АР3</nobr></p>' +
									'<p>Отметка верха: +158.950&nbsp;м</p>' +
									'<p>Материал: Бетон монолитный В60, F200, W12; Арматура &#8709;12А500С, &#8709;16А500С,</p>' +
									'<p>Уровень: 4 Этаж, Толщина:450&nbsp;мм, Площадь: 1057,97&nbsp;м&sup3;,</p>' +
									'<p>Дата устройства: 23 августа 2021&nbsp;г.</p>',
							},
						],
					},
					{
						time: '20.32',
						title: 'Эксплуатируемая кровля',
						content: [
							{
								type: 'html',
								content:
									'<p>Код классификатора: <nobr>200.060.006.003</nobr></p>' +
									'<p>Шифр проекта: <nobr>320АР-22-АР3</nobr></p>' +
									'<p>Пирог кровли: растительный слой, субстрат, геомат противоэрозионный, субстрат, дренажная мембрана, гидроизоляция, утеплитель, профлист</p>' +
									'<p>Площадь: 2590&nbsp;м&sup2;.</p>' +
									'<p>Толщина: 332&nbsp;мм.</p>',
							},
						],
					},
				],
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'white',
					startScreen: false,
					paddingTop: false,
					paddingBottom: false,
				},
			},
		},

		{
			type: 'techDetails',
			content: {
				sections: [
					{
						type: 'textBlock',
						content: {
							title: 'Применение <color-accent>технологии</color-accent>',
							html:
								'<p>/ На&nbsp;основе строительной информационной модели, отображающей в&nbsp;цифрах и&nbsp;графически выполнение по&nbsp;каждому виду работ, средствами в&nbsp;Autodesk Navisworks и&nbsp;Lement PRO реализована возможность наблюдения за&nbsp;ходом строительства в&nbsp;режиме реального времени, а&nbsp;также выполнение <nobr>план-факт</nobr> анализа.</p>' +
								'<p>Процесс эксплуатации выходит на&nbsp;качественно новый уровень благодаря обработке и&nbsp;хранению <nobr>инженерно-технической</nobr> документации по&nbsp;проекту в&nbsp;среде общих данных, обеспечивающей безопасный доступ к&nbsp;данным и&nbsp;высокую скорость обмена информацией между всеми участниками процесса.</p>',
						},
					},
					{
						type: 'featuresList',
						content: {
							title: 'Актуальность <color-accent>применения</color-accent>',
							list: [
								'Обеспечивает возможность привязки атрибутивных данных к каждому элементу 3D-модели',
								'Устранение ошибок в проекте за счет сборки всего проекта в едином информационном пространстве',
								'Поиск и разрешение пространственно-временных коллизий',
								'Автоматический план-факт анализ по каждому виду работ',
								'Сокращение сроков принятия  решений за счет оперативного получения информации об объекте',
								'Высокая скорость обмена информацией о проекте между его участниками',
							],
						},
					},
				],
				animation: 'bimCubeView',
			},
			wrapper: {
				type: 'stripsVerticalDouble',
				props: {
					color: 'dark',
					before: 'accent',
					paddingTop: false,
				},
			},
		},

		{
			type: 'brioMRS',
			content: {
				title: 'Визуализация интеграции <br />с BRIO MRS',
				project: {
					title: 'Павильон «Атомной энергии»',
					company: 'РосАтом',
					dates: 'авг. 2020 – окт. 2021',
				},
				pictures: [
					{
						src: '/temp/ar-collage-vdnkh-bg.jpg',
						width: 1920,
						height: 1080,
						alt: 'Павильон «Атомной энергии»',
					},
					{
						src: '/temp/ar-collage-vdnkh-top.png',
						width: 1920,
						height: 1080,
						alt: 'Павильон «Атомной энергии»',
					},
				],
			},
		},

		{
			type: 'splitViewSlides',
			content: {
				slides: [
					{
						title: 'Скорость работы и рост качества',
						text: 'Позволяет сократить срок принятия управленческих решений',
						icon: null,
					},
					{
						title: 'Заголовок второго слайда',
						text: 'Позволяет сократить срок принятия управленческих решений',
						icon: null,
					},
					{
						title: 'Скорость работы и рост качества',
						text: 'Позволяет сократить срок принятия управленческих решений',
						icon: null,
					},
					{
						title: 'Заголовок четвертый',
						text: 'Позволяет сократить срок принятия управленческих решений',
						icon: null,
					},
					{
						title: 'Скорость работы и рост качества',
						text: 'Позволяет сократить срок принятия управленческих решений',
						icon: null,
					},
				],
				settings: {
					strips: 'none',
					slideOnPrev: false,
					animation: 'bimFeaturesView',
				},
			},
		},

		{
			type: 'infoBlock',
			content: {
				subtitle:
					'<color-accent>Интеграция с BRIO MRS</color-accent> позволяет визуализировать цифровые объекты, встраивая их в окружающий физический мир',
				text: '<p>/ Технологическая аппаратно-цифровая платформа смешанной реальности. Позволяет эффективно управлять процессами проектирования, строительства объектов на всех стадиях жизненного цикла.</p>',
			},
			wrapper: {
				type: 'stripsVerticalDouble',
				props: {
					color: 'white',
					before: 'dark',
					paddingTop: true,
				},
			},
		},

		{
			type: 'picturesGallery',
			content: {
				title: 'Обучение',
				text: 'Наши отношения в команде крепкие и дружеские.<br />Наша преданность делу и уважение друг к другу создают ценные продукты',
				items: [
					{
						src: '/temp/pic-03.jpg',
						width: 692,
						height: 576,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-04.jpg',
						width: 692,
						height: 718,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-05.jpg',
						width: 692,
						height: 474,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-06.jpg',
						width: 692,
						height: 576,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-03.jpg',
						width: 692,
						height: 576,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-04.jpg',
						width: 692,
						height: 718,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-05.jpg',
						width: 692,
						height: 474,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-06.jpg',
						width: 692,
						height: 576,
						alt: 'Название картинки',
					},
				],
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'white',
					paddingBottom: true,
				},
			},
		},

		{
			type: 'benefitsFeed',
			content: {
				title: 'Преимущества<br /><color-accent>интеграции</color-accent>',
				items: [
					{
						title: 'Обеспечение возможности привязки атрибутивных данных',
						content: [
							{
								type: 'html',
								content:
									'<p>Позволяет эффективно управлять процессами проектирования, строительства объектов на всех стадиях жизненного цикла.</p>',
							},
						],
					},
					{
						title: 'Поиск и разрешение пространственно-временных коллизий',
						content: [
							{
								type: 'html',
								content:
									'<p>Позволяет эффективно управлять процессами проектирования, строительства объектов на всех стадиях жизненного цикла.</p>',
							},
						],
					},
					{
						title: '4D-BIM-моделирование хода строительных работ',
						content: [
							{
								type: 'html',
								content:
									'<p>Позволяет эффективно управлять процессами проектирования, строительства объектов на всех стадиях жизненного цикла.</p>',
							},
						],
					},
					{
						title: 'Высокая скорость обмена информацией о проекте между его участникам',
						content: [
							{
								type: 'html',
								content:
									'<p>Позволяет эффективно управлять процессами проектирования, строительства объектов на всех стадиях жизненного цикла.</p>',
							},
						],
					},
					{
						title: 'Сбор, обработка и хранение инженерно-технической документации по проекту ',
						content: [
							{
								type: 'html',
								content:
									'<p>Позволяет эффективно управлять процессами проектирования, строительства объектов на всех стадиях жизненного цикла.</p>',
							},
						],
					},
				],
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'dark',
				},
			},
		},

		{
			type: 'projectsGallery',
			content: {
				title: 'Проекты<br /><color-accent>на основе технологии</color-accent>',
				titleUrl: '/projects',
				items: [
					{
						title: 'Спортивный комплекс «Олимпийский»',
						url: '/projects/project',
						picture: {
							src: '/temp/project-01.jpg',
							width: 1280,
							height: 904,
							alt: 'Спортивный комплекс «Олимпийский»',
						},
					},
					{
						title: 'Новая штаб-квартира «Яндекса»',
						url: '/projects/project',
						picture: {
							src: '/temp/project-02.jpg',
							width: 1280,
							height: 904,
							alt: 'Новая штаб-квартира «Яндекса»',
						},
					},
					{
						title: 'Автовокзал в составе транспортно-пересадочного узла «Щелковский»',
						url: '/projects/project',
						picture: {
							src: '/temp/project-05.jpg',
							width: 1280,
							height: 904,
							alt: 'Автовокзал в составе транспортно-пересадочного узла «Щелковский»',
						},
					},
					{
						title: 'Павильон «Атомной энергии»',
						url: '/projects/project',
						picture: {
							src: '/temp/project-03.jpg',
							width: 1280,
							height: 904,
							alt: 'Павильон «Атомной энергии»',
						},
					},
					{
						title: '«Москва-Сити» <br />one tower',
						url: '/projects/project',
						picture: {
							src: '/temp/project-04.jpg',
							width: 1280,
							height: 904,
							alt: '«Москва-Сити» one tower',
						},
					},
					{
						title: 'Автовокзал в составе транспортно-пересадочного узла «Щелковский»',
						url: '/projects/project',
						picture: {
							src: '/temp/project-05.jpg',
							width: 1280,
							height: 904,
							alt: 'Автовокзал в составе транспортно-пересадочного узла «Щелковский»',
						},
					},
					{
						title: 'Павильон «Атомной энергии»',
						url: '/projects/project',
						picture: {
							src: '/temp/project-03.jpg',
							width: 1280,
							height: 904,
							alt: 'Павильон «Атомной энергии»',
						},
					},
				],
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'dark',
				},
			},
		},
	],
};
