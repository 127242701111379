import css from './FormComponent.module.scss';
import classnames from 'classnames';
import type { UseInputReturn } from '@core/Form/FormComponent/hooks/useInput';
import React, {
	PropsWithChildren,
	HTMLAttributes,
	Dispatch,
	SetStateAction,
	useState,
	useEffect,
	useCallback,
	FormEvent,
	useRef,
	LegacyRef,
} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormResponse } from '@core/Form/FormComponent/components/FormResponse/FormResponse';

export type FormSumbitHandlerType = (e: FormEvent, ctx?: FormContextProps) => void;

export type FormState = 'waiting' | 'pending' | 'success' | 'reject';

export type FormStorePayload = Pick<
	UseInputReturn<any>,
	'error' | 'value' | 'setError' | 'setValue'
>;
export type FormStore = Record<string, FormStorePayload>;

export interface FormProps extends HTMLAttributes<HTMLFormElement> {
	onSubmit: FormSumbitHandlerType;
	grcKey?: string;
}

export interface FormContextProps {
	formState?: FormState;
	setFormState?: Dispatch<SetStateAction<FormState>>;
	response?: FormResponse | null;
	storeResponse?: Dispatch<SetStateAction<FormResponse | null>>;
	store?: FormStore;
	register?: (name: string, payload: FormStorePayload) => void;
	checkCaptcha?: () => Promise<string | null>;
	resetCaptcha?: () => void;
}

export const FormContext = React.createContext<FormContextProps>({});

export const FormComponent = React.forwardRef<HTMLFormElement, PropsWithChildren<FormProps>>(
	({ onSubmit, grcKey, children, ...props }, ref) => {
		const [formState, setFormState] = useState<FormState>('waiting');
		const [response, storeResponse] = useState<FormResponse | null>(null);

		useEffect(() => {
			return () => {
				storeResponse(null);
				setFormState('waiting');
			};
		}, []);

		const [store, setStore] = useState<FormStore>({});

		const register = useCallback((name: string, payload: FormStorePayload) => {
			setStore((prev) => ({
				...prev,
				[name]: payload,
			}));
		}, []);

		const [showResponse, setShowResponse] = useState(false);

		useEffect(() => {
			setShowResponse(formState === 'success');

			return () => {
				setShowResponse(false);
			};
		}, [formState]);

		const recaptcha = useRef<ReCAPTCHA>();

		const checkCaptcha = useCallback(async () => {
			if (grcKey && recaptcha.current) {
				return recaptcha.current.executeAsync();
			} else {
				return new Promise<string>((resolve) => setTimeout(() => resolve(''), 10));
			}
		}, [grcKey, recaptcha]);

		const resetCaptcha = useCallback(() => {
			if (grcKey && recaptcha.current) {
				recaptcha.current.reset();
			}
		}, [grcKey, recaptcha]);

		return (
			<FormContext.Provider
				value={{
					formState,
					setFormState,
					response,
					storeResponse,
					store,
					register,
					checkCaptcha,
					resetCaptcha,
				}}>
				<div className={css.formWrapper}>
					<form
						ref={ref}
						className={classnames(css.form, css[formState])}
						onSubmit={(e) => {
							onSubmit(e, {
								formState,
								setFormState,
								response,
								storeResponse,
								store,
								register,
								checkCaptcha,
								resetCaptcha,
							});
						}}
						{...props}>
						{children}
						{grcKey && (
							<div tabIndex={-1}>
								<ReCAPTCHA
									ref={recaptcha as LegacyRef<ReCAPTCHA>}
									size="invisible"
									sitekey={grcKey}
								/>
							</div>
						)}
					</form>
					<FormResponse active={showResponse} response={response} />
				</div>
			</FormContext.Provider>
		);
	}
);
