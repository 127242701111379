import css from './GoToNext.module.scss';
import React, { FC, memo } from 'react';
import { Section, Container, Text, RawHtml, TitleLink, Tag } from '@core';

export interface GoToNextProps {
	pre?: string;
	text?: string;
	url?: string;
}

export const GoToNext: FC<GoToNextProps> = memo(({ pre, text, url }) => {
	return (
		<Section className={css.section}>
			<Container>
				<Text className={css.pre} size="sm" color="gray">
					<RawHtml content={pre} />
				</Text>
				<Tag className={css.link} type="h2">
					<TitleLink url={url} content={text} />
				</Tag>
			</Container>
		</Section>
	);
});
