import css from './CardsTicker.module.scss';
import classnames from 'classnames';
import fastdom from 'fastdom';
import React, {
	FC,
	PropsWithChildren,
	HTMLAttributes,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { Section, Box, Container, ProgressBar } from '@core';
import { ViewportContext } from '@context';
import { useScrollWithEase } from '@hooks';
import { getOffset, normalize, scrollToPosition } from '@utils';

interface CardsTickerProps {
	size: number;
	oneCardVh?: number;
	endPad?: number;
	startPad?: number;
	overlap?: number;
	resistance?: number;
	before?: ReactNode;
	after?: ReactNode;
	classNameWrap?: string;
	classNameScreen?: string;
	elevated?: boolean;
	inContainer?: boolean;
	progressBar?: boolean;
}

const DefaultWrapper: FC = ({ children = [] }) => {
	return <>{children}</>;
};

export const CardsTicker: FC<PropsWithChildren<CardsTickerProps & HTMLAttributes<HTMLElement>>> =
	React.memo(
		({
			size,
			oneCardVh = 50, // ? aspect vw/vh * card wide (40vw), 16/9 * 40 = 71.1vh
			endPad = 1,
			startPad = 1,
			overlap = 1,
			resistance = 0.125,
			before,
			after,
			className,
			classNameWrap,
			classNameScreen,
			elevated,
			inContainer,
			progressBar,
			children,
		}) => {
			const ref = useRef<HTMLDivElement | null>(null);
			const [wrap, setWrap] = useState<HTMLDivElement | null>();
			const [ticker, setTicker] = useState<HTMLDivElement | null>();

			const feedSize = size + endPad + startPad;
			const scrollWay = Math.max(feedSize * oneCardVh, 100);

			const { vh, vw } = useContext(ViewportContext);

			const [wayX, setWayX] = useState(0);
			const [seek, setSeek] = useState(0);

			useEffect(() => {
				const wrapWidth = wrap?.offsetWidth || vw;
				const tickerWidth = ticker?.offsetWidth || vw;

				setWayX(Math.max(tickerWidth - wrapWidth, 0));
			}, [vw, wrap, ticker, size]);

			const padTop = ((startPad * oneCardVh) / 100) * vh;
			const padBottom = ((endPad * oneCardVh) / 100) * vh;

			const onScroll = useCallback(
				(current: number) => {
					const trackHeight = (scrollWay / 100) * vh;
					const destination = trackHeight - vh * overlap;
					const position = current - vh * overlap;
					const normalized = 1 - normalize(position, destination - padTop, padBottom, true);

					setSeek(normalized);
				},
				[scrollWay, vh, padTop, padBottom, overlap]
			);

			useScrollWithEase(ref, onScroll, {
				ease: resistance,
				edge: 'bottom',
			});

			useEffect(() => {
				fastdom.mutate(() => {
					if (ticker) {
						ticker.style.transform = `translate3d(-${seek * wayX}px, 0, 0)`;
					}
				});
			}, [ticker, seek, wayX]);

			const onFocus = useCallback(
				(e) => {
					const section = ref?.current;
					if (!section) return;

					const isFocusVisible = e.target.matches(':focus-visible');
					if (!isFocusVisible) return;

					const wrapOffset = getOffset(section).top;
					const trackHeight = (scrollWay / 100) * vh;

					const index = Array.prototype.indexOf.call(e.target.parentNode?.children || [], e.target);
					const frag = index / (feedSize - 1);

					if (index < size - 1) {
						scrollToPosition(wrapOffset + padTop + trackHeight * frag);
					}
				},
				[size, vh, feedSize, scrollWay, padTop]
			);

			const Wrapper = inContainer ? Container : DefaultWrapper;

			return (
				<Section
					ref={ref}
					className={classnames(className, css.main)}
					style={{
						height: `${scrollWay}vh`,
					}}>
					<Box className={classnames(css.sticky, classNameScreen)}>
						{before}
						<Wrapper>
							<Box
								className={classnames(classNameWrap, css.wrap, { [css.elevated]: elevated })}
								ref={setWrap}>
								<Box ref={setTicker} onFocus={onFocus} className={css.ticker}>
									{children}
								</Box>
							</Box>
						</Wrapper>
						{after}
						{progressBar && <ProgressBar seek={seek} />}
					</Box>
				</Section>
			);
		}
	);
