import React, { FC, useContext } from 'react';
import { ViewportContext } from '@context';
import { ActivityScopesMobile } from '@components/ActivityScopes/components/ActivityScopesMobile/ActivityScopesMobile';
import { ActivityScopesRegular } from '@components/ActivityScopes/components/ActivityScopesRegular/ActivityScopesRegular';

export interface ActivityScopeType {
	title?: string;
	url?: string;
	shape: ServiceType;
}

export interface ActivityScopesProps {
	title?: string;
	titleUrl?: string;
	items: Array<ActivityScopeType>;
}

export const ActivityScopes: FC<ActivityScopesProps> = React.memo((props) => {
	const { bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	return (
		<>
			{isMob && <ActivityScopesMobile {...props} />}
			{!isMob && <ActivityScopesRegular {...props} />}
		</>
	);
});
