import css from './SplashMobile.module.scss';
import classnames from 'classnames';
import fastdom from 'fastdom';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { Box, Video } from '@core';
import { normalize } from '@utils';
import { HomeChildProps, HomeIntroContext } from '@components/HomeIntro/HomeIntro';
import { VideoType } from '@components/HomeIntro/components/Splash/components/SplashRegular/SplashRegular';
import { useIntersectionObserver } from '@hooks';

export interface Feature {
	text: string;
	media?: Array<Media>;
}

export type FeaturesType = Array<Feature>;

interface Props extends HomeChildProps {
	features: FeaturesType;
}

export const SplashMobile: FC<Props> = React.memo(({ start, end, ...rest }) => {
	const [wrap, setWrap] = useState<HTMLDivElement | null>(null);

	const { seek: seekMain } = useContext(HomeIntroContext);
	const seek = normalize(seekMain, end, start, true);

	useEffect(() => {
		fastdom.mutate(() => {
			if (!wrap) return;

			wrap.style.setProperty('--up', `${seek}`);
			wrap.style.setProperty('--scale', `${1 - seek}`);
		});
	}, [wrap, seek]);

	return (
		<Box ref={setWrap} className={classnames(css.module)}>
			<SplashContent {...rest} />
		</Box>
	);
});

export const SplashContent: FC<Pick<Props, 'features'>> = React.memo(({ features }) => {
	return (
		<>
			{features.map((item, i) => {
				return <SplashPoint key={item.text} index={i} {...item} />;
			})}
		</>
	);
});

export const SplashPoint: FC<Feature & { index: number }> = React.memo(({ index, media }) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const mainMediaItem = media && media[0];

	// useEffect(() => {
	// 	const el = ref.current;
	// 	if (!el) return;
	//
	// 	const onTick = () => {};
	//
	// 	Performance.addListener(onTick);
	//
	// 	return () => {
	// 		Performance.removeListener(onTick);
	// 	};
	// }, []);

	const entry = useIntersectionObserver(ref, {
		threshold: 0,
		freezeOnceVisible: false,
	});

	return (
		<Box ref={ref} className={classnames(css.point, css[`variant-${index + 1}`])}>
			<Box className={classnames(css.pointInner)}>
				<Box className={classnames(css.pointBox)}>
					<Box className={classnames(css.pointBoxMedia)}>
						{mainMediaItem?.type === 'picture' && (
							<picture>
								<Image
									src={(mainMediaItem as Picture).src}
									alt={(mainMediaItem as Picture).alt}
									layout="fill"
									sizes="50vw"
									quality={100}
									priority={index < 4}
								/>
							</picture>
						)}
						{mainMediaItem?.type === 'video' && (
							<Video
								className={css.pointBoxVideo}
								playing={!!entry?.isIntersecting}
								mp4={(mainMediaItem as VideoType).mp4}
								width={mainMediaItem.width}
								height={mainMediaItem.height}
							/>
						)}
					</Box>
				</Box>
			</Box>
		</Box>
	);
});
