import { PageData } from '@api/hooks/types';

export const corp: PageData = {
	meta: {
		title: 'Жизнь в Fensma',
		description:
			'Наш коллектив живет насыщенной жизнью, важной частью корпоративной культуры являются совместные мероприятия, праздники и поездки.',
	},
	data: [
		{
			type: 'intro',
			content: {
				title: 'Корпоративная<br /><color-accent>жизнь</color-accent>',
				breadcrumbs: [{ text: 'Карьера', url: '/career' }, { text: 'Жизнь в Fensma' }],
			},
		},

		{
			type: 'preambleTicker',
			content: {
				text: 'Наш коллектив живет насыщенной жизнью, важной частью <color-accent>корпоративной культуры</color-accent> являются совместные мероприятия, праздники и поездки',
				ticker: 'Жизнь Fensma',
				picture: {
					src: '/temp/pic-29.jpg',
					width: 1920,
					height: 1080,
					alt: 'Жизнь Fensma',
				},
			},
			wrapper: {
				type: 'stripsVertical',
				props: {
					color: 'white',
					startScreen: true,
					paddingTop: false,
				},
			},
		},

		{
			type: 'article',
			content: {
				items: [
					{
						type: 'html',
						content:
							'<h2>Спортивные мероприятия</h2>' +
							'<p>/ Одним из важнейших элементов корпоративной жизни является спорт.<br />Сотрудники могут найти занятия по вкусу: присоединиться к беговой команде, вступить в ряды сборной по мини-футболу, а также продемонстрировать свое мастерство в корпоративных чемпионатах.</p>' +
							'<h2>Культурная жизнь</h2>' +
							'<p>/ Наши отрудники увлекаются фотографией, сочиняют стихи, хорошо рисуют и обладают другими талантами, могут участвовать в регулярных корпоративных конкурсах.</p>' +
							'<h2>Социальная ответственность</h2>' +
							'<p>/ Мы поддерживаем стремление сотрудников, удваивая денежные пожертвования, направленные на помощь подопечным благотворительных фондов, больниц, приютов. Мы вместе собрали и сдали в переработку 346 кг батареек и 42 тонны офисной бумаги, потому что хотим жить в чистой стране и дышать чистым воздухом. Мы стремимся создать среду равных возможностей. Мы ищем людей, которые разделяют наши ценности.</p>',
					},
				],
				pictures: [
					{
						src: '/temp/pic-24.jpg',
						width: 480,
						height: 540,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-30.jpg',
						width: 480,
						height: 540,
						alt: 'Название картинки 2',
					},
				],
			},
			wrapper: {
				type: 'stripsVertical',
				props: {
					color: 'dark',
					startScreen: false,
					paddingTop: true,
				},
			},
		},

		{
			type: 'picturesGallery',
			content: {
				title: 'Галерея',
				items: [
					{
						src: '/temp/pic-25.jpg',
						width: 432,
						height: 360,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-26.jpg',
						width: 432,
						height: 448,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-27.jpg',
						width: 432,
						height: 296,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-28.jpg',
						width: 432,
						height: 360,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-25.jpg',
						width: 432,
						height: 360,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-26.jpg',
						width: 432,
						height: 448,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-27.jpg',
						width: 432,
						height: 296,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-28.jpg',
						width: 432,
						height: 360,
						alt: 'Название картинки',
					},
				],
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'dark',
					paddingTop: true,
				},
			},
		},

		{
			type: 'eventsFeed',
			links: {
				self: '/events',
			},
			content: {
				title: 'Последние<br /><color-accent>события</color-accent>',
				titleUrl: '/events',
				items: [
					{
						title:
							'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip в качестве делового партнёра',
						url: '/events/event',
						date: '2021-09-28 02:34:56',
						picture: {
							src: '/temp/event-1.png',
							width: 1280,
							height: 920,
							alt: 'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip',
						},
					},
					{
						title:
							'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
						url: '/events/event',
						date: '2021-09-25 02:34:56',
						picture: {
							src: '/temp/event-2.png',
							width: 1280,
							height: 920,
							alt: 'Участие в международной выставке GEOFLUID 2021',
						},
					},
					{
						title:
							'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
						url: '/events/event',
						date: '2021-09-20 02:34:56',
						picture: {
							src: '/temp/event-1.png',
							width: 1280,
							height: 920,
							alt: 'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip',
						},
					},
					{
						title:
							'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip в качестве делового партнёра',
						url: '/events/event',
						date: '2021-08-18 02:34:56',
						picture: {
							src: '/temp/event-2.png',
							width: 1280,
							height: 920,
							alt: 'Участие в международной выставке GEOFLUID 2021',
						},
					},
					{
						title:
							'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip в качестве делового партнёра',
						url: '/events/event',
						date: '2021-08-12 02:34:56',
						picture: {
							src: '/temp/event-1.png',
							width: 1280,
							height: 920,
							alt: 'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip',
						},
					},
					{
						title:
							'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
						url: '/events/event',
						date: '2021-07-06 02:34:56',
						picture: {
							src: '/temp/event-2.png',
							width: 1280,
							height: 920,
							alt: 'Участие в международной выставке GEOFLUID 2021',
						},
					},
					{
						title:
							'Участие в международной выставке GEOFLUID 2021, которая проходит в итальянском городе Пьяченца',
						url: '/events/event',
						date: '2021-05-02 02:34:56',
						picture: {
							src: '/temp/event-1.png',
							width: 1280,
							height: 920,
							alt: 'Компания FENSMA принимала участие в международном строительном чемпионате @worldconchip',
						},
					},
				],
				hasTrailAnimation: false,
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'dark',
					paddingTop: true,
				},
			},
		},

		{
			type: 'feedback',
			content: {
				title: 'Присоединяйся<br /><color-accent>к&nbsp;команде</color-accent>',
				text: '<p>Ознакомьтесь с нашими актуальными <a href="/career/vacancies">Вакансиями</a> или отправьте нам заявку и мы свяжемся с вами</p>',
				form: {
					action: '/forms/feedback',
					method: 'post',
					grcKey: '6LfURdcZAAAAAAz9aJ1T0WeUAeVGQI3RsarvmhRi',
					items: [
						{
							type: 'text',
							name: 'name',
							label: 'ФИО',
							required: true,
							validation: {
								required: 'Заполните это поле',
							},
						},
						{
							type: 'email',
							name: 'email',
							label: 'Электронная почта',
							required: true,
							pattern: '[0-9a-z_.-]+@[0-9a-z_]+\\.[a-z]{2,5}',
							validation: {
								required: 'Заполните это поле',
								pattern: 'Некорректный адрес email',
							},
						},
						{
							type: 'tel',
							name: 'phone',
							label: 'Номер телефона',
							required: true,
							mask: '+0 000 0000000',
							pattern: '^(\\+\\d)\\s\\d{3}\\s\\d{7}$',
							validation: {
								required: 'Заполните это поле',
								pattern: 'Некорректный номер телефона',
							},
						},
						{
							type: 'file',
							name: 'file',
							placeholder: 'Прикрепить резюме',
							accept: 'application/pdf',
							acceptLabel: 'pdf',
							sizeLimit: 52428800,
							sizeLimitLabel: 'До 50 Мб',
							validation: {
								accept: 'Недопустимый формат файла',
								size: 'Превышен допустимый размер файла',
							},
						},
					],
					button: 'Отправить',
					notice:
						'Нажимая «Отправить», я соглашаюсь с условиями<br /><a href="/" target="_blank">Политики конфиденциальности</a> и <a href="/" target="_blank">Пользовательского соглашения</a>',
				},
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'white',
					paddingTop: false,
					paddingBottom: false,
				},
			},
		},

		{
			type: 'goToNext',
			content: {
				pre: 'Перейти к следующему разделу',
				text: 'Профессионалам',
				url: '/career/prof',
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'transparent',
					paddingTop: false,
					paddingBottom: false,
				},
			},
		},
	],
};
