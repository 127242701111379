import css from '../StoryLine.module.scss';
import classnames from 'classnames';
import React, { FC, useContext } from 'react';
import { Box } from '@core';
import { StoryLineSeekContext } from '@components/StoryLine/components/StoryLineLayout';

export interface StoryLineProgressProps {}

export const StoryLineProgress: FC<StoryLineProgressProps> = ({}) => {
	const seek = useContext(StoryLineSeekContext);
	return <Box className={css.progress} style={{ height: `${seek * 100}%` }} />;
};
