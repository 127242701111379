import React, { FC, useContext } from 'react';
import { HomeChildProps } from '@components/HomeIntro/HomeIntro';
import { ViewportContext } from '@context';
import { SplashRegular } from '@components/HomeIntro/components/Splash/components/SplashRegular/SplashRegular';
import { SplashMobile } from '@components/HomeIntro/components/Splash/components/SplashMobile/SplashMobile';

interface Feature {
	text: string;
}

export type FeaturesType = Array<Feature>;

interface Props extends HomeChildProps {
	features: FeaturesType;
}

export const Splash: FC<Props> = React.memo((props) => {
	const { bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	return (
		<>
			{!isMob && <SplashRegular {...props} />}
			{isMob && <SplashMobile {...props} />}
		</>
	);
});
