import axios, { AxiosResponse } from 'axios';
import { getJWT, setJWT } from '@utils';

const API_URL = process.env.NEXT_PUBLIC_API_URL || '';

/*
 * Отсечение слэшей
 */
export const withoutTrailingSlash = (url: string) => url.replace(/\/$/, '');
export const withoutLeadingSlash = (url: string) => url.replace(/^\//, '');

export const trimSlash = (url: string) => withoutLeadingSlash(withoutTrailingSlash(url));

/*
 * Собирает url
 */
export const concatUrls = (base: string, url: string) => {
	base = withoutTrailingSlash(base);
	url = withoutLeadingSlash(url);

	if (url.match(base)) return url;

	return `${base}/${url}`;
};

/*
 * Возвращает полный url
 */
export const getUrl = (url: string): string => {
	return `${withoutTrailingSlash(API_URL)}/${withoutLeadingSlash(url)}`;
};

/*
 * Добавляет переданную локаль в url
 */
export const prepareApiUrl = (url: string, locale?: string, prefix: string = ''): string => {
	if (url.match(/(^\/(ru|en))/)) {
		return '/404';
		// return url.replace(/(^\/(ru|en))/, `$1${prefix}`);
	}
	return `/${locale}${prefix}${url}`;
};

export const withLocale = (url: string, locale?: string): string => {
	url = url.replace(/\/(ru|en)$/, '').replace(/^\/(ru|en)\//, '/');
	const i18n = locale ? `/${locale}` : `/ru`;
	return `${i18n}${url}`;
};

/*
 * Подготовка search string
 */
export const prepareSearchString = (url?: string) => {
	const search = url?.split('?');
	if (!search || !search[1]) return '';
	return search[1].replace(/(%5B)/gi, '[').replace(/(%5D)/gi, ']');
};

/*
 * Готовит заголовки
 */
export const getHeaders = (headers: Headers): Record<string, string> => {
	const access = getJWT();

	if (access) {
		headers.set('Authorization', `Bearer ${access}`);
	}

	headers.set('content-type', `application/json`);

	return Object.fromEntries(headers.entries());
};

/*
 * Обновление токена
 */
export const refreshToken = (): Promise<AxiosResponse> => {
	const refresh = getJWT('refresh');

	return axios({
		method: 'POST',
		url: getUrl('auth/refresh/'),
		data: { refresh: refresh },
	}).then((response) => {
		try {
			setJWT({
				access: response.data.access,
			});
			return response;
		} catch (e) {
			throw response;
		}
	});
};

export * from './jwt';
