import css from '../VacanciesGallery.module.scss';
import classnames from 'classnames';
import React, { FC } from 'react';
import { Box, Icon, LinkTag, RawHtml, Tag, Text } from '@core';

export interface VacanciesGalleryPreviewProps {
	title?: string;
	text?: string;
	index?: number;
	url?: string;
	className?: string;
}

export const VacanciesGalleryPreview: FC<VacanciesGalleryPreviewProps> = React.memo(
	({ title, text, index, url, className }) => {
		const classes = classnames(css.preview, className, 'is-accent-card');

		const content = (
			<Box className={css.content}>
				<Tag className={css.title} type="h3" content={title} isHTML={true} isDiv={true} />
				<Text size="md" color="inherit">
					<RawHtml content={text} />
				</Text>
			</Box>
		);

		return url ? (
			<LinkTag className={classes} href={url}>
				<Icon id="link" className={css.arrow} />
				{content}
			</LinkTag>
		) : (
			<Box className={classes}>{content}</Box>
		);
	}
);
