import css from './IconicProjectsMobile.module.scss';
import type { IconicProjectsProps } from '@components/IconicProjects/IconicProjects';
import React, { FC, useState } from 'react';
import { Box, TitleLink, Tag, Container } from '@core';
import { ProjectPreview } from '@components/ProjectsPreviews/components/ProjectPreview/ProjectPreview';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import classnames from 'classnames';
import { useRouter } from 'next/router';

export const IconicProjectsMobile: FC<IconicProjectsProps> = ({ aside, items }) => {
	const { locale } = useRouter();
	const swipeTip =
		locale === 'en' ? 'Swipe to next project' : 'Смахнуть, чтобы смотреть следущий проект';

	const [end, setEnd] = useState(false);

	const handleUpdate = (splide: any) => {
		if (splide) {
			const end = splide.Components.Controller.getEnd() + 1;
			const cur = splide.Components.Controller.getIndex() + 1;

			setEnd(cur === end);
		}
	};

	return (
		<Box className={css.module}>
			<Container className={css.head}>
				<Tag type="h1">
					<TitleLink url={aside?.url} content={aside?.title} />
				</Tag>
			</Container>
			<Box className={css.content}>
				<Splide
					options={{
						type: 'slide',
						autoWidth: true,
						autoHeight: true,
						arrows: false,
						pagination: false,
						speed: 800,
						rewindSpeed: 1200,
					}}
					onMove={handleUpdate}
					onMounted={handleUpdate}>
					{items?.map((project, i) => (
						<SplideSlide key={project.title}>
							<ProjectPreview num={i + 1} {...project} />
						</SplideSlide>
					))}
				</Splide>
				<Container className={classnames(css.tip, { [css.tipHidden]: end })}>{swipeTip}</Container>
			</Box>
		</Box>
	);
};
