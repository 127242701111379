import css from './PreambleMediaTransition.module.scss';
import classnames from 'classnames';
import React, {
	FC,
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import Image from 'next/image';
import { Box, Video as VideoComponent } from '@core';
import { ViewportContext } from '@context';
import { useScrollWithEase } from '@hooks';
import { normalize } from '@utils';
import fastdom from 'fastdom';

interface PreambleMediaTransitionProps {
	size?: number;
	picture?: Picture;
	video?: Video;
}

interface PreambleMediaContentProps extends Partial<PreambleMediaTransitionProps> {
	active?: boolean;
	opacity?: number;
	overlayOpacity?: number;
}

interface PreambleMediaTwinFramesProps {}

export const PreambleMediaTransition = React.memo(
	React.forwardRef<HTMLDivElement, PreambleMediaTransitionProps>(
		({ size = 3, picture, video }, forwardedRef) => {
			const ref = useRef<HTMLDivElement | null>(null);

			const { vh, bp } = useContext(ViewportContext);
			const isMob = bp === 'xs';

			const [seek, setSeek] = useState(0);
			const [active, setActive] = useState(false);

			const onScroll = useCallback(
				(current: number, height: number) => {
					const start = height;
					const end = current - vh * 0.6;
					const absolute = 1 - end / start;
					const relative = Math.max(Math.min(absolute, 1), 0);

					setSeek(relative);
				},
				[vh]
			);

			useScrollWithEase(ref, onScroll, {
				ease: 1, // 0.125,
				edge: 'bottom',
			});

			useEffect(() => {
				fastdom.mutate(() => {
					const section = ref?.current;

					if (section) {
						const stepA = normalize(seek, 0.15, 0, true);
						const stepB = normalize(seek, 0.5, 0.15, true);
						const stepC = normalize(seek, 0.6, 0.4, true);

						section.style.setProperty('--stepA', `${stepA}`);
						section.style.setProperty('--stepB', `${stepB}`);
						section.style.setProperty('--stepC', `${stepC}`);

						setActive(seek >= 0.6);
					}
				});
			}, [ref, seek]);

			size = isMob ? size / 2 : size;

			return (
				<Box
					ref={ref}
					className={css.section}
					style={{
						height: `${100 * size}vh`,
					}}>
					<Box className={css.track}>
						<Box className={css.sticky}>
							<PreambleMediaTwinFrames>
								<PreambleMediaContent
									picture={picture}
									video={video}
									active={active}
									opacity={normalize(seek, 0.6, 0.25, true)}
									overlayOpacity={normalize(seek, 0.7, 0.6, true)}
								/>
							</PreambleMediaTwinFrames>
						</Box>
					</Box>
				</Box>
			);
		}
	)
);

const PreambleMediaContent: FC<PreambleMediaContentProps> = React.memo(
	({ opacity, overlayOpacity, active, picture, video }) => {
		const videoStyle = { '--overlay': overlayOpacity } as React.CSSProperties;
		return (
			<Box
				className={css.mediaContent}
				style={{
					opacity: opacity || 0,
				}}>
				{picture && (
					<Image src={picture.src} alt={picture?.alt} layout="fill" quality={100} priority={true} />
				)}
				{video?.mp4 && (
					<VideoComponent
						className={css.mediaContentVideo}
						style={videoStyle}
						mp4={video.mp4}
						playing={!!active}
					/>
				)}
			</Box>
		);
	}
);

const PreambleMediaTwinFrames: FC<PropsWithChildren<PreambleMediaTwinFramesProps>> = React.memo(
	({ children }) => {
		return (
			<Box className={css.twinFrames}>
				<Box className={classnames(css.twinFramesBase, css.twinFramesRect)}>
					<div className={classnames(css.twinFramesStroke, css.twinFramesStrokeRight)} />
					<div className={classnames(css.twinFramesStroke, css.twinFramesStrokeLeft)} />
				</Box>
				<Box className={classnames(css.twinFramesTop, css.twinFramesRect)}>{children}</Box>
			</Box>
		);
	}
);
