import { PageData } from '@api/hooks/types';
import { VacancyDetailsType } from '@components/Vacancies/components/VacancyDetails/VacancyDetails';
import { footer, FooterData } from '@api/mock/footer';
import { header, HeaderData } from '@api/mock/header';
import { home } from '@api/mock/pages/home';
import { contacts } from '@api/mock/pages/contacts';
import { standard } from '@api/mock/pages/standard';
import { bim } from '@api/mock/pages/perspectives/bim';
import { projects } from '@api/mock/pages/projects';
import { project } from '@api/mock/pages/project';
import { events } from '@api/mock/pages/events';
import { event } from '@api/mock/pages/event';
import { generalContract } from '@api/mock/pages/activities/general-contract';
import { conversation } from '@api/mock/pages/activities/conversation';
import { company, tech, story, report, partners, control } from '@api/mock/pages/company';
import { career, corp, prof, students, stories, vacancies, vacancy } from '@api/mock/pages/career';
import { news, news2019, news2020 } from '@api/mock/newsList';
import { eventsAll, eventsNews, eventsTurnToWork } from '@api/mock/eventsList';
import { mapPoints, mapPointsFiltered } from '@api/mock/mapPoints';
import { reportsList, reportsList2020, reportsList2019 } from '@api/mock/reportsList';
import { documentsList, documentsListOne, documentsListTwo } from '@api/mock/documentsList';
import { rulesList, rulesListBuildingYard } from '@api/mock/rulesList';
import { vacanciesList, vacanciesListOther } from '@api/mock/vacanciesList';
import { vacancyDetails } from '@api/mock/vacancyDetails';
import { formError } from '@api/mock/forms/error';
import { formSuccess } from '@api/mock/forms/success';

export const mocks: Record<
	string,
	PageData | HeaderData | FooterData | FormResponse | VacancyDetailsType
> = {
	'/ru/menu/header': header,
	'/ru/menu/footer': footer,

	'/en/menu/header': header,
	'/en/menu/footer': footer,

	'/en/pages': home,
	'/en/pages/m-home': home,

	'/ru/pages/m-projects': projects,
	'/ru/pages/m-projects/:id': project,

	'/ru/pages/m-events': events,
	'/ru/pages/m-events/:id': event,

	'/ru/pages/m-perspectives/bim': bim,
	'/ru/pages/m-perspectives/program': standard,

	'/ru/pages/m-activities/general-contract': generalContract,
	'/ru/pages/m-activities/conversation': conversation,
	'/ru/pages/m-activities/zero-cycle': standard,
	'/ru/pages/m-activities/general-design': standard,
	'/ru/pages/m-activities/epc': standard,

	'/ru/pages/m-company': company,
	'/ru/pages/m-company/tech': tech,
	'/ru/pages/m-company/story': story,
	'/ru/pages/m-company/report': report,
	'/ru/pages/m-company/partners': partners,
	'/ru/pages/m-company/control': control,

	'/ru/pages/m-career': career,
	'/ru/pages/m-career/corp': corp,
	'/ru/pages/m-career/prof': prof,
	'/ru/pages/m-career/students': students,
	'/ru/pages/m-career/stories': stories,
	'/ru/pages/m-career/vacancies': vacancies,
	'/ru/pages/m-career/vacancy/id/:id': vacancy,

	'/ru/pages/m-contacts': contacts,

	'/forms/feedback': formError,
};

export const getMock = <T = PageData>(url: string): T | undefined => {
	let mock;

	Object.keys(mocks).forEach((r) => {
		const pattern = r.replace(/(\(\?)?:\w+/g, (match, optional) => {
			return optional ? match : '([^/?]+)';
		});

		const regExp = new RegExp(`^` + pattern + `(?:\\\\?([\\\\s\\\\S]*))?$`);
		let result = regExp.exec(url);

		if (!result) {
			return;
		}

		mock = mocks[r];
	});

	return mock;
};

export const mocksMappingByUrl: Record<string, any> = {
	'/events': {
		default: eventsAll,
		'1': eventsNews,
		'2': eventsTurnToWork,
	},
	'/events/news': {
		default: news,
		'2020': news2020,
		'2019': news2019,
	},
	'/projects/map': {
		default: mapPoints,
		manufacturing: mapPointsFiltered,
	},
	'/company/reports': {
		default: reportsList,
		'2020': reportsList2020,
		'2019': reportsList2019,
	},
	'/company/documents': {
		default: documentsList,
		'1': documentsListOne,
		'2': documentsListTwo,
	},
	'/career/vacancies': {
		default: vacanciesList,
		egypt: vacanciesListOther,
	},
	'/career/rules': {
		default: rulesList,
		'building-yard': rulesListBuildingYard,
	},
};

export const getDataMock = async (url: string) => {
	const frags = url.split('?');
	const pathname = frags[0];
	const query = frags[1];

	if (pathname.includes('/m-career/vacancy/id')) {
		return vacancy;
	}

	switch (pathname) {
		case '/m-events':
			const eventsType = new URLSearchParams(query).get('event_type');
			const events = mocksMappingByUrl[pathname];

			return events ? (eventsType && events[eventsType] ? events[eventsType] : events.default) : {};

		case '/m-events/news':
			const year = new URLSearchParams(query).get('year');
			const news = mocksMappingByUrl[pathname];

			return news ? (year && news[year] ? news[year] : news.default) : {};

		case '/m-projects/map':
			const mapPointsType = new URLSearchParams(query).get('type');
			const points = mocksMappingByUrl[pathname];

			return points
				? mapPointsType && points[mapPointsType]
					? points[mapPointsType]
					: points.default
				: {};

		case '/m-company/reports':
			const reportsYear = new URLSearchParams(query).get('year');
			const reports = mocksMappingByUrl[pathname];

			return reports
				? reportsYear && reports[reportsYear]
					? reports[reportsYear]
					: reports.default
				: {};

		case '/m-company/documents':
			const docsSection = new URLSearchParams(query).get('documents_section');
			const docs = mocksMappingByUrl[pathname];

			return docs ? (docsSection && docs[docsSection] ? docs[docsSection] : docs.default) : {};

		case '/m-career/vacancies':
			const vacLoc = new URLSearchParams(query).get('location');
			const vacs = mocksMappingByUrl[pathname];

			return vacs ? (vacLoc && vacs[vacLoc] ? vacs[vacLoc] : vacs.default) : {};

		case '/m-career/vacancy/id/1':
		case '/m-career/vacancy/id/2':
			return vacancyDetails;

		case '/m-career/rules':
			const rulesSection = new URLSearchParams(query).get('rules_section');
			const rules = mocksMappingByUrl[pathname];

			return rules
				? rulesSection && rules[rulesSection]
					? rules[rulesSection]
					: rules.default
				: {};
	}
};

export * from './header';
export * from './footer';
export * from './pages/home';
export * from './pages/perspectives/bim';
export * from './pages/projects';
export * from './pages/events';
