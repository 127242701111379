import css from './AboutContent.module.scss';
import classnames from 'classnames';
import fastdom from 'fastdom';
import { InfoBlock, InfoBlockProps } from '@components/InfoBlock/InfoBlock';
import React, {
	FC,
	HTMLAttributes,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { AnimationType } from '@components/animations';
import { animationComponents } from '@components/animations/Animation/Animation';
import useIntersectionObserver from '@hooks/useIntersectionObserver';
import { Box } from '@core';
import Image from 'next/image';
import { Move } from '@utils';
import { useScrollWithEase } from '@hooks';
import { ViewportContext } from '@context';

interface AboutContentProps extends InfoBlockProps {
	animation?: AnimationType;
	pictures?: Array<Picture | Array<Picture>>;
}

export const AboutContent: FC<AboutContentProps> = React.memo(
	({ title, subtitle, sentence, text, animation = 'twinFramesView', pictures }) => {
		const ref = useRef<HTMLDivElement | null>(null);

		const entry = useIntersectionObserver(ref, {
			threshold: 0.25,
			freezeOnceVisible: true,
		});

		return (
			<InfoBlock
				className={classnames(css.module, { [css.visible]: entry?.isIntersecting })}
				title={title}
				subtitle={subtitle}
				sentence={sentence}
				sentenceClassName={css.heading}
				text={text}>
				<AboutContentAside ref={ref} animation={animation} pictures={pictures} />
			</InfoBlock>
		);
	}
);

export const AboutContentAside = React.memo(
	React.forwardRef<
		HTMLDivElement,
		Pick<AboutContentProps, 'animation' | 'pictures'> & HTMLAttributes<HTMLDivElement>
	>(({ animation, pictures }, ref) => {
		const Animation = animation && animationComponents[animation];
		const hasPictures = pictures && pictures.length > 0;

		return (
			<Box ref={ref} className={css.aside}>
				{!hasPictures && animation && Animation && <Animation />}
				{hasPictures && <AboutContentPictures pictures={pictures} />}
			</Box>
		);
	})
);

export const AboutContentPictures: FC<
	Pick<AboutContentProps, 'pictures'> & HTMLAttributes<HTMLDivElement>
> = React.memo(({ pictures }) => {
	const ref = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const container = ref?.current;
		if (!container) return;

		const onTick = ({ x, y }: { x: number; y: number }) => {
			fastdom.mutate(() => {
				container.style.setProperty('--mx', `${x}`);
				container.style.setProperty('--my', `${y}`);
			});
		};

		Move.addListener(onTick);

		return () => {
			Move.removeListener(onTick);
		};
	}, [ref]);

	const { vh } = useContext(ViewportContext);

	const onScroll = useCallback(
		(current: number) => {
			const quarter = 0; // vh / 4;
			const height = vh - quarter;

			const target = Math.min(Math.max(current - quarter, -height), height) / height;

			fastdom.mutate(() => {
				if (ref?.current) {
					ref.current.style.setProperty('--sy', `${target.toFixed(4)}`);
				}
			});
		},
		[vh]
	);

	useScrollWithEase(ref, onScroll, {
		ease: 0.125,
		edge: 'top',
	});

	return (
		<Box ref={ref} className={css.pictures}>
			{pictures?.map((item, i) => {
				const pics = Array.isArray(item) ? item : [item];
				const src = Array.isArray(item) ? item[0].src : item.src;
				return <AboutContentPicturesGroup key={src + '+' + i} pictures={pics} />;
			})}
		</Box>
	);
});

export const AboutContentPicturesGroup: FC<{ pictures: Array<Picture> }> = React.memo(
	({ pictures }) => {
		const ref = useRef<HTMLDivElement | null>(null);
		const [counter, setState] = useState(0);
		const [lock, setLock] = useState(false);

		const handleHoverIn = useCallback(() => {
			if (lock || !pictures?.length) return;
			setState((prev) => {
				return prev + 1 > pictures.length - 1 ? 0 : prev + 1;
			});
		}, [pictures, lock]);

		useEffect(() => {
			let timer = 0;
			const container = ref?.current;

			if (container) {
				setLock(true);

				const entities = container.querySelectorAll(`.${css.picturesEntity}`);
				const target = entities[counter];

				if (target) {
					target.classList.add(css.isShown);

					entities.forEach((el) => {
						if (el !== target) {
							el.classList.add(css.isDown);
						}
					});

					window.clearTimeout(timer);

					timer = window.setTimeout(() => {
						entities.forEach((el) => {
							if (el !== target) {
								el.classList.remove(css.isShown);
							}
							el.classList.remove(css.isDown);
							setLock(false);
						});
					}, 800);
				}
			}

			return () => {
				window.clearTimeout(timer);
			};
		}, [counter]);
		return (
			<Box ref={ref} className={css.picturesGroup} onMouseEnter={handleHoverIn}>
				<Box className={css.picturesGroupInner}>
					{pictures.map((item, i) => (
						<Box key={item.src + '+' + i} className={css.picturesEntity}>
							<Image
								className={css.picture}
								key={item.src + '+' + i}
								src={item.src}
								alt={item.alt}
								layout="fill"
								sizes="(max-width: 639px) 100vw, 35vw"
							/>
						</Box>
					))}
				</Box>
			</Box>
		);
	}
);
