import { PageData } from '@api/hooks/types';

export const prof: PageData = {
	meta: {
		title: 'Профессионалам',
		description:
			'Наши сотрудники — наш главный капитал, поэтому мы уделяем большое внимание развитию карьеры и профессиональному совершенствованию.',
	},
	data: [
		{
			type: 'intro',
			content: {
				title: 'Все возможности<br /><color-accent>для развития</color-accent>',
				breadcrumbs: [{ text: 'Карьера', url: '/career' }, { text: 'Профессионалам' }],
			},
		},

		{
			type: 'preambleMedia',
			content: {
				subtitle:
					'<color-accent>Наши сотрудники&nbsp;&mdash; наш главный капитал,</color-accent> поэтому мы уделяем большое внимание развитию карьеры и профессиональному совершенствованию',
				text: '/ Компания Fensma стремительно развивается. Внедрение системы управления талантами позволяет закрывать вакансии внутренними кандидатами, которые увлечены своим делом и готовы расти и развиваться вместе с компанией. Планирование карьеры в Fensma – одно из приоритетных направлений кадровой работы. Мы гордимся достижениями и результатами наших сотрудников',
				picture: {
					src: '/temp/pic-43.jpg',
					width: 1920,
					height: 1080,
					alt: 'Стажировка',
				},
			},
			wrapper: {
				type: 'stripsVertical',
				props: {
					color: 'white',
					startScreen: true,
					withStripedTail: false,
				},
			},
		},

		{
			type: 'article',
			content: {
				items: [
					{
						type: 'html',
						content:
							'<h1>Что вас ждёт</h1>' +
							'<ol>' +
							'<li>Интересные задачи, профессиональное развитие и&nbsp;карьерный рост</li>' +
							'<li>Возможность реализовать свои идеи</li>' +
							'<li>Работу в&nbsp;команде, ориентированной на&nbsp;успех и&nbsp;достижение целей</li>' +
							'<li>Атмосферу развития и&nbsp;созидания</li>' +
							'<li>Современное техническое оснащение офиса и&nbsp;строительных площадок</li>' +
							'</ol>' +
							'<hr />' +
							'<h3>В центре внимания Fensma — люди, и нам важно, чтобы наши сотрудники чувствовали себя хорошо</h3>' +
							'<p>/ 19 июня 2020 года. Яндекс представил проект новой <nobr>штаб-квартиры</nobr> в&nbsp;Гагаринском районе Москвы. Здание будет расположено на&nbsp;месте гостиницы &laquo;Корстон&raquo;.</p>' +
							'<p>Территория <nobr>штаб-квартиры</nobr> и&nbsp;первые два этажа будут открыты для всех. На&nbsp;нижних этажах будут работать кафе, аптеки, салоны красоты и&nbsp;другие организации, а&nbsp;вокруг офиса появится общественное пространство, где любой человек сможет отдохнуть, перекусить или позаниматься спортом. Яндекс также благоустроит большую территорию возле <nobr>штаб-квартиры</nobr>: между улицами Академика Зелинского и&nbsp;Академика Зельдовича появится зелёный парк, который отлично подойдёт для прогулок на&nbsp;свежем воздухе.</p>',
					},
				],
				pictures: [
					{
						src: '/temp/pic-30.jpg',
						width: 480,
						height: 540,
						alt: 'Название картинки',
					},
					{
						src: '/temp/pic-14.jpg',
						width: 480,
						height: 540,
						alt: 'Название картинки 2',
					},
				],
			},
			wrapper: {
				type: 'stripsVertical',
				props: {
					color: 'dark',
					paddingTop: true,
					paddingBottom: false,
				},
			},
		},

		{
			type: 'personsGallery',
			content: {
				title: 'Вдохновляющий<br /><color-accent>опыт</color-accent>',
				items: [
					{
						title: 'Мария Иванова',
						subtitle: 'Главный менеджер по охране окружающей среды',
						text: '<p>Маша закончила факультет строительства и была первым консультантом по вопросам окружающей среды по вопросам строительства, когда она присоединилась к команде в 2011 году. Сейчас она является менеджером по охране окружающей среды на самых крупных и значимых строительных объектов.</p>',
						picture: {
							src: '/temp/pic-31.jpg',
							width: 320,
							height: 408,
							alt: 'Мария Иванова',
						},
					},
					{
						title: 'Алиса Демидова',
						subtitle: 'Руководитель',
						text: '<p>Алиса закончила факультет строительства и была первым консультантом по вопросам окружающей среды по вопросам строительства, когда она присоединилась к команде в 2011 году. Сейчас она является менеджером по охране окружающей среды на самых крупных и значимых строительных объектов.</p>',
						picture: {
							src: '/temp/pic-32.jpg',
							width: 320,
							height: 408,
							alt: 'Мария Иванова',
						},
					},
					{
						title: 'Мария Иванова',
						subtitle: 'Главный менеджер по охране окружающей среды',
						text: '<p>Маша закончила факультет строительства и была первым консультантом по вопросам окружающей среды по вопросам строительства, когда она присоединилась к команде в 2011 году. Сейчас она является менеджером по охране окружающей среды на самых крупных и значимых строительных объектов.</p>',
						picture: {
							src: '/temp/pic-31.jpg',
							width: 320,
							height: 408,
							alt: 'Мария Иванова',
						},
					},
					{
						title: 'Алиса Демидова',
						subtitle: 'Руководитель',
						text: '<p>Алиса закончила факультет строительства и была первым консультантом по вопросам окружающей среды по вопросам строительства, когда она присоединилась к команде в 2011 году. Сейчас она является менеджером по охране окружающей среды на самых крупных и значимых строительных объектов.</p>',
						picture: {
							src: '/temp/pic-32.jpg',
							width: 320,
							height: 408,
							alt: 'Мария Иванова',
						},
					},
					{
						title: 'Мария Иванова',
						subtitle: 'Главный менеджер по охране окружающей среды',
						text: '<p>Маша закончила факультет строительства и была первым консультантом по вопросам окружающей среды по вопросам строительства, когда она присоединилась к команде в 2011 году. Сейчас она является менеджером по охране окружающей среды на самых крупных и значимых строительных объектов.</p>',
						picture: {
							src: '/temp/pic-31.jpg',
							width: 320,
							height: 408,
							alt: 'Мария Иванова',
						},
					},
					{
						title: 'Алиса Демидова',
						subtitle: 'Руководитель',
						text: '<p>Алиса закончила факультет строительства и была первым консультантом по вопросам окружающей среды по вопросам строительства, когда она присоединилась к команде в 2011 году. Сейчас она является менеджером по охране окружающей среды на самых крупных и значимых строительных объектов.</p>',
						picture: {
							src: '/temp/pic-32.jpg',
							width: 320,
							height: 408,
							alt: 'Мария Иванова',
						},
					},
				],
				forcedScroll: false,
				verticalOnMob: false,
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'dark',
					paddingTop: true,
					paddingBottom: false,
				},
			},
		},

		{
			type: 'vacanciesGallery',
			content: {
				title: 'Вакансии',
				titleUrl: '/career/vacancies',
				items: [
					{
						title: 'Стажировка 1',
						text: 'Оплачиваемая стажировка для студентов строительных специальностей',
						url: '/career/vacancies/vacancy',
					},
					{
						title: 'Стажировка 2',
						text: 'Оплачиваемая стажировка для студентов строительных специальностей',
						url: '/career/vacancies/vacancy',
					},
					{
						title: 'Стажировка 3',
						text: 'Оплачиваемая стажировка для студентов строительных специальностей',
						url: '/career/vacancies/vacancy',
					},
					{
						title: 'Стажировка 4',
						text: 'Оплачиваемая стажировка для студентов строительных специальностей',
						url: '/career/vacancies/vacancy',
					},
					{
						title: 'Стажировка 5',
						text: 'Оплачиваемая стажировка для студентов строительных специальностей',
						url: '/career/vacancies/vacancy',
					},
				],
				forcedScroll: false,
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'transparent',
					paddingTop: true,
				},
			},
		},

		{
			type: 'feedback',
			content: {
				title: 'Присоединяйся<br /><color-accent>к&nbsp;команде</color-accent>',
				text: '<p>Ознакомьтесь с нашими актуальными <a href="/career/vacancies">Вакансиями</a> или отправьте нам заявку и мы свяжемся с вами</p>',
				form: {
					action: '/forms/feedback',
					method: 'post',
					grcKey: '6LfURdcZAAAAAAz9aJ1T0WeUAeVGQI3RsarvmhRi',
					items: [
						{
							type: 'text',
							name: 'name',
							label: 'ФИО',
							required: true,
							validation: {
								required: 'Заполните это поле',
							},
						},
						{
							type: 'email',
							name: 'email',
							label: 'Электронная почта',
							required: true,
							pattern: '[0-9a-z_.-]+@[0-9a-z_]+\\.[a-z]{2,5}',
							validation: {
								required: 'Заполните это поле',
								pattern: 'Некорректный адрес email',
							},
						},
						{
							type: 'tel',
							name: 'phone',
							label: 'Номер телефона',
							required: true,
							mask: '+0 000 0000000',
							pattern: '^(\\+\\d)\\s\\d{3}\\s\\d{7}$',
							validation: {
								required: 'Заполните это поле',
								pattern: 'Некорректный номер телефона',
							},
						},
						{
							type: 'file',
							name: 'file',
							placeholder: 'Прикрепить резюме',
							accept: 'application/pdf',
							acceptLabel: 'pdf',
							sizeLimit: 52428800,
							sizeLimitLabel: 'До 50 Мб',
							validation: {
								accept: 'Недопустимый формат файла',
								size: 'Превышен допустимый размер файла',
							},
						},
					],
					button: 'Отправить',
					notice:
						'Нажимая «Отправить», я соглашаюсь с условиями<br /><a href="/" target="_blank">Политики конфиденциальности</a> и <a href="/" target="_blank">Пользовательского соглашения</a>',
				},
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'white',
					paddingTop: false,
					paddingBottom: false,
				},
			},
		},

		{
			type: 'goToNext',
			content: {
				pre: 'Перейти к следующему разделу',
				text: 'Студентам',
				url: '/career/students',
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'transparent',
					paddingTop: false,
					paddingBottom: false,
				},
			},
		},
	],
};
