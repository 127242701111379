import css from './StoryLine.module.scss';
import React, { FC } from 'react';
import { Section, Container, Heading } from '@core';
import { StoryLineLayout } from '@components/StoryLine/components/StoryLineLayout';

export interface StoryLineEvent {
	text: string;
	url: string;
}

export interface StoryLineSection extends StoryLineEvent {
	title?: string;
	picture: Picture;
	events: Array<StoryLineEvent>;
}

export interface StoryLineProps {
	title?: string;
	sections: Array<StoryLineSection>;
}

export const StoryLine: FC<StoryLineProps> = React.memo(({ title, sections }) => {
	return (
		<Section className={css.module}>
			<Container>
				<Heading className={css.heading} title={title} offsetType="regular" />
				<StoryLineLayout sections={sections} />
			</Container>
		</Section>
	);
});
