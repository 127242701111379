import css from './ActivityScopesCard.module.scss';
import classnames from 'classnames';
import type { ActivityScopeType } from '@components/ActivityScopes/ActivityScopes';
import React, { FC, HTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { Box, Icon, Tag } from '@core';
import { isTouchDevice, Move } from '@utils';
import { useOutsideClick } from '@hooks';
import { spheres } from '../ActivitySpheres/ActivitySpheres';

export interface ActivityScopesCardProps extends ActivityScopeType {
	className?: string;
}

const isTouch = isTouchDevice() || false;

export const ActivityScopesCard: FC<ActivityScopesCardProps> = ({
	className,
	title,
	url,
	shape,
}) => {
	const ref = useRef<HTMLAnchorElement | null>(null);
	const [clicked, setClicked] = useState(false);

	const handleOutsideClick = useCallback(() => {
		setClicked(false);
	}, []);

	useOutsideClick(ref, handleOutsideClick);

	return (
		<Link href={url || ''}>
			<a
				ref={ref}
				className={classnames(className, css.module, {
					[css.clicked]: clicked,
				})}
				onClick={(e) => {
					if (isTouch && !clicked) {
						e.preventDefault();
						setClicked(true);
					}
				}}>
				<Box className={css.content}>
					{shape && <ActivitySphere shape={shape} className={css.canvas} />}
					<Tag className={css.title} type="h3" content={title} isHTML={true} />
					<Icon className={css.arrow} id="link" />
				</Box>
			</a>
		</Link>
	);
};

export const ActivitySphere = React.memo(
	React.forwardRef<
		HTMLDivElement,
		Pick<ActivityScopesCardProps, 'shape'> & HTMLAttributes<HTMLDivElement>
	>(({ shape, className }, ref) => {
		const [canvas, setCanvas] = useState<HTMLDivElement | null>();

		useEffect(() => {
			if (!canvas) return;

			const onTick = ({ x, y }: { x: number; y: number }) => {
				canvas.style.cssText = `
					--mx: ${x};
					--my: ${y};
				`;
			};

			Move.addListener(onTick);

			return () => {
				Move.removeListener(onTick);
			};
		}, [canvas]);

		const Svg = spheres[shape] || (() => <></>);

		return (
			<div className={className} ref={setCanvas}>
				<div ref={ref}>
					<Svg />
				</div>
			</div>
		);
	})
);
