import { AnchorHTMLAttributes } from 'react';
import { CookieConsentProps } from '@core/CookieConsent/CookieConsent';

interface FooterLink {
	text: string;
	url: string;
	isContact?: boolean;
	props?: AnchorHTMLAttributes<HTMLAnchorElement>;
}

export interface FooterDoc {
	text: string;
	url: string;
}

export interface FooterData {
	navigation: Array<FooterLink>;
	phone?: string;
	email?: string;
	cookiePopupContent?: CookieConsentProps;
	privacyPolicy?: FooterDoc;
	safetyPolicy?: FooterDoc;
	healthPolicy?: FooterDoc;
	environmentalPolicy?: FooterDoc;
	qualityPolicy?: FooterDoc;
}

export const footer: FooterData = {
	navigation: [
		{
			text: 'Проекты',
			url: '/projects',
		},
		{
			text: 'О компании',
			url: '/about-us',
		},
		{
			text: 'Карьера',
			url: '/work-us',
		},
		{
			text: 'Переспективы',
			url: '/bim-modelirovanie',
		},
		{
			text: 'Новости',
			url: '/events',
		},
		{
			text: 'Деятельность',
			url: '/general-contractor',
		},
		{
			text: 'Контакты',
			url: '/contacts',
		},
		{
			text: 'telegram',
			url: 'https://t.me/rdclr_home',
			isContact: true,
		},
		{
			text: 'vkontakte',
			url: 'https://vk.com/redcollar',
			isContact: true,
		},
	],
	phone: '+7 (495) 780-48-14',
	email: 'info@fensma.ru',
	cookiePopupContent: {
		title: 'Мы используем cookie-файлы',
		text: 'Находясь на нашем сайте, вы соглашаетесь с <a href="/dummy/dummy.pdf" target="_blank">правилами использования файлов cookie</a>',
		accept: 'Хорошо',
		decline: 'Отказаться',
	},
};
