import type { ScrollData } from '@utils/utils.performance/scroll';
import { useEffect } from 'react';
import { Scroll } from '@utils';

export const useScroll = (cb: (data: ScrollData) => void) => {
	useEffect(() => {
		let timer = 0;

		const onScroll = () => {
			const data = Scroll.getData();
			cb(data);
		};

		onScroll();
		timer = window.setTimeout(onScroll, 100);

		Scroll.addListener(onScroll);

		return () => {
			Scroll.removeListener(onScroll);
			window.clearTimeout(timer);
		};
	}, [cb]);
};
