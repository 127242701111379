import css from '../Header.module.scss';
import React, { FC } from 'react';
import Link from 'next/link';

interface Props {}

export const Logo: FC<Props> = () => {
	return (
		<Link href="/">
			<a className={css.logo} aria-label="Fensma">
				<svg width="114" height="24" viewBox="0 0 114 24">
					<g fillRule="nonzero">
						<path
							fill="#B41E9C"
							d="M0 23.574V.414h18.101v3.913H4.711v5.146h11.525v3.913H4.71v10.188z"
						/>
						<path d="M17.332 23.574V.414h17.217v3.913H22.042v5.146H33.28v3.913H22.042v6.274h12.965v3.914z" />
						<path d="M34.363.414v23.16h4.357V8.353l9.42 15.22h4.71V.415h-4.356v15.517L38.92.414z" />
						<path d="M51.959 15.621h4.582c.273 1.52.828 2.641 1.663 3.353.844.72 1.97 1.073 3.392 1.073 1.512 0 2.645-.312 3.408-.953.764-.632 1.15-1.376 1.15-2.232 0-.545-.16-1.009-.482-1.4-.322-.385-.885-.713-1.688-.993-.539-.2-1.785-.528-3.738-1.016-2.508-.625-4.268-1.393-5.28-2.305-1.424-1.273-2.13-2.833-2.13-4.674 0-1.176.337-2.289 1.004-3.321.667-1.024 1.631-1.809 2.893-2.345C57.995.264 59.508 0 61.3 0c2.91 0 5.096.64 6.567 1.929 1.47 1.288 2.243 3.16 2.315 5.313h-4.71c-.201-1.2-.627-2.056-1.286-2.576-.652-.52-1.64-.785-2.95-.785-1.35 0-2.412.28-3.175.84-.49.36-.74.84-.74 1.441 0 .552.233 1.024.7 1.409.586.504 2.017 1.016 4.3 1.56 2.283.528 3.962 1.088 5.056 1.665 1.093.576 1.945 1.36 2.564 2.352.619 1 .924 2.225.924 3.69 0 1.328-.37 2.569-1.11 3.729-.739 1.16-1.784 2.024-3.142 2.585-1.35.56-3.038.848-5.064.848-2.934 0-5.192-.68-6.768-2.033-1.575-1.344-2.516-3.769-2.821-6.346z" />
						<path d="M70.164 23.574V.414h7.05l4.17 15.797L85.509.414h7.066v23.16h-4.349l-.016-18.23-4.59 18.23h-4.525L74.53 5.344l-.016 18.23z" />
						<path d="M113.609 23.574h-5.08l-2.001-5.274h-9.332l-1.937 5.274h-4.976L99.31.414h4.975l9.324 23.16zm-8.608-9.187l-3.232-8.571-3.143 8.57h6.375z" />
					</g>
				</svg>
			</a>
		</Link>
	);
};
