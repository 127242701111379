import css from './Share.module.scss';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { Box } from '@core';

export interface ShareLink {
	text: string;
	href: string;
}

export interface ShareProps {
	title?: string;
	items: Array<ShareLink>;
}

export const Share: FC<ShareProps> = React.memo(({ title, items }) => {
	return (
		<Box className={css.sharing}>
			{title && <Box className={css.title}>{title}</Box>}
			<Box className={css.links}>
				{items.map((item) => (
					<ShareLink key={item.text + item.href} {...item} />
				))}
			</Box>
		</Box>
	);
});

export const ShareLink: FC<ShareLink> = React.memo(({ text, href }) => {
	const ref = useRef<HTMLAnchorElement | null>(null);

	useEffect(() => {
		const link = ref?.current;
		if (!link) return;

		const origin = encodeURIComponent(window.location.origin);
		const title = encodeURIComponent(document.title);

		link.href = href + `?url=${origin}&title=${title}`;
	}, [href]);

	const handleClick = useCallback((e) => {
		e.preventDefault();

		const isSSR = typeof window === 'undefined';
		const target = e.target as HTMLAnchorElement;

		if (!isSSR && target) {
			window.open(
				target.href,
				'Share',
				`top=200,left=${window.innerWidth / 2 - 300},width=600,height=400`
			);
		}
	}, []);

	return (
		<a ref={ref} onClick={handleClick}>
			{text}
		</a>
	);
});
