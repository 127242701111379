import css from './ProjectsPreviewsRegular.module.scss';
import React, { FC } from 'react';
import { Section, Box, Heading } from '@core';
import { ProjectsPreviewsProps } from '@components/ProjectsPreviews/ProjectsPreviews';
import { ProjectPreview } from '@components/ProjectsPreviews/components/ProjectPreview/ProjectPreview';

export const ProjectsPreviewsRegular: FC<ProjectsPreviewsProps> = React.memo(
	({ title, titleUrl, ...rest }) => {
		return (
			<Section className={css.module}>
				{title && (
					<Heading className={css.heading} title={title} titleUrl={titleUrl} offsetType="regular" />
				)}
				<Box>
					<ProjectsPreviewsRegularContent {...rest} />
				</Box>
			</Section>
		);
	}
);

export const ProjectsPreviewsRegularContent: FC<ProjectsPreviewsProps> = React.memo(({ items }) => {
	return (
		<>
			{Array.isArray(items) &&
				items?.map((project, i) => (
					<ProjectPreview key={project.title} className={css.preview} num={i + 1} {...project} />
				))}
		</>
	);
});
