import css from '../ReportsMain.module.scss';
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Container, Box, Tag, Text, RawHtml } from '@core';
import { useScrollWithEase } from '@hooks';
import { ViewportContext } from '@context';
import { ReportsMainChart } from '@components/ReportsMain/components/ReportsMainChart/ReportsMainChart';
import { ReportsMainProps } from '@components/ReportsMain/ReportsMain';
import { findLastIndex, normalize } from '@utils';
import classnames from 'classnames';

const overlap = 0.1;

export const ReportsMainPresentation: FC<Pick<ReportsMainProps, 'charts'>> = ({ charts }) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const [seek, setSeek] = useState(0);

	const { vh } = useContext(ViewportContext);
	const scrollWay = Math.max(6 * 50, 100);

	const onScroll = useCallback(
		(current: number) => {
			const height = (scrollWay / 100) * vh;
			const start = height - vh * (1 - overlap * 2);
			const end = current - vh;
			const absolute = 1 - end / start;
			const relative = Math.min(Math.max(Math.min(absolute, 1 / (1 - overlap * 3)), 0), 1);

			setSeek(relative);
		},
		[vh, scrollWay]
	);

	useScrollWithEase(ref, onScroll, {
		ease: 0.35,
		edge: 'bottom',
	});

	const hasItems = charts && Array.isArray(charts) && charts.length > 0;
	const yearsArray = useMemo(() => charts?.map((slide) => slide?.year || ''), [charts]);

	const [stages, setStages] = useState<number[]>([]);
	const handleChartStages = useCallback((stages: number[]) => {
		setStages(stages);
	}, []);

	const current = Math.max(
		0,
		findLastIndex(stages, (stage, i) => {
			return seek > stage;
		})
	);

	const [captionHeight, setCaptionHeight] = useState<number | string>('auto');

	useEffect(() => {
		const wrap = ref?.current;
		if (wrap) {
			const activeCaption = wrap.querySelector<HTMLDivElement>(
				`.${css.presentationDescCaptionFrag}.${css.isShown}`
			);
			setCaptionHeight(activeCaption?.offsetHeight || 'auto');
		}
	}, [ref, current]);

	return (
		<article
			ref={ref}
			className={css.presentation}
			style={{
				height: `${scrollWay}vh`,
				minHeight: `100vh`,
			}}>
			<Box className={css.presentationSticky}>
				<Container className={css.presentationContent}>
					<Box className={css.presentationDesc}>
						<Tag
							className={css.presentationDescCaption}
							style={{ height: captionHeight }}
							type="h2"
							isDiv={true}>
							<>
								{hasItems &&
									charts?.map((slide, i) => (
										<RawHtml
											key={`presentation-${slide?.caption}-${i}`}
											className={classnames(css.presentationDescCaptionFrag, {
												[css.isShown]: current === i,
											})}
											content={slide?.caption}
										/>
									))}
							</>
						</Tag>
						<Text className={css.presentationDescText} size="md" color="gray">
							<>
								{hasItems &&
									charts?.map((slide, i) => (
										<RawHtml
											key={`presentation-text-${i}`}
											className={classnames(css.presentationDescTextFrag, {
												[css.isShown]: current === i,
											})}
											content={slide?.text}
										/>
									))}
							</>
						</Text>
					</Box>
					<ReportsMainChart
						seek={seek}
						years={yearsArray}
						onSetStages={handleChartStages}
						className={css.presentationChart}
					/>
				</Container>
			</Box>
		</article>
	);
};
