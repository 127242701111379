import css from './TestimonialsFeed.module.scss';
import classnames from 'classnames';
import type { PageSectionProps } from '@api/hooks/types';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, Heading, Container, Section } from '@core';
import { useReloadTimeout } from '@hooks';
import useIntersectionObserver from '@hooks/useIntersectionObserver';
import {
	TestimonialProps,
	Testimonial,
} from '@components/TestimonialsFeed/components/Testimonial/Testimonial';

export interface TestimonialsFeedProps extends PageSectionProps {
	title?: string;
	items?: TestimonialProps[];
}

type TestimonialsFeedHeadingProps = Pick<TestimonialsFeedProps, 'title'>;

const delay = 800;

export const TestimonialsFeed: FC<TestimonialsFeedProps> = React.memo(({ title, items }) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const entry = useIntersectionObserver(ref, {
		threshold: 0.25,
		freezeOnceVisible: true,
	});

	useEffect(() => {
		if (ref?.current && entry?.isIntersecting) {
			ref.current.classList.add(css.isVisible);
		}
	}, [entry?.isIntersecting]);

	return (
		<Section ref={ref}>
			<Container>
				<TestimonialsFeedHeading title={title} />
				<TestimonialsFeedContent data={items} />
			</Container>
		</Section>
	);
});

const TestimonialsFeedHeading: FC<TestimonialsFeedHeadingProps> = React.memo(({ title }) => {
	return <Heading className={classnames(css.heading, 'indent-margin')} title={title} />;
});

const TestimonialsFeedContent: FC<{
	data?: TestimonialsFeedProps['items'];
	isLoading?: boolean;
}> = React.memo(({ data, isLoading }) => {
	const loading = useReloadTimeout(!!isLoading, delay);
	const [items, setItems] = useState(data);

	useEffect(() => {
		const timer = window.setTimeout(
			() => {
				setItems(data);
			},
			loading ? delay : 50
		);
		return () => {
			if (timer) window.clearTimeout(timer);
		};
	}, [loading, data]);

	return (
		<Box className={classnames(css.feed, { [css.isLoading]: loading }, 'indent-margin')}>
			<>
				{items?.map((item, i) => (
					<Testimonial key={`testimonial-${i}-${item.title}`} {...item} />
				))}
			</>
		</Box>
	);
});
