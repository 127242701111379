import css from './ProjectsOnMap.module.scss';
import type { PageSectionProps } from '@api/hooks/types';
import type { MapObject } from '@core';
import React, { FC } from 'react';
import { Section, Container, Heading, FilterSelects, MapBox } from '@core';
import { useFilterQuery } from '@hooks';
import { useData } from '@api';

export interface ProjectsOnMapProps extends PageSectionProps {
	title?: string;
	filters?: SelectType[];
	items: MapObject[];
}

export const ProjectsOnMap: FC<ProjectsOnMapProps> = React.memo(
	({ links, title, filters, items }) => {
		const { query } = useFilterQuery();
		const { data: fetched, isLoading } = useData<PaginatedData<MapObject>>({
			url: links?.self,
			query,
		});

		return (
			<Section className={css.module}>
				<Container>
					{title && <Heading className={css.heading} offsetType="regular" title={title} />}
					{filters && <FilterSelects items={filters} />}
				</Container>
				<MapBox objects={fetched?.data || items} isLoading={isLoading} />
			</Section>
		);
	}
);
