import css from '../TaskGallery.module.scss';
import classnames from 'classnames';
import React, { FC, memo } from 'react';
import { Box, LinkTag, RawHtml } from '@core';
import { isTouchDevice, padForNum } from '@utils';

export interface TaskPreviewProps {
	text: string;
	index: number;
	url?: string;
	stats?: string;
	className?: string;
}

export const TaskGalleryPreview: FC<TaskPreviewProps> = memo(
	({ text, index, url, stats, className }) => {
		const classes = classnames(css.preview, className, 'is-accent-card');

		const content = (
			<Box className={css.content}>
				<RawHtml className={css.text} content={text} />
				<Box className={classnames(css.num, 'h1')}>
					{stats && <RawHtml content={stats} />}
					{!stats && padForNum(index + 1, '00')}
				</Box>
			</Box>
		);

		return url ? (
			<LinkTag className={classes} href={url}>
				{content}
			</LinkTag>
		) : (
			<Box className={classes}>{content}</Box>
		);
	}
);
