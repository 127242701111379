import css from './SimpleWrapper.module.scss';
import classnames from 'classnames';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';

interface Props {
	color?: 'white' | 'dark' | 'transparent';
	offsetTop?: boolean;
	offsetBottom?: boolean;
	paddingTop?: boolean;
	paddingTopShort?: boolean;
	paddingBottom?: boolean;
	startScreen?: boolean;
}

export const SimpleWrapper: FC<PropsWithChildren<Props>> = ({
	color = 'white',
	offsetTop = false,
	offsetBottom = false,
	paddingTop = true,
	paddingTopShort = false,
	paddingBottom = false,
	startScreen,
	children,
}) => {
	const [shown, setShown] = useState(false);

	useEffect(() => {
		if (startScreen) {
			setTimeout(() => {
				setShown(true);
			}, 10);
		}
	}, [startScreen]);

	return (
		<div
			className={classnames(css.module, css[color], `is-${color}-section`, 'clip-path-wrapper', {
				[css.paddingTop]: paddingTop,
				[css.paddingTopShort]: paddingTopShort,
				[css.paddingBottom]: paddingBottom,
				[css.offsetTop]: offsetTop,
				[css.offsetBottom]: offsetBottom,
				[css.startScreen]: startScreen,
				[css.startScreenShown]: startScreen && shown,
			})}>
			<div className={css.inner}>{children}</div>
		</div>
	);
};
