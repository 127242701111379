import css from '../BenefitsFeed.module.scss';
import classnames from 'classnames';
import { BenefitsItemType } from '@components/BenefitsFeed/BenefitsFeed';
import React, { FC } from 'react';
import { Flex, Box, Tag, Text, RawHtml } from '@core';
import { padForNum } from '@utils';

export const BenefitsItem: FC<BenefitsItemType & { index: number }> = React.memo(
	({ index, title, text, content, size = 'sm', color = 'gray' }) => {
		const textProps = {
			className: classnames(
				{ [css.itemText]: size === 'sm', [css.itemBigText]: size !== 'sm' },
				'wysiwyg'
			),
			size,
			color,
		};

		return (
			<Flex className={css.item} wrap="nowrap">
				<Tag className={css.itemNum} type="h2">
					{padForNum(index + 1, '00')}
				</Tag>
				<Box className={css.itemContent}>
					<Tag className={css.itemTitle} type="h3">
						{title}
					</Tag>
					{content && (
						<Text {...textProps}>
							{Array.isArray(content) &&
								content?.map((item, i) => {
									return <Tag key={item?.content?.slice(i, i + 8) + '-' + i} {...item} />;
								})}
							{typeof content === 'string' && <RawHtml content={content} />}
						</Text>
					)}
					{!content && text && (
						<Text {...textProps}>
							<RawHtml content={text} />
						</Text>
					)}
				</Box>
			</Flex>
		);
	}
);

BenefitsItem.displayName = 'BenefitsItem';
