import css from './AnnouncesFeed.module.scss';
import React, { FC, useEffect, useRef } from 'react';
import { Box, Heading, FilterLinks, Container, Section, Flex } from '@core';
import { AnnounceProps, Announce } from '@components/AnnouncesFeed/components/Announce/Announce';
import useIntersectionObserver from '@hooks/useIntersectionObserver';
import classnames from 'classnames';

export interface PostsFeedProps {
	title?: string;
	titleUrl?: string;
	filters: Array<FilterLinkType>;
	items?: AnnounceProps[];
}

type PostsFeedHeadingProps = Pick<PostsFeedProps, 'title' | 'titleUrl' | 'filters'>;
type PostsFeedContentProps = Pick<PostsFeedProps, 'items'>;

export const AnnouncesFeed: FC<PostsFeedProps> = React.memo(({ title, filters, items }) => {
	// Start animation
	const ref = useRef<HTMLDivElement | null>(null);
	const entry = useIntersectionObserver(ref, {
		threshold: 0.25,
		freezeOnceVisible: true,
	});

	useEffect(() => {
		if (ref?.current && entry?.isIntersecting) {
			ref.current.classList.add(css.visible);
		}
	}, [entry?.isIntersecting]);

	return (
		<Section ref={ref}>
			<Container>
				<AnnouncesFeedHeading title={title} filters={filters} />
				<AnnouncesFeedContent items={items} />
			</Container>
		</Section>
	);
});

const AnnouncesFeedHeading: FC<PostsFeedHeadingProps> = React.memo(
	({ title, titleUrl, filters }) => {
		return (
			<Heading
				className={classnames(css.heading, 'indent-margin')}
				offsetType="regular"
				title={title}
				titleUrl={titleUrl}>
				{filters && filters.length && <FilterLinks items={filters} />}
			</Heading>
		);
	}
);

const AnnouncesFeedContent: FC<PostsFeedContentProps> = React.memo(({ items }) => {
	return (
		<Box className="indent-margin">
			<Flex className={css.feed}>
				{items?.map((item, i) => (
					<Announce key={item.title + '-' + i} {...item} className={css.card} />
				))}
			</Flex>
		</Box>
	);
});
