import css from './MediaSection.module.scss';
import React, {
	FC,
	MutableRefObject,
	SyntheticEvent,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import Image from 'next/image';
import { Section, Box, Container, Tag, ProgressBar } from '@core';
import { checkKeyCode, convertHMS, Performance } from '@utils';
import { ViewportContext } from '@context';
import { VideoCursor } from '@core/VideoCursor/VideoCursor';
import classnames from 'classnames';
import { useIsomorphicLayoutEffect } from '@hooks';

interface MediaSectionProps {
	title?: string;
	cursor?: {
		play: string;
		stop: string;
	};
	bg?: Picture;
	video?: Video;
	youtube?: string;
}

const ease = 0.125;
const fadeDelay = 1600;

const getDuration = (el?: HTMLVideoElement | null) => {
	const duration = el?.duration;
	return duration ? Math.ceil(duration) : 0;
};

export const MediaSection: FC<MediaSectionProps> = React.memo(
	({
		title,
		cursor = {
			play: 'Воспроизвести',
			stop: 'Остановить',
		},
		bg,
		video,
		youtube,
	}) => {
		const timer = useRef(0);

		const canvas = useRef<HTMLDivElement | null>(null);
		const [videoEl, setVideoEl] = useState<HTMLVideoElement | null>(null);

		const [fade, setFade] = useState(false);
		const [playing, setPlaying] = useState(false);
		const [waiting, setWaiting] = useState(false);
		const [duration, setDuration] = useState(video?.duration || 0);
		const [currentTime, setCurrentTime] = useState(0);

		const seek = currentTime / duration;
		const cursorText = !playing ? cursor.play : cursor.stop;

		const youtubeId = useMemo(() => youtube?.split('v=')[1], [youtube]);

		const handleClick = useCallback(
			(e?: React.MouseEvent) => {
				e && e.preventDefault();

				if (youtubeId) {
					setFade(true);
					setPlaying(true);
					return;
				}

				if (timer.current) {
					window.clearTimeout(timer.current);
				}

				const state = !playing;

				if (videoEl) {
					if (state) {
						videoEl.play().then(() => {
							window.setTimeout(() => {
								setFade(true);
							}, fadeDelay);
						});
					} else {
						videoEl.pause();
						setFade(false);
					}
				}

				setPlaying(state);
			},
			[videoEl, youtubeId, playing]
		);

		useIsomorphicLayoutEffect(() => {
			if (youtubeId) return;

			let timeout = 0;

			const onLoaded = () => {
				setDuration(getDuration(videoEl));
			};

			const onMove = () => {
				setFade(false);

				if (timer.current) {
					window.clearTimeout(timer.current);
				}

				if (playing) {
					timer.current = window.setTimeout(() => {
						if (videoEl?.played) setFade(true);
					}, fadeDelay);
				}
			};

			const onTick = () => {
				if (playing && videoEl) {
					setCurrentTime(videoEl.currentTime);
				}
			};

			setDuration(getDuration(videoEl));
			timeout = window.setTimeout(onLoaded, 1000);

			window.addEventListener('mousemove', onMove);
			window.addEventListener('loadedmetadata', onLoaded);

			Performance.addListener(onTick);

			return () => {
				if (timer.current) {
					window.clearTimeout(timer.current);
				}
				if (timeout) {
					window.clearTimeout(timeout);
				}

				window.removeEventListener('mousemove', onMove);
				window.removeEventListener('loadedmetadata', onLoaded);

				Performance.removeListener(onTick);
			};
		}, [videoEl, playing, youtubeId]);

		useEffect(() => {
			const onKeypress = (e: KeyboardEvent) => {
				if (e.target === canvas.current && checkKeyCode(e, 32)) {
					e.preventDefault();
					handleClick();
				}
			};

			document.documentElement.addEventListener('keydown', onKeypress);
			return () => {
				document.documentElement.removeEventListener('keydown', onKeypress);
			};
		}, [canvas, handleClick]);

		return (
			<Section>
				<Box
					ref={canvas}
					tabIndex={0}
					className={classnames(css.module, { [css.fade]: fade, [css.playing]: playing })}
					onClick={handleClick}>
					{bg && (
						<picture className={css.bg}>
							<Image src={bg.src} alt="" layout="fill" quality={100} />
						</picture>
					)}
					{video && !youtubeId && (
						<video
							ref={setVideoEl}
							className={css.video}
							onWaiting={() => setWaiting(true)}
							onPlaying={() => setWaiting(false)}
							onLoadedMetadataCapture={() => setDuration(getDuration(videoEl))}
							preload="metadata"
							loop>
							<source src={video.mp4} type="video/mp4" />
						</video>
					)}
					{youtubeId && fade && (
						<Box className={css.player}>
							<iframe
								src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1`}
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							/>
						</Box>
					)}
					<MediaSectionContent title={title} />
					<MediaSectionCursor
						ref={canvas}
						playing={playing}
						waiting={waiting}
						text={cursorText}
						time={Math.max(duration - currentTime, 0)}
					/>
					<ProgressBar className={css.progress} seek={seek} />
				</Box>
			</Section>
		);
	}
);

export const MediaSectionContent: FC<MediaSectionProps> = React.memo(({ title }) => {
	return (
		<Container className={css.content}>
			{title && <Tag type="h2" content={title} isHTML={true} className={css.title} />}
		</Container>
	);
});

export const MediaSectionCursor = React.memo(
	React.forwardRef<
		HTMLDivElement,
		{ playing: boolean; waiting: boolean; text: string; time: number }
	>(({ playing, waiting, text, time }, ref) => {
		const { vw, vh, bp } = useContext(ViewportContext);
		const isMob = bp === 'xs';

		const [x, setX] = useState(0);
		const [y, setY] = useState(0);

		useEffect(() => {
			const canvas = (ref as MutableRefObject<HTMLDivElement>)?.current;

			if (!canvas) return;

			let x = 0;
			let y = 0;

			let ax = vw / 2;
			let ay = vh / 2;

			const onMove = (e: MouseEvent) => {
				if (e.target !== canvas) return;

				const { left, top } = (e.target as HTMLElement).getBoundingClientRect();

				ax = e.clientX - left;
				ay = e.clientY - top;
			};

			const onTick = () => {
				if (x === ax && y === ay) return;

				const diffX = ax - x;
				const diffY = ay - y;

				const deltaX = Math.abs(diffX) < 0.001 ? 0 : diffX * ease;
				const deltaY = Math.abs(diffY) < 0.001 ? 0 : diffY * ease;

				if (deltaX) {
					x = x + deltaX;
				} else {
					x = ax;
				}

				if (deltaY) {
					y = y + deltaY;
				} else {
					y = ay;
				}

				setX(x / vw);
				setY(1 - y / vh);
			};

			Performance.addListener(onTick);
			canvas.addEventListener('mousemove', onMove);

			return () => {
				Performance.removeListener(onTick);
				canvas.removeEventListener('mousemove', onMove);
			};
		}, [ref, vw, vh]);

		return (
			<VideoCursor
				x={isMob ? 0 : x}
				y={isMob ? 0 : y}
				playing={playing}
				loading={waiting}
				className={css.cursor}>
				{text}
				<br />
				{convertHMS(time)}
			</VideoCursor>
		);
	})
);
