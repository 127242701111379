import css from './VacanciesGallery.module.scss';
import React, { FC, useContext } from 'react';
import { Section, Box, CardsSlider, Heading, CardsTicker, CardsTickerFeed } from '@core';
import {
	VacanciesGalleryPreviewProps,
	VacanciesGalleryPreview,
} from '@components/VacanciesGallery/component/VacanciesGalleryPreview';
import { ViewportContext } from '@context';

interface VacanciesGalleryProps {
	title?: string;
	titleUrl?: string;
	items: Array<VacanciesGalleryPreviewProps>;
	forcedScroll: boolean;
}

export const VacanciesGallery: FC<VacanciesGalleryProps> = React.memo(
	({ title, titleUrl, items, forcedScroll }) => {
		const { bp } = useContext(ViewportContext);
		const isMob = bp === 'xs';

		return (
			<Section>
				<Box className={css.module}>
					{forcedScroll && !isMob && (
						<CardsTicker
							endPad={1.5}
							size={items.length}
							className={css.module}
							before={
								<Heading
									className={css.heading}
									title={title}
									titleUrl={titleUrl}
									classNameTitle="indent-margin"
									inContainer={true}
								/>
							}>
							<CardsTickerFeed items={items} ChildCard={VacanciesGalleryPreview} />
						</CardsTicker>
					)}
					{(!forcedScroll || isMob) && (
						<>
							<Heading
								className={css.heading}
								title={title}
								titleUrl={titleUrl}
								classNameTitle="indent-margin"
								inContainer={true}
							/>
							<CardsSlider
								className={css.slider}
								items={items}
								ChildCard={VacanciesGalleryPreview}
							/>
						</>
					)}
				</Box>
			</Section>
		);
	}
);
