import { getMock } from '@api/mock';
import { FetchConfig, fetcher } from '@api';

export const submitForm = async ({
	url,
	method = 'post',
	headers = new Headers({}),
	body = undefined,
}: FetchConfig) => {
	// Debug form data
	// if (body instanceof FormData) {
	// 	for (const pair of body.entries()) {
	// 		console.log(pair[0] + '=' + pair[1]);
	// 	}
	// }

	const needsMock = false;
	const mock = async () => getMock<FormResponse>(url);

	if (needsMock && !!mock) {
		return mock();
	}

	return fetcher<FormResponse>({
		url,
		method,
		headers,
		body,
	});
};
