import css from '../StoryLine.module.scss';
import classnames from 'classnames';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Box } from '@core';
import {
	StoryLineSeekContext,
	StoryLineWayContext,
} from '@components/StoryLine/components/StoryLineLayout';
import { Move, normalize } from '@utils';
import Image from 'next/image';

export interface StoryLinePicturesProps {
	pictures: Array<Picture>;
}

export const StoryLinePictures: FC<StoryLinePicturesProps> = React.memo(({ pictures }) => {
	const [container, setContainer] = useState<HTMLDivElement | null>(null);

	const seek = useContext(StoryLineSeekContext);
	const { value: spreading } = useContext(StoryLineWayContext);

	let collector = 0;
	const stages = spreading.map((item) => {
		const result = normalize(seek, (collector + item) / 100, collector / 100, true);
		collector += item;
		return Math.min(Math.max(result, 0.5), 1) * 2 - 1;
	});

	useEffect(() => {
		const pictures = Array.from(container?.querySelectorAll(`picture`) || []);

		if (pictures?.length) {
			pictures?.slice(1).forEach((pic, i) => {
				pic.style.clipPath = `polygon(0 0, ${stages[i] * 100}% 0, ${
					stages[i] * 100
				}% 100%, 0% 100%)`;
			});
		}
	}, [container, stages]);

	useEffect(() => {
		if (!container) return;

		const onTick = ({ x, y }: { x: number; y: number }) => {
			container.style.setProperty('--mx', x.toString());
			container.style.setProperty('--my', y.toString());
		};

		Move.addListener(onTick);

		return () => {
			Move.removeListener(onTick);
		};
	}, [container]);

	return (
		<Box className={css.layoutAside}>
			<Box className={css.layoutAsideSticky}>
				<Box ref={setContainer} className={css.layoutAsideMedia}>
					{pictures?.map((pic, i) => {
						if (!pic) return null;
						return (
							<picture key={`story-picture-${pic.src}-${i}`}>
								<Image
									src={pic.src}
									alt={pic.alt}
									layout="fill"
									sizes="(max-width: 639px) 100vw, 30vw"
								/>
							</picture>
						);
					})}
				</Box>
			</Box>
		</Box>
	);
});
