import css from '../StoryLine.module.scss';
import classnames from 'classnames';
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, RawHtml, Tag, Text, LinkTag, Icon } from '@core';
import { StoryLineEvent, StoryLineSection } from '@components/StoryLine/StoryLine';
import { useScrollWithEase } from '@hooks';
import { ViewportContext } from '@context';
import { StoryLineWayContext } from '@components/StoryLine/components/StoryLineLayout';
import Image from 'next/image';

export interface StoryLineFeedProps {
	sections: Array<StoryLineSection>;
}

export interface StoryLineFeedSectionProps extends StoryLineSection {}

export const StoryLineFeed: FC<StoryLineFeedProps> = React.memo(({ sections }) => {
	const [wrapper, setWrapper] = useState<HTMLDivElement | null>(null);
	const { setValue: setSpreading } = useContext(StoryLineWayContext);

	useEffect(() => {
		if (wrapper) {
			const sections = Array.from(wrapper.querySelectorAll<HTMLDivElement>(`.${css.section}`));

			const spreading = sections.map((item) => {
				return (item.offsetHeight / wrapper.offsetHeight) * 100;
			});

			setSpreading(spreading);
		}
	}, [wrapper, setSpreading]);

	return (
		<Box ref={setWrapper} className={css.layoutContent}>
			{sections.map((section, i) => (
				<StoryLineFeedSection key={section?.title + '-' + i} {...section} />
			))}
		</Box>
	);
});

export const StoryLineFeedSection: FC<StoryLineFeedSectionProps> = React.memo(
	({ title, text, url, events, picture }) => {
		const { bp } = useContext(ViewportContext);
		const isMob = bp === 'xs';

		return (
			<Box className={css.section}>
				<StoryLineFeedEvent title={title} text={text} url={url} />
				{events && (
					<Box className={css.events}>
						{events.map(({ text, url }, i) => (
							<StoryLineFeedEvent key={text + '-' + i} text={text} url={url} />
						))}
					</Box>
				)}
				{isMob && picture && (
					<picture className={css.picture}>
						<Image
							src={picture.src}
							alt={picture.alt}
							width={picture.width}
							height={picture.height}
							layout="intrinsic"
						/>
					</picture>
				)}
			</Box>
		);
	}
);

export const StoryLineFeedEvent: FC<
	StoryLineEvent & { title?: StoryLineFeedSectionProps['title'] }
> = React.memo(({ title, text, url }) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const { vh } = useContext(ViewportContext);
	const [active, setActive] = useState(false);

	const onScroll = (current: number) => {
		const midPosition = Math.max(current - vh / 2, 0);
		setActive(midPosition === 0);
	};

	useScrollWithEase(ref, onScroll, {
		ease: 1,
		edge: 'top',
	});

	const content = (
		<Box ref={ref}>
			{title && <Tag className={css.caption} type="h2" content={title} isHTML={true} />}
			{text && (
				<Text className={css.text} size="md" color="gray">
					<RawHtml content={text} />
				</Text>
			)}
		</Box>
	);

	return url ? (
		<LinkTag className={classnames(css.item, { [css.highlight]: active })} href={url}>
			<Icon className={css.arrow} id="link" />
			{content}
		</LinkTag>
	) : (
		<Box className={classnames(css.item, { [css.highlight]: active })}>{content}</Box>
	);
});
