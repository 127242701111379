import css from './PicturesGallery.module.scss';
import React, { FC, useRef, useState } from 'react';
import Image from 'next/image';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Section, Container, Box, Tag, Text, RawHtml, DragCursor } from '@core';
import { useMousePosition } from '@hooks';
import { padForNum } from '@utils';

interface Props {
	title?: string;
	text?: string;
	items?: Array<Picture>;
	pictures?: Array<Picture>;
}

export const PicturesGallery: FC<Props> = React.memo(({ title, text, items = [], pictures }) => {
	if (pictures) {
		items = pictures;
	}

	const sliderRef = useRef<HTMLDivElement | null>(null);
	const splideInst = useRef<Splide | null>(null);

	const { px, py } = useMousePosition<HTMLDivElement | null>(sliderRef);

	const [amount, setAmount] = useState(items?.length || 0);
	const [current, setCurrent] = useState(1);

	const updateCounts = (splide: any) => {
		if (splide) {
			const end = splide.Components.Controller.getEnd() + 1;
			const cur = splide.Components.Controller.getIndex() + 1;

			setAmount(end);
			setCurrent(cur);
		}
	};

	return (
		<Section>
			<Container>
				<Box className={css.head}>
					<Tag className={css.title} type="h1" content={title} isHTML={true} />
					{text && (
						<Text className={css.preamble} size="md" color="gray">
							<RawHtml content={text} />
						</Text>
					)}
				</Box>
			</Container>
			<Box className={css.slider}>
				<Container>
					<Box className={css.sliderHead}>
						<Box className={css.sliderCounter}>
							<span className={css.current}>{padForNum(current, '00')}</span>
							<span>&nbsp;&mdash; {amount}</span>
						</Box>
					</Box>
					<Box className={css.wrap} ref={sliderRef}>
						<Splide
							ref={splideInst}
							onMove={updateCounts}
							onMounted={updateCounts}
							options={{
								type: 'slide',
								autoWidth: true,
								arrows: false,
								pagination: false,
								speed: 800,
								rewindSpeed: 1200,
							}}>
							{items?.map((picture, i) => (
								<SplideSlide key={`picture-gallery-${i}-${picture.alt}`}>
									<picture className={css.picture}>
										<Image
											src={picture.src}
											alt={picture.alt}
											layout="fill"
											sizes="(max-width: 639px) 80vw, 40vw"
											// width={picture.width}
											// height={picture.height}
											// layout="responsive"
										/>
									</picture>
								</SplideSlide>
							))}
						</Splide>
						<DragCursor className={css.cursor} x={px} y={py} />
					</Box>
				</Container>
			</Box>
		</Section>
	);
});
