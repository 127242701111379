import css from './SplashRegular.module.scss';
import classnames from 'classnames';
import fastdom from 'fastdom';
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { Box, Video } from '@core';
import { Move, normalize } from '@utils';
import { HomeChildProps, HomeIntroContext } from '@components/HomeIntro/HomeIntro';
import { getStages } from '@components/HomeIntro/utils';
import useIntersectionObserver from '@hooks/useIntersectionObserver';

export interface Feature {
	text: string;
	media?: Array<Media>;
}

export type VideoType = Video & { type: string };
export type FeaturesType = Array<Feature>;

export type SplashContentProps = Pick<SplashRegularProps, 'features'> & { playing?: boolean };

export interface SplashRegularProps extends HomeChildProps {
	features: FeaturesType;
}

const add = (n?: number) => (n !== undefined ? Math.max(n, 0.6) - 0.6 : 0);
const pos = (cur: number, prev?: number) => cur + add(prev);

export const SplashRegular: FC<SplashRegularProps> = React.memo(
	({ screens: { full: screens }, start, end, ...rest }) => {
		const [wrap, setWrap] = useState<HTMLDivElement | null>(null);

		const { seek: seekMain } = useContext(HomeIntroContext);

		const seek = normalize(seekMain, end, start, true);
		const stages = getStages(seek, screens * 2);
		const playing = stages[4] > 0 && normalize(seekMain, end + 1 / screens / 2, start, true) < 1;

		useEffect(() => {
			fastdom.mutate(() => {
				if (!wrap) return;

				const a = pos(stages[4]);
				const b = pos(stages[5], a);
				const c = pos(stages[6], b);
				const d = pos(stages[7], c);
				const e = pos(stages[8], d);

				wrap.style.setProperty('--up', `${1 - stages[3]}`);
				wrap.style.setProperty('--fade', `${stages[3]}`);

				wrap.style.setProperty('--showMediaA', `${(1 - a) * 100}%`);
				wrap.style.setProperty('--showMediaB', `${(1 - b) * 100}%`);
				wrap.style.setProperty('--showMediaC', `${(1 - c) * 100}%`);
				wrap.style.setProperty('--showMediaD', `${(1 - d) * 100}%`);
				wrap.style.setProperty('--showMediaE', `${(1 - e) * 100}%`);
			});
		}, [wrap, stages]);

		useEffect(() => {
			if (!wrap) return;

			const onTick = ({ x, y }: { x: number; y: number }) => {
				fastdom.mutate(() => {
					wrap.style.setProperty('--mx', `${x}`);
					wrap.style.setProperty('--my', `${y}`);
				});
			};

			Move.addListener(onTick);

			return () => {
				Move.removeListener(onTick);
			};
		}, [wrap]);

		return (
			<Box ref={setWrap} className={classnames(css.module)}>
				<SplashContent {...rest} playing={playing} />
			</Box>
		);
	}
);

export const SplashContent: FC<SplashContentProps> = React.memo(({ features, playing }) => {
	return (
		<>
			{features.map((item, i) => {
				return <SplashPoint key={item.text} index={i} {...item} playing={playing} />;
			})}
		</>
	);
});

export const SplashPoint: FC<Feature & { index: number; playing?: boolean }> = React.memo(
	({ index, text, media, playing }) => {
		const [counter, setState] = useState(0);

		const handleHoverIn = useCallback(() => {
			if (typeof media === 'undefined') return;
			setState((prev) => {
				return prev + 1 > media.length - 1 ? 0 : prev + 1;
			});
		}, [media]);

		const ref = useRef<HTMLDivElement | null>(null);

		const entry = useIntersectionObserver(ref, {
			threshold: 0,
			freezeOnceVisible: false,
		});

		const timer = useRef(0);

		useEffect(() => {
			const container = ref?.current;
			if (container) {
				const entities = container.querySelectorAll(`.${css.pointBoxMediaEntity}`);
				const target = entities[counter];

				if (target) {
					target.classList.add(css.isShown);

					entities.forEach((el) => {
						if (el !== target) {
							el.classList.add(css.isDown);
						}
					});

					if (timer.current) {
						window.clearTimeout(timer.current);
					}
					timer.current = window.setTimeout(() => {
						entities.forEach((el) => {
							if (el !== target) {
								el.classList.remove(css.isShown);
							}
							el.classList.remove(css.isDown);
						});
					}, 800);
				}
			}
		}, [counter]);

		return (
			<Box ref={ref} className={classnames(css.point, css[`variant-${index + 1}`])}>
				<Box className={css.pointInner}>
					<Box className={css.pointBox}>
						<Box className={css.pointBoxText}>
							<p>{text}</p>
						</Box>
						<Box className={css.pointBoxMedia} onMouseEnter={handleHoverIn}>
							{media?.map((item, i) => {
								return (
									<Box key={item?.type + '-' + i} className={css.pointBoxMediaEntity}>
										{item?.type === 'picture' && (
											<picture>
												<Image
													src={(item as Picture).src}
													alt={(item as Picture).alt}
													layout="fill"
													sizes="30vw"
													quality={100}
													priority={true}
												/>
											</picture>
										)}
										{item?.type === 'video' && (item as VideoType)?.mp4 && (
											<Video
												className={css.pointBoxVideo}
												playing={entry?.isIntersecting && counter === i}
												mp4={(item as VideoType).mp4}
												width={item.width}
												height={item.height}
											/>
										)}
									</Box>
								);
							})}
						</Box>
					</Box>
				</Box>
			</Box>
		);
	}
);
