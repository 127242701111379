import React, { FC, useContext } from 'react';
import { ProjectsPreviewsProps } from '@components/ProjectsPreviews/ProjectsPreviews';
import { ViewportContext } from '@context';
import { IconicProjectsMobile } from '@components/IconicProjects/components/IconicProjectsMobile/IconicProjectsMobile';
import { IconicProjectsRegular } from '@components/IconicProjects/components/IconicProjectsRegular/IconicProjectsRegular';

export interface IconicProjectsProps extends ProjectsPreviewsProps {
	aside?: {
		title?: string;
		url?: string;
		bg: Picture;
	};
}

export const IconicProjects: FC<IconicProjectsProps> = React.memo((props) => {
	const { bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	return (
		<>
			{isMob && <IconicProjectsMobile {...props} />}
			{!isMob && <IconicProjectsRegular {...props} />}
		</>
	);
});
