import css from '../StatsGallery.module.scss';
import classnames from 'classnames';
import React, { FC, memo, useCallback, useRef, useState } from 'react';
import { Box, RawHtml } from '@core';
import { useOutsideClick } from '@hooks';
import { isTouchDevice } from '@utils';

export interface StatsPreviewProps {
	type: 'head' | 'child';
	title: string;
	text: string;
	className?: string;
}

const isTouch = isTouchDevice() || false;

export const StatsGalleryPreview: FC<StatsPreviewProps> = memo(
	({ type, title, text, className }) => {
		const ref = useRef<HTMLDivElement | null>(null);
		const [clicked, setClicked] = useState(false);

		const handleOutsideClick = useCallback(() => {
			setClicked(false);
		}, []);

		useOutsideClick(ref, handleOutsideClick);

		return (
			<Box
				className={classnames(css.preview, css[type], className, {
					[css.clicked]: clicked,
				})}
				ref={ref}
				onClick={(e) => {
					if (isTouch) {
						e.preventDefault();
						setClicked((prev) => !prev);
					}
				}}>
				<Box className={css.content}>
					<Box className={css.title}>{title}</Box>
					<RawHtml className={css.text} content={text} />
				</Box>
			</Box>
		);
	}
);
