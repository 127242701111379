import css from './Text.module.scss';
import classnames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';

interface TextInterface {
	size?: 'sm' | 'md' | 'lg';
	color?: 'dark' | 'gray' | 'white' | 'inherit';
	className?: string;
}

export const Text: FC<PropsWithChildren<TextInterface>> = React.memo(
	({ size = 'sm', color = 'dark', className, children }) => {
		return <div className={classnames(css[size], css[color], className)}>{children}</div>;
	}
);
