import css from './ReportsMain.module.scss';
import classnames from 'classnames';
import React, { FC, useContext } from 'react';
import { ViewportContext } from '@context';
import { Section, Container, Box, Flex, RawHtml, Tag } from '@core';
import { ReportsMainPresentation } from '@components/ReportsMain/components/ReportsMainPresentation';

export interface ReportsYearProps {
	year?: string;
	caption?: string;
	text?: string;
}

export interface ReportsMainProps {
	title?: string;
	indicator?: {
		value?: string;
		label?: string;
	};
	charts?: ReportsYearProps[];
}

export const ReportsMain: FC<ReportsMainProps> = React.memo(({ title, indicator, charts }) => {
	return (
		<Section className={css.section}>
			<article className={css.preamble}>
				<Container>
					{indicator && (
						<Flex className={css.indicator}>
							<RawHtml className={css.indicatorValue} content={indicator.value} />
							<RawHtml className={css.indicatorLabel} content={indicator.label} />
						</Flex>
					)}
					<Tag
						className={classnames(css.title, 'indent-text')}
						type="h2"
						content={title}
						isHTML={true}
					/>
				</Container>
			</article>
			<ReportsMainPresentation charts={charts} />
		</Section>
	);
});
