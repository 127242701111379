import React, { FC } from 'react';
import type { UseInputBindType } from '@core/Form/FormComponent/hooks/useInput';
import { FormChoice } from '@core/Form/FormChoice/FormChoice';

interface FormRadiogroupProps extends FormFieldType {
	bind?: UseInputBindType;
}

export const FormRadiogroup: FC<FormRadiogroupProps> = ({
	type,
	name,
	required,
	items,
	bind = {},
	value,
}) => {
	return (
		<>
			{items?.map((choice, i) => (
				<FormChoice
					key={`form-choice-${name}-${choice.value}-${i}`}
					type={type as 'radio'}
					name={name}
					value={choice.value as string}
					disabled={choice.disabled}
					checked={choice.value === value}
					required={required}
					{...bind}>
					{choice.label}
				</FormChoice>
			))}
		</>
	);
};
