export const isTouchDevice = (): boolean => {
	try {
		document.createEvent('TouchEvent');
		return true;
	} catch (e) {
		return (
			typeof window !== 'undefined' &&
			('ontouchstart' in window || !!window.navigator.maxTouchPoints)
		);
	}
};

export const isIE = (): number | false => {
	const match = navigator.userAgent.match(/(?:MSIE | Trident\/.*; rv:)(\d+)/);
	return match ? parseInt(match[1]) : false;
};

export const isFirefox = (): boolean => {
	return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
};

export const isSafari = (): boolean => {
	if (typeof window === 'undefined') return false;
	return /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
};

export const webP = (): boolean => {
	let c = document.createElement('canvas');
	return !!(c.getContext && c.getContext('2d'))
		? c.toDataURL('image/webp').indexOf('data:image/webp') === 0
		: false;
};

export const supportsPassiveEvents = (): boolean => {
	let supports = false;
	try {
		let opts = Object.defineProperty({}, 'passive', {
			get: function () {
				return (supports = true);
			},
		});
		window.addEventListener('testPassive', (): void => {}, opts);
		window.removeEventListener('testPassive', (): void => {}, opts);
	} catch (e) {}
	return supports;
};

export const supportsPositionSticky = (): boolean => {
	const prefix = ['', '-o-', '-webkit-', '-moz-', '-ms-'];
	const test = document.head.style;

	for (let i = 0; i < prefix.length; i += 1) {
		test.position = `${prefix[i]}sticky`;
	}

	return !!test.position.match('sticky');
};
