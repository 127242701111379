import { PageData } from '@api/hooks/types';

export const partners: PageData = {
	meta: {
		title: 'Отзывы партнёров',
		description:
			'Сегодня клиентами и партнерами компании являются более 1000 крупнейших предприятий и компаний, в числе которых - инвестиционные и производственные компании, кредитно финансовые организации, крупные международные компании.',
	},
	data: [
		{
			type: 'intro',
			content: {
				title: 'Отзывы<br /><color-accent>партнёров</color-accent>',
				breadcrumbs: [{ text: 'О компании' }, { text: 'Партнёры' }],
			},
		},

		{
			type: 'aboutContent',
			content: {
				sentence:
					'Сегодня клиентами и партнерами компании являются более 1000 крупнейших предприятий и компаний, в числе которых - инвестиционные и производственные компании, кредитно финансовые организации, крупные международные компании.',
			},
			wrapper: {
				type: 'stripsVertical',
				props: {
					color: 'white',
					startScreen: true,
					paddingBottom: true,
				},
			},
		},

		{
			type: 'partners',
			content: {
				title: 'Доверие<br /><color-accent>партнеров</color-accent>',
				items: [
					{
						title: 'Росатом',
						logo: {
							src: '/partners/rosatom.svg',
						},
						url: 'https://www.rosatom.ru/',
					},
					{
						title: 'Evraz',
						logo: {
							src: '/partners/evraz.svg',
						},
						url: 'https://www.evraz.com/ru/',
					},
					{
						title: 'МКБ',
						logo: {
							src: '/partners/mkb.svg',
						},
						url: 'https://mkb.ru/',
					},
					{
						title: 'Атомстройэкспорт',
						logo: {
							src: '/partners/ase.svg',
						},
						url: 'http://www.atomex.ru/',
					},
					{
						title: 'Альфабанк',
						logo: {
							src: '/partners/alfabank.svg',
						},
						url: 'https://alfabank.ru/',
					},
					{
						title: 'ФОДД',
						logo: {
							src: '/partners/fodd.svg',
						},
						url: 'https://www.fodd.ru/',
					},
					{
						title: 'ВТБ',
						logo: {
							src: '/partners/vtb.svg',
						},
						url: 'https://www.vtb.ru/',
					},
					{
						title: 'Bergauf',
						logo: {
							src: '/partners/bergauf.svg',
						},
						url: 'https://bergauf.ru/',
					},
					{
						title: 'MR Group',
						logo: {
							src: '/partners/mr-group.svg',
						},
						url: 'https://www.mr-group.ru/',
					},
					{
						title: 'ICG',
						logo: {
							src: '/partners/icg.svg',
						},
						url: 'https://www.icgam.com/',
					},
				],
				settings: {
					color: 'white',
					colorEnd: 'dark',
					colorMob: 'white',
					titleIndent: true,
					topCollapse: false,
					topStripsMobile: false,
				},
			},
		},

		{
			type: 'testimonialsFeed',
			content: {
				title: 'Благодарственные<br /><color-accent>письма</color-accent>',
				items: [
					{
						title: 'Альфа-Банк',
						text: '<p>Финансовый партнёр</p>',
						url: '/dummy/dummy.pdf',
						picture: {
							src: '/temp/pic-23-sm.jpg',
							width: 480,
							height: 680,
							alt: 'Альфа-Банк',
						},
					},
					{
						title: 'Liebher',
						text: '<p>Поставщик техники</p>',
						url: '/dummy/dummy.pdf',
						picture: {
							src: '/temp/pic-23-sm.jpg',
							width: 480,
							height: 680,
							alt: 'Liebher',
						},
					},
					{
						title: 'Evraz',
						text: '<p>Работы нулевого цикла</p>',
						url: '/dummy/dummy.pdf',
						picture: {
							src: '/temp/pic-23-sm.jpg',
							width: 480,
							height: 680,
							alt: 'Liebher',
						},
					},
					{
						title: 'Мечел',
						text: '<p>Поставщик металлоконструций</p>',
						url: '/dummy/dummy.pdf',
						picture: {
							src: '/temp/pic-23-sm.jpg',
							width: 480,
							height: 680,
							alt: 'Liebher',
						},
					},
					{
						title: 'Strabag',
						text: '<p>Строительный партнёр</p>',
						url: '/dummy/dummy.pdf',
						picture: {
							src: '/temp/pic-23-sm.jpg',
							width: 480,
							height: 680,
							alt: 'Liebher',
						},
					},
					{
						title: 'Aecom',
						text: '<p>Консалтинговый партнёр</p>',
						url: '/dummy/dummy.pdf',
						picture: {
							src: '/temp/pic-23-sm.jpg',
							width: 480,
							height: 680,
							alt: 'Liebher',
						},
					},
				],
			},
			wrapper: {
				type: 'stripsHorizontalLeft',
				props: {
					color: 'dark',
				},
			},
		},
	],
};
