import { PageData } from '@api/hooks/types';

export const projects: PageData = {
	meta: {
		title: 'Строим будущее',
		description: '',
		metaTags: [
			{
				content: 'Строим будущее',
				name: 'og:title',
				nameType: 'property',
			},
			{
				content: 'Строим будущее',
				name: 'og:description',
				nameType: 'property',
			},
		],
	},
	data: [
		{
			type: 'intro',
			content: {
				title: 'Строим<br /><color-accent>будущее</color-accent>',
				breadcrumbs: [{ text: 'Проекты' }],
			},
		},

		{
			type: 'projectsPreviews',
			content: {
				title: 'Знаковые<br /><color-accent>объекты</color-accent>',
				items: [
					{
						title: 'Спортивный комплекс<br /><color-accent>«Олимпийский»</color-accent>',
						text: 'Устройство ограждения конструкций котлована при реконструкции здания бассейна. Устройство сайного основания при строительстве подземной части сооружения',
						url: '/projects/project',
						picture: {
							src: '/temp/project-01.jpg',
							width: 1280,
							height: 904,
							alt: 'Спортивный комплекс «Олимпийский»',
						},
					},
					{
						title: 'Новая штаб-квартира<br /><color-accent>«Яндекса»</color-accent>',
						text: 'Устройство ограждения котлована и анкерной системы',
						url: '/projects/project',
						picture: {
							src: '/temp/project-02.jpg',
							width: 1280,
							height: 904,
							alt: 'Новая штаб-квартира «Яндекса»',
						},
					},
					{
						title: 'Павильон<br /><color-accent>«Атомной энергии»</color-accent>',
						text: 'Устройство ограждения конструкций котлована при реконструкции здания бассейна. Устройство сайного основания при строительстве подземной части сооружения',
						url: '/projects/project',
						picture: {
							src: '/temp/project-03.jpg',
							width: 1280,
							height: 904,
							alt: 'Пафильон «Атомной энергии»',
						},
					},
					{
						title: '<color-accent>«Москва-Сити»</color-accent><br />one tower',
						text: 'Устройство ограждения котлована из буросекущихся свай при строительстве многофункционального высотного жилого комплекса с подземной автостоянкой',
						url: '/projects/project',
						picture: {
							src: '/temp/project-04.jpg',
							width: 1280,
							height: 904,
							alt: '«Москва-Сити» one tower',
						},
					},
					{
						title: 'Автовокзал в составе ТПУ <color-accent>«Щелковский»</color-accent>',
						text: 'Устройство свайных фундаментов под башенные краны, «стена в грунте» траншейного типа глубиной 53м, устройство баретт траншейного типа',
						url: '/projects/project',
						picture: {
							src: '/temp/project-05.jpg',
							width: 1280,
							height: 904,
							alt: 'Автовокзал в составе транспортно-пересадочного узла «Щелковский»',
						},
					},
				],
			},
			wrapper: {
				type: 'stripsTwoAxis',
				props: {
					color: 'white',
					startScreen: true,
				},
			},
		},

		{
			type: 'projectsOnMap',
			links: {
				self: '/projects/map',
			},
			content: {
				title: 'Все объекты<br /><color-accent>на карте</color-accent>',
				filters: [
					{
						name: 'type',
						placeholder: 'Вид объекта',
						options: [
							{ label: 'Все виды', value: null },
							{ label: 'Гражданские постройки', value: 'civilian' },
							{ label: 'Промышленность', value: 'manufacturing' },
							{ label: 'Транспортное строительство', value: 'transport' },
						],
					},
					{
						name: 'period',
						placeholder: 'Период работ',
						options: [
							{ label: 'Все периоды', value: null },
							{ label: '2021 год', value: '2021' },
							{ label: '2020 год', value: '2020' },
							{ label: '2019 год', value: '2019' },
						],
					},
					{
						name: 'scope',
						placeholder: 'Сфера деятельности',
						options: [
							{ label: 'Все сферы деятельности', value: null },
							{ label: 'Генеральный подряд', value: '1' },
							{ label: 'Генеральное проектирование', value: '2' },
							{ label: 'Охрана окружающей среды', value: '3' },
							{ label: 'Техника безопасности', value: '4' },
							{ label: 'EPC контракт', value: '5' },
							{ label: 'Работы нулевого цикла', value: '6' },
							{ label: 'Культура производства', value: '7' },
						],
					},
				],
				items: [
					{
						id: '001',
						coordinates: [55.781105, 37.626287],
						data: {
							title: 'Спортивный комплекс<br /><color-accent>«Олимпийский»</color-accent>',
							text: 'Устройство ограждения конструкций котлована при реконструкции здания бассейна. Устройство сайного основания при строительстве подземной части сооружения',
							url: 'https://fensma.ru/',
							address: 'г. Москва, Олимпийский проспект, 16с1',
						},
					},
					{
						id: '002',
						coordinates: [55.705087, 37.564757],
						data: {
							title: 'Новая штаб-квартира<br /><color-accent>«Яндекса»</color-accent>',
							text: '19 июня 2020 года. Яндекс представил проект новой штаб-квартиры в Гагаринском районе Москвы. Здание будет расположено на месте гостиницы «Корстон».',
							address: 'г. Москва, ул. Косыгина, 15',
						},
					},
					{
						id: '003',
						coordinates: [55.832332, 37.624675],
						data: {
							title: 'Павильон<br /><color-accent>«Атомной энергии»</color-accent>',
							text: 'Устройство ограждения конструкций котлована при реконструкции здания бассейна. Устройство сайного основания при строительстве подземной части сооружения',
							url: 'https://fensma.ru/',
							address: 'г. Москва, проспект Мира, 119с19А',
						},
					},
					{
						id: '004',
						coordinates: [55.751463, 37.539446],
						data: {
							title: '<color-accent>«Москва-Сити»</color-accent><br />one tower',
							text: 'Устройство ограждения котлована из буросекущихся свай при строительстве многофункционального высотного жилого комплекса с подземной автостоянкой',
							url: 'https://fensma.ru/',
							address: 'г. Москва, 1-й Красногвардейский пр-д, 13',
						},
					},
					{
						id: '005',
						coordinates: [55.714698, 37.516485],
						data: {
							title: 'Автовокзал в составе ТПУ<br /><color-accent>«Щелковский»</color-accent>',
							text: 'Устройство свайных фундаментов под башенные краны, «стена в грунте» траншейного типа глубиной 53м, устройство баретт траншейного типа',
							url: 'https://fensma.ru/',
							address: 'г. Москва, ул. Мосфильмовская, д. 17Б',
						},
					},
				],
			},
			wrapper: {
				type: 'simpleWrapper',
				props: {
					color: 'transparent',
				},
			},
		},
	],
};
