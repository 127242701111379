import css from '../SplitViewSlides.module.scss';
import classnames from 'classnames';
import fastdom from 'fastdom';
import type { SplitViewSlidesProps } from '@components/SplitViewSlides/SplitViewSlides';
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Section, Box } from '@core';
import { useScrollWithEase } from '@hooks';
import { ViewportContext } from '@context';
import { SplitViewSlidesContent } from './SplitViewSlidesContent';
import { normalize } from '@utils';

export const SplitViewSlidesRegular: FC<SplitViewSlidesProps> = ({ slides, settings }) => {
	const size = slides?.length || 1;
	const { strips = 'normal' } = settings || {};

	const { vh, vw } = useContext(ViewportContext);

	const ref = useRef<HTMLDivElement | null>(null);
	const refOuter = useRef<HTMLDivElement | null>(null);

	const [seek, setSeek] = useState(0);
	const [stripElements, setStripElements] = useState<HTMLElement[]>([]);

	useEffect(() => {
		const strips = refOuter?.current?.querySelectorAll<HTMLElement>(`.strip`);
		setStripElements(strips ? Array.from(strips).reverse() : []);
	}, []);

	const onScroll = useCallback(
		(current: number, height: number) => {
			const start = height - vh;
			const end = current - vh;
			const absolute = 1 - end / start;
			const relative = Math.max(Math.min(absolute, 1), 0);

			/*
			 * Со смещением от крайних положений
			 */
			const inset = vh / 2 / (height - vh);
			const normalized = normalize(relative, 1 - inset, inset, true);

			setSeek(normalized);
		},
		[vh]
	);

	useScrollWithEase(ref, onScroll, {
		ease: 0.25,
		edge: 'bottom',
	});

	const handleStrips = useCallback(
		(seek: number) => {
			const factor = strips === 'reverse' ? -1.4 : 0.4;
			const translate = Math[factor > 0 ? 'max' : 'min'](seek * (factor * vw), 0);

			fastdom.mutate(() => {
				stripElements.forEach((strip, i) => {
					strip.style.transform = `translate3d(${(translate * (1 - i * 0.2)).toFixed(1)}px, 0, 0)`;
				});
			});
		},
		[vw, strips, stripElements]
	);

	const handleApproach = useCallback((seek: number) => {
		fastdom.mutate(() => {
			const container = refOuter?.current;
			if (container) {
				container.style.setProperty('--approachSeek', seek.toString());
			}
		});
	}, []);

	const onScrollOuter = useCallback(
		(current: number, height: number, posY?: number) => {
			const start = strips === 'reverse' ? vh / 2 : height - (ref.current?.clientHeight || 0);
			const end = current - vh;
			const absolute = 1 - end / start;
			const relative = Math.max(Math.min(absolute, 1), 0);

			handleStrips(relative);

			if (settings?.slideOnPrev && posY) {
				const pvh = vh * 0.5;
				const relative = Math.max(Math.min(current - height + pvh * 0.9, pvh), 0) / pvh;

				handleApproach(relative);
			}
		},
		[settings, strips, vh, handleStrips, handleApproach]
	);

	useScrollWithEase(refOuter, onScrollOuter, {
		ease: 1,
		edge: 'bottom',
	});

	return (
		<Section
			ref={refOuter}
			className={classnames(css.outer, {
				[css.outerNormal]: strips === 'normal',
				[css.outerReverse]: strips === 'reverse',
				[css.outerClosed]: strips === 'none',
				[css.outerApproach]: settings?.slideOnPrev,
			})}>
			<Box
				ref={ref}
				className={css.inner}
				style={{
					minHeight: `${size * 100}vh`,
				}}>
				<div>
					{slides?.map((item, i) => {
						return (
							<span
								key={`split-view-slider-focus-trap-${i}`}
								tabIndex={0}
								className={css.focusTrap}
								style={{
									top: `${(i + 1) * 75}vh`,
								}}
							/>
						);
					})}
				</div>
				<Box className={css.sticky}>
					<SplitViewSlidesContent seek={seek} slides={slides} animation={settings?.animation} />
					{strips === 'reverse' && <Strips strips={strips} />}
				</Box>
			</Box>
			{strips === 'normal' && <Strips strips={strips} />}
		</Section>
	);
};

export const Strips: FC<SplitViewSlidesProps['settings']> = React.memo(({ strips }) => {
	return (
		<Box className={classnames('strips', `strips--${strips}`)}>
			<div className="strip" />
			<div className="strip" />
			<div className="strip" />
			<div className="strip" />
		</Box>
	);
});
