import css from './ProgressBar.module.scss';
import classnames from 'classnames';
import fastdom from 'fastdom';
import React, { FC, HTMLAttributes, useEffect, useState } from 'react';

interface Props {
	seek: number;
	withTransition?: boolean;
}

export const ProgressBar: FC<Props & HTMLAttributes<HTMLDivElement>> = ({
	seek,
	withTransition,
	className,
}) => {
	const [element, setElement] = useState<HTMLDivElement | null>(null);

	useEffect(() => {
		fastdom.mutate(() => {
			if (element) element.style.width = `${seek * 100}%`;
		});
	}, [element, seek]);
	return (
		<div className={classnames(css.outer, className)}>
			<div
				ref={setElement}
				className={classnames(css.inner, { [css.withTransition]: withTransition })}
			/>
		</div>
	);
};
