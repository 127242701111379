import useSWR from 'swr';
import { ReturnedSWRData } from '@api/hooks/types';
import { fetcher } from '@api/utils/fetcher';
import { getDataMock, mocksMappingByUrl } from '@api/mock';

interface IUseDataProps {
	url?: string | null;
	query?: string;
}

export const getData = async (url: string) => {
	const needsMock =
		!!process.env.NEXT_PUBLIC_MOCK && mocksMappingByUrl.hasOwnProperty(url.split('?')[0]);

	if (needsMock) {
		return getDataMock(url);
	}

	return fetcher<any>(url);
};

export const useData = <T>(
	{ url, query }: IUseDataProps,
	needMock: boolean = false
): ReturnedSWRData<T> => {
	const { data, error } = useSWR(
		url ? `${url}${query ? `?${query}` : ``}` : null,
		needMock ? getDataMock : getData,
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
			revalidateOnReconnect: true,
		}
	);

	return {
		data: data,
		isLoading: !error && !data && !!url,
		isError: error && !!url,
	};
};
