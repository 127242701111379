import { useEffect, useState } from 'react';

interface Position {
	a: number | null;
	b: number | null;
	g: number | null;
}

export const useGyro = (): Position => {
	const [position, setPosition] = useState<Position>({ a: null, b: null, g: null });

	useEffect(() => {
		const setFromEvent = (e: DeviceOrientationEvent) => {
			setPosition({ a: e.alpha || 0, b: e.beta || 0, g: e.gamma || 0 });
		};

		const doe =
			typeof DeviceOrientationEvent !== 'undefined' ? (DeviceOrientationEvent as any) : undefined;

		if (typeof doe !== 'undefined') {
			if (typeof doe.requestPermission !== 'undefined') {
				doe.requestPermission().then((response: any) => {
					if (response == 'granted') {
						window.addEventListener('deviceorientation', setFromEvent);
					}
				});
			} else {
				window.addEventListener('deviceorientation', setFromEvent);
			}
		}

		return () => {
			window.removeEventListener('deviceorientation', setFromEvent);
		};
	}, []);

	return position;
};
