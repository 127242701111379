import css from './Header.module.scss';
import classnames from 'classnames';
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import type { PageMeta } from '@api/hooks/types';
import type { ScrollData } from '@utils/utils.performance/scroll';
import { Container } from '@core';
import { Logo } from './components/Logo';
import { NavBox } from './components/NavBox';
import { useHeaderData } from '@api';
import { header } from '@api/mock';
import { useScroll } from '@hooks';
import { PageLoaderContext, ViewportContext } from '@context';
import { PageTransitionContext } from '@components/Layout/components/PageTransition/PageTransition';
// import scrollLocker from '@utils/utils.scroll/ScrollLocker';

export interface HeaderProps {
	langSwitch?: PageMeta['langSwitch'];
}

export const Header: FC<HeaderProps> = React.memo(({ langSwitch }) => {
	const { data } = useHeaderData('/menu/header');

	return (
		<HeaderWrap>
			<Container className={css.container}>
				<Logo />
				<NavBox menu={data?.menu} contacts={data?.contacts} langSwitch={langSwitch} />
			</Container>
		</HeaderWrap>
	);
});

export const HeaderWrap: FC = React.memo(({ children }) => {
	const [onWhite, setOnWhite] = useState(false);
	const [isShown, setIsShown] = useState(false);

	const { bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	const { loadingState } = useContext(PageLoaderContext);
	const pageTransitionState = useContext(PageTransitionContext);

	const fsHeight = useRef(0);

	useEffect(() => {
		if (pageTransitionState === 'waiting') {
			const firstScreen = document.querySelector<HTMLElement>('.first-screen');
			const isWhite = !!firstScreen?.classList?.contains('is-white-section');

			fsHeight.current = firstScreen?.offsetHeight || 0;

			setOnWhite(isWhite);
		}
	}, [pageTransitionState]);

	const onScroll = useCallback(
		({ top: scrollTop }: ScrollData) => {
			const firstScreen = document.querySelector<HTMLElement>('.first-screen');
			const fsHeight = firstScreen?.offsetHeight || 0;

			if (fsHeight) {
				const scrollRule = scrollTop <= fsHeight - 200;
				const scrollLockRule = true; // !scrollLocker._state;
				const loadingRule = loadingState === 'complete';
				const transitionRule = true; // pageTransitionState !== 'exit' && pageTransitionState !== 'enter';

				setIsShown(scrollRule && scrollLockRule && loadingRule && transitionRule);
			}
		},
		[loadingState]
	);

	useScroll(onScroll);

	const onFocus = useCallback(() => {
		if (!isShown) {
			window.scrollTo(0, 0);
		}
	}, [isShown]);

	return (
		<header
			className={classnames(
				css.header,
				{
					[css.headerOnWhite]: onWhite,
					[css.headerShown]:
						isShown &&
						(!isMob ? pageTransitionState !== 'exit' && pageTransitionState !== 'enter' : true),
				},
				'page__header'
			)}
			onFocus={onFocus}>
			{children}
		</header>
	);
});
