import css from './PreambleMedia.module.scss';
import classnames from 'classnames';
import { InfoBlockProps } from '@components/InfoBlock/InfoBlock';
import React, { FC, useRef } from 'react';
import { PreambleMediaAbout } from '@components/PreambleMedia/components/PreambleMediaAbout/PreambleMediaAbout';
import { PreambleMediaTransition } from '@components/PreambleMedia/components/PreambleMediaTransition/PreambleMediaTransition';
import { Box } from '@core';
import { PreambleMediaAfterword } from '@components/PreambleMedia/components/PreambleMediaAfterword/PreambleMediaAfterword';

export interface PreambleMediaProps extends InfoBlockProps {
	picture?: Picture;
	video?: Video;
	afterword?: {
		title?: string;
		text?: string;
		url?: string;
	};
}

export const PreambleMedia: FC<PreambleMediaProps> = React.memo(
	({ title, subtitle, text, sentence, picture, video, afterword }) => {
		const ref = useRef<HTMLDivElement | null>(null);

		return (
			<Box ref={ref} className={classnames(css.section)}>
				<PreambleMediaAbout title={title} subtitle={subtitle} sentence={sentence} text={text} />
				<PreambleMediaTransition
					ref={ref}
					picture={picture}
					video={video}
					size={!!afterword ? 6 : 3}
				/>
				{afterword && <PreambleMediaAfterword {...afterword} />}
			</Box>
		);
	}
);
