import css from './InfoBlock.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { Section, Container, Tag, RawHtml, Text, Box, Flex } from '@core';

export interface InfoBlockProps {
	title?: string;
	subtitle?: string;
	text?: string;
	sentence?: string;
	sentenceClassName?: string;
	indent?: boolean;
	indentMargin?: boolean;
}

interface InfoBlockColumnsProps {
	left?: React.ReactNode;
	right?: React.ReactNode;
}

export const InfoBlock: FC<PropsWithChildren<InfoBlockProps & HTMLAttributes<HTMLDivElement>>> =
	React.memo(
		({
			title,
			subtitle,
			text,
			sentence,
			sentenceClassName,
			className,
			indent = true,
			indentMargin = false,
			children,
		}) => {
			return (
				<Section className={className}>
					<Container>
						{title && (
							<Tag
								className={classnames(css.title, { 'indent-margin': indent })}
								type="h2"
								visualType="h1"
								content={title}
								isHTML={true}
							/>
						)}
						{subtitle && (
							<Tag
								className={classnames(css.subtitle, {
									'indent-text': indent,
									'indent-margin': indentMargin,
								})}
								type={title ? 'h3' : 'h2'}
								visualType="h2"
								content={subtitle}
								isHTML={true}
							/>
						)}
						{sentence && (
							<Tag
								type="sentence"
								className={classnames(sentenceClassName, { 'indent-margin': indent })}
								content={sentence}
							/>
						)}
						{text && !children && (
							<Text className={css.textBox} size="md" color="gray">
								<RawHtml content={text} />
							</Text>
						)}
						{text && children && (
							<InfoBlockColumns
								left={children}
								right={
									text ? (
										<Text className={classnames('wysiwyg', css.textBox)} size="md" color="gray">
											<RawHtml content={text} />
										</Text>
									) : null
								}
							/>
						)}
					</Container>
				</Section>
			);
		}
	);

export const InfoBlockColumns: FC<InfoBlockColumnsProps> = React.memo(({ left, right }) => {
	return (
		<>
			{(left || right) && (
				<Flex className={css.columns}>
					{left && <Box className={css.left}>{left}</Box>}
					{right && <Box className={css.right}>{right}</Box>}
				</Flex>
			)}
		</>
	);
});
