import css from './DocumentPreview.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, useRef } from 'react';
import { Box, Icon, LinkTag, Tag } from '@core';
import useIntersectionObserver from '@hooks/useIntersectionObserver';
import { fullFormatDate } from '@utils';

export interface DocumentPreviewProps extends HTMLAttributes<HTMLDivElement> {
	title?: string;
	date?: string;
	url?: string;
}

export const DocumentPreview: FC<DocumentPreviewProps> = React.memo(
	({ title, date, url, className }) => {
		const ref = useRef<HTMLDivElement | null>(null);

		const entry = useIntersectionObserver(ref, {
			threshold: 0.25,
			freezeOnceVisible: true,
		});

		const fulldate = fullFormatDate(date);

		const content = (
			<Box ref={ref}>
				{title && <Tag className={css.caption} type="h3" content={title} isHTML={true} />}
				{fulldate && (
					<time className={css.date} dateTime={fulldate.datetime}>
						{fulldate.concat}
					</time>
				)}
			</Box>
		);

		const classNames = classnames(className, css.item, { [css.isVisible]: entry?.isIntersecting });

		return url ? (
			<a className={classNames} href={url} target="_blank" rel="noreferrer noopener">
				<Icon className={css.arrow} id="link" />
				{content}
			</a>
		) : (
			<Box className={classNames}>{content}</Box>
		);
	}
);
