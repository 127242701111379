import css from './TraineeshipPreamble.module.scss';
import React, { FC } from 'react';
import { InfoBlockProps, InfoBlock } from '@components/InfoBlock/InfoBlock';

interface TraineeshipPreambleProps extends InfoBlockProps {}

export const TraineeshipPreamble: FC<TraineeshipPreambleProps> = React.memo(
	({ title, subtitle, text, sentence }) => {
		return (
			<>
				<InfoBlock
					className={css.section}
					title={title}
					subtitle={subtitle}
					sentence={sentence}
					text={text}
				/>
			</>
		);
	}
);
