import css from './Footer.module.scss';
import classnames from 'classnames';
import React, { FC, useContext, useMemo } from 'react';
import Link from 'next/link';
import { Box, Container, Flex } from '@core';
import { useFooterData } from '@api';
import { footer } from '@api/mock';
import { useRouter } from 'next/router';
import { CookieConsent } from '@core/CookieConsent/CookieConsent';
import { FooterDocuments } from './FooterDocuments';
import { ViewportContext } from '@context';

type LocaleType = 'ru' | 'en';
interface FooterProps {}

const year = new Date().getFullYear();

const grecaptchaTerms: Record<LocaleType, JSX.Element> = {
	ru: (
		<>
			Все права защищены. Сайт защищен reCAPTCHA и применяются{' '}
			<a href="https://policies.google.com/privacy">Политика конфиденциальности</a>и{' '}
			<a href="https://policies.google.com/terms">Условия обслуживания</a> Google.
		</>
	),
	en: (
		<>
			All rights reserved. This site is protected by reCAPTCHA and the Google
			<a href="https://policies.google.com/privacy">Privacy Policy</a> and
			<a href="https://policies.google.com/terms">Terms of Service</a> apply.
		</>
	),
};

export const Footer: FC<FooterProps> = React.memo(() => {
	const { data } = useFooterData('/menu/footer');
	const { locale } = useRouter();

	const nav = useMemo(() => data?.navigation || [], [data]);
	const phone = useMemo(() => data?.phone, [data]);
	const email = useMemo(() => data?.email, [data]);
	const terms = useMemo(() => grecaptchaTerms[(locale || 'ru') as LocaleType], [locale]);

	const navGroups = [];

	for (let i = 0; i < nav.length; i += nav.length / 3) {
		navGroups.push(nav.slice(i, i + nav.length / 3));
	}

	const { bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	const footerDocuments = <FooterDocuments items={[data?.privacyPolicy, data?.safetyPolicy, data?.healthPolicy, data?.environmentalPolicy, data?.qualityPolicy]} />;

	return (
		<>
			<footer className={classnames(css.footer, 'page__footer')}>
				<Container>
					<Flex className={css.nav}>
						{navGroups.map((links, i) => (
							<ul key={`footer-nav-col-${i}`} className={css.menu}>
								{links.map((link, j) => {
									return (
										<li
											key={`footer-nav-col-${i}-item-${j}`}
											className={classnames({ [css.contactsLink]: link.isContact })}>
											<Link href={link.url}>
												<a {...link.props}>{link.text}</a>
											</Link>
										</li>
									);
								})}
							</ul>
						))}
						<ul className={classnames(css.menu)}>
							{phone && (
								<li className={css.contactsLink}>
									<a href={`tel:${phone.replace(/\D/gi, '')}`}>{phone}</a>
								</li>
							)}
							{email && (
								<li className={css.contactsLink}>
									<a href={`mailto:${email}`}>{email}</a>
								</li>
							)}
						</ul>
					</Flex>
					<Link href="/">
						<a className={css.logo}>
							<svg viewBox="0 0 114 24">
								<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
									<polygon
										fill="#B41E9C"
										fillRule="nonzero"
										points="0 23.5738 0 0.414062 18.1013 0.414062 18.1013 4.32736 4.7102 4.32736 4.7102 9.47308 16.2365 9.47308 16.2365 13.3864 4.7102 13.3864 4.7102 23.5738"
									/>
									<polygon
										fill="#FFFFFF"
										fillRule="nonzero"
										points="17.332 23.5738 17.332 0.414062 34.5492 0.414062 34.5492 4.32737 22.0422 4.32737 22.0422 9.47308 33.2792 9.47308 33.2792 13.3864 22.0422 13.3864 22.0422 19.6605 35.0073 19.6605 35.0073 23.5738"
									/>
									<polygon
										fill="#FFFFFF"
										fillRule="nonzero"
										points="34.3633 0.414062 34.3633 23.5738 38.7198 23.5738 38.7198 8.35271 48.1402 23.5738 52.8504 23.5738 52.8504 0.414062 48.4939 0.414062 48.4939 15.9312 38.9208 0.414062"
									/>
									<path
										d="M51.959,15.6212 L56.5406,15.6212 C56.8139,17.1417 57.3685,18.2621 58.2044,18.9743 C59.0484,19.6946 60.1737,20.0467 61.5964,20.0467 C63.1075,20.0467 64.2409,19.7346 65.0045,19.0944 C65.7681,18.4622 66.1539,17.7179 66.1539,16.8616 C66.1539,16.3174 65.9931,15.8533 65.6716,15.4612 C65.3501,15.077 64.7874,14.7489 63.9837,14.4688 C63.4451,14.2688 62.1992,13.9406 60.246,13.4525 C57.7382,12.8283 55.9779,12.06 54.9652,11.1477 C53.5424,9.87529 52.8351,8.31477 52.8351,6.47416 C52.8351,5.29777 53.1727,4.1854 53.8398,3.15305 C54.507,2.12871 55.4715,1.34445 56.7335,0.80827 C57.9954,0.264088 59.5066,0 61.299,0 C64.2087,0 66.395,0.640213 67.866,1.92864 C69.3369,3.21707 70.1085,5.0897 70.1809,7.24241 L65.4707,7.24241 C65.2697,6.04201 64.8437,5.18573 64.1846,4.66556 C63.5335,4.14538 62.5449,3.88129 61.2347,3.88129 C59.8843,3.88129 58.8233,4.16139 58.0597,4.72157 C57.5694,5.08169 57.3203,5.56186 57.3203,6.16206 C57.3203,6.71424 57.5534,7.1864 58.0196,7.57052 C58.6063,8.07469 60.0371,8.58686 62.3198,9.13104 C64.6026,9.65922 66.2825,10.2194 67.3756,10.7956 C68.4688,11.3718 69.3208,12.1561 69.9397,13.1484 C70.5586,14.1487 70.8641,15.3731 70.8641,16.8376 C70.8641,18.1661 70.4943,19.4065 69.7549,20.5669 C69.0154,21.7272 67.9705,22.5915 66.6121,23.1517 C65.2617,23.7119 63.5737,24 61.5482,24 C58.6144,24 56.3557,23.3198 54.7803,21.9673 C53.2049,20.6229 52.2644,18.1981 51.959,15.6212 Z"
										fill="#FFFFFF"
										fillRule="nonzero"
									/>
									<polygon
										fill="#FFFFFF"
										fillRule="nonzero"
										points="70.1641 23.5738 70.1641 0.414062 77.2133 0.414062 81.3849 16.2113 85.5084 0.414062 92.5737 0.414062 92.5737 23.5738 88.2252 23.5738 88.2091 5.3437 83.6195 23.5738 79.0941 23.5738 74.5286 5.3437 74.5126 23.5738"
									/>
									<path
										d="M113.609,23.5738 L108.529,23.5738 L106.528,18.3 L97.1958,18.3 L95.2587,23.5738 L90.2832,23.5738 L99.3097,0.414062 L104.285,0.414062 L113.609,23.5738 Z M105.001,14.3867 L101.769,5.81586 L98.6265,14.3867 L105.001,14.3867 Z"
										fill="#FFFFFF"
										fillRule="nonzero"
									/>
								</g>
							</svg>
						</a>
					</Link>
					<Flex className={css.bottom}>
						<Box className={css.copyright}>
							<p>
								&copy;&nbsp;2010 &mdash; {year} Fensma.
								<span className="hidden-xs hidden-sm">{terms}</span>
							</p>
							{!isMob && footerDocuments}
						</Box>
						<Box className={css.developer}>
							<a
								className={css.developerLink}
								href="https://redcollar.ru/"
								target="_blank"
								rel="noreferrer noopener">
								<svg width="27" height="12" viewBox="0 0 27 12" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M13.5265 5.95554L25.5147 0.773438L27 2.16862L17.9293 11.7854L13.4735 7.64969L9.07073 11.7854L0 2.16862L1.48526 0.773438L13.5265 5.95554Z"
										fill="#F51B1B"
									/>
								</svg>
								<span>{locale === 'en' ? 'Designed by Red Collar' : 'Cделано в Red Collar'}</span>
							</a>
						</Box>
					</Flex>
					{isMob && (
						<>
							<div className={css.terms}>{terms}</div>
							{footerDocuments}
						</>
					)}
				</Container>
			</footer>
			{data?.cookiePopupContent && <CookieConsent {...data?.cookiePopupContent} />}
		</>
	);
});
