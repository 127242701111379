import React, { FC, HTMLAttributes, useMemo } from 'react';
import { FormElement } from '@core/Form/FormElement/FormElement';
import { Input } from '@core/Form/Input/Input';
import { TextArea } from '@core/Form/Input/TextArea';
import { FormChoice } from '@core/Form/FormChoice/FormChoice';
import { FormRadiogroup } from '@core/Form/FormChoice/components/FormRadiogroup';
import { useInput } from '@core/Form/FormComponent/hooks/useInput';
import { getValidationConfig } from '@core/Form/FormComponent/utils/validation';

interface FormChildProps extends HTMLAttributes<HTMLDivElement> {
	props: FormFieldType;
}

export const FormChild: FC<FormChildProps> = ({ props, className }) => {
	const { validation, ...rest } = props;
	const { bind, error, value } = useInput<string | boolean>(props.name, {
		value: '',
		type: props.type,
		validation: getValidationConfig(props),
	});

	const child = useMemo(() => {
		switch (rest.type) {
			case 'select':
				return <></>;

			case 'radio':
				return <FormRadiogroup {...rest} value={value as string} bind={bind} />;

			case 'checkbox':
				return (
					<FormChoice {...rest} {...bind} type={rest.type as 'checkbox'}>
						{rest.label}
					</FormChoice>
				);

			case 'textarea':
				return <TextArea hasError={!!error} {...rest} {...bind} />;

			default:
				return <Input hasError={!!error} {...rest} {...bind} />;
		}
	}, [rest, bind, error, value]);

	return props ? (
		<FormElement className={className} error={error}>
			{child}
		</FormElement>
	) : null;
};
