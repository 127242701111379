import React, { FC, useContext } from 'react';
import { ViewportContext } from '@context';
import { TechPreambleMobile } from '@components/TechPreamble/components/TechPreambleMobile';
import { TechPreambleRegular } from '@components/TechPreamble/components/TechPreambleRegular';

export interface TechPreambleProps {
	text?: string;
	sentence?: string;
	ticker?: string;
}

export const TechPreamble: FC<TechPreambleProps> = React.memo((props) => {
	const { bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	return (
		<>
			{isMob && <TechPreambleMobile {...props} />}
			{!isMob && <TechPreambleRegular {...props} />}
		</>
	);
});
