import css from './BrioMRS.module.scss';
import classnames from 'classnames';
import fastdom from 'fastdom';
import React, { FC, useContext, useEffect, useRef, useState, useCallback, useMemo } from 'react';
import Image from 'next/image';
import { Section, Box, Container, Tag } from '@core';
import { useGyro } from '@hooks';
import { ViewportContext } from '@context';
import { Breakpoint, normalize, Performance } from '@utils';

interface BrioMRSProps {
	title?: string;
	project?: {
		title?: string;
		company?: string;
		dates?: string;
	};
	pictures?: Array<Picture>;
}

interface BrioMRSPicturesProps {
	mouseTarget: { x: number; y: number };
	pictures?: Array<Picture>;
}

interface BrioMRSContentProps {
	title: BrioMRSProps['title'];
	project: BrioMRSProps['project'];
}

const ease = 0.125;

export const BrioMRS: FC<BrioMRSProps> = React.memo(({ title, project, pictures }) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const { vh, vw } = useContext(ViewportContext);

	const [mouseTarget, setMouseTarget] = useState({ x: 0, y: 0 });

	useEffect(() => {
		setMouseTarget({ x: vw / 2, y: vh / 2 });
	}, [vh, vw]);

	const { a, b, g } = useGyro();

	useEffect(() => {
		if (g !== null && b !== null) {
			const x = normalize(g, 90, -90, true);
			const y = normalize((b - 90) * 2, 90, -90, true);

			setMouseTarget({ x: x * vw, y: y * vh });
		}
	}, [a, b, g, vh, vw]);

	const handleMouseMove = useCallback((e: React.MouseEvent<HTMLElement>) => {
		if (e.clientX === undefined || e.clientY === undefined) {
			return;
		}

		const rect = e.currentTarget.getBoundingClientRect();
		const x = e.clientX - rect.left;
		const y = e.clientY - rect.top;

		setMouseTarget({ x, y });
	}, []);

	return (
		<Section>
			<Box
				ref={ref}
				className={classnames(css.module, 'clip-path-wrapper')}
				onMouseMove={handleMouseMove}>
				<BrioMRSPictures mouseTarget={mouseTarget} pictures={pictures} />
				<BrioMRSContent title={title} project={project} />
			</Box>
		</Section>
	);
});

export const BrioMRSContent: FC<BrioMRSContentProps> = React.memo(({ title, project }) => {
	return (
		<Container className={css.content}>
			<Box className={css.head}>
				<span>{project?.title}</span>
				<span>{project?.company}</span>
				<span>{project?.dates}</span>
			</Box>
			{title && <Tag type="h2" content={title} isHTML={true} className={css.title} />}
		</Container>
	);
});

export const BrioMRSPictures: FC<BrioMRSPicturesProps> = React.memo(({ mouseTarget, pictures }) => {
	const { vh, vw, bp = 'hd' } = useContext(ViewportContext);
	const rectSize: Record<Breakpoint, number[]> = useMemo(
		() => ({
			xs: [146 / 2, 116 / 2],
			sm: [320 / 2, 238 / 2],
			md: [320 / 2, 238 / 2],
			lg: [320 / 2, 238 / 2],
			hd: [320 / 2, 238 / 2],
		}),
		[]
	);
	const currentSize = useMemo(() => {
		const size = rectSize[bp];
		if (bp === 'hd') {
			size[0] = vw * 0.1;
			size[1] = vw * 0.074375;
		}
		return size;
	}, [bp, vw, rectSize]);

	mouseTarget.x = Math.min(
		Math.max(mouseTarget.x, rectSize[bp][0] + 10),
		vw - rectSize[bp][0] - 10
	);
	mouseTarget.y = Math.min(
		Math.max(mouseTarget.y, rectSize[bp][1] + 10),
		vh - rectSize[bp][1] - 10
	);

	const [mouse, setMouse] = useState({ x: 0, y: 0 });
	const [clipRef, setClipRef] = useState<HTMLDivElement | null>(null);

	useEffect(() => {
		setMouse({ x: vw / 2, y: vh / 2 });
	}, [vh, vw]);

	useEffect(() => {
		const onTick = () => {
			const diffX = mouseTarget.x - mouse.x;
			const diffY = mouseTarget.y - mouse.y;

			const deltaX = Math.abs(diffX) < 0.001 ? 0 : diffX * ease;
			const deltaY = Math.abs(diffY) < 0.001 ? 0 : diffY * ease;

			if (deltaX && deltaY) {
				const nextX = mouse.x + deltaX;
				const nextY = mouse.y + deltaY;
				setMouse({ x: nextX, y: nextY });
			} else {
				setMouse(mouseTarget);
			}
		};

		Performance.addListener(onTick);

		return () => {
			Performance.removeListener(onTick);
		};
	}, [mouse.x, mouse.y, mouseTarget]);

	useEffect(() => {
		fastdom.mutate(() => {
			if (clipRef) {
				clipRef.style.clipPath = `polygon(${[
					`${mouse.x - currentSize[0]}px ${mouse.y - currentSize[1]}px`,
					`${mouse.x + currentSize[0]}px ${mouse.y - currentSize[1]}px`,
					`${mouse.x + currentSize[0]}px ${mouse.y + currentSize[1]}px`,
					`${mouse.x - currentSize[0]}px ${mouse.y + currentSize[1]}px`,
				].join(',')})`;
			}
		});
	}, [bp, clipRef, mouse, currentSize]);

	return (
		<div className={css.pictures}>
			{pictures && pictures[0] && (
				<div className={css.background}>
					<Image
						src={pictures[0].src}
						alt={pictures[0]?.alt || ''}
						layout="fill"
						quality={100}
						priority={true}
					/>
				</div>
			)}
			{pictures && pictures[1] && (
				<div ref={setClipRef} className={css.foreground}>
					<Image
						src={pictures[1].src}
						alt={pictures[1]?.alt || ''}
						layout="fill"
						quality={100}
						priority={true}
					/>
				</div>
			)}
			<div
				className={css.crossPoint}
				style={{
					left: mouse.x,
					top: mouse.y,
				}}>
				<div className={css.crossFrame}>
					<div className={classnames('corner', css.crossFrameLT)} />
					<div className={classnames('corner', css.crossFrameRT)} />
					<div className={classnames('corner', css.crossFrameLB)} />
					<div className={classnames('corner', css.crossFrameRB)} />
					<div className={css.crossLineLeft} />
					<div className={css.crossLineRight} />
					<div className={css.crossLineBottom} />
					<svg className={css.crossCenter} viewBox="0 0 19 11">
						<path d="M18.2632 10.03L9.63158 1.39844L1 10.03" />
					</svg>
				</div>
			</div>
		</div>
	);
});
