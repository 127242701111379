import css from './Feedback.module.scss';
import classnames from 'classnames';
import React, { FC, useCallback, useContext, useRef, useState } from 'react';
import { Section, Container, Box, Flex, Text, RawHtml, Tag, Button } from '@core';
import { FormComponent, FormSumbitHandlerType } from '@core/Form/FormComponent/FormComponent';
import { FormChild } from '@core/Form/FormComponent/components/FormChild/FormChild';
import { FormGroup } from '@core/Form/FormComponent/components/FormGroup/FormGroup';
import { EnvelopeView } from '@components/animations/EnvelopeView/EnvelopeView';
import { useScrollWithEase } from '@hooks';
import { ViewportContext } from '@context';
import { submitForm } from '@api/utils/submit';

interface FeedbackProps {
	title?: string;
	text?: string;
	form?: FormType;
	wrapperProps?: {
		color?: 'white' | 'dark';
	};
}

export const Feedback: FC<FeedbackProps> = React.memo(({ title, text, form, wrapperProps }) => {
	const { color = 'white' } = wrapperProps || {};

	return (
		<Section
			className={classnames(css.section, color && css[color], {
				[`is-${color}-section`]: !!color,
			})}>
			<Container>
				<FeedbackHeadline title={title} text={text} />
				<FeedbackForm form={form} />
			</Container>
		</Section>
	);
});

export const FeedbackHeadline: FC<Pick<FeedbackProps, 'title' | 'text'> & { autoplay?: boolean }> =
	React.memo(({ title, text, autoplay }) => {
		const ref = useRef<HTMLDivElement | null>(null);

		const { vh } = useContext(ViewportContext);
		const [seek, setSeek] = useState(1);

		const onScroll = useCallback(
			(current: number) => {
				const frag = vh / 4;
				const height = vh - frag;

				const target = Math.min(Math.max(current - frag, -height), height) / height;

				setSeek(Math.max(target, 0));
			},
			[vh]
		);

		useScrollWithEase(ref, onScroll, {
			ease: 0.25,
			edge: 'top',
		});

		return (
			<Flex className={css.headline}>
				<Box className={css.headlineLeft}>
					<Tag className={css.headlineTitle} type="h1" content={title} isHTML={true} />
					{text && (
						<Text className={css.headlineText} size="md" color="gray">
							<RawHtml content={text} />
						</Text>
					)}
				</Box>
				<Box ref={ref} className={css.headlineRight}>
					<EnvelopeView seek={seek} autoplay={autoplay} />
				</Box>
			</Flex>
		);
	});

export const FeedbackForm: FC<Pick<FeedbackProps, 'form'>> = React.memo(({ form }) => {
	const [isLoading, setIsloading] = useState(false);

	const handleSubmit: FormSumbitHandlerType = useCallback(
		(e, ctx) => {
			e.preventDefault();

			if (!form) return;

			const formEl = e.target as HTMLFormElement;
			const formData = new FormData(formEl);

			if (form.providedData) {
				for (const key in form.providedData) {
					if (form.providedData.hasOwnProperty(key)) {
						formData.append(key, form.providedData[key]);
					}
				}
			}

			if (ctx?.setFormState) {
				ctx.setFormState('pending');
			}

			setIsloading(true);

			/*
			 * Заголовки запроса
			 */
			const headers = new Headers();

			headers.set('Content-Type', 'multipart/form-data');
			headers.set('Accept', 'application/json');
			headers.set('type', 'formData');

			/*
			 * Проверка каптчи
			 */
			const checkCaptcha =
				ctx?.checkCaptcha ||
				(() => new Promise<string>((resolve) => setTimeout(() => resolve(''), 10)));

			checkCaptcha()
				.then((token) => {
					formData.append('recaptcha', token || '');

					/*
					 * Запрос
					 */
					submitForm({
						url: form.action,
						method: form.method,
						headers,
						body: formData,
					})
						/*
						 * Обработка ответа
						 */
						.then((response) => {
							setIsloading(false);

							switch (response?.status) {
								/*
								 * Все ок, показываем сообщение
								 */
								case 'success':
									ctx?.setFormState && ctx.setFormState('success');
									ctx?.storeResponse && ctx?.storeResponse(response);
									break;

								/*
								 * Бэк шлёт ошибки, помечаем поля
								 */
								case 'error':
									ctx?.resetCaptcha && ctx?.resetCaptcha();
									ctx?.setFormState && ctx.setFormState('reject');

									if (response?.errors) {
										Object.entries(response.errors).forEach(([name, message]) => {
											const { setError } = ctx?.store ? ctx.store[name] : { setError: null };

											if (setError) {
												setError({
													type: 'backend',
													message,
												});
											}
										});

										ctx?.setFormState && ctx.setFormState('waiting');
									}
									break;
							}
						});
				})
				.catch((error) => {
					console.warn(error);
				});
		},
		[form]
	);

	return (
		<FormComponent grcKey={form?.grcKey} onSubmit={handleSubmit}>
			<Flex className={css.formFlow}>
				{form?.items?.map((item, i) => {
					const key = `form-child-${i}`;

					switch (item.type) {
						case 'group':
							const group = item as FormGroupType;
							return <FormGroup className={css.formChild} key={key} props={group} />;

						default:
							const field = item as FormFieldType;
							return <FormChild className={css.formChild} key={key} props={field} />;
					}
				})}
			</Flex>
			<Box className={css.formFooter}>
				<Button type="submit" isLoading={isLoading}>
					{form?.button || 'Отправить'}
				</Button>
				{form?.notice && (
					<Text className={css.formFooterNotice} size="sm" color="gray">
						<RawHtml content={form.notice} />
					</Text>
				)}
			</Box>
		</FormComponent>
	);
});
