import css from './ActivityScopesRegular.module.scss';
import type { ActivityScopesProps } from '@components/ActivityScopes/ActivityScopes';
import React, { FC } from 'react';
import { CardsTicker, CardsTickerFeed, Container, Tag, TitleLink } from '@core';
import { ActivityScopesCard } from '@components/ActivityScopes/components/ActivityScopesCard/ActivityScopesCard';

export const ActivityScopesRegular: FC<ActivityScopesProps> = ({ title, titleUrl, items = [] }) => {
	return (
		<CardsTicker
			endPad={1.5}
			size={items?.length}
			className={css.module}
			before={<ActivityScopesHeading title={title} titleUrl={titleUrl} />}>
			<CardsTickerFeed items={items} ChildCard={ActivityScopesCard} />
		</CardsTicker>
	);
};

type ActivityScopesHeadingProps = Pick<ActivityScopesProps, 'title' | 'titleUrl'> & {
	className?: string;
};

export const ActivityScopesHeading: FC<ActivityScopesHeadingProps> = React.memo(
	({ title, titleUrl, className }) => {
		return (
			<Container className={className}>
				<Tag type="h1">
					<TitleLink url={titleUrl} content={title} />
				</Tag>
			</Container>
		);
	}
);
