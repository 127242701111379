import css from './Sentence.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { RawHtml } from '@core';

interface Props {
	content?: string;
}

export const Sentence: FC<PropsWithChildren<Props & HTMLAttributes<HTMLDivElement>>> = ({
	content,
	className,
}) => {
	return <RawHtml className={classnames(className, css.sentence, 'h3')}>{content}</RawHtml>;
};
