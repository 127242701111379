import css from './RulesFeed.module.scss';
import classnames from 'classnames';
import type { PageSectionProps } from '@api/hooks/types';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Heading, FilterLinks, Container, Section } from '@core';
import { useData } from '@api';
import { useFilterQuery, useReloadTimeout } from '@hooks';
import useIntersectionObserver from '@hooks/useIntersectionObserver';
import { BenefitsItemType } from '@components/BenefitsFeed/BenefitsFeed';
import { BenefitsListItem } from '@components/BenefitsList/components/BenefitsListItem';

type RuleItem = BenefitsItemType;

export interface DocumentsFeedProps extends PageSectionProps {
	title?: string;
	titleUrl?: string;
	filters: Array<FilterLinkType>;
	items?: RuleItem[];
}

const delay = 800;

export const RulesFeed: FC<DocumentsFeedProps> = React.memo(
	({ links, title, titleUrl, filters, items }) => {
		/*
		 * Data fetching
		 */
		const { query } = useFilterQuery();
		const { data: fetched, isLoading } = useData<PaginatedData<RuleItem>>({
			url: links?.self,
			query,
		});

		const [fetching, setFetching] = useState(false);

		const handleFilterClick = useCallback(() => {
			let timer = 0;
			if (ref.current) {
				setFetching(true);

				timer = window.setTimeout(() => {
					setFetching(false);
				}, 100);
			}
			return () => {
				if (timer) window.clearTimeout(timer);
			};
		}, []);

		/*
		 * Start animation
		 */
		const ref = useRef<HTMLDivElement | null>(null);
		const entry = useIntersectionObserver(ref, {
			threshold: 0.5,
			freezeOnceVisible: true,
		});

		useEffect(() => {
			if (ref?.current && entry?.isIntersecting) {
				ref.current.classList.add(css.isVisible);
			}
		}, [entry?.isIntersecting]);

		return (
			<Section ref={ref}>
				<Container>
					<Heading
						className={classnames(css.heading, 'indent-margin')}
						title={title}
						titleUrl={titleUrl}>
						{filters && filters.length && (
							<FilterLinks onClick={handleFilterClick} items={filters} />
						)}
					</Heading>
					<RulesFeedContent isLoading={isLoading || fetching} data={fetched?.data || items} />
				</Container>
			</Section>
		);
	}
);

const RulesFeedContent: FC<{
	data?: DocumentsFeedProps['items'];
	isLoading?: boolean;
}> = React.memo(({ data, isLoading }) => {
	const loading = useReloadTimeout(!!isLoading, delay);
	const [items, setItems] = useState(data);

	useEffect(() => {
		const timer = window.setTimeout(
			() => {
				setItems(data);
			},
			loading ? delay : 50
		);
		return () => {
			if (timer) window.clearTimeout(timer);
		};
	}, [loading, data]);

	return (
		<Box
			className={classnames(css.feed, { [css.isLoading]: loading }, 'indent-margin', 'is-visible')}>
			<>
				{items?.map((item, i) => (
					<BenefitsListItem key={`rules-feed-${i}-${item.title}`} index={i} {...item} />
				))}
			</>
		</Box>
	);
});
