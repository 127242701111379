import React, { FC } from 'react';
import { FooterDoc } from '@api/mock';
import css from './Footer.module.scss';

interface FooterDocumentsProps {
	items: Array<FooterDoc | undefined>;
}

export const FooterDocuments: FC<FooterDocumentsProps> = React.memo(({ items }) => {
	return (
		<ul className={css.documentLinks}>
			{items.map((item, idx) => {
				if (!item) {
					return null;
				}

				const { text, url } = item;

				return (
					<li key={text + idx}>
						<a href={url} target="_blank" rel="noreferrer noopener">
							{text}
						</a>
					</li>
				);
			})}
		</ul>
	);
});
